import { type ReactNode, type ForwardedRef, forwardRef, memo, useCallback, useMemo, useRef, useState } from 'react';
import { Box, Typography } from '@mui/joy';
import { type LexicalEditor, ParagraphNode } from 'lexical';

import { type InitialConfigType, LexicalComposer } from '@lexical/react/LexicalComposer';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalClickableLinkPlugin from '@lexical/react/LexicalClickableLinkPlugin';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { EditorRefPlugin } from '@lexical/react/LexicalEditorRefPlugin';
import { ClearEditorPlugin } from '@lexical/react/LexicalClearEditorPlugin';

import { LinkNode, AutoLinkNode } from '@lexical/link';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { ListItemNode, ListNode } from '@lexical/list';

import useMethods, { type EditorInputMethodsType } from './model/useMethods';
import useAnchor from './model/useAnchor';

import EditorTheme from './themes/EditorTheme';
import LexicalTheme from './themes/LexicalTheme';

import HtmlPlugin from './plugins/HtmlPlugin';
import FloatingMenuPlugin, { type FloatingMenuPluginMethodsType } from './plugins/FloatingMenuPlugin';
import DraggableBlockPlugin from './plugins/DraggableBlockPlugin';
import FocusAndBlurPlugin from './plugins/FocusAndBlurPlugin';
import AutoLinkPlugin from './plugins/AutoLinkPlugin';
import LinkPlugin from './plugins/LinkPlugin';

export { type EditorInputMethodsType };

export type EditorInputProps = {
  placeholder?: ReactNode;
  disabled?: boolean;
  onEnter?: () => void;
  onChange?: (value: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  isDraggable?: boolean;
  hasHeadingPanel?: boolean;
  hasListPanel?: boolean;
  hasRichPanel?: boolean;
};

const editorNodes = [HeadingNode, LinkNode, ListNode, ListItemNode, QuoteNode, AutoLinkNode, ParagraphNode];

const EditorInput = (props: EditorInputProps, forwardedRef: ForwardedRef<EditorInputMethodsType>) => {
  const { placeholder, disabled, isDraggable, onEnter, onChange, onFocus, onBlur, hasHeadingPanel, hasListPanel, hasRichPanel } = props;

  const editorRef = useRef<LexicalEditor>(null);
  const floatingMenuPluginRef = useRef<FloatingMenuPluginMethodsType>(null);

  const [isLinkEditMode, setIsLinkEditMode] = useState<boolean>(false);

  useMethods(editorRef, forwardedRef);
  const { onRef: onAnchorRef, elementRef: onAnchorElementRef } = useAnchor();

  const initialConfig: InitialConfigType = useMemo(
    () => ({
      namespace: 'LexicalEditor',
      theme: EditorTheme,
      nodes: editorNodes,
      onError: (error: Error) => {
        throw error;
      },
      editable: !disabled,
    }),
    [],
  );

  const handleChange = useCallback((html: string) => {
    onChange?.(html);
  }, []);

  return (
    <LexicalComposer initialConfig={initialConfig}>
      <LexicalTheme />
      <RichTextPlugin
        contentEditable={
          <Box
            sx={{
              border: 0,
              display: 'flex',
              position: 'relative',
              outline: 0,
              zIndex: 0,
            }}
          >
            <Box
              sx={{
                flex: 'auto',
                zIndex: 0,
              }}
              ref={onAnchorRef}
            >
              <ContentEditable className="EditorTheme__ContentEditable" style={{ outline: 'none' }} autoFocus={false} />
            </Box>
          </Box>
        }
        placeholder={
          <Typography
            sx={{
              position: 'absolute',
              userSelect: 'none',
              pointerEvents: 'none',
              color: (theme) => theme.palette.neutral['400'],
            }}
          >
            {placeholder}
          </Typography>
        }
        ErrorBoundary={LexicalErrorBoundary}
      />
      <ListPlugin />
      <LinkPlugin />
      <ClearEditorPlugin />
      <FocusAndBlurPlugin onFocus={onFocus} onBlur={onBlur} />
      <AutoLinkPlugin />
      <EditorRefPlugin editorRef={editorRef} />
      <HtmlPlugin onHtmlChanged={handleChange} />
      <LexicalClickableLinkPlugin />
      <FloatingMenuPlugin
        ref={floatingMenuPluginRef}
        isLinkEditMode={isLinkEditMode}
        setIsLinkEditMode={setIsLinkEditMode}
        isLink={isLinkEditMode}
        hasHeadingPanel={hasHeadingPanel}
        hasListPanel={hasListPanel}
        hasRichPanel={hasRichPanel}
      />
      {isDraggable && onAnchorElementRef && <DraggableBlockPlugin anchorElem={onAnchorElementRef} />}
    </LexicalComposer>
  );
};

export default memo(forwardRef(EditorInput));
