import { type SagaReturnType, takeLatest, put, cancel, delay } from 'redux-saga/effects';

import type { MaterialType, CollectionType, DocumentType } from 'app/entities';
import Alert from 'components/Alert';

import * as api from 'services/api';

import { guard } from 'utils';

import { call, select } from 'store/utils/saga/effects';
import { checkUnauthorized } from 'store/utils/credentials';
import * as userStore from 'store/nodes/user';
import * as materialStore from 'store/nodes/content';
import * as collectionStore from 'store/nodes/playlist';
import * as noteStore from 'store/nodes/note';

import * as actions from '../actions';
import * as selectors from '../selectors';

export const config = {
  action: [
    actions.loadList.type,
    actions.loadNextPage.type,
    actions.enableTempTagFilter.type,
    actions.enableFilter.type,
    actions.disableFilter.type,
    actions.toggleFilter.type,
  ],
  method: takeLatest,
};

export function* func(
  action: SagaReturnType<
    | typeof actions.loadList
    | typeof actions.loadNextPage
    | typeof actions.enableTempTagFilter
    | typeof actions.enableFilter
    | typeof actions.disableFilter
    | typeof actions.toggleFilter
  >,
) {
  const currentData = yield* select(userStore.selectors.getMy);
  if (!currentData) {
    yield put(actions.addPage([], { nextCursor: null, hasNext: false }, true));
    yield put(actions.loadNextPageDone(true));
    yield cancel();
    return;
  }

  let reload = false;
  if (action.type === actions.loadList.type || action.type === actions.loadNextPage.type) {
    reload = action.payload.reload || false;
  }
  if (
    action.type === actions.enableTempTagFilter.type ||
    action.type === actions.enableFilter.type ||
    action.type === actions.disableFilter.type ||
    action.type === actions.toggleFilter.type
  ) {
    reload = true;
  }

  const enabledFilters = yield* select(selectors.enabledFilters);
  const nextCursor = yield* select(selectors.selectLoadNextCursor(reload));

  const response = yield* call(() =>
    api.resource.user.library.feed(
      {
        cursor: nextCursor,
        pageSize: 24,
      },
      [...enabledFilters.list],
    ),
  );
  yield checkUnauthorized(response);

  if (response.error || !response.data) {
    Alert.error(response?.error?.message || 'Server error #1');
    yield put(actions.loadNextPageDone(reload));
    yield cancel();
    return;
  }

  const materials: MaterialType[] = [];
  const collections: CollectionType[] = [];
  const documents: DocumentType[] = [];
  response.data.items.forEach((item) => {
    if (guard.isMaterial(item)) {
      materials.push(item);
    }
    if (guard.isCollection(item)) {
      collections.push(item);
    }
    if (guard.isDocument(item)) {
      documents.push(item);
    }
  });

  if (materials.length > 0) {
    yield put(materialStore.actions.setItem(materials));
  }
  if (collections.length > 0) {
    yield put(collectionStore.actions.setItem({ data: collections }));
  }
  if (documents.length > 0) {
    const preparedDocuments = yield* noteStore.utils.prepareDocument(documents);
    yield put(noteStore.actions.setItem(preparedDocuments.map((data) => ({ data }))));
  }

  yield delay(10);
  yield put(actions.addPage(response.data.items, response.data.paginationInfo, reload));
  yield put(actions.loadNextPageDone(reload));
}
