import { memo, type MouseEvent, useCallback, useEffect, useRef } from 'react';
import { Box, Card, CardActions, Link, Typography } from '@mui/joy';

import { useNavigate } from 'navigation/hooks';
import { saveLastRoute } from 'utils';

import type { ItemAttribute, ItemId } from 'widgets/Copilot/store/types';

import Icon from 'ui/Icon';

type UnauthorizedProps = {
  id: ItemId;
  attribute: ItemAttribute;
  onMount?: () => void;
};

const Unauthorized = (props: UnauthorizedProps) => {
  const { id, attribute, onMount } = props;

  const navigate = useNavigate();

  const mountRef = useRef(false);

  useEffect(() => {
    if (!mountRef.current) {
      mountRef.current = true;
      onMount?.();
    }
  }, [onMount]);

  const handleClick = useCallback(
    (event: MouseEvent<HTMLAnchorElement>) => {
      event.stopPropagation();
      event.preventDefault();
      saveLastRoute();
      navigate('Auth/Start');
    },
    [navigate],
  );

  return (
    <Card
      variant="soft"
      color="neutral"
      size="md"
      sx={{
        '--Card-radius': (theme) => theme.radius.xl,
        backgroundColor: 'transparent',
        transition: '300ms background-color ease',
        '&:hover': {
          backgroundColor: (theme) => theme.palette.neutral.softBg,
          '@media (max-width: 767px)': {
            backgroundColor: 'transparent',
          },
        },
        '.MuiIconButton-root': {
          opacity: 0,
          transition: '300ms opacity ease',
        },
        '&:hover .MuiIconButton-root': {
          opacity: 1,
        },
      }}
    >
      <Typography>
        <b>Dear guest,</b> please{' '}
        <Link href="/welcome" onClick={handleClick}>
          login
        </Link>
        {' or '}
        <Link href="/welcome" onClick={handleClick}>
          register
        </Link>{' '}
        to chat here.
      </Typography>
      <CardActions sx={{ minHeight: 36, '--Card-padding': 0 }}>
        <Box display="flex" flexDirection="row" alignItems="center" gap={1} mr="auto">
          <Icon name="lightbulb-on" fw weight="light" color="icon" />
          <Typography fontSize={13} fontWeight={600}>
            Copilot answer
          </Typography>
        </Box>
      </CardActions>
    </Card>
  );
};

export default memo(Unauthorized);
