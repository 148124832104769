import type { ResultLegacy, AuthorType } from 'app/entities';
import request from 'services/api/request';

export default async (id: number): Promise<ResultLegacy<AuthorType>> => {
  const { data, error } = await request.get<AuthorType>(
    `/stack-1/author/${id}`,
  );
  if (error || !data) {
    return { data, error };
  }
  data.contentIds = [];
  return { data, error };
};
