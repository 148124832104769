import { type CSSProperties, type FC, memo, useCallback, useEffect, useState, useMemo, useRef } from 'react';
import { Box, type BoxProps, useTheme } from '@mui/joy';

import { unit } from 'utils';

import { usePictureMeta } from './model/usePictureMeta';
import { flattenStyle } from './model/flattenStyle';
import { useImage } from './model/useImage';
import Icon from 'ui/Icon';

export type ImageType = {
  id: number;
  url: string;
  width?: number | null;
  height?: number | null;
  updatedAt?: string;
  createdAt: string;
};

type PictureProps = {
  style?: CSSProperties | CSSProperties[];
  width?: number;
  height?: number;
  source?: ImageType | string | null;
  radius?: number | false;
  radiusLeft?: number | false;
  radiusRight?: number | false;
  aspectRatio?: number | 'none';
  hasBackground?: boolean;
  size?: 'small' | 'medium' | 'large' | 'original';
  onLoad?: () => void;
  onError?: () => void;
};

/**
 * Picture component displays an image with optional styling and loading/error handling.
 * @param {PictureProps} props - The props for the component.
 * @returns {JSX.Element}
 */
const Picture: FC<PictureProps> = memo((props) => {
  const { style, width, height, source, radius = 5, radiusLeft, radiusRight, aspectRatio, hasBackground, size = 'original', onLoad, onError } = props;

  const [isLoaded, setLoaded] = useState<boolean>(false);
  const [isEmpty, setEmpty] = useState<boolean>(false);

  const theme = useTheme();
  const backgroundColor = theme.palette.background.level1;

  const meta = usePictureMeta(source || '', size, width, height);

  const handleLoadEnd = useCallback(() => {
    setLoaded(true);
    setEmpty(false);
    onLoad?.();
  }, [onLoad]);

  const handleLoadError = useCallback(() => {
    setLoaded(false);
    setEmpty(true);
    onError?.();
  }, [onError]);

  useEffect(() => {
    if (!meta.url) {
      handleLoadError();
      return;
    }
    setLoaded(false);
    setEmpty(false);
  }, [handleLoadError, meta.url]);

  const containerStyle = useMemo(() => {
    const result: BoxProps['sx'] = {
      position: 'relative',
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      overflow: 'hidden',
      '& img': {
        width: '100%',
        maxHeight: '100%',
        objectFit: 'cover',
        backgroundColor: hasBackground ? backgroundColor : undefined,
        ...(typeof radius !== 'undefined' && typeof radius !== 'boolean' ? { borderRadius: unit(radius) } : undefined),
        ...(typeof radiusLeft !== 'undefined' && typeof radiusLeft !== 'boolean'
          ? { borderBottomLeftRadius: unit(radiusLeft), borderTopLeftRadius: unit(radiusLeft) }
          : undefined),
        ...(typeof radiusRight !== 'undefined' && typeof radiusRight !== 'boolean'
          ? { borderBottomRightRadius: unit(radiusRight), borderTopRightRadius: unit(radiusRight) }
          : undefined),
      },
    };
    if (aspectRatio !== 'none' && meta.ratio) {
      result.aspectRatio = meta.ratio;
    }
    if (aspectRatio !== 'none' && !meta.ratio && meta.width && meta.height) {
      result.aspectRatio = meta.width / meta.height;
    }
    if (aspectRatio !== 'none' && aspectRatio) {
      result.aspectRatio = aspectRatio;
    }
    if (width) {
      result.width = unit(width);
    }
    if (height) {
      result.height = unit(height);
    }
    if (typeof radius !== 'undefined' && typeof radius !== 'boolean') {
      result.borderRadius = unit(radius);
    }
    if (typeof radiusLeft !== 'undefined' && typeof radiusLeft !== 'boolean') {
      result.borderBottomLeftRadius = unit(radiusLeft);
      result.borderTopLeftRadius = unit(radiusLeft);
    }
    if (typeof radiusRight !== 'undefined' && typeof radiusRight !== 'boolean') {
      result.borderBottomRightRadius = unit(radiusRight);
      result.borderTopRightRadius = unit(radiusRight);
    }
    return { ...result, ...flattenStyle(style) };
  }, [aspectRatio, width, height, radius, radiusLeft, radiusRight, style, meta.ratio, meta.width, meta.height, hasBackground, backgroundColor]);

  const emptyStyle = useMemo(
    (): CSSProperties => ({
      position: 'absolute',
      alignItems: 'center',
      justifyContent: 'center',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      display: 'flex',
      ...(typeof radius !== 'undefined' && typeof radius !== 'boolean' ? { borderRadius: unit(radius) } : {}),
      ...(typeof radiusLeft !== 'undefined' && typeof radiusLeft !== 'boolean'
        ? { borderBottomLeftRadius: unit(radiusLeft), borderTopLeftRadius: unit(radiusLeft) }
        : {}),
      ...(typeof radiusRight !== 'undefined' && typeof radiusRight !== 'boolean'
        ? { borderBottomRightRadius: unit(radiusRight), borderTopRightRadius: unit(radiusRight) }
        : {}),
      backgroundColor: theme.palette.background.level2,
    }),
    [radius, radiusLeft, radiusRight, theme.palette.background.level2],
  );

  const renderEmpty = useMemo(() => {
    if (!isEmpty || isLoaded) {
      return null;
    }
    return (
      <Box sx={emptyStyle}>
        <Icon name="image-slash" weight="solid" sx={{ opacity: 0.5 }} />
      </Box>
    );
  }, [isEmpty, isLoaded, emptyStyle]);

  const containerRef = useRef<HTMLDivElement>(null);

  useImage(containerRef, { src: meta.url || '', onLoad: handleLoadEnd, onError: handleLoadError });

  return (
    <Box ref={containerRef} sx={containerStyle}>
      {renderEmpty}
    </Box>
  );
});

export default Picture;
