import { memo, useCallback, useState } from 'react';
import Box from '@mui/joy/Box';
import Avatar from 'ui/Avatar';
import Typography from '@mui/joy/Typography';

import { useDispatcher, useSelector } from 'store/utils/redux/hooks';
import * as userStore from 'store/nodes/user';
import * as playlistStore from 'store/nodes/playlist';

import PermissionSelect from 'widgets/CollectionShare/components/PermissionSelect';

import { userFullName } from 'utils';

type userListProps = {
  userId: number;
  permissionId: string;
  resourceId: number;
};

const UserList = (props: userListProps) => {
  const { userId, permissionId, resourceId } = props;
  const [permission, setPermission] = useState(permissionId);
  const user = useSelector(userStore.selectors.getById(userId));
  const myUser = useSelector(userStore.selectors.getMy);

  const dispatcher = useDispatcher();
  const isLoading = useSelector(playlistStore.selectors.isLoadingPermission(userId, permission, resourceId));

  const handleSelectChange = useCallback(
    (_: any, newValue: string) => {
      setPermission(newValue);
      if (!userId || !newValue || !resourceId) {
        return;
      }
      if (newValue === 'delete') {
        dispatcher.playlist.deletePermission({
          userId,
          collectionId: resourceId,
          permissionId,
        });
        setPermission(permissionId);
        return;
      }
      dispatcher.playlist.updatePermission({
        userId,
        permissionId: Number(newValue),
        collectionId: resourceId,
      });
    },
    [userId, resourceId, permissionId],
  );

  if (!user) {
    return null;
  }

  return (
    <Box display="flex" justifyContent="space-between">
      <Box display="flex" gap={1} ml={0.5} mr="auto" alignItems="center">
        {!!user?.photo && <Avatar src={user.photo.url} thumbnail="small" />}
        {!user?.photo && (
          <Avatar src={user?.photo?.url} thumbnail="small">
            <Typography fontSize={22}>{userFullName(user).slice(0, 1)}</Typography>
          </Avatar>
        )}
        <Typography fontSize={15} fontWeight={500}>
          {`${user?.name || ''} ${user?.surname}` || 'User'}
        </Typography>
      </Box>
      <PermissionSelect disabled={userId === myUser?.id} isLoading={isLoading} hasDelete value={permission} onChange={handleSelectChange} />
    </Box>
  );
};

export default memo(UserList);
