import { type FilterItemType } from 'app/entities';

export default (filters?: FilterItemType[]) => {
  const result: Record<string, string> = {};
  if (!filters) {
    return result;
  }
  filters.forEach((item) => {
    Object.entries(item.query).forEach(([property, newItems]) => {
      const oldItems = result[property] ? (result[property] || '').split(',') : [];
      result[property] = [...new Set([...oldItems, ...newItems])].join(',');
      if (result[property] === '') {
        delete result[property];
      }
    });
  });
  return result;
};
