import { type FC, memo, useCallback, useMemo } from 'react';
import { type BoxProps, Typography } from '@mui/joy';
import { useSelector } from 'react-redux';
import { createUseStyles } from 'react-jss';
import { isEqual } from 'lodash';
import { useRoute } from 'navigation/hooks';

import { useDispatcher } from 'store/utils/redux/hooks';
import * as noteStore from 'store/nodes/note';
import { type CopilotAvailableContextsType } from 'store/nodes/note/slice';
import * as copilotStore from 'widgets/Copilot/store';

import DropdownMenu from 'ui/DropdownMenu';

const ContextSelect: FC<Pick<BoxProps, 'm' | 'mx' | 'my' | 'mt' | 'mr' | 'mb' | 'ml'>> = (props) => {
  const route = useRoute<'Note'>();
  const { ...rest } = props;
  const id = route.params?.id === 'create' ? 'create' : Number(route.params?.id);

  const dispatcher = useDispatcher();
  const classes = useStyles();

  const selectedContext = useSelector(noteStore.selectors.selectedContext);
  const availableContexts = useSelector(noteStore.selectors.availableContexts);
  const isShow = useSelector(copilotStore.selectors.rightBarIsShow);
  const isAnswerTyping = useSelector(copilotStore.selectors.isAnswerTyping('*'));

  const options = useMemo(() => {
    const map = {
      note: 'Note',
      'library-fulltext': 'Library',
    };
    return availableContexts.map((value) => ({
      value,
      label: map[value] || value,
      disabled: id === 'create' && ['note']?.includes(value),
    }));
  }, [availableContexts]);

  const handleChange = useCallback((value: CopilotAvailableContextsType) => {
    dispatcher.note.setSelectedCopilotContext({ value });
  }, []);

  return (
    <>
      {isShow && selectedContext && (
        <DropdownMenu
          disabled={isAnswerTyping}
          options={options}
          value={selectedContext}
          onChange={handleChange}
          labelPrefix={
            <Typography component="span" className={classes.labelPrefix} fontSize={16} fontWeight="400" sx={{ color: 'inherit' }}>
              Context:&nbsp;
            </Typography>
          }
          {...rest}
        />
      )}
    </>
  );
};

const useStyles = createUseStyles({
  labelPrefix: {
    '@media (max-width: 1280px)': {
      '&.MuiTypography-root': {
        display: 'none',
      },
    },
  },
});

export default memo(ContextSelect, (prevProps, nextProps) => isEqual(prevProps, nextProps));
