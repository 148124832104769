import { memo, useCallback } from 'react';
import { StyleSheet } from 'react-native';

import { useSelector } from 'store/utils/redux/hooks';
import * as userStore from 'store/nodes/user';

import MenuButton from 'components/UIBar/elements/MenuButton';
import { useNavigate } from 'navigation/hooks';
import Picture from 'components/Picture';

type ThemeSwitcherProps = {
  isSlim?: boolean;
};

const Settings = (props: ThemeSwitcherProps) => {
  const { isSlim } = props;
  const navigate = useNavigate();

  const userProfile = useSelector(userStore.selectors.getMy);

  const handleProfile = useCallback(() => {
    navigate('Profile', { login: 'my' });
  }, []);

  if (!userProfile) {
    return null;
  }

  return (
    <>
      <MenuButton
        style={styles.colorSchemeItem}
        onPress={handleProfile}
        text="My profile"
        isSlim={isSlim}
        isSelected={false}
        revertSlim
        iconRight={<Picture width={24} height={24} radius={12} source={userProfile?.photo} size="small" aspectRatio={1} />}
      />
    </>
  );
};

const styles = StyleSheet.create({
  colorSchemeItem: {
    justifyContent: 'space-between',
  },
});

export default memo(Settings);
