import { createSelector } from '@reduxjs/toolkit';
import { type DefaultRootState } from 'react-redux';
import md5 from 'blueimp-md5';

const selector = createSelector(
  (state: DefaultRootState) => state.searchModal.filter,
  (state: DefaultRootState) => state.searchModal.list.meta,
  (filter, meta) => ({
    isConsistent:
      meta.resultWithFilterHash ===
      md5(
        JSON.stringify({
          text: filter.text,
          type: filter.type.value,
          tags: filter.tags,
        }),
      ),
    firstPage: {
      isLoading: meta.isFirstPageLoading,
      isLoaded: meta.isFirstPageLoaded,
    },
    nextPage: {
      isLoading: meta.isNextPageLoading,
      isLoaded: meta.isNextPageLoaded,
    },
  }),
);

export default (state: DefaultRootState) => selector(state);
