import { memo, useCallback } from 'react';
import { IconButton, Tooltip } from '@mui/joy';

import Icon from 'ui/Icon';
import { useRoute } from 'navigation/hooks';
import { useDispatcher } from 'store/utils/redux/hooks';

const PlusButton = () => {
  const route = useRoute();
  const dispatcher = useDispatcher();

  const handlePlusOpen = useCallback(() => {
    if (route.isScreen('Playlist') && route.params && 'resourceId' in route.params && route.params.resourceId) {
      dispatcher.plus.open({
        toCollection: { id: Number(route.params.resourceId), append: true },
      });
      return;
    }
    dispatcher.plus.open();
  }, []);

  return (
    <Tooltip arrow variant="outlined" size="sm" title="Add to library" disableInteractive>
      <IconButton size="sm" color="primary" variant="solid" onClick={handlePlusOpen}>
        <Icon name="plus" size="1x" fw weight="regular" color="#ffffff" />
      </IconButton>
    </Tooltip>
  );
};

export default memo(PlusButton);
