import { type DefaultRootState } from 'react-redux';
import { createCachedSelector } from 're-reselect';

type Result<ValueType> = {
  options: string[];
  value: ValueType;
  meta: {
    isLoading: boolean;
    isLoaded: boolean;
  };
};

const selector = createCachedSelector(
  (state: DefaultRootState, filterName: 'type') => state.content.list.filters[filterName],
  (state: DefaultRootState) => state.content.list.meta,
  (_, __, defaultValue: any) => defaultValue,
  (filter, meta, defaultValue) => {
    return {
      options: filter.options,
      value: filter.value || defaultValue,
      meta: {
        isLoading: meta.isFiltersLoading,
        isLoaded: meta.isFiltersLoaded,
      },
    };
  },
)((state, filterName, defaultValue) => `${filterName}-${defaultValue}`);

export const filter =
  <ValueType = string | number | boolean>(filterName: 'type', defaultValue: ValueType) =>
  (state: DefaultRootState) =>
    selector(state, filterName, defaultValue) as Result<ValueType>;
