import type { LexicalEditor, LexicalNode } from 'lexical';

const scrollToNode = (editor: LexicalEditor, node: LexicalNode) => {
  if (!node) {
    return;
  }
  const domElement = editor.getElementByKey(node.getKey());
  if (!domElement) {
    return;
  }
  domElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
};

export default scrollToNode;
