import { createAction } from '@reduxjs/toolkit';

import type { AuthorType } from 'app/entities';

import capitalize from 'utils/capitalize';

export const loadProfile = createAction('AUTHOR/LOAD_PROFILE', (id: number) => ({
  payload: {
    id,
    optimistic: false,
  },
}));

export const loadProfileDone = createAction('AUTHOR/LOAD_PROFILE_DONE', (id: number) => ({
  payload: {
    id,
  },
}));

export const setProfile = createAction('AUTHOR/SET_PROFILE', (data: AuthorType | AuthorType[]) => {
  const dataFinal = (Array.isArray(data) ? data : [data]).map((item) => ({
    ...item,
    name: capitalize(item?.name || ''),
    surname: capitalize(item?.surname || ''),
    contentIds: item?.contentIds || [],
  }));
  return {
    payload: {
      data: dataFinal,
    },
  };
});
