import type { ResultLegacy, JobType, JobInput } from 'app/entities';
import request from 'services/api/request';

export default async (
  params: Omit<JobInput, 'id'>,
): Promise<ResultLegacy<JobType>> => {
  const { data, error } = await request.post<JobType>('/stack-1/user/jobs', {
    body: params,
  });
  return { data, error };
};
