import { memo } from 'react';
import { Box, useTheme } from '@mui/joy';

const Divider = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        width: 0,
        borderLeft: `1px solid ${theme.palette.divider}`,
        mx: 0.5,
      }}
    />
  );
};

export default memo(Divider);
