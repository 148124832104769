import { memo, useCallback, useMemo } from 'react';
import { Box, Button, Sheet, Typography } from '@mui/joy';

import { useDispatcher, useSelector } from 'store/utils/redux/hooks';
import { selectors } from './store';

import useSubscriptionConfig from './model/useSubscriptionConfig';

const Banner = () => {
  const dispatcher = useDispatcher();
  const currentPlan = useSelector(selectors.currentPlan);
  const { banners } = useSubscriptionConfig();

  const handleUpgrade = useCallback(() => {
    dispatcher.subscription.showPricing();
  }, []);

  const items = useMemo(() => banners.filter((item) => item.showFor.includes(currentPlan?.id || '')), [banners, currentPlan?.id]);

  if (!currentPlan || !currentPlan.isLoaded || currentPlan.id === null || items.length === 0) {
    return null;
  }

  return (
    <Box component="div" ml={1.125} mr={1.5} my={1.75} display="flex" flexDirection="column" gap={2}>
      {items.map((item) => (
        <Sheet
          key={item.id}
          variant="soft"
          sx={{
            borderRadius: (theme) => parseFloat(theme.radius.md) * 1.25,
            p: 1.125,
          }}
        >
          <Typography color="neutral" level="body-md">
            <span dangerouslySetInnerHTML={{ __html: item.title }} />
          </Typography>
          <Typography color="neutral" level="body-xs" mt={0.875}>
            <span dangerouslySetInnerHTML={{ __html: item.description }} />
          </Typography>
          <Box
            mt={1}
            sx={{
              '--variant-solidBg': (theme) => theme.palette.background.body,
              '--variant-solidHoverBg': (theme) => theme.palette.background.level2,
              '--variant-solidActiveBg': (theme) => theme.palette.background.level3,
            }}
          >
            <Button
              variant="solid"
              color="neutral"
              sx={{
                fontSize: 16,
                fontWeight: 300,
                borderRadius: (theme) => theme.radius.md,
                color: 'text.primary',
                py: 0.5,
                minHeight: 24,
              }}
              onClick={handleUpgrade}
            >
              {item.buttonLabel}
            </Button>
          </Box>
        </Sheet>
      ))}
    </Box>
  );
};

export default memo(Banner);
