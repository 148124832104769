import { type SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';

import { useOnInputEnter } from 'hooks';

import { useDispatcher, useSelector } from 'store/utils/redux/hooks';
import * as userStore from 'store/nodes/user';
import * as onboardingStore from 'widgets//Onboarding/store';

type FormValues = {
  photo: string | null;
  name: string | null;
  login: string | null;
  location: string | null;
};

const validate = (values: Partial<FormValues>): { isValid: boolean; errors: Partial<FormValues> } => {
  let isValid = true;
  const errors: Partial<FormValues> = {};
  if (!values?.name) {
    isValid = false;
    if (values?.name !== null) {
      errors.name = 'required';
    }
  }
  if (!values?.login) {
    isValid = false;
    if (values?.login !== null) {
      errors.login = 'required';
    }
  }
  return {
    isValid,
    errors,
  };
};

const useSetUpState = () => {
  const dispatcher = useDispatcher();
  const userProfile = useSelector(userStore.selectors.getByLogin('my'));
  const isLoading = useSelector(onboardingStore.selectors.isLoading);

  const [photo, setPhoto] = useState<string | undefined>(undefined);
  const [name, setName] = useState<string | null>(null);
  const [login, setLogin] = useState<string | null>(null);

  const validation = useMemo(() => validate({ name, login }), [name, login]);

  useEffect(() => {
    if (userProfile?.login && userProfile?.name) {
      setLogin(userProfile?.login);
      setName(userProfile?.name);
    }
    if (userProfile?.photo?.url) {
      setPhoto(userProfile?.photo?.url);
    }
  }, [userProfile]);

  const handleChangeName = useCallback((event: { target: { value: SetStateAction<string | null> } }) => {
    setName(event.target.value);
  }, []);

  const handleChangeLogin = useCallback((event: { target: { value: SetStateAction<string | null> } }) => {
    setLogin(event.target.value);
  }, []);

  const handleSubmit = useCallback(() => {
    if (!validation.isValid) {
      return;
    }
    dispatcher.onboarding.setupProfile({
      name,
      photo,
      login,
    });
  }, [dispatcher.onboarding, validation.isValid, name, photo, login]);

  const { onKeyDown } = useOnInputEnter(
    useCallback(() => {
      handleSubmit();
    }, [handleSubmit]),
  );

  return useMemo(
    () => ({
      photo,
      name,
      login,
      validation,
      onChangeName: handleChangeName,
      onChangePhoto: setPhoto,
      onChangeLogin: handleChangeLogin,
      onSubmit: handleSubmit,
      onKeyDown,
      isLoading,
    }),
    [photo, name, login, validation, handleChangeName, setPhoto, handleChangeLogin, handleSubmit, onKeyDown, isLoading],
  );
};

export default useSetUpState;
