import { useMemo, useRef, useState } from 'react';
import * as uuid from 'uuid';

type Nullable<T> = { [K in keyof T]: T[K] | null };

type FormStore<FormData> = {
  set: (...args: [fields: FormData] | [field: keyof FormData, value: string | null]) => FormStore<FormData>;
  get: (field: string) => string | null;
  getValues: () => FormData;
};

const useFormStore = <FormData = Record<string, string | null>>() => {
  const [id, setId] = useState<string>(uuid.v4());
  const data = useRef<any>({});

  const store = useMemo(
    (): FormStore<FormData> => ({
      set: (...args: [fields: FormData] | [field: keyof FormData, value: string | null]) => {
        if (args.length === 1) {
          data.current = {};
          Object.entries((args as any)[0]).forEach(([key, value]) => {
            data.current[key] = value || null;
          });
        }
        if (args.length === 2) {
          const [field, value] = args;
          data.current[field] = value || null;
        }
        setId(uuid.v4());
        return store;
      },
      get: (field: string) => data.current?.[field],
      getValues: () => ({ ...data.current }),
    }),
    [],
  );

  const values = useMemo(() => ({ ...data.current }), [id]);

  return [store, values];
};

export default useFormStore;
