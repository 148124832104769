import { Platform } from 'react-native';
import 'react-native-get-random-values';
import App from './src';

if (Platform.OS === 'web' && typeof document !== 'undefined') {
  // <script src="https://kit.fontawesome.com/42011962da.js" crossOrigin="anonymous"></script>
  const script = document.createElement('script');
  script.src = 'https://kit.fontawesome.com/42011962da.js';
  script.crossOrigin = 'anonymous';
  document.head.appendChild(script);

  const googleFontsLink = document.createElement('link');
  googleFontsLink.rel = 'preconnect';
  googleFontsLink.href = 'https://fonts.googleapis.com';
  document.head.appendChild(googleFontsLink);

  const googleFontsLink2 = document.createElement('link');
  googleFontsLink2.rel = 'preconnect';
  googleFontsLink2.href = 'https://fonts.gstatic.com';
  googleFontsLink2.crossOrigin = 'anonymous';
  document.head.appendChild(googleFontsLink2);

  const googleFontsStyle = document.createElement('link');
  googleFontsStyle.rel = 'stylesheet';
  googleFontsStyle.href =
    'https://fonts.googleapis.com/css2?family=IBM+Plex+Serif:ital,wght@0,200;0,300;0,400;0,500;0,600;' +
    '0,700;1,200;1,300;1,400;1,500;1,600;1,700&family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap';
  document.head.appendChild(googleFontsStyle);

  const robotoStyle = document.createElement('link');
  robotoStyle.rel = 'stylesheet';
  robotoStyle.href = 'https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap';
  document.head.appendChild(robotoStyle);
}

const isTestDomain = typeof document.location !== 'undefined' && document.location.host === 'test.iki.ai';

if (Platform.OS === 'web' && isTestDomain) {
  const script = document.createElement('script');
  script.id = 'cookieyes';
  script.src = 'https://cdn-cookieyes.com/client_data/5b737b8e24b38c370d6c61db/script.js';
  document.head.appendChild(script);
  const style = document.createElement('style');
  style.innerText = `
    [data-cky-tag="revisit-consent"] {
      display: none!important;
    }
  `;
  document.head.appendChild(style);
}

export default App;
