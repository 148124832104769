import { type SagaReturnType, takeEvery, put, cancel } from 'redux-saga/effects';

import { select, call } from 'store/utils/saga/effects';

import * as api from 'services/api';

import Alert from 'components/Alert';

import * as actions from '../actions';
import * as selectors from '../selectors';

export const config = {
  action: actions.noteUpdate.type,
  method: takeEvery,
};

export function* func(action: SagaReturnType<typeof actions.noteUpdate>) {
  const { id, resourceId, text } = action.payload;

  if (!resourceId) {
    yield put(actions.noteUpdateDone(resourceId, id));
    yield cancel();
    return;
  }

  const material = yield* select(selectors.dataById(resourceId));
  if (!material) {
    yield put(actions.noteUpdateDone(resourceId, id));
    yield cancel();
    return;
  }

  const { data, ...response } = yield* call(() => api.resource.content.note.set(resourceId, text));
  const error = Array.isArray(response.error) ? response.error?.[0] || null : response.error;
  if (error || !data) {
    yield put(actions.noteUpdateDone(resourceId, id));
    Alert.error(error?.message);
    yield cancel();
    return;
  }

  yield put(actions.noteUpdateDone(resourceId, id));

  yield put(actions.loadById(resourceId));
}
