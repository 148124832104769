import { useCallback, useMemo } from 'react';

import { useDispatcher } from 'store/utils/redux/hooks';
import type { ResourceType } from 'widgets/Copilot/store/types';

export default (resourceType: ResourceType, resourceId?: number | null) => {
  const dispatcher = useDispatcher();

  const sendQuestion = useCallback(
    (text: string) => {
      if (!text) {
        return;
      }
      dispatcher.copilot.sendQuestion({
        resourceType,
        resourceId: resourceId || null,
        text,
      });
    },
    [resourceType, resourceId],
  );

  const sendCommand = useCallback(
    (label?: string, text?: string) => {
      if (!label || !text) {
        return;
      }
      dispatcher.copilot.sendCommand({
        resourceType,
        resourceId: resourceId || null,
        label,
        text,
      });
    },
    [resourceType, resourceId],
  );

  const stop = useCallback(() => {
    dispatcher.copilot.stop();
  }, []);

  const answerReload = useCallback((requestId: string) => {
    dispatcher.copilot.reloadAnswer({ requestId });
  }, []);

  const answerSaveToNote = useCallback((requestId: string) => {
    dispatcher.copilot.saveAnswerToNote({ requestId });
  }, []);

  return useMemo(
    () => ({
      sendQuestion,
      sendCommand,
      stop,
      answerReload,
      answerSaveToNote,
    }),
    [sendQuestion, sendCommand, stop, answerReload, answerSaveToNote],
  );
};
