import { ArrayQueue, Websocket, WebsocketBuilder as WSBuilder, WebsocketEvent as WSEvent } from 'websocket-ts';

import credentials from 'services/api/credentials';
import EventEmitter, { type EmitterSubscription } from 'lib/EventEmitter';

import getAppId from 'utils/getAppId';
import getSessionId from 'utils/getSessionId';

import log from 'store/nodes/socket/model/log';

type EventMap = {
  message: (event: MessageEvent) => void;
  open: () => void;
  close: () => void;
  disconnect: () => void;
  ping: () => void;
  error: () => void;
};

type OptionsType = {
  pingInMs?: number | undefined;
};

class Socket {
  private readonly emitter = new EventEmitter();

  private socket: Websocket | null = null;

  private pingInterval: number | null = null;

  private options: OptionsType = {};

  private handleSocketOpen = () => {
    log('socket.handleSocketOpen()');
    this.startPingCircle();
    this.emitter.emit('open', {});
  };

  private handleSocketClose = (_: Websocket, event: CloseEvent) => {
    log('socket.handleSocketClose()');
    if (!this.socket) {
      return;
    }
    this.stopPingCircle();
    this.socket.removeEventListener(WSEvent.open, this.handleSocketOpen);
    this.socket.removeEventListener(WSEvent.close, this.handleSocketClose);
    this.socket.removeEventListener(WSEvent.message, this.handleMessage);
    this.socket.removeEventListener(WSEvent.error, this.handleSocketError);
    if (event.reason === 'by user') {
      this.emitter.emit('close', {});
    } else {
      this.emitter.emit('disconnect', {});
    }
    this.socket = null;
  };

  private startPingCircle = () => {
    if (!('pingInMs' in this.options) || typeof this.options.pingInMs === 'undefined') {
      return;
    }
    this.stopPingCircle();
    log('socket.startPingCircle()');

    const ping = () => {
      if (this.socket && this.socket.readyState === WebSocket.OPEN && navigator.onLine) {
        this.socket.send(
          JSON.stringify({
            action: 'ping',
            message: 'ping',
            isAuthorized: !!credentials.sessionId(),
            userId: credentials.sessionId() || credentials.getAnonymousId(),
            appId: getAppId(),
            sessionId: getSessionId(),
          }),
        );
        this.emitter.emit('ping', {});
      } else {
        log('socket is not open or offline, cannot send ping');
      }
    };

    setTimeout(ping, 10);
    this.pingInterval = setInterval(ping, this.options.pingInMs) as unknown as number;
  };

  private stopPingCircle = () => {
    log('socket.stopPingCircle()');
    if (this.pingInterval === null) {
      return;
    }
    clearInterval(this.pingInterval);
    this.pingInterval = null;
  };

  private handleMessage = (_: Websocket, event: MessageEvent) => {
    this.emitter.emit('message', event);
  };

  private handleSocketError = (_: Websocket) => {
    log('socket.handleSocketError()');
    this.emitter.emit('error', {});
    this.close();
  };

  public isConnected() {
    return this.socket !== null;
  }

  public open(options?: OptionsType) {
    log('socket.open()');
    if (this.isConnected()) {
      return true;
    }

    this.options = { ...options };

    const accessToken = credentials.getAccess() || credentials.getAnonymousId();
    if (!accessToken) {
      return false;
    }

    const url = `${process.env.API_WEBSOCKET}?token=${accessToken}&app_id=${getAppId()}&session_id=${getSessionId()}`;

    this.socket = new WSBuilder(url).withBuffer(new ArrayQueue()).build();
    this.socket.addEventListener(WSEvent.open, this.handleSocketOpen);
    this.socket.addEventListener(WSEvent.close, this.handleSocketClose);
    this.socket.addEventListener(WSEvent.message, this.handleMessage);
    this.socket.addEventListener(WSEvent.error, this.handleSocketError);
    return true;
  }

  public close() {
    log('socket.close()');
    if (!this.socket) {
      return;
    }
    this.socket.close(1000, 'by user');
    this.socket = null;
  }

  public addListener<T extends keyof EventMap>(type: T, listener: EventMap[T]): EmitterSubscription {
    return this.emitter.addListener(type, listener);
  }
}

export default Socket;
