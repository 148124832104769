import { useMemo } from 'react';

import type { ResourceType } from 'app/entities';
import { guard } from 'utils';

const usePosterSources = (data?: ResourceType | null) =>
  useMemo(() => {
    if (guard.isCollection(data)) {
      if (data?.cover) {
        return [data?.cover];
      }
      if (!data?.cover) {
        return data?.contentImages || [];
      }
      return [];
    }
    if (guard.isMaterial(data)) {
      if (data.image) {
        return [data.image.url];
      }
      return [];
    }
    if (guard.isNote(data)) {
      return [];
    }
    if (guard.isDocument(data)) {
      return [];
    }
    return [];
  }, [data]);

export default usePosterSources;
