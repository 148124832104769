import { debounce, put, cancel } from 'redux-saga/effects';

import { select } from 'store/utils/saga/effects';

import * as actions from '../actions';
import * as selectors from '../selectors';

export const config = {
  action: [actions.setFilterType.type],
  method: debounce.bind(null, 50),
};

export function* func() {
  const listMeta = yield* select(selectors.listMeta);

  if (listMeta.isConsistent) {
    yield cancel();
    return;
  }

  yield put(actions.loadNextPage(true));
}
