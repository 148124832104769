import { cancel, put, type SagaReturnType, takeEvery } from 'redux-saga/effects';

import { call, select } from 'store/utils/saga/effects';
import * as userStore from 'store/nodes/user';

import network from 'lib/network';

import { actions } from '../slice';
import type { StateType, AppSumoStateType, SourceType } from '../../types';

export const config = {
  action: [actions.loadState.type],
  method: takeEvery,
};

const loadStatus = async (): Promise<StateType | 'reset' | null> => {
  const { errors, data } = await network
    .request<StateType>('/stack-1/payment/subscription', {
      baseUrl: process.env.PROTECTED_API_URL || '/api',
    })
    .get();
  if ((errors?.length || 0) > 0 || !data) {
    return 'reset';
  }
  return {
    status: data?.status || null,
    plan: `${data?.plan || 0}`,
    startDate: data?.startDate || null,
    expirationDate: data?.expirationDate || null,
    hasPaymentMethod: data?.hasPaymentMethod || null,
    canceledAt: data?.canceledAt || null,
    cancelAt: data?.cancelAt || null,
  };
};

const loadAppSumoStatus = async (): Promise<StateType | 'reset' | null> => {
  const { errors, data } = await network.request<AppSumoStateType>('/stack-1/licensing/subscription').get();
  if ((errors?.length || 0) > 0 || !data) {
    return 'reset';
  }
  return {
    status: !data?.test && data.event !== 'diactivate' ? 'active' : null,
    plan: `${data.tier}`,
    startDate: null,
    expirationDate: null,
    hasPaymentMethod: null,
    canceledAt: null,
    cancelAt: null,
  };
};

export function* func(action: SagaReturnType<typeof actions.loadState>) {
  const user = yield* select(userStore.selectors.getByLogin('my'));
  let state: StateType | 'reset' | null = null;
  let source: SourceType = 'stripe';
  if (user?.isAppsumo) {
    state = yield* call(() => loadAppSumoStatus());
    source = 'appsumo';
  } else {
    state = yield* call(() => loadStatus());
  }

  if (!state || state === 'reset') {
    yield put(actions.loadStateDone({ source, state: 'reset' }));
    yield cancel();
    return;
  }

  yield put(actions.loadStateDone({ source, state }));
}
