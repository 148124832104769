import { type ChangeEvent, memo } from 'react';
import { Switch, Typography } from '@mui/joy';

type SwitchPrivacyProps = {
  value: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

const SwitchPrivacy = (props: SwitchPrivacyProps) => {
  const { value, onChange } = props;

  return (
    <Typography component="label" fontWeight={300} fontSize={13} startDecorator={<Switch checked={value} onChange={onChange} />} sx={{ cursor: 'pointer', userSelect: 'none' }}>
      {value ? 'Private on' : 'Private off'}
    </Typography>
  );
};

export default memo(SwitchPrivacy);
