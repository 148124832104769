import { type ReactElement, memo, useCallback, useState } from 'react';
import { Box, IconButton, Tooltip, Typography } from '@mui/joy';

import { useNavigate } from 'navigation/hooks';

import { useDispatcher, useSelector } from 'store/utils/redux/hooks';
import * as userStore from 'store/nodes/user';
import * as authorStore from 'store/nodes/author';

import dialog from 'components/Dialog';
import Icon from 'ui/Icon';

import ContentSwiper from './ui/ContentSwiper';

const defaultProps = {
  editable: false,
};

type PlaylistsProps = {
  userLogin?: string;
  authorId?: number;
  editable?: boolean;
} & typeof defaultProps;

const Contents = (props: PlaylistsProps): ReactElement | null => {
  const { userLogin, authorId, editable } = props;

  const [isEditable, setEditable] = useState(false);

  const navigate = useNavigate();
  const dispatcher = useDispatcher();

  const profile = useSelector((state) => {
    if (userLogin) {
      return userStore.selectors.getByLogin(userLogin)(state);
    }
    if (authorId) {
      return authorStore.selectors.getById(authorId)(state);
    }
    return null;
  });

  const handleEditStart = useCallback(() => {
    setEditable(true);
  }, []);

  const handleEditCancel = useCallback(() => {
    setEditable(false);
  }, []);

  if (!profile || !profile.contentIds || !profile.contentIds.length) {
    return null;
  }

  return (
    <Box>
      <Box display="flex" flexDirection="row" alignItems="center">
        <Typography fontSize={20} fontWeight="bolder" flex={1}>
          Added
        </Typography>
        {userLogin === 'my' && editable && (
          <Tooltip arrow variant="outlined" size="sm" title="Edit material list">
            <IconButton onClick={!isEditable ? handleEditStart : handleEditCancel}>
              <Icon size="xl" name="gear" fw weight="light" color="primary" />
            </IconButton>
          </Tooltip>
        )}
      </Box>
      <ContentSwiper isEditable={isEditable} contentIds={profile.contentIds} />
    </Box>
  );
};

Contents.defaultProps = defaultProps;

export default memo(Contents);
