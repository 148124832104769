import { memo, type MouseEvent, type KeyboardEvent, type FocusEvent } from 'react';
import CircularProgress from '@mui/joy/CircularProgress';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';

import SelectInput from 'widgets/CollectionShare/ui/SelectInput';

import { useSelector } from 'store/utils/redux/hooks';
import * as playlistStore from 'store/nodes/playlist';

type PermissionSelectProps = {
  value?: string | number;
  placeholder?: string;
  onChange?: (event: MouseEvent | KeyboardEvent | FocusEvent | null, value: string) => void;
  hasDelete?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
};

const PermissionSelect = (props: PermissionSelectProps) => {
  const { hasDelete, value, onChange, disabled, placeholder, isLoading } = props;
  const selectPermissions = useSelector(playlistStore.selectors.selectPermissions());

  let options = selectPermissions.map((option: { name: string; id: { toString: () => any } }) => ({
    label: option.name.toLowerCase(),
    value: option.id.toString(),
  }));

  if (hasDelete) {
    options = [...options, { label: '', value: 'divider' }, { label: 'delete', value: 'delete' }];
  }

  if (isLoading) {
    return (
      <Box flexDirection="row" display="flex" marginRight={0.5} height={40} justifyContent="center" alignItems="center">
        <Typography color="neutral" fontSize={16} sx={{ mr: 2 }}>
          applying
        </Typography>
        <CircularProgress size="sm" />
      </Box>
    );
  }

  return <SelectInput value={value} onChange={onChange} disabled={disabled} options={options} placeholder={placeholder} />;
};

export default memo(PermissionSelect);
