const map = {
  collection: 'PLAYLIST',
  Collection: 'PLAYLIST',
  material: 'CONTENT',
  Material: 'CONTENT',
  note: 'NOTE',
  Note: 'NOTE',
  document: 'DOCUMENT',
  Document: 'DOCUMENT',
  'new-note': 'NOTE',
};

const getOldResourceType = (type: keyof typeof map) => map[type];

export default getOldResourceType;
