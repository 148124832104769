import { type DefaultRootState } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';

const selector = createSelector(
  (state: DefaultRootState) => state.library.list.sequence,
  (state: DefaultRootState) => state.library.list.paginationInfo,
  (sequence, paginationInfo) => ({
    sequence,
    paginationInfo,
  }),
);

export default (state: DefaultRootState) => selector(state);
