import { type SagaReturnType, takeEvery, cancel } from 'redux-saga/effects';

import { call } from 'store/utils/saga/effects';
import { request } from 'services/api';
import stripTags from 'utils/stripTags';

import * as actions from '../actions';

export const config = {
  action: actions.update.type,
  method: takeEvery,
};

export function* func(action: SagaReturnType<typeof actions.update>) {
  const items = !Array.isArray(action.payload.data) ? [action.payload.data] : action.payload.data;

  if (items.length === 0) {
    yield cancel();
    return;
  }

  yield* call(() =>
    Promise.all(
      items.map((item) =>
        request.post(`/stack-1/notes/${item.id}`, {
          body: {
            text: item.text,
            plain_text: stripTags(item.text),
          },
        }),
      ),
    ),
  );
}
