import { useEffect, useMemo } from 'react';

import { useDispatcher, useSelector } from 'store/utils/redux/hooks';
import * as relationStore from 'store/nodes/relation';
import * as userStore from 'store/nodes/user';
import * as playlistStore from 'store/nodes/playlist';

import { useRoute } from 'navigation/hooks';

const useUsers = () => {
  const dispatcher = useDispatcher();
  const route = useRoute<'Playlist'>();

  const user = useSelector(userStore.selectors.getMy);

  const collection = useSelector(playlistStore.selectors.dataById(route.params.resourceId));

  const listSubscriptions = useSelector(relationStore.selectors.list('subscriptions'));
  const listSubscribers = useSelector(relationStore.selectors.list('subscribers'));

  const filteredUsers = useMemo(() => {
    const inviteUserIds = collection?.permissions?.map((value) => value.user.id) || [];
    const combinedSubscriptionsAndSubscribers = [...new Set([...listSubscriptions.sequence, ...listSubscribers.sequence].map((item) => item.id))];
    return combinedSubscriptionsAndSubscribers.filter((id) => !inviteUserIds.includes(id));
  }, [listSubscriptions.sequence, listSubscribers.sequence, collection]);

  const users = useSelector(userStore.selectors.getById(filteredUsers));

  useEffect(() => {
    if (!user) {
      return;
    }
    dispatcher.relation.load(user.login);
  }, [user]);

  return users;
};

export default useUsers;
