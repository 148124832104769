import { forwardRef, memo, useEffect, useImperativeHandle, useRef } from 'react';
import { isEqual } from 'lodash';
import { Box } from '@mui/joy';
import cn from 'classnames';

import { useEditorState } from 'hooks';

import { useSelector } from 'store/utils/redux/hooks';
import * as navigationStore from 'store/nodes/navigation';

import Editor, { type EditorMethods } from 'widgets/Editor';

import { createUseStyles } from 'react-jss';
import useData from '../model/useData';
import useSave from '../model/useSave';
import useCreate from '../model/useCreate';

import TextStyles from './TextStyles';

export { type EditorMethods };

type EditorProcessorProps = {
  id: number | 'create';
  contentWrapperClassName?: string;
  autofocus?: boolean;
  onFocus?: (event: FocusEvent) => void;
  onBlur?: (event: FocusEvent) => void;
};

const EditorProcessor = forwardRef<EditorMethods, EditorProcessorProps>((props, forwardedRef) => {
  const { id, contentWrapperClassName, autofocus, onFocus, onBlur } = props;

  const classes = useStyles();
  const { data, initHtml } = useData(id);

  const [prevPath] = useSelector(navigationStore.selectors.pathname);
  const editorRef = useRef<EditorMethods | null>(null);

  const save = useSave();
  const create = useCreate();

  const { html, text, hasContent, onChange } = useEditorState(data?.text);

  useImperativeHandle(forwardedRef, () => ({
    clear: ((...args) => editorRef?.current?.clear(...args)) as EditorMethods['clear'],
    set: ((...args) => editorRef?.current?.set(...args)) as EditorMethods['set'],
    focus: ((...args) => editorRef?.current?.focus(...args)) as EditorMethods['focus'],
    insert: ((...args) => editorRef?.current?.insert(...args)) as EditorMethods['insert'],
  }));

  useEffect(() => {
    if (id === 'create' && autofocus) {
      editorRef.current?.focus();
    }
  }, []);

  useEffect(() => {
    if (prevPath === '/note/create') {
      return;
    }
    if (initHtml) {
      editorRef.current?.set(initHtml);
    }
    if (initHtml && autofocus) {
      editorRef.current?.focus();
    }
  }, [initHtml, prevPath]);

  useEffect(() => {
    if (typeof id === 'number') {
      save(id, html, text);
    }
    if (id === 'create' && hasContent) {
      create(html, text);
    }
  }, [id, html, text, save, hasContent]);

  return (
    <TextStyles contentWrapperClassName={contentWrapperClassName}>
      <Editor
        ref={editorRef}
        onHtmlChanged={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        slotProps={{
          input: {
            className: cn(contentWrapperClassName, classes.input),
          },
        }}
      />
      <Box mb={6} />
    </TextStyles>
  );
});

const useStyles = createUseStyles({
  input: {
    minHeight: 'calc(100vh - 100px)',
  },
});

export default memo(EditorProcessor, (prevProps, nextProps) => isEqual(prevProps, nextProps));
