import { createSelector } from '@reduxjs/toolkit';
import { type DefaultRootState } from 'react-redux';
import md5 from 'blueimp-md5';

const selector = createSelector(
  (state: DefaultRootState) => state.library.filter,
  (state: DefaultRootState) => state.library.list.meta.hasAnyItems,
  (state: DefaultRootState) => state.library.list.meta.resultWithFilterHash,
  (state: DefaultRootState) => state.library.list.meta.isFirstPageLoading,
  (state: DefaultRootState) => state.library.list.meta.isFirstPageLoaded,
  (state: DefaultRootState) => state.library.list.meta.isNextPageLoading,
  (state: DefaultRootState) => state.library.list.meta.isNextPageLoaded,
  (filter, hasAnyItems, resultWithFilterHash, isFirstPageLoading, isFirstPageLoaded, isNextPageLoading, isNextPageLoaded) => ({
    hasAnyItems,
    isConsistent: resultWithFilterHash === md5(JSON.stringify(filter.enabledMap)),
    firstPage: {
      isLoading: isFirstPageLoading,
      isLoaded: isFirstPageLoaded,
    },
    nextPage: {
      isLoading: isNextPageLoading,
      isLoaded: isNextPageLoaded,
    },
  }),
);

export default (state: DefaultRootState) => selector(state);
