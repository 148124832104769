/**
 * Module to cache Image objects by src.
 */
export class ImageCache {
  private static cache = new Map<string, HTMLImageElement>();

  /**
   * Get an Image from the cache.
   * @param {string} src - The image source URL.
   * @returns {HTMLImageElement | undefined} The cached Image element or undefined.
   */
  static get(src: string): HTMLImageElement | undefined {
    return this.cache.get(src);
  }

  /**
   * Add an Image to the cache.
   * @param {string} src - The image source URL.
   * @param {HTMLImageElement} img - The Image element.
   */
  static set(src: string, img: HTMLImageElement): void {
    this.cache.set(src, img);
  }
}
