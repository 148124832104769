import delay from 'utils/delay';

const openInNewTab = async (url: string) => {
  const a = document.createElement('a');
  a.href = url;
  a.target = '_blank';
  a.click();
  a.remove();
  await delay(100);
};

export default openInNewTab;
