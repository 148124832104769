import IconSVG from './IconSVG';
import PlatformGroup from './PlatformGroup';

export { IconSVG, PlatformGroup };

export class Items {
  public readonly items: Record<string, IconSVG | PlatformGroup>;

  constructor(items: Record<string, IconSVG | PlatformGroup>) {
    this.items = items;
  }

  getByName = (name: string): IconSVG | PlatformGroup => this.items[name];
}
