import { takeLatest, put, cancel } from 'redux-saga/effects';

import Alert from 'components/Alert';
import * as api from 'services/api';

import { call, select } from 'store/utils/saga/effects';
import * as userStore from 'store/nodes/user';

import * as actions from '../actions';

export const config = {
  action: actions.loadFilters.type,
  method: takeLatest,
};

export function* func() {
  const currentData = yield* select(userStore.selectors.getMy);
  if (!currentData) {
    yield put(actions.loadFiltersDone());
    yield cancel();
    return;
  }
  const { data, error } = yield* call(() => api.resource.user.library.filters());
  if (error || !data) {
    Alert.error(error?.message);
    yield put(actions.loadFiltersDone(true));
    yield cancel();
    return;
  }
  yield put(actions.setFilters(data));
  yield put(actions.loadFiltersDone());
  yield cancel();
}
