import type { ResultLegacy, MaterialType } from 'app/entities';

import request from 'services/api/request';
import interaction from 'services/api/interaction/route';
import remark from 'services/api/remark';
import * as note from 'services/api/note/route';

export default async (ids: number | number[]): Promise<ResultLegacy<MaterialType[], Error | Error[] | null>> => {
  if (!ids || (Array.isArray(ids) && ids.length === 0)) {
    return {
      data: [],
      error: null,
    };
  }
  const idsFinal = Array.isArray(ids) ? ids : [ids];
  const query = {
    ids: idsFinal.join(','),
  };

  const [contents, remarksMap, interactionsMap, myNotesMap] = await Promise.all([
    request.get<MaterialType[]>('/stack-1/content', { query }),
    remark.items(idsFinal, 100),
    interaction.items('content', idsFinal),
    note.getByResourceIds(idsFinal),
  ]);

  if (contents.error || !contents.data || interactionsMap.error || !interactionsMap.data) {
    return {
      data: null,
      error: contents.error || interactionsMap.error,
    };
  }

  const data = contents.data.map((content) => ({
    ...content,
    ...interactionsMap?.data?.[content?.id],
    remarks: remarksMap?.data?.[content?.id]?.items || [],
    myNotes: myNotesMap?.data?.[content?.id] || [],
  }));

  return {
    data,
    error: null,
  };
};
