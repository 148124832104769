import { type DefaultRootState } from 'react-redux';

export const selectLoadNextCursor =
  (reload: boolean) =>
  (state: DefaultRootState): string | null => {
    const nextCursor = state.content.list.paginationInfo?.nextCursor as string | null;
    if (reload || !nextCursor) {
      return null;
    }
    return nextCursor;
  };
