import { createReducer } from '@reduxjs/toolkit';

import type { AuthStore } from './types';
import * as actions from './actions';

const initialState: AuthStore = {
  signIn: {
    data: {},
    isLoading: false,
    result: {
      success: null,
      error: null,
    },
  },
  signUp: {
    isLoading: false,
    result: {
      success: null,
      error: null,
    },
  },
  passwordReset: {
    isLoading: false,
    result: {
      success: null,
      error: null,
    },
  },
  passwordUpdate: {
    isLoading: false,
    result: {
      success: null,
      error: null,
    },
  },
  signOut: {
    isLoading: false,
    result: {
      success: null,
      error: null,
    },
  },
};

export default createReducer(initialState, (builder) =>
  builder
    .addCase(actions.doSignIn, (state, action) => {
      state.signIn.isLoading = true;
      state.signIn.result.success = null;
      state.signIn.result.error = null;
    })
    .addCase(actions.doSignInDone, (state, action) => {
      state.signIn.isLoading = false;
    })
    .addCase(actions.setSignInEmail, (state, action) => {
      state.signIn.data.email = action.payload.email;
    })
    .addCase(actions.doPasswordReset, (state, action) => {
      state.passwordReset.isLoading = true;
      state.passwordReset.result.success = null;
      state.passwordReset.result.error = null;
    })
    .addCase(actions.doPasswordResetAgain, (state, action) => {
      state.passwordReset.isLoading = false;
      state.passwordReset.result.success = null;
      state.passwordReset.result.error = null;
    })
    .addCase(actions.doPasswordResetDone, (state, action) => {
      state.passwordReset.isLoading = false;
      state.passwordReset.result.success = !action.payload?.error;
      state.passwordReset.result.error = action.payload?.error ? action.payload.error.message : null;
    })
    .addCase(actions.doPasswordUpdate, (state, action) => {
      state.passwordUpdate.isLoading = true;
      state.passwordUpdate.result.success = null;
      state.passwordUpdate.result.error = null;
    })
    .addCase(actions.doPasswordUpdateDone, (state, action) => {
      state.passwordUpdate.isLoading = false;
      state.passwordUpdate.result.success = !action.payload?.error;
      state.passwordUpdate.result.error = action.payload?.error ? action.payload.error.message : null;
    })
    .addCase(actions.doSignUp, (state, action) => {
      state.signUp.isLoading = true;
      state.signUp.result.success = null;
      state.signUp.result.error = null;
    })
    .addCase(actions.doSignUpDone, (state, action) => {
      state.signUp.isLoading = false;
      state.signUp.result.success = !action.payload?.error;
      state.signUp.result.error = action.payload?.error ? action.payload.error.message : null;
    })
    .addCase(actions.logOut, (state, action) => {
      state.signOut.isLoading = true;
      state.signOut.result.success = null;
      state.signOut.result.error = null;
    })
    .addCase(actions.logOutDone, (state, action) => {
      state.signOut.isLoading = false;
      state.signOut.result.success = !action.payload?.error;
      state.signOut.result.error = action.payload?.error ? action.payload.error.message : null;
    }),
);
