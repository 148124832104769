import { type SagaReturnType, takeLatest, put, cancel } from 'redux-saga/effects';

import { call } from 'store/utils/saga/effects';

import network from 'lib/network';
import Alert from 'components/Alert';
import { actions } from '../slice';

export const config = {
  action: actions.skip.type,
  method: takeLatest,
};

export function* func(action: SagaReturnType<typeof actions.skip>) {
  const { target } = action.payload;

  const { errors, data } = yield* call(() =>
    network
      .request<{
        resumed: boolean;
      }>('/stack-2/onboarding/skip', {
        baseUrl: process.env.PROTECTED_API_URL || '/api',
      })
      .body({ target })
      .post(),
  );

  if (errors) {
    Alert.error(errors[0]?.message);
    yield cancel();
    return;
  }

  yield put(actions.skipDone());
  yield put(actions.nextStep());
}
