import { type DefaultRootState } from 'react-redux';
import { createCachedSelector } from 're-reselect';

const selector = createCachedSelector(
  (state: DefaultRootState, login: string) => state.user.data[login] || null,
  (userData) => userData,
)((state, login) => login);

export default (login?: 'my' | string) => (state: DefaultRootState) => {
  let targetLogin = login;
  if (targetLogin === 'my' && state.user.my) {
    targetLogin = state.user.my.login;
  }
  if (!targetLogin) {
    return null;
  }
  return selector(state, targetLogin);
};
