import { createSelector } from '@reduxjs/toolkit';
import { type DefaultRootState } from 'react-redux';
import md5 from 'blueimp-md5';

const selector = createSelector(
  (state: DefaultRootState) => state.search.filter,
  (state: DefaultRootState) => state.search.list.meta.resultWithFilterHash,
  (filter, resultWithFilterHash) => resultWithFilterHash === md5(JSON.stringify(filter)),
);

export default (state: DefaultRootState) => selector(state);
