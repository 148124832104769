import { type SagaReturnType, takeLatest, put, cancel } from 'redux-saga/effects';

import { navigate } from 'navigation/methods';
import * as api from 'services/api';

import dialog from 'components/Dialog';
import Alert from 'components/Alert';

import { call } from 'store/utils/saga/effects';
import * as noteStore from 'store/nodes/note';
import * as actions from '../actions';

export const config = {
  action: actions.remove.type,
  method: takeLatest,
};

const confirm = (): Promise<boolean> =>
  new Promise((resolve) => {
    dialog.show(
      {
        title: 'Confirmation',
        text: 'Are you sure want to remove this\nfrom your library?',
      },
      [
        {
          label: 'Cancel',
          variant: 'outlined',
          lightColor: '#888888',
          darkColor: '#9A99A2',
          handle: (dialogId: string) => {
            dialog.hide(dialogId);
            resolve(false);
          },
        },
        {
          label: 'Yes',
          variant: 'contained',
          lightColor: '#db3327',
          darkColor: '#db3327',
          textLightColor: '#ffffff',
          textDarkColor: '#ffffff',
          handle: (dialogId: string) => {
            dialog.hide(dialogId);
            resolve(true);
          },
        },
      ],
    );
  });

export function* func(action: SagaReturnType<typeof actions.remove>) {
  const { resourceType, resourceId, onSuccessNavigate } = action.payload;

  const isConfirmed = yield* call(confirm);
  if (!isConfirmed) {
    yield put(actions.removeDone());
    yield cancel();
    return;
  }

  yield put(actions.removeFromSequence(resourceType, resourceId));

  const { data, error } = yield* call(() => api.resource.user.library.remove(resourceType, resourceId));
  if (error || !data) {
    Alert.error(error?.message);
    yield put(actions.removeDone());
    yield cancel();
    return;
  }

  if (resourceType === 'note') {
    yield put(noteStore.actions.removeItem({ id: resourceId }));
  }

  yield put(actions.removeDone());
  if (!onSuccessNavigate) {
    yield cancel();
    return;
  }

  const { name, params } = onSuccessNavigate;
  yield* call(() => navigate(name, params));
}
