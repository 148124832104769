import { createAction } from '@reduxjs/toolkit';

export interface IRequestPayload {
  type?: string;
  payload: Record<never, never>;
  meta: {
    processButtonName: string;
  };
}

export const setButtonProcess = createAction('COMMON/SET_BUTTON_PROCESS', (buttonName = '') => ({
  payload: {},
  meta: {
    processButtonName: buttonName,
  },
}));
