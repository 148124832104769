import { type PropsWithChildren, type ReactElement, memo, useCallback, useState } from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';

import { type FontSizeVariant } from 'font';
import { View, Text } from 'components/Themed';
import { unit } from 'utils';

const defaultProps = {
  size: 17,
  lightColor: '#000000',
  darkColor: '#ffffff',
};

type DescriptionProps = {
  size?: FontSizeVariant;
  lightColor?: string;
  darkColor?: string;
} & typeof defaultProps;

const Description = (props: PropsWithChildren<DescriptionProps>): ReactElement | null => {
  const { children, size, lightColor, darkColor } = props;

  const [isOpen, setOpen] = useState(false);

  const handleToggle = useCallback(() => {
    if (isOpen) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [isOpen]);

  if (!children || typeof children !== 'string') {
    return null;
  }

  const crop = 400;

  if (children.length < crop) {
    return (
      <View style={styles.container}>
        <Text size={size} style={styles.description} lightColor={lightColor} darkColor={darkColor}>
          {children}
        </Text>
      </View>
    );
  }

  const dotPosition = children.indexOf('.', crop);

  return (
    <View style={styles.container}>
      <Text size={size} lightColor={lightColor} darkColor={darkColor} style={styles.description}>
        {!isOpen && `${children.substring(0, dotPosition)}...`}
        {isOpen && children}{' '}
        <TouchableOpacity onPress={handleToggle} activeOpacity={0.8}>
          <Text size={17} weight="medium" darkColor="#497CFF" lightColor="#497CFF">
            {!isOpen ? 'show more' : 'show less'}
          </Text>
        </TouchableOpacity>
      </Text>
    </View>
  );
};

Description.defaultProps = defaultProps;

const styles = StyleSheet.create({
  container: {},
  description: {
    marginTop: unit(12),
  },
});

export default memo(Description);
