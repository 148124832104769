import connect from '../../utils/saga/connect';
import * as loadById from './sagas/loadById';
import * as remove from './sagas/remove';
import * as updateInBackground from './sagas/updateInBackground';
import * as addItem from './sagas/addItem';
import * as removeItem from './sagas/removeItem';
import * as saveNewNote from './sagas/saveNewNote';
import * as collectionUpdated from './sagas/collectionUpdated';
import * as materialsMetadataUpdated from './sagas/materialsMetadataUpdated';
import * as sortMaterials from './sagas/sortMaterials';
import * as permissionChanged from './sagas/permissionChanged';
import * as createPermission from './sagas/createPermission';
import * as deletePermission from './sagas/deletePermission';
import * as updatePermission from './sagas/updatePermission';
import * as loadPermissionRole from './sagas/loadPermisionRole';
import * as loadItem from './sagas/loadItem';

export default connect([
  loadById,
  remove,
  updateInBackground,
  saveNewNote,
  addItem,
  removeItem,
  collectionUpdated,
  createPermission,
  deletePermission,
  updatePermission,
  loadPermissionRole,
  permissionChanged,
  materialsMetadataUpdated,
  sortMaterials,
  loadItem,
]);
