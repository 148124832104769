import { memo } from 'react';
import { Box, Button, FormControl, FormLabel, Input, Typography } from '@mui/joy';

import Icon from 'ui/Icon';

import DualPanels from 'widgets/DualPanels';

import useInviteTeamState from '../model/useInviteTeamState';
import Header from '../ui/Header';
import SkipButton from '../ui/SkipButton';

const backgroundProps = {
  imageBackground: require('widgets/Onboarding/assets/invite.jpg'),
};

const InviteTeam = () => {
  const { emails, onAddEmails, onSubmit, areAllEmailsValid, onChange, onSkip, isValidEmail, onKeyDown, isLoading, isSkipping } = useInviteTeamState();

  return (
    <DualPanels
      leftHeaderPanel={<Header />}
      leftTitlePanel={
        <Box display="flex" flexDirection="column" alignItems="center" gap={1.5}>
          <Typography textAlign="center" fontSize={52} fontWeight={600} lineHeight={1}>
            Invite your team to <br /> your knowledge space
          </Typography>
          <Typography textAlign="center" fontSize={16} fontWeight={400}>
            Spaces are shared library for your knowledge. Invite your team and build your library together.
          </Typography>
        </Box>
      }
      leftPanel={
        <>
          <Box display="flex" flexDirection="column" gap={2}>
            {emails.map((email, index) => (
              <FormControl size="md" inputMode="email" key={index} disabled={isSkipping || isLoading} error={!isValidEmail(email) && email !== ''}>
                {index === 0 && <FormLabel sx={{ color: (theme) => theme.palette.neutral.plainColor }}>Email</FormLabel>}
                <Input
                  placeholder="Enter team member email"
                  variant="soft"
                  required
                  value={email || ''}
                  name={`email-${index}`}
                  onChange={onChange(index)}
                  onKeyDown={onKeyDown}
                  size="lg"
                  sx={{
                    '--_Input-focusedHighlight': (theme) => theme.palette.neutral.softColor,
                  }}
                />
              </FormControl>
            ))}
            <Button
              onClick={onAddEmails}
              variant="plain"
              color="neutral"
              startDecorator={<Icon name="plus" />}
              disabled={!emails[emails.length - 1] || !isValidEmail(emails[emails.length - 1]) || isSkipping || isLoading}
              sx={{ justifyContent: 'flex-start' }}
            >
              add another member
            </Button>
            <Button
              fullWidth
              onClick={onSubmit}
              loading={isLoading}
              size="lg"
              variant="soft"
              color="neutral"
              sx={{
                mt: 2,
              }}
              endDecorator={<Icon name="arrow-right" color="inherit" />}
              disabled={!areAllEmailsValid || isSkipping}
            >
              Next
            </Button>
          </Box>
          <Box alignSelf="center" display="flex" flexDirection="row" alignItems="baseline" mt={2} pl={3} gap={0.5}>
            <Typography fontSize={14}>Not ready yet?</Typography>
            <SkipButton onClick={onSkip} loading={isSkipping} disabled={isLoading} />
          </Box>
        </>
      }
      rightPanelBackGround={backgroundProps}
    />
  );
};

export default memo(InviteTeam);
