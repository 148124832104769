import { useCallback } from 'react';
import { Platform } from 'react-native';
import * as Linking from 'expo-linking';

const useGetOpenLink = () => {
  return useCallback((url: string) => {
    if (Platform.OS === 'web') {
      window.open(url, '_blank');
    } else {
      Linking.canOpenURL(url)
        .then((supported) => {
          if (!supported) {
            return undefined;
          }
          return Linking.openURL(url);
        })
        .catch((error) => {
          console.log((error as Error).message);
        });
    }
  }, []);
};

export default useGetOpenLink;
