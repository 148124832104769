import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type { Stripe } from '@stripe/stripe-js';
import type { ScreenParams } from 'navigation/types';

export type StepState = {
  profile: boolean;
  about: boolean;
  invite: boolean;
  trial: boolean;
};

export type OnboardingStore = {
  profile: boolean;
  about: boolean;
  invite: boolean;
  trial: boolean;
  isLoading: boolean;
  isSkipLoading: boolean;
  navigated: boolean;
  ignore: StepState;
};

const initialState: OnboardingStore = {
  profile: false,
  about: false,
  invite: false,
  trial: false,
  isLoading: false,
  isSkipLoading: false,
  navigated: false,
  ignore: {
    profile: false,
    about: false,
    invite: true,
    trial: false,
  },
};

const stepsOrder: Array<keyof StepState> = ['profile', 'about', 'invite', 'trial'];

const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    setupProfile: (
      state,
      action: PayloadAction<{
        name: string | null;
        photo: string | undefined;
        login: string | null;
      }>,
    ) => {
      state.isLoading = true;
    },
    setupProfileDone: (state) => {
      state.isLoading = false;
    },
    tellUsAbout: (state, action: PayloadAction<{ profession: string; howUse: string }>) => {
      state.isLoading = true;
    },
    tellUsAboutDone: (state) => {
      state.isLoading = false;
    },
    inviteTeam: (state, action: PayloadAction<{ emails: string[] }>) => {
      state.isLoading = true;
    },
    inviteTeamDone: (state) => {
      state.isLoading = false;
    },
    startTrial: (state, action: PayloadAction<{ planId: string; promoCode?: string | null; paymentMethodId: string; stripe: Stripe }>) => {
      state.isLoading = true;
    },
    startTrialDone: (state) => {
      state.isLoading = false;
    },
    skip: (state, action: PayloadAction<{ target: 'about' | 'invite' | 'trial' }>) => {
      state.isSkipLoading = true;
    },
    skipDone: (state) => {
      state.isSkipLoading = false;
    },
    route: (state, action: PayloadAction<{ exitScreen: keyof ScreenParams }>) => {
      return state;
    },
    routeDone: (state, action: PayloadAction<{ navigated: boolean }>) => {
      state.navigated = action.payload.navigated;
    },
    loadState: (state) => {
      return state;
    },
    loadStateDone: (state, action: PayloadAction<{ state: StepState; ignore?: Partial<StepState> }>) => {
      state.ignore = {
        ...state.ignore,
        ...action.payload.ignore,
      };
      state.profile = (action.payload.state.profile || state.ignore.profile) ?? false;
      state.about = (action.payload.state.about || state.ignore.about) ?? false;
      state.invite = (action.payload.state.invite || state.ignore.invite) ?? false;
      state.trial = (action.payload.state.trial || state.ignore.trial) ?? false;
    },
    nextStep: (state) => {
      const activeSteps = stepsOrder.filter((step) => !state.ignore[step]);
      for (const step of activeSteps) {
        if (!state[step]) {
          state[step] = true;
          break;
        }
      }
    },
    prevStep: (state) => {
      const activeSteps = stepsOrder.filter((step) => !state.ignore[step]);
      const stepsCompleted = activeSteps.filter((step) => state[step]);
      if (stepsCompleted.length > 0) {
        const lastStep = stepsCompleted[stepsCompleted.length - 1];
        state[lastStep] = false;
      }
    },
  },
});

export const { reducer, actions } = onboardingSlice;
