import connect from 'store/utils/saga/connect';

import * as loadFilters from './sagas/loadFilters';
import * as loadNextPage from './sagas/loadNextPage';
import * as loadById from './sagas/loadById';
import * as loadRelated from './sagas/loadRelated';
import * as hide from './sagas/hide';
import * as loadWatched from './sagas/loadWatched';
import * as remove from './sagas/remove';
import * as highlightDelete from './sagas/highlightDelete';
import * as noteUpdate from './sagas/noteUpdate';
import * as noteDelete from './sagas/noteDelete';
import * as materialIndexed from './sagas/materialIndexed';
import * as triggerReloadByFilters from './sagas/triggerReloadByFilters';

export default connect([loadFilters, loadNextPage, loadById, loadRelated, hide, loadWatched, remove, highlightDelete, noteUpdate, noteDelete, triggerReloadByFilters, materialIndexed]);
