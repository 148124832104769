import { takeLatest, put, cancel, type SagaReturnType } from 'redux-saga/effects';

import Alert from 'components/Alert';
import * as api from 'services/api';

import { call } from 'store/utils/saga/effects';

import * as actions from '../actions';

export const config = {
  action: actions.loadWatched.type,
  method: takeLatest,
};

export function* func(action: SagaReturnType<typeof actions.loadWatched>) {
  const { data, error } = yield* call(() => api.resource.content.watching());

  if (!data) {
    yield put(actions.loadWatchedDone());
    yield cancel();
    return;
  }

  if (error) {
    Alert.error(error?.message || 'server error #34');
    yield put(actions.loadWatchedDone());
    yield cancel();
    return;
  }

  yield put(actions.setWatched(data));
  yield put(actions.loadWatchedDone());
}
