import { type HTMLAttributeAnchorTarget } from 'react';

const openLink = (link: string, target?: HTMLAttributeAnchorTarget) => {
  const anchor = document.createElement('a');
  if (target) {
    anchor.target = target;
  }
  anchor.href = link;
  anchor.click();
};

export default openLink;
