import { useCallback, useEffect, useState } from 'react';
import { type ScaledSize, Dimensions } from 'react-native';

const getValue = (currentWidth: number, scheme?: Record<number, number>): number | undefined => {
  let result;

  if (!scheme) {
    return result;
  }

  Object.entries(scheme).forEach(([width, numColumns]) => {
    if (currentWidth >= Number(width)) {
      result = numColumns;
    }
  });

  return result;
};

export default (scheme?: Record<number, number>) => {
  const [value, setValue] = useState<undefined | number>(getValue(Dimensions.get('window').width, scheme));

  const handleDimensionChange = useCallback(
    ({ window }: { window: ScaledSize }) => {
      if (!scheme) {
        return;
      }
      setValue(getValue(window.width, scheme));
    },
    [scheme],
  );

  useEffect(() => {
    const handler = Dimensions.addEventListener('change', handleDimensionChange);
    return () => {
      handler.remove();
    };
  }, [handleDimensionChange]);

  return value;
};
