import useSubscriptionConfig from 'widgets/Subscription/model/useSubscriptionConfig';
import { useMemo } from 'react';

const useTrialPlans = () => {
  const subscriptionConfig = useSubscriptionConfig();
  return useMemo(
    () => subscriptionConfig.plans.filter((item) => ((subscriptionConfig.trial.items || []) as string[]).includes(item.identity.planId)),
    [],
  );
};

export default useTrialPlans;
