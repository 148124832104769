import { type SagaReturnType, put, cancel, takeEvery } from 'redux-saga/effects';

import * as api from 'services/api';
import type { PaginationInput } from 'app/entities';

import { select, call } from 'store/utils/saga/effects';
import { checkUnauthorized } from 'store/utils/credentials';
import * as userStore from 'store/nodes/user';

import Alert from 'components/Alert';

import * as actions from '../actions';
import * as selectors from '../selectors';

export const config = {
  action: actions.loadNextPage.type,
  method: takeEvery,
};

export function* func(action: SagaReturnType<typeof actions.loadNextPage>) {
  const { listType } = action.payload;

  const nextParams = yield* select(selectors.nextParams(listType));
  const pagination: PaginationInput = {
    pageSize: 50,
    cursor: nextParams?.cursor,
  };

  const response = yield* call(() => api.resource.user.relation[listType]?.(nextParams.login, pagination));
  yield checkUnauthorized(response);
  if (response.error || !response.data) {
    Alert.error(response.error?.message || 'Server error #31');
    yield put(actions.loadNextPageDone(listType));
    yield cancel();
    return;
  }

  yield put(userStore.actions.setProfile({ data: response.data.items }));
  yield put(actions.addPage(listType, response.data.items, response.data.paginationInfo));

  yield put(actions.loadNextPageDone(listType));
}
