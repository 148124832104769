import useLayoutName from './model/useLayoutName';

import EmptyLayout from './layouts/Empty';
import SearchLayout from './layouts/Search';
import RegularLayout from './layouts/Regular';

const Library = () => {
  const layoutName = useLayoutName();

  if (layoutName === 'empty') {
    return <EmptyLayout />;
  }

  if (layoutName === 'search') {
    return <SearchLayout />;
  }

  return <RegularLayout />;
};

export default Library;
