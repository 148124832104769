import { type DefaultRootState } from 'react-redux';
import { createCachedSelector } from 're-reselect';

const selector = createCachedSelector(
  (state: DefaultRootState) => state.content.list.filters.type.options || [],
  (_: DefaultRootState, exclude: string[] | undefined) => exclude,
  (options, exclude) => {
    let list = [...options];
    if (Array.isArray(exclude) && exclude.length > 0) {
      const excludeFinal = exclude.map((item) => item.toLowerCase());
      list = list.filter((item) => !excludeFinal.includes(item.toLowerCase()));
    }
    return list?.map((item) => ({
      label: item,
      value: item.toLowerCase(),
    }));
  },
)((state, exclude) => JSON.stringify(exclude));

export const filterOptions = (exclude?: string[]) => (state: DefaultRootState) => selector(state, exclude);
