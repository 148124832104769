import { memo, type MouseEvent, useCallback, useEffect, useState } from 'react';
import { IconButton } from '@mui/joy';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $getSelection, $isRangeSelection, $isRootOrShadowRoot } from 'lexical';
import { REMOVE_LIST_COMMAND, INSERT_CHECK_LIST_COMMAND, INSERT_ORDERED_LIST_COMMAND, INSERT_UNORDERED_LIST_COMMAND, $isListNode } from '@lexical/list';
import { $findMatchingParent } from '@lexical/utils';
import Icon from 'ui/Icon';

type ListPanelProps = {
  editor: ReturnType<typeof useLexicalComposerContext>[0];
};

const ListPanel = (props: ListPanelProps) => {
  const { editor } = props;

  const [listType, setListType] = useState<string | null>(null);

  const buttons = [
    { value: 'bullet', icon: 'list', ariaPressed: listType === 'bullet' },
    { value: 'number', icon: 'list-ol', ariaPressed: listType === 'number' },
    // { value: 'check', icon: 'list-check', ariaPressed: listType === 'check' },
  ];

  useEffect(() => {
    editor.registerUpdateListener(({ editorState }) => {
      editorState.read(() => {
        const selection = $getSelection();
        if (!$isRangeSelection(selection)) {
          setListType(null);
          return;
        }
        const anchorNode = selection.anchor.getNode();
        const element =
          anchorNode.getKey() === 'root'
            ? anchorNode
            : $findMatchingParent(anchorNode, (e) => {
                const parent = e.getParent();
                return parent !== null && $isRootOrShadowRoot(parent);
              });

        if ($isListNode(element)) {
          setListType(element.getListType());
        } else {
          setListType(null);
        }
      });
    });
  }, [editor]);

  const listCommandMap = {
    bullet: INSERT_UNORDERED_LIST_COMMAND,
    number: INSERT_ORDERED_LIST_COMMAND,
    check: INSERT_CHECK_LIST_COMMAND,
  };

  const toggleList = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      const type = event.currentTarget.value;
      editor.update(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
          if (listType === type) {
            editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
            setListType(null);
          } else {
            editor.dispatchCommand(listCommandMap[type as keyof typeof listCommandMap], undefined);
            setListType(type);
          }
        }
      });
    },
    [editor, listType],
  );

  return (
    <>
      {buttons.map((button) => (
        <IconButton key={button.value} value={button.value} aria-pressed={button.ariaPressed} onClick={toggleList}>
          <Icon name={button.icon} fw weight="regular" color="inherit" />
        </IconButton>
      ))}
    </>
  );
};

export default memo(ListPanel);
