import type { ResultLegacy, CommunityItemVariant } from 'app/entities';
import request from 'services/api/request';
import interaction from 'services/api/interaction/route';

import { guard } from 'utils';

export default async (): Promise<ResultLegacy<CommunityItemVariant[]>> => {
  const { data, error } = await request.get<CommunityItemVariant[]>(
    '/stack-1/user/community/feed',
  );

  const collectionIds: number[] = [];
  data?.forEach((group) => {
    if (group.title !== 'Playlists') {
      return;
    }
    group.payload.forEach((item) => {
      if (guard.isCollection(item)) {
        collectionIds.push(item.id);
      }
    });
  });

  const [collectionsInteractionMap] = await Promise.all([
    collectionIds ? interaction.items('playlist', collectionIds) : null,
  ]);

  return {
    // @ts-ignore
    data:
      data?.map((group) => {
        if (group.title === 'Playlists') {
          return {
            title: 'Collections',
            payload: group.payload.map((item) => {
              if (guard.isCollection(item)) {
                return {
                  ...item,
                  ...(collectionsInteractionMap?.data?.[item.id] || {}),
                };
              }
              return item;
            }),
          };
        }
        return group;
      }) || null,
    error,
  };
};
