import { memo } from 'react';
import { Box, Button, FormControl, FormLabel, Input, Typography } from '@mui/joy';

import DualPanels from 'widgets/DualPanels';

import Icon from 'ui/Icon';

import useAboutState from '../model/useAboutState';
import Header from '../ui/Header';
import SkipButton from '../ui/SkipButton';

const backgroundProps = {
  imageBackground: require('widgets/Onboarding/assets/about.jpg'),
};

const TellUsAboutYou = () => {
  const { howUse, profession, submitByEnter, onChangeRole, onChangeUseCase, onSkip, onSubmit, isLoading, isSkipping } = useAboutState();

  return (
    <DualPanels
      leftHeaderPanel={<Header />}
      leftTitlePanel={
        <Box display="flex" flexDirection="column" alignItems="center" gap={1.5} mb={2}>
          <Typography textAlign="center" fontSize={52} fontWeight={600} lineHeight={1}>
            Tell us about you
          </Typography>
        </Box>
      }
      leftPanel={
        <>
          <Box display="flex" flexDirection="column" gap={2}>
            <FormControl size="md" inputMode="email">
              <FormLabel sx={{ color: (theme) => theme.palette.neutral.plainColor }}>Describe your role</FormLabel>
              <Input
                placeholder="Enter use role"
                variant="soft"
                required
                value={profession}
                name="profession"
                disabled={isSkipping || isLoading}
                onChange={onChangeRole}
                size="lg"
                sx={{
                  '--_Input-focusedHighlight': (theme) => theme.palette.neutral.softColor,
                }}
              />
            </FormControl>
            <FormControl size="md" inputMode="email">
              <FormLabel sx={{ color: (theme) => theme.palette.neutral.plainColor }}>How you planning to use IKI?</FormLabel>
              <Input
                placeholder="Enter use case"
                variant="soft"
                required
                value={howUse}
                name="howUse"
                disabled={isSkipping || isLoading}
                onChange={onChangeUseCase}
                onKeyDown={submitByEnter}
                size="lg"
                sx={{
                  '--_Input-focusedHighlight': (theme) => theme.palette.neutral.softColor,
                }}
              />
            </FormControl>
            <Button
              fullWidth
              loading={isLoading}
              disabled={!howUse || !profession || isSkipping}
              onClick={onSubmit}
              size="lg"
              variant="soft"
              color="neutral"
              sx={{
                mt: 2,
              }}
              endDecorator={<Icon name="arrow-right" color="inherit" />}
            >
              Next
            </Button>
          </Box>
          <SkipButton alignSelf="center" mt={2} pl={3} onClick={onSkip} loading={isSkipping} disabled={isLoading} />
        </>
      }
      rightPanelBackGround={backgroundProps}
    />
  );
};

export default memo(TellUsAboutYou);
