import { type MouseEvent, memo, useCallback, useMemo } from 'react';
import { Box, Typography, Button } from '@mui/joy';
import { chunk } from 'lodash';

import { unit } from 'utils';

import { useScreenSize } from 'hooks';

const presets = [
  'What is design thinking?',
  'What is a good retention for SaaS?',
  'Knowledge graphs',
  'Figma tutorials',
  'Graph neural networks and their applications',
  'How to get to Product Market Fit?',
  'Kinetic typography',
  'Modern design systems',
];

type SearchPresetsProps = {
  onPresetPress: (event: MouseEvent<HTMLButtonElement>, context: { preset: string }) => void;
};

const SearchPresets = (props: SearchPresetsProps) => {
  const { onPresetPress } = props;

  const screenSize = useScreenSize();

  const presetsChunks = useMemo(() => chunk(presets, screenSize.is499 ? presets.length : Math.ceil(presets.length / 2)), [presets, screenSize]);

  const handleClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      const key = Number(event.currentTarget.getAttribute('data-key'));
      if (Number.isNaN(key)) {
        return;
      }
      onPresetPress?.(event, { preset: presets[key] });
    },
    [onPresetPress],
  );

  return (
    <Box display="flex" alignItems="center" flexDirection="column" mt={6}>
      <Typography fontSize={16} fontWeight={500}>
        Discover hot topics this week
      </Typography>
      <Box display="flex" flexDirection="row" mt={2} alignSelf="stretch" gap={1}>
        {presetsChunks.map((presetsChunk, blockKey) => (
          <Box key={blockKey} display="flex" gap={1.5} flexDirection="column" flex={1}>
            {presetsChunk.map((preset, buttonKey) => (
              <Button
                key={preset}
                data-key={blockKey * Math.ceil(presets.length / 2) + buttonKey}
                sx={{
                  '&': {
                    background: (theme) => theme.palette.background.level1,
                    fontWeight: 400,
                  },
                  '&:hover': {
                    background: (theme) => theme.palette.background.level2,
                  },
                  '@media (hover: none)': {
                    '&:hover': {
                      background: (theme) => theme.palette.background.level1,
                    },
                  },
                  '&:active': {
                    background: (theme) => theme.palette.background.level3,
                  },
                }}
                onClick={handleClick}
              >
                <Box display="flex" flex={1} justifyContent="flex-start">
                  <Typography
                    textColor="text.primary"
                    fontSize={15}
                    textAlign="left"
                    sx={{
                      '@media (max-width: 499px)': {
                        fontSize: 13,
                      },
                    }}
                  >
                    {preset}
                  </Typography>
                </Box>
              </Button>
            ))}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default memo(SearchPresets);
