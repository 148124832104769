import { type CSSProperties } from 'react';
import { type StyleProp, type TextStyle, type ViewStyle, Platform } from 'react-native';

import type {
  AuthorType,
  ImageType,
  MaterialType,
  CollectionType,
  RagMaterialType,
  NoteType,
  DocumentType,
  UserType,
  SearchItemType,
  ActivityNewContentType,
  ActivityNewPlaylistType,
  ActivityCompleteContentType,
  ActivityCompletePlaylistType,
  ActivityCommentContentType,
  ActivityCommentPlaylistType,
  ActivityNewJobType,
  SourceMaterialType,
  NoteKey,
  ResourceKey,
  CollectionPermissionType,
  PoorMaterialType,
  PoorCollectionType,
  BasicUserType,
} from 'app/entities';

export const isHTMLDivElement = (element: HTMLDivElement | unknown): element is HTMLDivElement => {
  return Platform.OS === 'web' && element instanceof HTMLDivElement;
};

export const isHTMLInputElement = (element: HTMLInputElement | unknown): element is HTMLInputElement => {
  return Platform.OS === 'web' && element instanceof HTMLInputElement;
};

export const isHTMLElement = (element: HTMLElement | unknown): element is HTMLElement => {
  return Platform.OS === 'web' && element instanceof HTMLElement;
};

export const isWebStyleProp = (data: CSSProperties | StyleProp<ViewStyle> | StyleProp<TextStyle> | any): data is CSSProperties =>
  Platform.OS === 'web';

export const isPoorMaterial = (data: PoorMaterialType | unknown): data is PoorMaterialType =>
  !!data &&
  typeof data === 'object' &&
  'id' in data &&
  'resourceType' in data &&
  typeof data?.id === 'number' &&
  data?.id > 0 &&
  typeof data?.resourceType === 'string' &&
  data?.resourceType === 'CONTENT';

export const isPoorCollection = (data: PoorCollectionType | unknown): data is PoorCollectionType =>
  !!data &&
  typeof data === 'object' &&
  'id' in data &&
  'resourceType' in data &&
  typeof data?.id === 'number' &&
  data?.id > 0 &&
  typeof data?.resourceType === 'string' &&
  data?.resourceType === 'PLAYLIST';

export const isMaterial = (data: MaterialType | unknown): data is MaterialType =>
  !!data && typeof data === 'object' && 'type' in data && 'authors' in data && 'image' in data && !('materialsMetadata' in data);

export const isSourceMaterial = (data: SourceMaterialType | unknown): data is SourceMaterialType =>
  !!data && typeof data === 'object' && 'type' in data && 'isSaved' in data && 'originalUrl' in data && !('materialsMetadata' in data);

export const isCollection = (data: CollectionType | unknown): data is CollectionType =>
  !!data && typeof data === 'object' && 'cover' in data && 'materialsMetadata' in data && 'contentImages' in data;

export const isRagMaterial = (data: RagMaterialType | unknown): data is RagMaterialType =>
  !!data &&
  typeof data === 'object' &&
  (('__typename' in data && data.__typename === 'RagMaterial') || ('typename' in data && data.typename === 'RagMaterial'));

export const isSearchItem = (data: SearchItemType | unknown): data is SearchItemType =>
  !!data && typeof data === 'object' && 'element' in data && 'searchMeta' in data && (isMaterial(data.element) || isCollection(data.element));

export const isNote = (data: NoteType | unknown): data is NoteType =>
  !!data && typeof data === 'object' && '__typename' in data && data.__typename === 'Note';

export const isDocument = (data: DocumentType | unknown): data is DocumentType =>
  !!data && typeof data === 'object' && '__typename' in data && data.__typename === 'Document';

export const isImage = (source: ImageType | unknown): source is ImageType =>
  source !== null && typeof source === 'object' && 'id' in source && 'url' in source;

export const isAuthor = (data: AuthorType | unknown): data is AuthorType => {
  return !!data && typeof data === 'object' && 'id' in data && 'name' in data && 'surname' in data && 'owner' in data && 'originalUrl' in data;
};

export const isUser = (data: UserType | BasicUserType | unknown): data is UserType | BasicUserType => {
  return !!data && typeof data === 'object' && !Array.isArray(data) && 'id' in data && 'login' in data && 'name' in data && 'surname' in data;
};

export const isUserArray = (data: UserType[] | BasicUserType[] | unknown): data is UserType[] | BasicUserType[] => {
  return !!data && typeof data === 'object' && Array.isArray(data) && isUser(data?.[0]);
};

export const isActivityNewMaterial = (data: ActivityNewContentType | unknown): data is ActivityNewContentType => {
  return !!data && typeof data === 'object' && 'type' in data && data?.type === 'NEW_MATERIAL';
};

export const isActivityNewCollection = (data: ActivityNewPlaylistType | unknown): data is ActivityNewPlaylistType => {
  return !!data && typeof data === 'object' && 'type' in data && data?.type === 'NEW_PLAYLIST';
};

export const isActivityCompletedMaterial = (data: ActivityCompleteContentType | unknown): data is ActivityCompleteContentType => {
  return !!data && typeof data === 'object' && 'type' in data && data?.type === 'COMPLETED' && 'content' in data && !!data.content;
};

export const isActivityCompletedCollection = (data: ActivityCompletePlaylistType | unknown): data is ActivityCompletePlaylistType => {
  return !!data && typeof data === 'object' && 'type' in data && data?.type === 'COMPLETED' && 'playlist' in data && !!data.playlist;
};

export const isActivityCommentMaterial = (data: ActivityCommentContentType | unknown): data is ActivityCommentContentType => {
  return !!data && typeof data === 'object' && 'type' in data && data?.type === 'NEW_COMMENT' && 'content' in data && !!data.content;
};

export const isActivityCommentCollection = (data: ActivityCommentPlaylistType | unknown): data is ActivityCommentPlaylistType => {
  return !!data && typeof data === 'object' && 'type' in data && data?.type === 'NEW_COMMENT' && 'playlist' in data && !!data.playlist;
};

export const isActivityNewJob = (data: ActivityNewJobType | unknown): data is ActivityNewJobType => {
  return !!data && typeof data === 'object' && 'type' in data && data?.type === 'NEW_JOB' && 'job' in data && !!data.job;
};

export const isLink = (text: string | unknown): text is string => {
  return typeof text === 'string' && /^https?:\/\/.+\..+/.test(text);
};

export const isResourceKey = (key: ResourceKey | unknown): key is ResourceKey =>
  !!key &&
  typeof key === 'object' &&
  'resourceType' in key &&
  'resourceId' in key &&
  typeof key?.resourceType === 'string' &&
  typeof key?.resourceId === 'number';

export const isNoteKey = (key: NoteKey | unknown): key is NoteKey =>
  !!key && typeof key === 'object' && 'noteId' in key && typeof key?.noteId === 'number';

export const isCollectionPermissionType = (arr: CollectionPermissionType[]): arr is CollectionPermissionType[] => {
  return arr.every((item) => typeof item === 'object' && item !== null && 'user' in item && 'action' in item && 'permission' in item);
};

export const resourceKeyIsMaterial = (type: 'Material' | unknown): type is 'Material' =>
  typeof type === 'string' && 'Material'.toLowerCase() === type.toLowerCase();

export const resourceKeyIsCollection = (type: 'Collection' | unknown): type is 'Collection' =>
  typeof type === 'string' && 'Collection'.toLowerCase() === type.toLowerCase();

export const resourceKeyIsNote = (type: 'Note' | unknown): type is 'Note' => typeof type === 'string' && 'Note'.toLowerCase() === type.toLowerCase();

export const resourceKeyIsDocument = (type: 'Document' | unknown): type is 'Document' =>
  typeof type === 'string' && 'Document'.toLowerCase() === type.toLowerCase();

export const resourceKeyIsRagMaterial = (type: 'RagMaterial' | unknown): type is 'RagMaterial' =>
  typeof type === 'string' && 'RagMaterial'.toLowerCase() === type.toLowerCase();
