import { takeEvery, put, delay, call } from 'redux-saga/effects';
import { call as gCall } from 'store/utils/saga/effects';
import * as messageStore from 'store/nodes/message';
import * as modalStore from 'widgets/modals/store';

import { copyToClipboard, openWindowGenerator } from 'utils';

import Alert from 'components/Alert';
import network from 'lib/network';

import * as actions from '../actions';

/**
 * Saga configuration for handling YouTube authentication actions.
 */
export const config = {
  action: [messageStore.socketActions.messageInfo.type, actions.openYoutubeActivation.type],
  method: takeEvery,
};

/**
 * Generic result type for saga functions.
 *
 * @template T
 * @property {T | null} result - The result data, or null if there was an error.
 * @property {string | null} error - The error message, or null if there was no error.
 */
interface SagaResult<T> {
  result: T | null;
  error: string | null;
}

/**
 * Main saga function that handles YouTube authentication related actions.
 *
 * @generator
 * @param {ReturnType<typeof messageStore.socketActions.messageInfo | typeof actions.openYoutubeActivation>} action - The dispatched action.
 */
export function* func(action: ReturnType<typeof messageStore.socketActions.messageInfo | typeof actions.openYoutubeActivation>) {
  switch (action.type) {
    case messageStore.socketActions.messageInfo.type:
      yield* handleYoutubeAuth(action);
      break;
    case actions.openYoutubeActivation.type:
      yield* handleOpenYoutubeActivation(action);
      break;
    default:
      break;
  }
}

/**
 * Checks the YouTube authorization status by making a network request.
 *
 * @generator
 * @returns {Generator<any, SagaResult<'unauthorized' | string>, any>}
 * - Returns an object with the authorization status or an error message.
 */
function* checkYoutubeAuthStatus(): Generator<any, SagaResult<'unauthorized' | string>, any> {
  const { hasError, errors, data } = yield* gCall(() =>
    network.request<{ status: 'unauthorized' | 'success' | string }>(`/stack-1/auth/youtube/status`).get(),
  );

  if (hasError || !data) {
    const errorMessage = errors?.[0]?.message || 'An error occurred while checking YouTube authorization status.';
    return { result: null, error: errorMessage };
  }

  return { result: data.status, error: null };
}

/**
 * Completes the YouTube authorization process by polling the server.
 * Repeats the request until authorization is successful or the maximum number of retries is reached.
 *
 * @param {string} deviceCode - The device code obtained during the initiation of YouTube authorization.
 * @param {number} repeatInterval - The interval in seconds to wait between polling attempts.
 * @returns {Generator<any, SagaResult<boolean>, any>} - Returns true if authorization is successful, false otherwise.
 */
function* completeYoutubeAuth(deviceCode: string, repeatInterval: number): Generator<any, SagaResult<boolean>, any> {
  const maxRetries = 20;
  let attempt = 0;

  while (attempt < maxRetries) {
    const { hasError, errors, data } = yield* gCall(() =>
      network.request<{ status: 'authorization_pending' | 'success' }>(`/stack-1/auth/youtube/complete`).body({ device_code: deviceCode }).post(),
    );

    if (hasError || !data) {
      const errorMessage = errors?.[0]?.message || 'An error occurred while completing YouTube authorization.';
      return { result: null, error: errorMessage };
    }

    if (data.status === 'success') {
      return { result: true, error: null };
    }

    if (data.status === 'authorization_pending') {
      yield delay(repeatInterval * 1000);
      attempt++;
    } else {
      const errorMessage = `Unexpected authorization status: ${data.status}`;
      return { result: null, error: errorMessage };
    }
  }

  return { result: null, error: 'YouTube authorization timed out.' };
}

/**
 * Triggers the re-run of transcriptions for all previously uploaded YouTube links.
 *
 * @generator
 * @returns {Generator<any, SagaResult<boolean>, any>}
 */
function* rerunAllYoutubeTranscriptions(): Generator<any, SagaResult<boolean>, any> {
  const { hasError, errors } = yield* gCall(() => network.request(`/stack-1/youtube/transcription/rerun_all`).post());

  if (hasError) {
    const errorMessage = errors?.[0]?.message || 'An error occurred while restarting YouTube transcriptions.';
    return { result: null, error: errorMessage };
  }

  return { result: true, error: null };
}

/**
 * Handles YouTube authentication when a specific message action is received.
 *
 * @generator
 * @param {ReturnType<typeof messageStore.socketActions.messageInfo>} action - The action containing message info.
 */
function* handleYoutubeAuth(action: ReturnType<typeof messageStore.socketActions.messageInfo>) {
  const { id, context } = action.payload;
  const { type } = context;

  if (!['Youtube_transcription', 'youtube_oauth'].includes(type)) {
    return;
  }

  yield put(messageStore.actions.prevent({ id }));

  if (type === 'Youtube_transcription') {
    return;
  }

  yield put(actions.setYoutubeProcessingState({ value: 'open' }));

  const { result: status, error } = yield* checkYoutubeAuthStatus();
  if (error) {
    Alert.error(error);
    return;
  }

  if (!status || status !== 'unauthorized') {
    return;
  }

  const { hasError, errors, data } = yield* gCall(() => network.request<any>(`/stack-1/auth/youtube/initiate`).get());
  if (hasError) {
    const errorMessage = errors?.[0]?.message || 'An unexpected error occurred while initiating YouTube authentication.';
    Alert.error(errorMessage);
    return;
  }

  yield put(modalStore.actions.open('YoutubeAuth', data));
}

/**
 * Handles the action to open YouTube activation.
 *
 * @generator
 * @param {ReturnType<typeof actions.openYoutubeActivation>} action - The action containing activation details.
 */
function* handleOpenYoutubeActivation(action: ReturnType<typeof actions.openYoutubeActivation>) {
  const { userCode, verificationUrl, interval, deviceCode } = action.payload;

  yield put(actions.setYoutubeProcessingState({ value: 'code-activation' }));

  yield call(copyToClipboard, userCode);
  yield openWindowGenerator(verificationUrl, { title: 'Activation', timeout: 10 });

  yield put(actions.setYoutubeProcessingState({ value: 'device-activation' }));

  const { result: authCompleted, error: authError } = yield* completeYoutubeAuth(deviceCode, interval);
  if (authError) {
    Alert.error(authError);
    yield put(modalStore.actions.close('YoutubeAuth'));
    return;
  }

  yield delay(100);

  const { result: status, error: statusError } = yield* checkYoutubeAuthStatus();
  if (statusError) {
    Alert.error(statusError);
    yield put(modalStore.actions.close('YoutubeAuth'));
    return;
  }

  if (status && status !== 'unauthorized') {
    Alert.success('YouTube account successfully authorized.');

    const { result: rerunSuccess, error: rerunError } = yield* rerunAllYoutubeTranscriptions();
    if (rerunError) {
      Alert.error(rerunError);
    } else if (rerunSuccess) {
      // You may add a notification about successful transcription restart
    }
  } else {
    Alert.error('Error authorization on YouTube.');
  }

  yield put(modalStore.actions.close('YoutubeAuth'));
}
