import { memo } from 'react';
import { Box, Typography } from '@mui/joy';
import { useSelector } from 'react-redux';

import * as searchStore from 'store/nodes/search';

import { useScreenSize } from 'hooks';

import SearchMethods from './SearchMethods';
import Tags from '../../../elements/Tags';

const TopBarDesktop = () => {
  const screenSize = useScreenSize();

  const tags = useSelector(searchStore.selectors.filterTags);

  if (screenSize.is767) {
    return null;
  }

  return (
    <Box zIndex={10}>
      <Box display="flex" flex={1} flexDirection="column" ml={5} mt={2} gap={1}>
        <Typography fontSize={17}>Ask in:</Typography>
        <SearchMethods />
      </Box>
      {tags.length > 0 && (
        <Box display="flex" flexDirection="row" justifyContent="space-between" mt={1}>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Typography fontSize={17} color="neutral">
              with tag:{' '}
            </Typography>
            <Tags ml={0.5} />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default memo(TopBarDesktop);
