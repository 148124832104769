import connect from '../../utils/saga/connect';
import * as loadFilters from './sagas/loadFilters';
import * as loadNextPage from './sagas/loadNextPage';
import * as loadMeta from './sagas/loadMeta';
import * as remove from './sagas/remove';
import * as handleItemCreate from './sagas/handleItemCreate';
import * as handleCollectionDelete from './sagas/handleCollectionDelete';
import * as handleMaterialAdded from './sagas/handleMaterialAdded';

export default connect([loadFilters, loadNextPage, loadMeta, remove, handleCollectionDelete, handleMaterialAdded, handleItemCreate]);
