import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import type { NoteType, DocumentType } from 'app/entities';

export type ResourceType = 'collection' | 'material' | 'note' | 'library' | 'web' | 'global-fulltext' | 'library-fulltext';

export type CopilotAvailableContextsType = 'note' | 'library-fulltext';

type RelationType = {
  resourceType: ResourceType;
  resourceId: number;
};

export type NoteStore = {
  data: Record<number, NoteType | DocumentType>;
  relation: {
    resourceType: ResourceType;
    resourceId: number;
    noteId: number;
  }[];
  meta: {
    loadingMap: Record<number | string, boolean>;
  };
  copilot: {
    availableContexts: CopilotAvailableContextsType[];
    selectedContext: CopilotAvailableContextsType;
  };
};

const initialState: NoteStore = {
  data: {},
  relation: [],
  meta: {
    loadingMap: {},
  },
  copilot: {
    availableContexts: ['note', 'library-fulltext'],
    selectedContext: (localStorage.getItem('store.note.copilot.context') || 'note') as CopilotAvailableContextsType,
  },
};

const noteSlice = createSlice({
  name: 'note',
  initialState,
  reducers: {
    add(
      state,
      action: PayloadAction<{
        html: string;
        relation?: RelationType;
        openForEdit?: boolean;
      }>,
    ) {
      const { relation } = action.payload;
      if (relation) {
        const key = `${relation.resourceType}-${relation.resourceId}`;
        state.meta.loadingMap[key] = true;
      }
    },
    addDone(state, action: PayloadAction<{ relation: RelationType } | { id: number } | undefined>) {
      if (action.payload === undefined) {
        return;
      }
      if ('relation' in action.payload) {
        const { relation } = action.payload;
        const key = `${relation.resourceType}-${relation.resourceId}`;
        delete state.meta.loadingMap[key];
      } else {
        const { id } = action.payload;
        delete state.meta.loadingMap[id];
      }
    },
    update(state, action: PayloadAction<{ id: number; html: string; isPrivate?: boolean }>) {
      const { id } = action.payload;
      state.meta.loadingMap[id] = true;
    },
    updateDone(state, action: PayloadAction<{ id: number }>) {
      const { id } = action.payload;
      delete state.meta.loadingMap[id];
    },
    remove(state, action: PayloadAction<{ id: number; force?: boolean }>) {
      const { id } = action.payload;
      state.meta.loadingMap[id] = true;
    },
    removeDone(state, action: PayloadAction<{ id: number }>) {
      const { id } = action.payload;
      if (!id) {
        return;
      }
      delete state.meta.loadingMap[id];
    },
    setItem(
      state,
      action: PayloadAction<
        | {
            data:
              | (Partial<Omit<NoteType, 'id' | 'type' | 'internalUrl'>> & Pick<NoteType, 'id'>)
              | (Partial<Omit<DocumentType, 'id' | 'type' | 'internalUrl'>> & Pick<DocumentType, 'id'>);
            relation?: RelationType;
            mode?: 'update';
          }
        | { data: NoteType | DocumentType; relation?: RelationType; mode?: 'replace' }
        | {
            data:
              | (Partial<Omit<NoteType, 'id' | 'type' | 'internalUrl'>> & Pick<NoteType, 'id'>)
              | (Partial<Omit<DocumentType, 'id' | 'type' | 'internalUrl'>> & Pick<DocumentType, 'id'>);
            relation?: RelationType;
            mode?: 'update';
          }[]
        | { data: NoteType | DocumentType; relation?: RelationType; mode?: 'replace' }[]
      >,
    ) {
      const payload = Array.isArray(action.payload) ? action.payload : [action.payload];
      payload.forEach(({ data, relation, mode = 'replace' }) => {
        if (!data || !data.id || (mode === 'update' && !state.data[data.id])) {
          return;
        }
        delete state.meta.loadingMap[data.id];
        if (mode === 'replace') {
          state.data[data.id] = {
            internalUrl: `/note/${data.id}`,
            ...(data as NoteType),
          };
        }
        if (mode === 'update') {
          state.data[data.id] = {
            ...state.data[data.id],
            ...data,
          };
        }
        const noteRelationIndex = state.relation.findIndex((item) => item.noteId === data.id);
        if (!relation && noteRelationIndex > -1) {
          state.relation.splice(noteRelationIndex, 1);
          return;
        }
        if (!relation) {
          return;
        }
        const { resourceType, resourceId } = relation;
        if (noteRelationIndex !== -1) {
          return;
        }
        state.relation.push({
          resourceType,
          resourceId: Number(resourceId),
          noteId: data.id,
        });
      });
    },
    removeItem(state, action: PayloadAction<{ id: number } | { id: number }[]>) {
      const payload = Array.isArray(action.payload) ? action.payload : [action.payload];
      payload.forEach(({ id }) => {
        delete state.data[id];
        const noteRelationIndex = state.relation.findIndex((item) => item.noteId === id);
        if (noteRelationIndex > -1) {
          state.relation.splice(noteRelationIndex, 1);
        }
      });
    },
    loadById(state, action: PayloadAction<{ id: number }>) {
      const { id } = action.payload;
      state.meta.loadingMap[id] = true;
    },
    loadByIdDone(state, action: PayloadAction<{ id: number }>) {
      const { id } = action.payload;
      delete state.meta.loadingMap[id];
    },
    loadByResource(state, action: PayloadAction<{ resourceType: ResourceType; resourceId: number }>) {
      const { resourceType, resourceId } = action.payload;
      const key = `${resourceType}-${resourceId}`;
      state.meta.loadingMap[key] = true;
    },
    loadByResourceDone(state, action: PayloadAction<{ resourceType: ResourceType; resourceId: number }>) {
      const { resourceType, resourceId } = action.payload;
      const key = `${resourceType}-${resourceId}`;
      delete state.meta.loadingMap[key];
    },
    setSelectedCopilotContext(state, action: PayloadAction<{ value: CopilotAvailableContextsType }>) {
      const { value } = action.payload;
      localStorage.setItem('store.note.copilot.context', value);
      state.copilot.selectedContext = value;
    },
  },
});

export const { reducer, actions } = noteSlice;
