import { type PropsWithChildren, type ReactElement, memo, useMemo, cloneElement, useCallback } from 'react';
import { type DefaultRootState, useSelector } from 'react-redux';
import get from 'lodash.get';

import { useDispatcher } from 'store/utils/redux/hooks';

type ConnectInputProps = {
  itemsGetter?: string | ((state: DefaultRootState) => unknown);
  valueGetter?: string | ((state: DefaultRootState) => unknown);
  valueSetter?: string | ((state: DefaultRootState) => unknown) | ((value: any) => void);
  onChange?: (value: string) => void;
};

const ConnectInput = (props: PropsWithChildren<ConnectInputProps>): ReactElement => {
  const { itemsGetter, valueGetter, valueSetter, onChange, children } = props;

  const items = useSelector(typeof itemsGetter === 'function' ? itemsGetter : (state) => (itemsGetter ? get(state, itemsGetter) : undefined));
  const value = useSelector(typeof valueGetter === 'function' ? valueGetter : (state) => (valueGetter ? get(state, valueGetter) : undefined));
  const dispatcher = useDispatcher();

  const handleChange = useCallback(
    (newValue: string) => {
      if (!valueSetter) {
        return;
      }
      let setter;
      if (typeof valueSetter === 'string') {
        setter = get(dispatcher, valueSetter);
      }
      if (typeof valueSetter === 'function') {
        setter = valueSetter;
      }
      setter?.(newValue);
      onChange?.(newValue);
    },
    [onChange, dispatcher, valueSetter],
  );

  const clonedProps = useMemo(() => {
    const result: Record<string, any> = {
      onChange: handleChange,
    };
    if (value) {
      result.value = value;
    }
    if (items) {
      result.items = items;
    }
    return result;
  }, [value, items]);

  return cloneElement(children as ReactElement, clonedProps);
};

export default memo(ConnectInput);
