import { takeLatest, put, cancel } from 'redux-saga/effects';
import type { SagaReturnType } from 'redux-saga/effects';

import network from 'lib/network';

import { call } from 'store/utils/saga/effects';

import Alert from 'components/Alert';

import * as actions from '../actions';

export const config = {
  action: actions.doSignUp.type,
  method: takeLatest,
};

export function* func(action: SagaReturnType<typeof actions.doSignUp>) {
  const { email, password } = action.payload;

  const { errors, data } = yield* call(() =>
    network
      .request('/stack-1/auth/register', {
        baseUrl: process.env.PROTECTED_API_URL || '/api',
      })
      .body({ email, password })
      .post(),
  );

  const error = Array.isArray(errors) ? errors[0] : errors;
  if (error || !data) {
    Alert.error(error?.message || 'Server error #7');
    yield put(actions.doSignUpDone(error));
    yield cancel();
    return;
  }

  yield put(actions.doSignUpDone());

  yield put(actions.doSignIn(email, password, { event: 'register' }));
}
