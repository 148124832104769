import { type DefaultRootState } from 'react-redux';
import createCachedSelector from 're-reselect';

import type { ItemData, QueryType } from '../types';

const cacheKeyCreator = (state: DefaultRootState, itemId: string) => `question-${itemId}`;

const answerByRequestIdSelector = createCachedSelector(
  (state: DefaultRootState, itemId: string) => state.copilot.payload[itemId],
  (state: DefaultRootState, itemId: string) => state.copilot.attribute[itemId],
  (payload, attribute) => {
    if (!payload || !attribute || attribute.type !== 'question') {
      return null;
    }
    return {
      ...attribute,
      ...payload,
    } as ItemData<QueryType>;
  },
)(cacheKeyCreator);

export default (itemId: string) => (state: DefaultRootState) => {
  return answerByRequestIdSelector(state, itemId);
};
