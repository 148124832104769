import { type DefaultRootState } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';

const selector = createSelector(
  (state: DefaultRootState) => state.library.filter.panels.tagIds,
  (state: DefaultRootState) => state.library.filter.data,
  (ids, data) => ids?.map?.((id) => data[id]) || [],
);

export default (state: DefaultRootState) => selector(state);
