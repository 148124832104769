import { type DefaultRootState } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';

import * as libraryStore from 'store/nodes/library';

const selector = createSelector(
  (state: DefaultRootState) => state.search.filter.method,
  (state: DefaultRootState) => state.content.list.filters.type.options,
  (state: DefaultRootState) => libraryStore.selectors.panelTypes(state),
  (method, contentItems, libraryItems) => {
    if (['GlobalTitle', 'GlobalFullText'].includes(method)) {
      return contentItems.map((item) => ({
        id: item.toLowerCase(),
        label: item,
        attributes: {
          type: item,
          query: {},
        },
      }));
    }
    if (['LibraryFullText', 'LibraryTitle'].includes(method)) {
      return libraryItems.map((item) => ({
        id: item.id,
        label: item.label,
        attributes: {
          type: item.type,
          query: item.query,
        },
      }));
    }
    if (method === 'Web') {
      return [];
    }
    return [];
  },
);

export default (state: DefaultRootState) => selector(state);
