import { useMemo } from 'react';

export const useAvatarText = (name = '') => {
  return useMemo(() => {
    const preparedName = name?.toUpperCase().replace(/\s\s+/g, ' ').trim();
    const [firstWord, secondWord] = preparedName.split(' ');
    if (!firstWord) {
      return undefined;
    }
    if (!secondWord) {
      return firstWord.slice(0, 2);
    }
    return `${firstWord[0]}${secondWord[0]}`;
  }, [name]);
};
