import { takeEvery, delay, put } from 'redux-saga/effects';
import type { SagaReturnType } from 'redux-saga/effects';

import * as actions from '../actions';

export const config = {
  action: actions.dialogHide.type,
  method: takeEvery,
};

export function* func(action: SagaReturnType<typeof actions.dialogHide>) {
  yield delay(400);

  yield put(actions.dialogRemove(action.payload.id));
}
