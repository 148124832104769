import { useEffect, useMemo, useState } from 'react';
import network from 'lib/network';

export default (url?: string) => {
  const [html, setHtml] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!url) {
      return;
    }
    setIsLoading(true);
    network
      .request<{ article: { title: string; content: string } }>('/parse', {
        baseUrl: process.env.WEB_READER_DOMAIN,
      })
      .query({ url })
      .get()
      .then((response) => {
        const { data, errors } = response;
        if (!data || errors) {
          setHtml('Error loading');
          setIsLoading(false);
          return;
        }
        setHtml(data.article.content);
        setIsLoading(false);
      });
  }, [url]);

  return useMemo(
    () => ({
      html,
      isLoading,
    }),
    [html, isLoading],
  );
};
