import type { LexicalEditor, LexicalNode } from 'lexical';

const highlightNodes = (editor: LexicalEditor, nodes: LexicalNode[], initClass: string, outClass: string, easingMs: number) => {
  if (!nodes || !Array.isArray(nodes) || !nodes.length) {
    return;
  }
  nodes.forEach((node) => {
    const element = editor.getElementByKey(node.getKey());
    if (!element) {
      return;
    }
    element.classList.add(initClass);
    setTimeout(() => {
      element?.classList?.add?.(outClass);
    }, 20);
    setTimeout(() => {
      element?.classList?.remove?.(initClass, outClass);
    }, easingMs + 20);
  });
};

export default highlightNodes;
