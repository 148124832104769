import { type SagaReturnType, takeEvery, put, cancel } from 'redux-saga/effects';
import { type MaterialMetadataItemType } from 'app/entities';

import * as socketStore from 'store/nodes/socket';
import { call, select } from 'store/utils/saga/effects';

import { getRoute } from 'navigation/methods';

import Alert from 'components/Alert';
import * as selectors from 'store/nodes/playlist/selectors';
import { actions } from '../slice';

export const config = {
  action: socketStore.actions.collectionMaterialsMetadataUpdated.type,
  method: takeEvery,
};

const transformMaterialData = (materials: any[]): MaterialMetadataItemType[] => {
  const typeMapping = {
    CONTENT: 'material',
    NOTE: 'note',
  } as const;

  return materials.map((material) => ({
    id: material.id,
    position: material.position,
    type: typeMapping[material.type as keyof typeof typeMapping],
    isSmartListRecommendation: material.is_smart_list_recommendation,
    relatedToContentId: material.recommended_to_content_id,
  }));
};

export function* func(action: SagaReturnType<typeof socketStore.actions.collectionMaterialsMetadataUpdated>) {
  const { payload } = action;
  const { playlist_id: id } = payload;
  const collection = yield* select(selectors.dataById(id));
  if (!id || !payload.materials_metadata || !collection) {
    yield cancel();
    return;
  }

  const route = yield* call(() => getRoute<'Playlist'>());

  const materialsMetadata = transformMaterialData(payload.materials_metadata);

  const addingNote = collection.materialsMetadata.find((item) => item.type === 'new-note');
  if (addingNote) {
    const addingNoteAfterId = collection.materialsMetadata.find((item) => item.position === addingNote.position)?.id || null;
    const index = materialsMetadata.findIndex((item) => item.id === addingNoteAfterId);
    if (index === -1) {
      materialsMetadata.unshift(addingNote);
    } else {
      materialsMetadata.splice(index, 0, addingNote);
    }
  }

  yield put(
    actions.materialsMetadataUpdate({
      collectionId: id,
      items: materialsMetadata,
    }),
  );

  if (route.isScreen('Playlist') && Number(route.params.resourceId) === Number(id)) {
    Alert.info(`"${collection.title}" collection has been updated`);
  }

  // console.log('Need implement how update', id, materialsMetadata);
  // @todo Need implement how update
  // const [addedItems] = differenceBy(materialsMetadata, collection.materialsMetadata, 'id');
  // yield put(actions.materialsMetadataAdded({ collectionId: id, item: addedItems, after: null }));
}
