import { memo, useCallback, useMemo, useState } from 'react';
import { Box, Card, CardActions, IconButton, Tooltip, Typography } from '@mui/joy';

import { guard } from 'utils';

import { useSelector } from 'store/utils/redux/hooks';
import type { ResourceType } from 'widgets/Copilot/store/types';
import * as contentStore from 'store/nodes/content';

import TextExpand from 'ui/TextExpand';
import Icon from 'ui/Icon';

type SummaryProps = {
  resourceType: ResourceType;
  resourceId?: number | null;
};

const Summary = (props: SummaryProps) => {
  const { resourceType, resourceId } = props;

  const data = useSelector((state) => {
    if (resourceType === 'material') {
      return contentStore.selectors.dataById(resourceId)(state);
    }
    return null;
  });

  const [isCollapsed, setIsCollapsed] = useState(true);
  const [collapsible, setCollapsible] = useState(false);

  const handleCollapsibleChange = useCallback((value: boolean) => {
    setCollapsible(value);
  }, []);

  const handleToggleCollapse = useCallback(() => {
    setIsCollapsed(!isCollapsed);
  }, [isCollapsed]);

  const text = useMemo(() => {
    if (guard.isMaterial(data)) {
      return data.gptSummary || data.summary;
    }
    return null;
  }, [data]);

  if (!text) {
    return null;
  }

  return (
    <Card
      variant="soft"
      color="neutral"
      size="md"
      sx={{
        '&': {
          '--Card-radius': (theme) => theme.radius.xl,
          backgroundColor: 'transparent',
          transition: '300ms background-color ease',
        },
        '&:hover': {
          backgroundColor: (theme) => theme.palette.neutral.softBg,
          '@media (max-width: 767px)': {
            backgroundColor: 'transparent',
          },
        },
      }}
    >
      <TextExpand lines={8} isCollapsed={isCollapsed} onCollapsibleChange={handleCollapsibleChange}>
        {text.replace(/^```|```$/g, '')}
      </TextExpand>
      <CardActions sx={{ minHeight: 36, '--Card-padding': 0 }}>
        <Box display="flex" flexDirection="row" alignItems="center" gap={1} mr="auto">
          <Icon name="lightbulb-on" fw weight="light" color="icon" />
          <Typography fontSize={13} fontWeight={600}>
            Copilot summary
          </Typography>
        </Box>
        {collapsible && (
          <Tooltip arrow variant="outlined" size="sm" title={isCollapsed ? 'more' : 'less'}>
            <IconButton onClick={handleToggleCollapse}>
              <Icon name={isCollapsed ? 'angles-down' : 'angles-up'} weight="light" color="inherit" />
            </IconButton>
          </Tooltip>
        )}
      </CardActions>
    </Card>
  );
};

export default memo(Summary);
