import { memo, useEffect, useCallback, useState, useRef } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';

type FocusAndBlurPluginProps = {
  onFocus?: () => void;
  onBlur?: () => void;
};

const FocusAndBlurPlugin = (props: FocusAndBlurPluginProps) => {
  const { onFocus, onBlur } = props;

  const [editor] = useLexicalComposerContext();
  const [isFocused, setIsFocused] = useState(false);
  const timerRef = useRef<any>(null);

  const handleFocus = useCallback(
    (event: FocusEvent) => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
        timerRef.current = null;
      }
      if (!isFocused) {
        setIsFocused(true);
        onFocus?.();
      }
    },
    [onFocus, isFocused],
  );

  const handleBlur = useCallback(
    (event: FocusEvent) => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      timerRef.current = setTimeout(() => {
        if (isFocused) {
          setIsFocused(false);
          onBlur?.();
        }
      }, 200);
    },
    [onBlur, isFocused],
  );

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const rootElement = editor.getRootElement();
    if (rootElement) {
      rootElement.addEventListener('focus', handleFocus, true);
      rootElement.addEventListener('blur', handleBlur, true);
    }

    return () => {
      if (rootElement) {
        rootElement.removeEventListener('focus', handleFocus, true);
        rootElement.removeEventListener('blur', handleBlur, true);
      }
    };
  }, [editor, handleFocus, handleBlur]);

  return null;
};

export default memo(FocusAndBlurPlugin);
