import { call, race, take, delay } from 'redux-saga/effects';
import { eventChannel, type EventChannel, END } from 'redux-saga';
import openWindow from './openWindow';

/**
 * Opens a window and waits until it is closed or until timeout occurs.
 * @param url - The URL to open.
 * @param options - Optional parameters: title and timeout in seconds.
 */
function* openWindowGenerator(url: string, options?: { title?: string; timeout?: number }): Generator<any, void, unknown> {
  const { title = '', timeout = 30 } = options || {};

  const windowDescriptor = (yield call(openWindow, url, title)) as Window | null;

  if (!windowDescriptor) {
    return;
  }

  const channel = (yield call(eventChannel, (emitter) => {
    const windowChecker = setInterval(() => {
      if (windowDescriptor.closed) {
        emitter(END);
      }
    }, 100);

    return () => {
      clearInterval(windowChecker);
    };
  })) as EventChannel<any>;

  try {
    yield race([take(channel), delay(timeout * 1000)]);
  } finally {
    channel.close();
  }
}

export default openWindowGenerator;
