import type { DefaultRootState } from 'react-redux';

export const selectLoadNextCursor =
  (reload: boolean) =>
  (state: DefaultRootState): string | null => {
    const { content } = state;
    const nextCursor = content?.list?.paginationInfo?.nextCursor as string | null | undefined;
    if (reload || !nextCursor) {
      return null;
    }
    return nextCursor;
  };

export const createPlaylistSelectedIds = (state: DefaultRootState): number[] => state.myContent.createPlaylist.selectedIds;

export const createPlaylistIsContentSelected = (id: number) => (state: DefaultRootState) => state.myContent.createPlaylist.selectedIds.includes(id);

export const materialData = (state: DefaultRootState) => state.myContent.addMaterial.data;

export const materialLastCreatedId = (state: DefaultRootState) => state.myContent.addMaterial.lastCreatedId;

export const isAddMaterialParsing = (state: DefaultRootState): boolean => state.myContent.meta.isAddMaterialParsing;

export const isAddMaterialAdding = (state: DefaultRootState): boolean => state.myContent.meta.isAdding;
