import { type SagaReturnType, takeEvery, cancel } from 'redux-saga/effects';

import { gtm } from 'utils';

import * as messageStore from 'store/nodes/message';
import { call } from 'store/utils/saga/effects';

export const config = {
  action: messageStore.socketActions.messageInfo.type,
  method: takeEvery,
};

export function* func(action: SagaReturnType<typeof messageStore.socketActions.messageInfo>) {
  const { id, context } = action.payload;
  const { type: eventType, resourceType, resourceId } = context;

  if (eventType !== 'library_add') {
    yield cancel();
    return;
  }

  yield call(() =>
    gtm.dataLayer.push({
      event: 'add_to_library',
      data_type: resourceType,
      resource_id: resourceId,
    }),
  );
}
