import { memo } from 'react';
import { Box } from '@mui/joy';
import { useScreenSize } from 'hooks';

import SearchMethods from '../../../elements/SearchMethods';
import Tags from '../../../elements/Tags';

const TopBarMobile = () => {
  const screenSize = useScreenSize();

  if (!screenSize.is767) {
    return null;
  }

  return (
    <Box display="flex" flexDirection="column" mt={5} mb={2.5}>
      <SearchMethods alignItems="center" ml={1} />
      <Tags mt={2} />
    </Box>
  );
};

export default memo(TopBarMobile);
