import { useMemo } from 'react';
import type { ImageType } from 'app/entities';
import { updateImageSize } from 'utils';

/**
 * Hook to calculate meta data for the Picture component.
 * @param {ImageType | string | null} source - The image source.
 * @param {'small' | 'medium' | 'large' | 'original'} size - The size variant.
 * @param {number | undefined} width - The width of the image.
 * @param {number | undefined} height - The height of the image.
 * @returns {object} Meta data including url, width, height, and ratio.
 */
export function usePictureMeta(source: ImageType | string | null, size: 'small' | 'medium' | 'large' | 'original', width?: number, height?: number) {
  return useMemo(() => {
    const prepared = updateImageSize(source, size);
    if (!prepared) {
      return {
        url: null,
        width: null,
        height: null,
        ratio: null,
      };
    }
    return {
      url: prepared.url,
      width: width || prepared.width || null,
      height: height || prepared.height || null,
      ratio: prepared.width && prepared.height ? prepared.width / prepared.height : null,
    };
  }, [source, size, width, height]);
}
