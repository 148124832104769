import { createContext } from 'react';
import { Dimensions } from 'react-native';

import type { Config } from '../types';

export const Context = createContext<Config>({
  setScrollValue: () => undefined,
  getScrollValue: () => ({
    top: 0,
    dimension: {
      window: Dimensions.get('window'),
    },
  }),
});
