import { memo, type ReactElement } from 'react';
import { Image, StyleSheet, useWindowDimensions } from 'react-native';

import { View } from 'components/Themed';
import { unit } from 'utils';

import Icon from './assets/Icon.svg';

type PreviewProps = {
  uri?: string | null;
};

const Preview = (props: PreviewProps): ReactElement => {
  const { uri } = props;
  const dimension = useWindowDimensions();
  return (
    <View style={[styles.Preview, dimension.height < 700 && styles.min]} lightColor="#e7e7e7" darkColor="#232326">
      <View style={styles.icon}>
        <Icon fill="#4E4E53" />
      </View>
      {uri && <Image source={{ uri }} style={styles.image} />}
    </View>
  );
};

const styles = StyleSheet.create({
  Preview: {
    position: 'relative',
    width: unit(200),
    height: unit(200),
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: '#9A99A2',
    borderRadius: unit(8),
    overflow: 'hidden',
  },
  min: {
    width: unit(150),
    height: unit(150),
  },
  icon: {
    width: unit(74),
    height: unit(74),
  },
  image: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    aspectRatio: 1,
    borderRadius: unit(7),
  },
});

export default memo(Preview);
