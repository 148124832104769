import { memo, useCallback } from 'react';
import { StyleSheet } from 'react-native';

import { useSelector } from 'store/utils/redux/hooks';
import * as userStore from 'store/nodes/user';

import Icon from 'ui/Icon';
import { controller as modal } from 'components/Modal2';
import MenuButton from 'components/UIBar/elements/MenuButton';

type ThemeSwitcherProps = {
  isSlim?: boolean;
};

const Settings = (props: ThemeSwitcherProps) => {
  const { isSlim } = props;

  const userProfile = useSelector(userStore.selectors.getMy);

  const handleSetting = useCallback(() => {
    modal.popup.profileSettings.open();
  }, []);

  if (!userProfile) {
    return null;
  }

  return (
    <>
      <MenuButton
        style={styles.colorSchemeItem}
        onPress={handleSetting}
        text="Settings"
        isSlim={isSlim}
        isSelected={false}
        revertSlim
        iconRight={<Icon name="gear" fw size="lg" weight="light" color="icon" />}
      />
    </>
  );
};

const styles = StyleSheet.create({
  colorSchemeItem: {
    justifyContent: 'space-between',
  },
});

export default memo(Settings);
