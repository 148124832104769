import { takeEvery, put, cancel, take } from 'redux-saga/effects';
import { track } from '@amplitude/analytics-browser';

import { getRoute } from 'navigation/methods';

import { call, select } from 'store/utils/saga/effects';

import * as noteStore from 'store/nodes/note';
import * as collectionStore from 'store/nodes/playlist';
import * as interactionStore from 'store/nodes/interaction';
import * as userStore from 'store/nodes/user';

import * as libraryStore from 'store/nodes/library';
import { actions } from '../slice';

export const config = {
  action: actions.runPageHandler.type,
  method: takeEvery,
};

export function* func() {
  const route = yield* call(() => getRoute<'Playlist'>());
  if (!route.isScreen('Playlist') || !route.params.resourceId) {
    yield cancel();
    return;
  }

  const permission = yield* select((state) => state.playlist.permissions);
  if (permission.length === 0) {
    yield put(collectionStore.actions.loadPermissionRole());
  }

  const user = yield* select(userStore.selectors.getMy);
  if (!user && typeof localStorage !== 'undefined') {
    localStorage.setItem('last-route', `collection/${route.params.resourceId}`);
  }

  yield put(collectionStore.actions.loadById({ id: route.params.resourceId }));
  yield put(interactionStore.actions.view('playlist', route.params.resourceId));
  yield put(
    noteStore.actions.loadByResource({
      resourceType: 'collection',
      resourceId: route.params.resourceId,
    }),
  );

  const interactionFilters = yield* select(libraryStore.selectors.panelInteractions);
  yield put(libraryStore.actions.disableFilter(interactionFilters.map((item) => item.id)));

  yield take(collectionStore.actions.loadByIdDone);
  const collection = yield* select(collectionStore.selectors.dataById(route.params.resourceId));
  if (collection?.user) {
    yield put(userStore.actions.loadProfile(collection.user.login));
  }

  yield* call(() =>
    track('Playlist', {
      id: route.params.resourceId,
    }),
  );
}
