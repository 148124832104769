import { type SagaReturnType, takeEvery, put } from 'redux-saga/effects';

import { askSearchClear } from '../socketActions';

import { actions } from '../slice';

export const config = {
  action: [askSearchClear.type, actions.searchClear.type],
  method: takeEvery,
};

export function* func(action: SagaReturnType<typeof askSearchClear | typeof actions.searchClear>) {
  yield put(actions.setSequence({ sequence: [] }));
}
