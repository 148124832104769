import { v4 as uuidV4 } from 'uuid';

const getSessionId = () => {
  if (typeof sessionStorage === 'undefined') {
    return null;
  }

  let sessionId = sessionStorage.getItem('appId');
  if (sessionId) {
    return sessionId;
  }

  sessionId = `session-id.${uuidV4()}`;
  sessionStorage.setItem('appId', sessionId);

  return sessionId;
};

export default getSessionId;
