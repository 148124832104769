import one from './one';
import items from './items';
import watching from './watching';
import * as source from './source';
import * as filter from './filter';
import review from './review/route';
import search from './search/route';
import * as highlight from '../highlight/route';
import * as note from '../note/route';

export default {
  one,
  items,
  watching,
  source,
  filter,
  review,
  search,
  highlight,
  note,
};
