import { createSelector } from '@reduxjs/toolkit';
import { type DefaultRootState } from 'react-redux';

export const selector = createSelector(
  (state: DefaultRootState) => state.content.data[state.content.watched.id || 0],
  (state: DefaultRootState) => state.content.watched.isShow,
  (data, isShow) => ({
    data,
    isShow,
  }),
);

export const dataWatched = (state: DefaultRootState) => selector(state);
