import {
  type SagaReturnType,
  takeEvery,
  put,
  cancel,
} from 'redux-saga/effects';

import { call } from 'store/utils/saga/effects';
import { request } from 'services/api';

import * as actions from '../actions';

export const config = {
  action: actions.loadById.type,
  method: takeEvery,
};

export function* func(action: SagaReturnType<typeof actions.loadById>) {
  const ids = !Array.isArray(action.payload.id)
    ? [action.payload.id]
    : action.payload.id;
  if (!Array.isArray(ids) || ids.length === 0) {
    yield cancel();
    return;
  }

  const { data, error } = yield* call(() =>
    request.get('/stack-1/notes', { query: { ids: ids.join(',') } }),
  );

  if (!data || error) {
    console.log(error?.message || 'error load notes');
    yield cancel();
    return;
  }

  yield put(actions.setData(data));
}
