import { type DefaultRootState } from 'react-redux';

import subscriptionConfigSelector from './subscriptionConfig';

import { type CurrentPlanStateType } from '../../types';

const currentPlan = (state: DefaultRootState): CurrentPlanStateType => {
  const subscriptionConfig = subscriptionConfigSelector(state);
  const id = state.subscription.state.plan;
  const { hasPaymentMethod, expirationDate, canceledAt, status } = state.subscription.state;
  const isIncomplete = status === 'incomplete';
  const isPaused = !!canceledAt;
  const isLoaded = id !== '-1';

  let period: string | null = null;

  if (!isLoaded) {
    return {
      id,
      period,
      name: null,
      expirationDate,
      canceledAt,
      hasPaymentMethod,
      isIncomplete,
      isPaused,
      isLoaded,
    };
  }

  const { identity, name = null } = subscriptionConfig.plans.find((plan) => plan.identity.planId === id) || {};
  subscriptionConfig.periods.forEach((item) => {
    if (!identity || !!period) {
      return;
    }
    if (item.planIds.includes(identity?.planId)) {
      period = item.id;
    }
  });

  if (!identity) {
    return {
      id,
      period,
      name: null,
      expirationDate,
      canceledAt,
      hasPaymentMethod,
      isIncomplete,
      isPaused,
      isLoaded,
    };
  }

  return {
    id,
    period,
    name,
    expirationDate,
    canceledAt,
    hasPaymentMethod,
    isIncomplete,
    isPaused,
    isLoaded,
  };
};

export default currentPlan;
