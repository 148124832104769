import { memo, type SetStateAction } from 'react';
import { Box, RadioGroup, Radio, FormControl, Typography, List, ListItem } from '@mui/joy';
import Icon from 'ui/Icon';

import type { PromoDataType } from 'widgets/Subscription/ui/PromoInput';
import usePlan from 'widgets/Subscription/model/usePlan';

import { formatCurrency } from 'utils';

import useTrialPlans from '../../../model/useTrialPlans';
import useCostsWithPromo from '../../../model/useCostsWithPromo';

type BillingFormProps = {
  planId: string;
  onPlanChange: (event: { target: { value: SetStateAction<string> } }) => void;
  isDisabled?: boolean;
  isSkipLoading?: boolean;
  promo?: PromoDataType | null;
};

const BillingForm = (props: BillingFormProps) => {
  const { planId, onPlanChange, isDisabled, isSkipLoading, promo } = props;

  const trialPlans = useTrialPlans();
  const costMap = useCostsWithPromo(promo);
  const { plan } = usePlan(planId);

  const currentCost = costMap[planId];

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        maxWidth: 600,
        gap: 2,
      }}
    >
      <Box display="flex" flexDirection="row" gap={1}>
        <Icon
          family="kit"
          name="ikicopilot"
          fw
          size="xl"
          color="icon"
          sx={{
            width: 25,
            height: 22,
            ml: -0.4,
          }}
        />
        <Typography fontSize={16} fontWeight={500}>
          IKI {plan?.name} {!!plan?.note && plan?.note}
        </Typography>
      </Box>
      <FormControl disabled={isDisabled || isSkipLoading}>
        <RadioGroup value={planId} onChange={onPlanChange}>
          <List>
            {trialPlans.map((item) => {
              const cost = costMap[item.identity.planId];
              return (
                <ListItem key={item.identity.planId} sx={{ px: 0, alignItems: 'stretch' }}>
                  <Radio value={item.identity.planId} label={`${item.identity.label}`} />
                  <Box flex={1} />
                  {cost.type === 'regular' && <Typography component="span">{`${formatCurrency(cost.value)} / mo`}</Typography>}
                  {cost.type === 'special' && (
                    <Box textAlign="right">
                      <Typography component="span">{`${formatCurrency(cost.promo)} / mo for first ${promo?.durationInMonths} months`}</Typography>
                      <Typography component="span" color="neutral" level="body-xs">{`${formatCurrency(cost.value)} / mo thereafter`}</Typography>
                    </Box>
                  )}
                </ListItem>
              );
            })}
          </List>
        </RadioGroup>
      </FormControl>
      <Box display="flex" justifyContent="space-between" mb={2}>
        <Typography>Total</Typography>
        {currentCost.type === 'regular' && !promo && <Typography fontWeight={500}>{formatCurrency(currentCost.value * 12)} billed yearly</Typography>}
        {currentCost.type === 'special' && promo && (
          <Box textAlign="right">
            <Typography fontWeight={500}>
              <Typography color="primary">
                {formatCurrency(currentCost.promo * promo.durationInMonths + currentCost.value * (12 - promo.durationInMonths))}
              </Typography>{' '}
              billed for the first year
            </Typography>
            <Typography color="neutral" level="body-xs">
              {formatCurrency(currentCost.value * 12)} annually thereafter
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default memo(BillingForm);
