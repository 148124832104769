import { type SagaReturnType, takeEvery, put, cancel } from 'redux-saga/effects';

import * as messageStore from 'store/nodes/message';

export const config = {
  action: messageStore.socketActions.messageInfo.type,
  method: takeEvery,
};

export function* func(action: SagaReturnType<typeof messageStore.socketActions.messageInfo>) {
  const { id, context } = action.payload;
  const { type: eventType } = context;

  if (eventType !== 'stripe.customer.updated.added_payment_method') {
    yield cancel();
    return;
  }

  yield put(messageStore.actions.prevent({ id }));
}
