import moment from 'moment-timezone';

import type { ResultLegacy, CollectionType } from 'app/entities';

import request from 'services/api/request';
import interaction from 'services/api/interaction/route';
import network from 'lib/network';

export default async (
  ids: number[],
): Promise<ResultLegacy<CollectionType[]>> => {
  if (!ids || (Array.isArray(ids) && ids.length === 0)) {
    return {
      data: [],
      error: null,
    };
  }
  const idsFinal = Array.isArray(ids) ? ids : [ids];

  const [playlists, interactionMap, pendingContentCount] = await Promise.all([
    Promise.all(
      idsFinal.map((id) =>
        request.get<CollectionType>(`/stack-1/user/playlists/${id}`),
      ),
    ).then((results) => {
      let data: CollectionType[] = [];
      let error: Error | null = null;
      results.forEach((result, key) => {
        if (
          result.error &&
          'message' in result.error &&
          result.error.message === 'object_not_found'
        ) {
          data.push({
            id: idsFinal[key],
            internalUrl: '',
            contentImages: [],
            materialsMetadata: [],
            cover: null,
            createdAt: '',
            description: '',
            duration: '',
            isCompleted: false,
            isPrivate: false,
            isShown: false,
            level: '',
            tags: [],
            title: '',
            updatedAt: '',
            userId: 0,
            deletedAt: moment().toISOString(),
          });
          return;
        }
        if (result.error || !result.data) {
          error =
            (!Array.isArray(result.error) ? result.error : result.error?.[0]) ||
            new Error('Error server #15');
          data = [];
          return;
        }
        data.push(result.data);
      });
      return {
        data,
        error,
      };
    }),
    interaction.items('playlist', idsFinal),
    network
      .request<{
        pendingContentCount: Record<number, number | null>;
      }>('/stack-1/user/playlists/pendingContentCount')
      .query({ resourceIds: idsFinal })
      .get(),
  ]);

  if (
    playlists.error ||
    !playlists.data ||
    interactionMap.error ||
    !interactionMap.data
  ) {
    return {
      data: null,
      error: playlists.error || interactionMap.error,
    };
  }

  return {
    data: playlists.data.map((collection) => ({
      ...collection,
      ...interactionMap.data?.[collection?.id],
      pendingMaterialsCount:
        pendingContentCount.data?.pendingContentCount?.[collection?.id],
    })),
    error: null,
  };
};
