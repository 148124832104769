import { type SagaReturnType, takeEvery, put, cancel } from 'redux-saga/effects';

import * as socketStore from 'store/nodes/socket';
import { actions } from '../slice';

export const config = {
  action: socketStore.actions.copilotAnswer.type,
  method: takeEvery,
};

export function* func(action: SagaReturnType<typeof socketStore.actions.copilotAnswer>) {
  const { chunk, request_id: requestId } = action.payload;
  if (chunk === ':D0NE:') {
    yield put(actions.answerEnd({ requestId }));
    yield cancel();
    return;
  }
  yield put(actions.answerAddChunk({ requestId, text: chunk }));
}
