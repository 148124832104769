import { useSelector } from 'react-redux';
import * as searchStore from 'store/nodes/search';
import { useMemo } from 'react';

const useSearchState = () => {
  const list = useSelector(searchStore.selectors.list);
  const listMeta = useSelector(searchStore.selectors.listMeta);
  const listStatus = useSelector(searchStore.selectors.listStatus);

  return useMemo(() => {
    if (!listMeta.firstPage.isLoaded && !listMeta.firstPage.isLoading && list.sequence.length === 0) {
      return 'no-request';
    }
    if (listMeta.firstPage.isLoaded && !listMeta.firstPage.isLoading && list.sequence.length === 0 && listStatus === 'no-results') {
      return 'no-results';
    }
    if (listMeta.firstPage.isLoaded && !listMeta.firstPage.isLoading && list.sequence.length === 0 && listStatus === 'nothing-to-search') {
      return 'nothing-to-search';
    }
    return 'list';
  }, [listMeta.firstPage.isLoaded, listMeta.firstPage.isLoading, list.sequence.length, listStatus]);
};

export default useSearchState;
