import { memo, useCallback, useEffect } from 'react';
import { Card, CardContent, Box, Typography, useTheme } from '@mui/joy';

import { useDispatcher, useSelector } from 'store/utils/redux/hooks';
import * as contentStore from 'store/nodes/content';

import { guard } from 'utils';

import openLink from '../model/openLink';

type TooltipCardProps = {
  materialId: number;
};

const TooltipCard = (props: TooltipCardProps) => {
  const { materialId } = props;
  const dispatcher = useDispatcher();
  const theme = useTheme();

  const data = useSelector(contentStore.selectors.dataById(materialId));

  const handleClick = useCallback(() => {
    if (!data?.id) {
      return;
    }
    const resourceId = data.id;
    if (guard.isMaterial(data) && data?.type) {
      const resourceType = data.type.toLowerCase?.();
      openLink(`/content/${resourceType}/${resourceId}`, '_blank');
    }
  }, [data]);

  useEffect(() => {
    if (!materialId || data) {
      return;
    }
    dispatcher.content.loadById(materialId);
  }, [materialId, data]);

  return (
    <Card
      variant="plain"
      onClick={handleClick}
      orientation="horizontal"
      sx={{
        width: 320,
        cursor: 'pointer',
        alignItems: 'center',
      }}
    >
      <CardContent>
        <Box display="flex" m={-2}>
          {data?.image?.url && (
            <img
              src={data.image.url}
              loading="lazy"
              alt=""
              style={{
                height: 124,
                aspectRatio: 1,
                objectFit: 'cover',
                borderRadius: theme.radius.sm,
              }}
            />
          )}
          <Box display="flex" flexDirection="column" gap={1} pt={1} pb={!data?.image?.url ? 1 : 0} px={1.5}>
            <Typography
              level="title-lg"
              id="card-description"
              display="-webkit-box"
              overflow="hidden"
              fontWeight={600}
              fontSize={14}
              sx={{
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
              }}
            >
              {data?.title}
            </Typography>
            <Typography
              level="body-sm"
              aria-describedby="card-description"
              display="-webkit-box"
              overflow="hidden"
              fontSize={14}
              fontWeight={400}
              sx={{
                WebkitLineClamp: 3,
                WebkitBoxOrient: 'vertical',
              }}
            >
              {data?.description}
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default memo(TooltipCard);
