import { useCallback, useMemo } from 'react';
import { Platform } from 'react-native';

import { isCollection, isMaterial } from 'utils/guards';
import { guard } from 'utils';
import type { CollectionType, MaterialType, AuthorType, UserType } from 'app/entities';
import { useNavigate } from 'navigation/hooks';

import { useDispatcher } from 'store/utils/redux/hooks';

import { controller as modal } from 'components/Modal2';

export default (data?: MaterialType | CollectionType | { tags: string[] }) => {
  const navigate = useNavigate();
  const dispatcher = useDispatcher();

  const openSource = useCallback(() => {
    if (!guard.isMaterial(data) || !data.originalUrl) {
      return;
    }
    if (Platform.OS === 'web' && !!document) {
      const link = document.createElement('a');
      link.setAttribute('href', data.originalUrl);
      link.setAttribute('target', '_blank');
      link.click();
    }
  }, [data]);

  const openAuthor = useCallback((author: AuthorType | UserType | null) => {
    if (!author) {
      return;
    }
    if (guard.isUser(author)) {
      navigate('Profile', {
        login: author.login,
      });
      return;
    }
    if (guard.isAuthor(author) && author.owner) {
      navigate('Profile', {
        login: author.owner.login,
      });
      return;
    }
    if (guard.isAuthor(author) && !author.owner) {
      navigate('Author', {
        id: author.id,
      });
    }
  }, []);

  const openResource = useCallback(() => {
    if (!guard.isMaterial(data) && !guard.isCollection(data)) {
      return;
    }
    const resourceId = data.id;
    if (guard.isMaterial(data) && data?.type) {
      const resourceType = data.type.toLowerCase?.();
      navigate('Content', {
        resourceId,
        resourceType,
      });
    }
    if (guard.isCollection(data)) {
      navigate('Playlist', {
        resourceId,
      });
    }
  }, [data]);

  const searchByTag = useCallback((tag: string) => {
    if (!tag) {
      return;
    }
    navigate('Ask', { tags: [tag] });
  }, []);

  const filterLibraryByTag = useCallback((tag: string) => {
    if (!tag) {
      return;
    }
    dispatcher.library.enableTempTagFilter(tag);
  }, []);

  const toggleBookmark = useCallback(() => {
    if (!guard.isMaterial(data) && !guard.isCollection(data)) {
      return;
    }
    if (isMaterial(data)) {
      dispatcher.interaction.toggleBookmark('content', data.id);
    }
    if (isCollection(data)) {
      dispatcher.interaction.toggleBookmark('playlist', data.id);
    }
  }, [data]);

  const showMenu = useCallback(
    (options: {
      hasQueue?: boolean;
      hasComplete?: boolean;
      hasToCollection?: boolean;
      hasShare?: boolean;
      hasLink?: boolean;
      hasPlaySummary?: boolean;
      hasTLDR?: boolean;
      hasDislike?: boolean;
      hasShowRelated?: boolean;
      hasRemove?: boolean;
    }) => {
      if (!guard.isMaterial(data) && !guard.isCollection(data)) {
        return;
      }
      modal.menu.cardActions.open({
        resource: isMaterial(data) ? 'content' : 'playlist',
        resourceId: data?.id,
        options,
      });
    },
    [],
  );

  return useMemo(
    () => ({
      openSource,
      openAuthor,
      openResource,
      searchByTag,
      toggleBookmark,
      showMenu,
      filterLibraryByTag,
    }),
    [openSource, openAuthor, openResource, searchByTag, toggleBookmark, showMenu, filterLibraryByTag],
  );
};
