import { type ScaledSize, Platform, Dimensions } from 'react-native';

/**
 * @deprecated
 * @param v
 * @param dem
 */
export default (v: number | undefined, dem?: ScaledSize): number => {
  const isWeb = Platform.OS === 'web';
  const finalDem = dem || Dimensions.get('window');
  const finalValue = typeof v === 'number' && finalDem.width < 375 ? (v * finalDem.width) / 375 : v;
  if (!finalValue) {
    return undefined as unknown as number;
  }
  if (isWeb) {
    return `${finalValue / 16}rem` as unknown as number;
  }
  return finalValue;
};
