import feed from './feed';
import news from './news';
import filters from './filters';
import remove from './remove';

export default {
  feed,
  news,
  filters,
  remove,
};
