/**
 * If you are not familiar with React Navigation, check out the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */
import React, { memo, useCallback, useEffect } from 'react';
import { type ColorSchemeName } from 'react-native';

import Alert from 'components/Alert';

import { Router } from './router';
import { useNavigate, useRoute } from './hooks';
import * as navigateMethod from './methods/navigate';

import Stack from './Stack';

const defaultProps = {};

const MethodsProvider = memo(() => {
  const navigate = useNavigate();

  useEffect(() => {
    navigateMethod.setMethod(navigate);
  }, [navigate]);

  return null;
});

type LocationListenerProps = {
  onChange?: () => void;
};

const LocationListener = memo(({ onChange }: LocationListenerProps) => {
  const route = useRoute();

  useEffect(() => {
    onChange?.();
  }, [route]);

  return null;
});

type NavigationProps = {
  colorScheme: ColorSchemeName;
  onReady?: () => void;
  onLocationChange?: () => void;
} & typeof defaultProps;

const Toasts = memo(() => <Alert.Init />);

const Navigation = (props: NavigationProps) => {
  const { colorScheme, onReady, onLocationChange } = props;

  const handleReady = useCallback(() => {
    onReady?.();
  }, [onReady]);

  return (
    <Router onReady={handleReady}>
      <MethodsProvider />
      <LocationListener onChange={onLocationChange} />
      <Toasts />
      <Stack />
    </Router>
  );
};

Navigation.defaultProps = defaultProps;

export default memo(Navigation);
