import { memo, type PropsWithChildren } from 'react';
import { StyleSheet } from 'react-native';

import { View } from 'components/Themed';
import { useMarginUnits, useThemeColor } from 'hooks';

const defaultProps = {
  orientation: 'horizontal',
};

type DividerProps = {
  orientation?: 'horizontal' | 'vertical';
  margin?: string;
} & typeof defaultProps;

const Divider = (props: PropsWithChildren<DividerProps>) => {
  const { orientation, margin, children } = props;
  const marginUnits = useMarginUnits(margin);
  const borderColor = useThemeColor({ light: '#ADADAD', dark: '#303030' });
  return (
    <View style={[orientation === 'horizontal' && styles.dividerHorizontal, orientation === 'vertical' && styles.dividerVertical, styles.divider, marginUnits]}>
      <View style={[styles.border, orientation === 'horizontal' && styles.borderHorizontal, orientation === 'vertical' && styles.borderVertical, { borderColor }]} />
      <View style={styles.children}>{children}</View>
    </View>
  );
};

Divider.defaultProps = defaultProps;

const styles = StyleSheet.create({
  divider: {
    alignItems: 'stretch',
  },
  dividerHorizontal: {
    flexDirection: 'column',
  },
  dividerVertical: {
    flexDirection: 'row',
  },
  border: {
    position: 'absolute',
  },
  borderHorizontal: {
    borderWidth: 0,
    borderTopWidth: 1,
    height: 0,
    width: '100%',
    top: '50%',
  },
  borderVertical: {
    borderWidth: 0,
    borderLeftWidth: 1,
    width: 0,
    height: '100%',
    left: '50%',
  },
  children: {
    position: 'relative',
  },
});

export default memo(Divider);
