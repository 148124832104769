import { type SagaReturnType, takeLatest, put, cancel } from 'redux-saga/effects';

import { call, select } from 'store/utils/saga/effects';

import { gtm } from 'utils';
import network from 'lib/network';
import Alert from 'components/Alert';

import { actions } from '../slice';

export const config = {
  action: actions.startTrial.type,
  method: takeLatest,
};

export function* func(action: SagaReturnType<typeof actions.startTrial>) {
  const { planId, promoCode, paymentMethodId, stripe } = action.payload;

  const { errors, data } = yield* call(() =>
    network
      .request<{ requiresAction: boolean; clientSecret: string } | { success: boolean }>(`/stack-2/onboarding/payment/subscribe/plans/${planId}`, {
        baseUrl: process.env.PROTECTED_API_URL || '/api',
      })
      .body({
        trial: true,
        paymentMethodId,
        ...(promoCode ? { promotion_code: promoCode } : undefined),
      })
      .post(),
  );

  if ((errors?.length || 0) > 0 || !data) {
    yield* call(() => Alert.error(errors?.[0].message || 'Error subscribe #50'));
    yield put(actions.startTrialDone());
    yield cancel();
    return;
  }

  if ('success' in data && data?.success) {
    yield call(() => Alert.success('Subscription successful!'));
    yield put(actions.startTrialDone());
    yield put(actions.nextStep());
    yield cancel();
    return;
  }

  if ('requiresAction' in data && data?.requiresAction) {
    const { clientSecret } = data;
    const confirmCardPayment = stripe.confirmCardPayment.bind(stripe);
    const { error: confirmError, paymentIntent } = yield* call(() => confirmCardPayment(clientSecret));

    if (confirmError) {
      yield call(() => Alert.error(confirmError.message));
      yield put(actions.startTrialDone());
      yield cancel();
      return;
    }
    if (paymentIntent && paymentIntent.status === 'succeeded') {
      yield call(() =>
        gtm.dataLayer.push({
          event: 'purchase',
          ecommerce: {
            transaction_id: paymentIntent.id,
            currency: 'USD',
            value: (paymentIntent.amount / 1000).toFixed(2).replace('.', ','),
            items: [
              {
                item_name: planId === '1' ? 'Pro Monthly Subscription' : 'Pro Yearly Subscription',
                item_category: planId === '1' ? 'Monthly' : 'Yearly',
                item_list_name: 'Individual',
                price: (paymentIntent.amount / 1000).toFixed(2).replace('.', ','),
                quantity: 1,
              },
            ],
          },
        }),
      );
      yield call(() => Alert.success('Subscription successful!'));
      yield put(actions.startTrialDone());
      yield put(actions.nextStep());
      yield cancel();
    } else {
      yield call(() => Alert.error('Payment failed.'));
      yield put(actions.startTrialDone());
      yield cancel();
    }
    yield cancel();
    return;
  }

  yield* call(() => Alert.error('Unexpected error.'));
}
