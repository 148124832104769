import { type DefaultRootState } from 'react-redux';
import { createCachedSelector } from 're-reselect';

const selector = createCachedSelector(
  (state: DefaultRootState, key: string) => state.value.data[key] || null,
  (value) => value,
)((state, key) => key);

export default <T = any>(key: string) =>
  (state: DefaultRootState): T | null => {
    return selector(state, key) as T;
  };
