import { takeEvery, cancel } from 'redux-saga/effects';
import { track } from '@amplitude/analytics-browser';

import { getRoute } from 'navigation/methods';

import { call } from 'store/utils/saga/effects';

import { actions } from '../slice';

export const config = {
  action: actions.runPageHandler.type,
  method: takeEvery,
};

export function* func() {
  const route = yield* call(() => getRoute<'CommunityCollection'>());
  if (!route.isScreen('CommunityCollection')) {
    yield cancel();
    return;
  }

  yield* call(() => track('CommunityCollection'));
}
