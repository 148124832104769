import { useMemo } from 'react';

import { useSelector } from 'store/utils/redux/hooks';
import * as userStore from 'store/nodes/user';

import subscriptionAppSumoConfig from 'data/subscriptionAppSumoConfig.json';
import subscriptionStripeConfig from 'data/subscriptionStripeConfig.json';

const useSubscriptionConfig = (): typeof subscriptionStripeConfig => {
  const user = useSelector(userStore.selectors.getMy);
  return useMemo(() => {
    if (!user) {
      return subscriptionStripeConfig;
    }
    if (user?.isAppsumo) {
      return subscriptionAppSumoConfig as unknown as typeof subscriptionStripeConfig;
    }
    return subscriptionStripeConfig;
  }, [user?.isAppsumo, subscriptionStripeConfig, subscriptionAppSumoConfig]);
};

export default useSubscriptionConfig;
