import { toLegacyResourceType } from 'utils';

import type { SuccessResult } from 'app/entities';
import { request } from 'services/api';

export default async (
  resourceType: 'collection' | 'material' | 'note',
  resourceId: number,
): Promise<SuccessResult> => {
  const response = await request.delete<SuccessResult>(
    `/stack-1/user/library/${toLegacyResourceType(resourceType)}/${resourceId}`,
  );

  const [error] = Array.isArray(response?.error)
    ? response.error
    : [response?.error];
  if (Array.isArray(error)) {
    return {
      data: null,
      error,
    };
  }

  return {
    data: {
      success: true,
    },
    error,
  };
};
