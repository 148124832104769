import Subscription from 'widgets/Subscription/modals/Subscription';

import StripeElementsWrapper from 'components/StripeElementsWrapper';
import PlusBottomSheet from './components/PlusBottomSheet';
import EditMaterial from './components/EditMaterial';
import AddToCollection from './components/AddToCollection';
import AddOrEditCollection from './components/AddorEditCollection';
import SearchUser from './components/SearchUser';
import ProductVideo from './components/ProductVideo';
import AppSumoMatch from './components/AppSumoMatch';
import YoutubeAuth from './components/YoutubeAuth';

const Modals = () => (
  <>
    <PlusBottomSheet />
    <EditMaterial />
    <AddOrEditCollection />
    <AddToCollection />
    <SearchUser />
    <ProductVideo />
    <AppSumoMatch />
    <YoutubeAuth />
    <Subscription />
  </>
);

export default Modals;
