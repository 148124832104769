import { type SagaReturnType, takeLatest, put, cancel, delay } from 'redux-saga/effects';

import network from 'lib/network';
import Alert from 'components/Alert';
import { gtm } from 'utils';

import { call } from 'store/utils/saga/effects';
import * as socketActions from 'widgets/Subscription/store/socketActions';
import * as modalStore from 'widgets/modals/store';

import { actions } from '../slice';

export const config = {
  action: [actions.subscribeStripeElements.type, socketActions.subscriptionPaymentIntentSucceeded.type],
  method: takeLatest,
};

export function* func(action: SagaReturnType<typeof actions.subscribeStripeElements | typeof socketActions.subscriptionPaymentIntentSucceeded>) {
  if (action.type === actions.subscribeStripeElements.type) {
    const { errors, data } = yield* call(() =>
      network
        .request<{ requiresAction: boolean; clientSecret: string }>(`/stack-2/onboarding/payment/subscribe/plans/${action.payload.planId}`, {
          baseUrl: process.env.PROTECTED_API_URL || '/api',
        })
        .body({
          trial: action.payload.hasTrial === true,
          paymentMethodId: action.payload.paymentMethodId,
          ...(action.payload.promoCode ? { promotion_code: action.payload.promoCode } : undefined),
        })
        .post(),
    );

    if ((errors?.length || 0) > 0 || !data) {
      yield* call(() => Alert.error(errors?.[0].message || 'Error subscribe #50'));
      yield put(actions.subscribeStripeElementsDone());
      yield cancel();
      return;
    }

    if (data?.requiresAction) {
      const { clientSecret } = data;
      const confirmCardPayment = action.payload.stripe.confirmCardPayment.bind(action.payload.stripe);
      const { error: confirmError, paymentIntent } = yield* call(() => confirmCardPayment(clientSecret));
      if (confirmError) {
        yield call(() => Alert.error(confirmError.message));
        yield put(actions.subscribeStripeElementsDone());
        yield put(modalStore.actions.close('Subscription'));
        yield cancel();
      } else if (paymentIntent && paymentIntent.status === 'succeeded') {
        yield call(() =>
          gtm.dataLayer.push({
            event: 'purchase',
            ecommerce: {
              transaction_id: paymentIntent.id,
              currency: 'USD',
              value: (paymentIntent.amount / 1000).toFixed(2).replace('.', ','),
              items: [
                {
                  item_name: action.payload.planId === '1' ? 'Pro Monthly Subscription' : 'Pro Yearly Subscription',
                  item_category: action.payload.planId === '1' ? 'Monthly' : 'Yearly',
                  item_list_name: 'Individual',
                  price: (paymentIntent.amount / 1000).toFixed(2).replace('.', ','),
                  quantity: 1,
                },
              ],
            },
          }),
        );
        yield call(() => Alert.success('Subscription successful!'));
        yield put(modalStore.actions.close('Subscription'));
        yield cancel();
      } else {
        yield call(() => Alert.error('Payment failed.'));
        yield put(actions.subscribeStripeElementsDone());
        yield put(modalStore.actions.close('Subscription'));
        yield cancel();
      }
    }
  }
  if (action.type === socketActions.subscriptionPaymentIntentSucceeded.type) {
    yield delay(2000);
    yield put(actions.subscribeStripeElementsDone());
    yield put(modalStore.actions.close('Subscription'));
    yield cancel();
  }
}
