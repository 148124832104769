import { useMemo } from 'react';
import useSubscriptionConfig from './useSubscriptionConfig';

const usePeriodOptions = () => {
  const { periods } = useSubscriptionConfig();
  return useMemo(
    () =>
      periods.map((period) => ({
        value: period.id,
        label: period.name,
      })),
    [periods],
  );
};

export default usePeriodOptions;
