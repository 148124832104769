import { type ReactNode, type ReactElement, type JSXElementConstructor, memo, useCallback, useMemo, cloneElement, type MouseEvent, type CSSProperties } from 'react';
import { isEqual } from 'lodash';

import type { AuthorType, UserType } from 'app/entities';
import { guard } from 'utils';
import TextExpand from 'ui/TextExpand';
import Picture from 'components/Picture';

import { Box, type BoxProps, Typography } from '@mui/joy';
import moment from 'moment-timezone';

type AuthorBlockProps = {
  data: AuthorType | UserType;
  createData?: string;
  Component?: ReactNode;
  onPress?: (event: MouseEvent<HTMLElement>) => void;
} & Omit<BoxProps, 'onPress'>;

const prepareTime = (value: string) => {
  if (value.toLowerCase() === 'a few seconds ago') {
    return 'just now';
  }
  return value;
};

const AuthorBlock = (props: AuthorBlockProps) => {
  const { data, Component, createData, onPress, style, ...rest } = props;

  const handlePress = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      event.preventDefault();
      event.stopPropagation();
      onPress?.(event);
    },
    [onPress],
  );

  const photoFinal = useMemo(() => {
    if (Component) {
      return null;
    }
    if (guard.isUser(data) && data?.photo) {
      return data.photo;
    }
    if (guard.isAuthor(data) && data?.owner?.photo) {
      return data.owner.photo;
    }
    if (guard.isAuthor(data) && data?.photo) {
      return data.photo;
    }
    return null;
  }, [Component, data]);

  const nameFinal = useMemo(() => {
    if (Component) {
      return null;
    }
    let name = '';
    let surname = '';
    if (guard.isUser(data)) {
      name = data.name;
      surname = data.surname;
    }
    if (guard.isAuthor(data) && data?.owner) {
      name = data.owner.name;
      surname = data.owner.surname;
    }
    if (guard.isAuthor(data) && !data?.owner) {
      name = data.name || '';
      surname = data.surname || '';
    }
    if (!name && !surname) {
      return 'Guest';
    }
    const result = [];
    if (name) {
      result.push(name);
    }
    if (surname) {
      result.push(surname);
    }
    return result.join(' ');
  }, [Component, data]);

  const componentProps = useMemo(() => {
    if (!Component) {
      return null;
    }
    return { data };
  }, [Component, data, handlePress]);

  if (onPress) {
    return (
      <Box onClick={handlePress} display="flex" flexDirection="row" alignItems="center" gap={1} {...rest}>
        {!!Component && componentProps && cloneElement(Component as ReactElement<any, string | JSXElementConstructor<any>>, componentProps)}
        {!Component && (
          <>
            {!!photoFinal && <Picture source={photoFinal} size="small" width={24} height={24} radius={12} />}
            <TextExpand
              lines={1}
              style={style}
              slotsProps={{
                typography: {
                  fontSize: 12,
                  fontWeight: 400,
                },
              }}
            >
              {nameFinal}
            </TextExpand>
            {createData && (
              <>
                <Typography fontSize={12} mx={0.25}>
                  •
                </Typography>
                <TextExpand
                  lines={1}
                  style={style}
                  slotsProps={{
                    typography: {
                      fontSize: 12,
                      fontWeight: 400,
                    },
                  }}
                >
                  {prepareTime(moment(createData).fromNow())}
                </TextExpand>
              </>
            )}
          </>
        )}
      </Box>
    );
  }

  return (
    <Box style={style} display="flex" flexDirection="row" alignItems="center">
      {!!Component && componentProps && cloneElement(Component as ReactElement<any, string | JSXElementConstructor<any>>, componentProps)}
      {!Component && (
        <>
          <Picture source={photoFinal} size="small" width={20} height={20} style={{ marginRight: 10 }} radius={10} />
          <Typography fontSize={16} fontWeight="bolder">
            {nameFinal}
          </Typography>
          <Typography fontSize={12} mx={0.25}>
            •
          </Typography>
          <Typography fontSize={13}>{prepareTime(moment(createData).fromNow())}</Typography>
        </>
      )}
    </Box>
  );
};

export default memo(AuthorBlock, (prevProps, nextProps) => isEqual(prevProps, nextProps));
