import { type CSSProperties } from 'react';
import { type StyleProp, StyleSheet, type ViewStyle } from 'react-native';

export default (props: Record<any, any> | StyleProp<ViewStyle>): CSSProperties | undefined => {
  if (!props) {
    return undefined;
  }
  const result: Record<any, any> = {};
  Object.entries(StyleSheet.flatten(props)).forEach(([key, value]) => {
    if (key === 'paddingVertical') {
      result.paddingTop = value;
      result.paddingBottom = value;
      return;
    }
    if (key === 'paddingHorizontal') {
      result.paddingLeft = value;
      result.paddingRight = value;
      return;
    }
    if (key === 'marginVertical') {
      result.marginTop = value;
      result.marginBottom = value;
      return;
    }
    if (key === 'marginHorizontal') {
      result.marginLeft = value;
      result.marginRight = value;
      return;
    }
    if (key === 'transform' && Array.isArray(value)) {
      const transformArray = value.map((transformProps: string[]) => Object.entries(transformProps).map(([propKey, propValue]) => `${propKey}(${propValue})`));
      result.transform = transformArray.join(' ');
      return;
    }
    result[key] = value;
  });
  return result as CSSProperties;
};
