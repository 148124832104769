import { memo } from 'react';

import { type CollectionType } from 'app/entities';

import HorizontalSwiper from 'widgets/HorizontalSwiper';
import Card from 'widgets/Card';

type CollectionsSwiperProps = {
  collections: CollectionType[];
};

const CollectionsSwiper = (props: CollectionsSwiperProps) => {
  const { collections } = props;

  return (
    <HorizontalSwiper
      className="swiperCollection"
      breakpoints={{
        320: {
          slidesPerView: 1.25,
          spaceBetween: 16,
        },
        499: {
          slidesPerView: 1.5,
          spaceBetween: 16,
        },
        560: {
          slidesPerView: 2.5,
          spaceBetween: 16,
        },
        768: {
          slidesPerView: 2.5,
          spaceBetween: 16,
        },
        1280: {
          slidesPerView: 4.5,
          spaceBetween: 16,
        },
      }}
    >
      {collections.map((item) => (
        <Card
          key={`collection-${item.id}`}
          id={item.id}
          type="collection"
          hasDescription
          hasQueue
          hasTags
          hasLink
          hasAuthor
          hasIndexed
          tagLimit={3}
          descriptionLineLimit={3}
          sx={{
            mb: 4,
          }}
        />
      ))}
    </HorizontalSwiper>
  );
};

export default memo(CollectionsSwiper);
