import { memo, useEffect, useState } from 'react';
import { Box, useColorScheme } from '@mui/joy';

import PlanHead from 'widgets/Subscription/ui/Plans/ui/PlanHead';
import Divider from 'components/Divider';
import PlanInclude from 'widgets/Subscription/ui/Plans/ui/PlanInclude';
import Panel from 'widgets/Subscription/ui/Plans/ui/Panel';

import type { PlanDataType } from 'widgets/Subscription/types';
import usePlan from 'widgets/Subscription/model/usePlan';

type TryFreeProps = {
  planId: string;
};

const TryFree = (props: TryFreeProps) => {
  const { planId } = props;
  const { plan } = usePlan(planId);

  const { colorScheme } = useColorScheme();

  if (!plan) {
    return null;
  }

  return (
    <Panel key={plan.name} variant={colorScheme === 'dark' ? 'solid' : 'plain'}>
      <PlanHead
        name={plan.name}
        description={plan.description}
        note={plan.note}
        costValue={plan.cost.value}
        costBy={plan.cost.by}
        costNote={plan.cost.note}
      />
      <Divider margin="1.5 0" />
      <PlanInclude list={plan.includes} />
    </Panel>
  );
};

export default memo(TryFree);
