import { type ChangeEvent, type ReactElement, memo, useCallback, useMemo } from 'react';
import { useColorScheme } from '@mui/joy';
import Radio from '@mui/joy/Radio';
import RadioGroup, { type RadioGroupProps } from '@mui/joy/RadioGroup';

type OptionType<T> = {
  value: T;
  label: string;
};

type SegmentedInputProps<T> = {
  options: OptionType<T>[];
  value: T;
  onChange: (value: T) => void;
} & Omit<RadioGroupProps, 'onChange' | 'value'>;

const SegmentedInput = <_, T extends string>(props: SegmentedInputProps<T>) => {
  const { options, value, onChange, sx, ...rest } = props;

  const { colorScheme } = useColorScheme();

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange(event.target.value as any);
    },
    [onChange],
  );

  const optionsRender = useMemo(
    () =>
      options.map((item) => (
        <Radio
          key={item.value}
          value={item.value}
          label={item.label}
          color="neutral"
          disableIcon
          variant="plain"
          sx={{
            px: 2,
            alignItems: 'center',
          }}
          slotProps={{
            action: ({ checked }) => ({
              sx: {
                ...(checked && {
                  bgcolor: 'background.surface',
                  boxShadow: 'sm',
                  '&:hover': {
                    bgcolor: 'background.surface',
                  },
                }),
              },
            }),
          }}
        />
      )),
    [options],
  );

  return (
    <RadioGroup
      orientation="horizontal"
      aria-labelledby="segmented-control"
      value={value}
      onChange={handleChange}
      sx={{
        '&': {
          minHeight: 48,
          padding: '4px',
          borderRadius: '28px',
          bgcolor: 'neutral.softBg',
          '--RadioGroup-gap': '4px',
          '--Radio-actionRadius': '24px',
          '--joy-palette-background-surface': `var(${colorScheme === 'dark' ? '--joy-palette-neutral-100' : '--joy-palette-neutral-700'})`,
        },
        '& .Mui-checked': {
          '--joy-shadow-sm': 'none',
          '--variant-plainColor': `var(${colorScheme === 'dark' ? '--joy-palette-neutral-800' : '--joy-palette-neutral-100'})`,
        },
        ...sx,
      }}
      {...rest}
    >
      {optionsRender}
    </RadioGroup>
  );
};

export default memo(SegmentedInput) as <T extends string>(props: SegmentedInputProps<T>) => ReactElement;
