import { type SagaReturnType, takeLatest, cancel, put } from 'redux-saga/effects';

import { call } from 'store/utils/saga/effects';

import network from 'lib/network';

import { extensionByContentType } from 'utils';
import * as userStore from 'store/nodes/user';
import Alert from 'components/Alert';
import { actions } from '../slice';

export const config = {
  action: actions.setupProfile.type,
  method: takeLatest,
};

export function* func(action: SagaReturnType<typeof actions.setupProfile>) {
  const { name, login, photo } = action.payload;

  if (!name || !login) {
    yield put(actions.setupProfileDone());
    yield cancel();
  }

  let photoData: string | { data: string; extension: string } | undefined;

  if (typeof photo === 'string' && /^data:image\/[a-z]+;base64,/.test(photo)) {
    const [, contentType, data] = photo.match(/^data:(image\/[a-z]+);base64,(.*)/) || [];
    const extension = extensionByContentType(contentType, 'jpg');
    photoData = {
      data,
      extension,
    };
  }

  const { errors, data } = yield* call(() =>
    network
      .request<{
        resumed: boolean;
      }>('/stack-2/onboarding/profile', {
        baseUrl: process.env.PROTECTED_API_URL || '/api',
      })
      .body({
        photo: photoData,
        name,
        login,
      })
      .post(),
  );

  if (errors) {
    Alert.error(errors[0]?.message);
    yield put(actions.setupProfileDone());
    yield cancel();
    return;
  }

  yield put(userStore.actions.loadProfile('my'));
  yield put(actions.setupProfileDone());
  yield put(actions.nextStep());
  yield cancel();
}
