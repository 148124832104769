import { memo, type MouseEvent, useCallback } from 'react';
import {
  Box,
  Button,
  DialogTitle,
  List,
  ListItem,
  ListItemButton,
  ListItemContent,
  ListItemDecorator,
  Modal,
  ModalClose,
  ModalDialog,
  Typography,
} from '@mui/joy';
import { useDispatcher, useSelector } from 'store/utils/redux/hooks';

import { guard } from 'utils';
import Icon from 'ui/Icon';
import CollectionSingleCover from 'components/CollectionSingleCover';

import * as contentStore from 'store/nodes/content';
import * as noteStore from 'store/nodes/note';
import * as modalStore from 'widgets/modals/store';
import * as userStore from 'store/nodes/user';

const useData = (params: { resourceType: string; resourceId: number } | null) => {
  return useSelector((state) => {
    if (params?.resourceType === 'document' || params?.resourceType === 'note') {
      return noteStore.selectors.dataById(params?.resourceId)(state);
    }
    if (params?.resourceType === 'material') {
      return contentStore.selectors.dataById(params?.resourceId)(state);
    }
    return null;
  });
};

const AddToCollection = () => {
  const dispatcher = useDispatcher();
  const { isOpen, params } = useSelector(modalStore.selectors.state('AddToCollection'));
  const collections = useSelector(userStore.selectors.getCollections('my'));

  const data = useData(params);

  const handleModalClose = useCallback(async () => {
    dispatcher.modal.close('AddToCollection');
  }, []);

  const handleToNewCollection = useCallback(() => {
    if (!params?.resourceId) {
      return;
    }
    console.log('params', params);
    dispatcher.modal.open('AddorEditCollection', {
      create: {
        withItems: [
          {
            resourceId: params?.resourceId,
            resourceType: params.resourceType,
          },
        ],
      },
    });
  }, [params?.resourceId]);

  const handleItemPress = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      const id = event.currentTarget.getAttribute('data-id');
      if (guard.isMaterial(data)) {
        dispatcher.playlist.addMaterialItem({
          collectionId: Number(id),
          data,
          itemType: 'material',
          options: { append: true },
        });
      }
      if (guard.isNote(data)) {
        dispatcher.playlist.addNoteItem({
          collectionId: Number(id),
          itemType: 'note',
          text: data.text,
        });
      }
      if (guard.isDocument(data)) {
        dispatcher.playlist.addDocumentItem({
          collectionId: Number(id),
          data,
          itemType: 'document',
          options: { append: true },
        });
      }
    },
    [data],
  );

  return (
    <Modal open={isOpen} onClose={handleModalClose}>
      <ModalDialog
        variant="soft"
        size="lg"
        sx={{
          width: 380,
          '--ModalClose-radius': '1.125em',
          '--variant-plainHoverBg': 'var(--joy-palette-background-level2)',
          '--variant-plainActiveBg': 'var(--joy-palette-background-level3)',
        }}
      >
        <ModalClose
          onClick={handleModalClose}
          variant="soft"
          size="lg"
          sx={{
            '--ModalClose-radius': '1.125em',
          }}
        />
        <DialogTitle sx={{ mt: -1 }}>{`Add ${params?.resourceType} to collection`}</DialogTitle>
        {collections.length === 0 && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 4,
              height: 128,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography fontSize={17}>No available collections</Typography>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Typography fontSize={15}>You can</Typography>
              <Button variant="plain" onClick={handleToNewCollection}>
                create new collection
              </Button>
            </Box>
          </Box>
        )}
        {collections.length > 0 && (
          <List
            slotProps={{
              root: {},
            }}
            size="md"
            sx={{
              gap: 0.5,
              overflow: 'auto',
              mx: 'calc(-1 * var(--Card-padding))',
              px: 'calc(var(--Card-padding) / 2)',
              '--ListItem-paddingLeft': '0.5rem',
              '--ListItem-paddingRight': '0.5rem',
              '--ListItem-paddingY': '0.5rem',
              '--ListItem-radius': '0.5rem',
              '--ListItemDecorator-size': '2.25rem',
            }}
          >
            <ListItem
              sx={{
                '--icon-bgcolor': 'var(--joy-palette-background-level2)',
                '&:hover': {
                  '--icon-bgcolor': 'var(--joy-palette-background-level3)',
                },
              }}
            >
              <ListItemButton onClick={handleToNewCollection} sx={{ gap: 1 }}>
                <Box
                  component="div"
                  width="2.25rem"
                  height="2.25rem"
                  bgcolor="var(--icon-bgcolor)"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="0.25rem"
                >
                  <Icon
                    width={36}
                    height={36}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    size="lg"
                    name="plus"
                    sx={{ opacity: 0.85 }}
                  />
                </Box>
                <ListItemContent>Create new collection</ListItemContent>
              </ListItemButton>
            </ListItem>
            {collections.map((playlist, key) => (
              <ListItem key={key}>
                <ListItemButton
                  disabled={playlist.materialsMetadata.some((item) => item.id === params?.resourceId && item.type === params?.resourceType)}
                  data-id={playlist.id}
                  onClick={handleItemPress}
                  sx={{ gap: 1 }}
                >
                  <ListItemDecorator component="div" sx={{ flexShrink: 1, mr: 0 }}>
                    <CollectionSingleCover data={playlist} iconSize={36} heightPicture={36} widthPicture={36} />
                  </ListItemDecorator>
                  <ListItemContent
                    sx={{
                      display: '-webkit-box',
                      overflow: 'hidden',
                      WebkitLineClamp: 1,
                      WebkitBoxOrient: 'vertical',
                    }}
                  >
                    {playlist.title}
                  </ListItemContent>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        )}
      </ModalDialog>
    </Modal>
  );
};

export default memo(AddToCollection);
