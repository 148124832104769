const stripTags = (str?: string | null) => {
  if (!str) {
    return '';
  }
  return str
    .replace(/<br(\s*\/)?>/g, '\n')
    .replace(/(<([^>]+)>)/gi, ' ')
    .replace(/\s{2,10}/gi, ' ');
};

export default stripTags;
