import { type CSSProperties, useCallback, memo, useMemo } from 'react';
import { type StyleProp, type ViewStyle, Platform, StyleSheet, TouchableOpacity } from 'react-native';

import { isCommunityCollectionScreen, isCommunityPeersScreen, isStaffPicksScreen, isAskScreen, isLibraryScreen, isNewsScreen } from 'navigation/guards';
import { useNavigate, useRoute } from 'navigation/hooks';
import { useDispatcher } from 'store/utils/redux/hooks';

import { useThemeColor } from 'hooks';
import { unit } from 'utils';
import { controller as modal } from 'components/Modal2';
import { BlurView } from 'components/Themed';

import MenuIcon from '../elements/MenuIcon';

const Mobile = () => {
  const navigate = useNavigate();
  const route = useRoute();
  const dispatcher = useDispatcher();
  const selectedColor = useThemeColor({ light: '#000000', dark: '#ffffff' });

  const handleOpenHome = useCallback(() => {
    dispatcher.search.clear();
    navigate('Feed');
  }, []);

  const handleCommunity = useCallback(() => {
    dispatcher.search.clear();
    navigate('CommunityCollection');
  }, []);

  const handleOpenSearch = useCallback(() => {
    navigate('Ask');
  }, []);

  const handlePlus = useCallback((event: { currentTarget: any }) => {
    modal.menu.plus.open({
      callingTarget: event.currentTarget,
    });
  }, []);

  const handleLibrary = useCallback(() => {
    dispatcher.search.clear();
    navigate('Library', { interaction: 'all' });
  }, []);

  const containerStyle = useMemo(() => {
    const result: StyleProp<ViewStyle> = {
      ...StyleSheet.flatten(styles.MobileBar),
    };
    if (Platform.OS === 'web') {
      (result as CSSProperties).position = 'fixed';
    }
    return result;
  }, []);

  if (!(isCommunityPeersScreen(route) || isCommunityCollectionScreen(route) || isStaffPicksScreen(route) || isLibraryScreen(route) || isNewsScreen(route) || isAskScreen(route))) {
    return null;
  }

  return (
    <BlurView style={containerStyle} darkColor="#00000055" lightColor="#FFFFFF55" factor={8}>
      <TouchableOpacity style={[styles.button, isAskScreen(route) && styles.selected, { borderBottomColor: selectedColor }]} onPress={handleOpenSearch} activeOpacity={0.8}>
        <MenuIcon name="ZoomIn" />
      </TouchableOpacity>
      <TouchableOpacity style={[styles.button, isLibraryScreen(route) && styles.selected, { borderBottomColor: selectedColor }]} onPress={handleLibrary} activeOpacity={0.8}>
        <MenuIcon name="MenuLibraryOutline" />
      </TouchableOpacity>
      <TouchableOpacity style={styles.button} onPress={handlePlus} activeOpacity={0.8}>
        <MenuIcon name="MenuPlusOutline" size={40} />
      </TouchableOpacity>
      <TouchableOpacity
        style={[styles.button, (isCommunityCollectionScreen(route) || isCommunityPeersScreen(route)) && styles.selected, { borderBottomColor: selectedColor }]}
        onPress={handleCommunity}
        activeOpacity={0.8}
      >
        <MenuIcon name="MenuCommunityOutline" />
      </TouchableOpacity>
      <TouchableOpacity style={[styles.button, isStaffPicksScreen(route) && styles.selected, { borderBottomColor: selectedColor }]} onPress={handleOpenHome} activeOpacity={0.8}>
        <MenuIcon name="MenuStaffOutline" />
      </TouchableOpacity>
    </BlurView>
  );
};

const styles = StyleSheet.create({
  MobileBar: {
    position: 'absolute',
    height: unit(80),
    right: 0,
    bottom: 0,
    left: 0,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backdropFilter: 'blur(0.6rem)',
    zIndex: 1,
  },
  button: {
    width: unit(28),
    height: unit(80),
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  selected: {
    borderBottomWidth: unit(4),
    paddingTop: unit(4),
  },
  logo: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: unit(100),
  },
  buttons: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  actions: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: unit(100),
    marginRight: unit(16),
  },
  actionIcon: {
    marginLeft: unit(8),
    width: unit(40),
    height: unit(40),
    backgroundColor: '#1E1E1E',
    borderRadius: unit(20),
    alignItems: 'center',
    justifyContent: 'center',
  },
  tabButtonSmall: {
    position: 'relative',
    width: '25%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tabButtonBig: {
    position: 'relative',
    paddingHorizontal: unit(24),
    paddingVertical: unit(16),
  },
  tabButtonIcon: {
    position: 'absolute',
    width: unit(42),
    height: unit(42),
    borderWidth: 1,
    borderRadius: unit(26),
    borderColor: '#ffffff',
    borderStyle: 'solid',
  },
});

export default memo(Mobile);
