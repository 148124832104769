import { type RefObject, useEffect, useRef } from 'react';
import { ImageCache } from './imageCache';

type UseImageOptions = {
  src: string;
  onLoad?: () => void;
  onError?: () => void;
};

/**
 * Hook to manage image loading and attachment with caching.
 * @param {React.RefObject<HTMLElement>} containerRef - The ref of the container element.
 * @param {UseImageOptions} options - Options including src, onLoad, and onError callbacks.
 */
export function useImage(containerRef: RefObject<HTMLElement>, options: UseImageOptions) {
  const { src, onLoad, onError } = options;
  const imgRef = useRef<HTMLImageElement | null>(null);

  useEffect(() => {
    if (!containerRef.current || !src) {
      return undefined;
    }

    let img = ImageCache.get(src);

    const handleLoad = () => {
      if (img) {
        ImageCache.set(src, img);
        onLoad?.();
      }
    };

    const handleError = () => {
      onError?.();
    };

    if (img) {
      imgRef.current = img.cloneNode(true) as HTMLImageElement;
      containerRef.current.appendChild(imgRef.current);
      onLoad?.();
    } else {
      img = new Image();
      img.src = src;
      img.onload = () => {
        ImageCache.set(src, img!);
        handleLoad();
      };
      img.onerror = handleError;
      imgRef.current = img;
      containerRef.current.appendChild(imgRef.current);
    }

    return () => {
      if (imgRef.current && containerRef.current) {
        containerRef.current.removeChild(imgRef.current);
        imgRef.current = null;
      }
    };
  }, [src, containerRef, onLoad, onError]);
}
