import MarkdownIt from 'markdown-it';

const md = new MarkdownIt({
  html: false,
  linkify: true,
  typographer: true,
});

const isHtml = (text: string): boolean => {
  const htmlPattern = /^<[^>]+>[\s\S]*<\/[^>]+>$|^(<[^>]+>[\s\S]*<\/[^>]+>)+$/;
  return htmlPattern.test(text.trim());
};

const prepareHtml = (data: { text?: string | null } | null): null | string => {
  if (!data) {
    return null;
  }
  if (!data.text) {
    return '';
  }

  const text = data.text.trim();
  if (isHtml(text)) {
    return text;
  }

  return md.render(text);
};

export default prepareHtml;
