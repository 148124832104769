import { createReducer } from '@reduxjs/toolkit';

import type { RelationStore } from './types';
import * as actions from './actions';

const initialState: RelationStore = {
  user: {
    login: null,
  },
  subscribers: {
    list: {
      sequence: [],
      map: {},
      paginationInfo: {
        hasNext: false,
        nextCursor: null,
      },
    },
    meta: {
      isFirstPageLoading: false,
      isFirstPageLoaded: false,
      isNextPageLoading: false,
      isNextPageLoaded: false,
    },
  },
  unsubscribeActiveUser: {
    list: {
      sequence: [],
      map: {},
      paginationInfo: {
        hasNext: false,
        nextCursor: null,
      },
    },
    meta: {
      isFirstPageLoading: false,
      isFirstPageLoaded: false,
      isNextPageLoading: false,
      isNextPageLoaded: false,
    },
  },
  subscriptions: {
    list: {
      sequence: [],
      map: {},
      paginationInfo: {
        hasNext: false,
        nextCursor: null,
      },
    },
    meta: {
      isFirstPageLoading: false,
      isFirstPageLoaded: false,
      isNextPageLoading: false,
      isNextPageLoaded: false,
    },
  },
};

export default createReducer(initialState, (builder) =>
  builder
    .addCase(actions.clear, (state) => {
      const copy = JSON.parse(JSON.stringify(initialState));
      state.user = copy.user;
      state.subscribers = copy.subscribers;
      state.subscriptions = copy.subscriptions;
      state.unsubscribeActiveUser = copy.unsubscribeActiveUser;
    })
    .addCase(actions.load, (state, action) => {
      const { login } = action.payload;
      const copy = JSON.parse(JSON.stringify(initialState));
      state.user.login = login;
      state.subscribers = copy.subscribers;
      state.subscribers.meta.isFirstPageLoading = true;
      state.subscriptions = copy.subscriptions;
      state.subscriptions.meta.isFirstPageLoading = true;
      state.unsubscribeActiveUser = copy.unsubscribeActiveUser;
      state.unsubscribeActiveUser.meta.isFirstPageLoading = true;
    })
    .addCase(actions.loadDone, (state, action) => {
      state.subscribers.meta.isFirstPageLoading = false;
      state.subscriptions.meta.isFirstPageLoading = false;
      state.unsubscribeActiveUser.meta.isFirstPageLoading = false;
      state.subscribers.meta.isFirstPageLoaded = true;
      state.subscriptions.meta.isFirstPageLoaded = true;
      state.unsubscribeActiveUser.meta.isFirstPageLoaded = true;
    })
    .addCase(actions.loadNextPage, (state, action) => {
      const { listType } = action.payload;
      state[listType].meta.isNextPageLoading = true;
    })
    .addCase(actions.loadNextPageDone, (state, action) => {
      const { listType } = action.payload;
      state[listType].meta.isNextPageLoading = false;
      state[listType].meta.isNextPageLoaded = true;
    })
    .addCase(actions.addPage, (state, action) => {
      const { listType, items, paginationInfo } = action.payload;
      items.forEach((item) => {
        if (item.id in state[listType].list.map) {
          return;
        }
        state[listType].list.sequence.push({
          id: item.id,
        });
        state[listType].list.map[item.id] = state[listType].list.sequence.length - 1;
      });
      state[listType].list.paginationInfo.nextCursor = paginationInfo.nextCursor;
      state[listType].list.paginationInfo.hasNext = paginationInfo.hasNext;
    }),
);
