import { useCallback, useEffect, useMemo } from 'react';

import { useNavigate, useRoute } from 'navigation/hooks';

import { guard, saveLastRoute } from 'utils';

import { useDispatcher, useSelector } from 'store/utils/redux/hooks';
import * as userStore from 'store/nodes/user';
import * as authorStore from 'store/nodes/author';

import AdaptivePage from 'components/AdaptivePage';
import { controller as modal } from 'components/Modal2';

import { useHasSession } from 'hooks';
import { Box, Divider, Stack } from '@mui/joy';
import Info from './elements/Info';
import Jobs from './elements/Jobs';
import Skills from './elements/Skills';
import Playlists from './elements/Playlists';
import Contents from './elements/Contents';

const Profile = () => {
  const navigate = useNavigate();
  const route = useRoute();
  const dispatcher = useDispatcher();
  const hasSession = useHasSession();

  useEffect(() => {
    const { login } = route.params as Record<string, string>;
    if (login === 'my' && !hasSession) {
      saveLastRoute();
      navigate('Auth/Start');
    }
  }, [hasSession, route.params]);

  const { user, author } = useMemo(() => {
    if (route.isScreen('Profile')) {
      const { login, entity } = route.params as Record<string, string>;
      return {
        user: {
          login: login || ('my' as string),
          entity: entity as string,
        },
        author: null,
      };
    }
    if (route.isScreen('Author')) {
      const { id } = route.params as Record<string, number>;
      return {
        user: null,
        author: {
          id: id as number,
        },
      };
    }
    return {
      user: null,
      author: null,
    };
  }, [route]);

  const profile = useSelector((state) => {
    if (user) {
      return userStore.selectors.getByLogin(user.login)(state);
    }
    if (author) {
      return authorStore.selectors.getById(author.id)(state);
    }
    return null;
  });

  const handleSubscribe = useCallback(() => {
    if (!guard.isUser(profile)) {
      return;
    }
    if (!profile?.login || profile.isMy) {
      return;
    }
    dispatcher.user.subscribe(profile?.login);
  }, [profile]);

  const handleUnsubscribe = useCallback(() => {
    if (!guard.isUser(profile)) {
      return;
    }
    if (!profile?.login || profile.isMy) {
      return;
    }
    dispatcher.user.unsubscribe(profile?.login);
  }, [profile]);

  const handleRelationPersonPress = useCallback((login: string) => {
    modal.popup.relations.close(() => {
      navigate('Profile', { login });
    });
  }, []);

  const handleRelationsPress = useCallback(() => {
    if (!guard.isUser(profile)) {
      return;
    }
    modal.popup.relations.open({
      login: profile?.login,
      onPress: handleRelationPersonPress,
    });
  }, [profile]);

  const pagePaddingHorizontalScheme = useMemo(
    () => ({
      320: 16,
    }),
    [],
  );

  return (
    <AdaptivePage desktopHeaderLeftPanel="ask" desktopHeaderMiddlePanel={false} bodyMaxWidth={1280} paddingHorizontalScheme={pagePaddingHorizontalScheme} scrollToStart>
      <Info userLogin={user?.login} authorId={author?.id} onSubscribe={handleSubscribe} onUnsubscribe={handleUnsubscribe} onRelationsPress={handleRelationsPress} />
      <Divider
        sx={{
          mt: 2,
          mb: 2,
        }}
      />
      <Box display="flex" flexDirection="column" gap={4} mb={4}>
        <Skills userLogin={user?.login} />
        <Playlists userLogin={user?.login} editable />
        <Contents userLogin={user?.login} authorId={author?.id} editable />
        <Jobs userLogin={user?.login} />
      </Box>
    </AdaptivePage>
  );
};

export default Profile;
