import { useCallback, useContext } from 'react';

import { Context } from '../context';
import type { Config } from '../types';

const useSetDesktopBarConfig = () => {
  const context = useContext<Config>(Context);
  return useCallback((config: Omit<Config, 'setConfig'>) => {
    context?.setConfig?.(config);
  }, []);
};

export default useSetDesktopBarConfig;
