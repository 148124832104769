import { type SagaReturnType, takeEvery, put, cancel } from 'redux-saga/effects';

import type { CollectionType, MaterialType, RagMaterialType } from 'app/entities';
import { type SequenceType } from 'store/nodes/ask/slice';
import { guard } from 'utils';

import * as materialStore from 'store/nodes/content';
import * as collectionStore from 'store/nodes/playlist';
import * as searchStore from 'store/nodes/search';

import { actions } from '../slice';
import { askSearchResult } from '../socketActions';

export const config = {
  action: [askSearchResult.type],
  method: takeEvery,
};

export function* func(action: SagaReturnType<typeof askSearchResult>) {
  const { payload } = action;
  if (!payload.data || payload.data.length === 0) {
    yield cancel();
  }
  const items = payload.data;

  const sequence: SequenceType[] = [];
  const materials: MaterialType[] = [];
  const collections: CollectionType[] = [];
  const ragMaterial: RagMaterialType[] = [];
  items.forEach((item) => {
    if (guard.isRagMaterial(item)) {
      ragMaterial.push(item);
      sequence.push({
        id: item.id,
        type: 'RagMaterial',
      });
    }
    if (guard.isMaterial(item)) {
      materials.push(item);
      sequence.push({
        id: item.id,
        type: 'Material',
      });
    }
    if (guard.isPoorMaterial(item)) {
      sequence.push({
        id: item.id,
        type: 'Material',
      });
    }
    if (guard.isCollection(item)) {
      collections.push(item);
      sequence.push({
        id: item.id,
        type: 'Collection',
      });
    }
    if (guard.isPoorCollection(item)) {
      sequence.push({
        id: item.id,
        type: 'Collection',
      });
    }
  });

  if (materials.length > 0) {
    yield put(materialStore.actions.setItem(materials));
  }
  if (ragMaterial.length > 0) {
    yield put(searchStore.actions.setRagMaterial(ragMaterial));
  }
  if (collections.length > 0) {
    yield put(collectionStore.actions.setItem({ data: collections }));
  }

  if (payload.metadata.replace) {
    yield put(actions.setSequence({ sequence }));
  } else {
    yield put(actions.addSequence({ sequence }));
  }
}
