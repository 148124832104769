import { createReducer } from '@reduxjs/toolkit';

import * as actions from './actions';

import type { MenuStore } from './types';

const initialState: MenuStore = {
  leftBar: {
    width: 64,
  },
  panels: {
    collections: [],
    follow: [],
    newsTopics: [],
  },
};

export default createReducer(initialState, (builder) =>
  builder
    .addCase(actions.reset, (state) => {
      state.leftBar = {
        width: 64,
      };
    })
    .addCase(actions.setLeftBarWidth, (state, action) => {
      state.leftBar.width = action.payload.value;
    })
    .addCase(actions.setData, (state, action) => {
      if (!action?.payload?.data) {
        return;
      }
      const { follow, newsTopics } = action.payload.data;
      if (Array.isArray(follow)) {
        state.panels.follow = follow;
      }
      if (Array.isArray(newsTopics)) {
        state.panels.newsTopics = newsTopics;
      }
    }),
);
