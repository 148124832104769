import connect from '../../utils/saga/connect';
import * as processConfirmEmail from './sagas/processConfirmEmail';
import * as credentialListener from './sagas/credentialListener';
import * as checkAuth from './sagas/checkAuth';
import * as signIn from './sagas/signIn';
import * as signUp from './sagas/signUp';
import * as logOut from './sagas/logOut';
import * as oauth from './sagas/oauth';
import * as passwordReset from './sagas/passwordReset';
import * as passwordUpdate from './sagas/passwordUpdate';

export default connect([processConfirmEmail, credentialListener, checkAuth, signIn, signUp, logOut, oauth, passwordReset, passwordUpdate]);
