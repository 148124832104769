import { createReducer } from '@reduxjs/toolkit';

import type { AuthorStore } from './types';
import * as actions from './actions';

const initialState: AuthorStore = {
  data: {},
  meta: {
    loadingMap: {},
  },
};

export default createReducer(initialState, (builder) =>
  builder
    .addCase(actions.loadProfile, (state, action) => {
      state.meta.loadingMap[action.payload.id] = true;
    })
    .addCase(actions.loadProfileDone, (state, action) => {
      delete state.meta.loadingMap[action.payload.id];
    })
    .addCase(actions.setProfile, (state, action) => {
      if (!action.payload.data || !Array.isArray(action.payload.data)) {
        return;
      }
      action.payload.data.forEach((item) => {
        state.data[item.id] = item;
      });
    }),
);
