import connect from 'store/utils/saga/connect';
import * as send from './sagas/send';
import * as copilotAnswer from './sagas/copilotAnswer';
import * as reloadAnswer from './sagas/reloadAnswer';
import * as saveAnswerToNote from './sagas/saveAnswerToNote';
import * as clear from './sagas/clear';
import * as trigger from './sagas/trigger';
import * as loadAvailableModels from './sagas/loadAvailableModels';
import * as loadSelectedModel from './sagas/loadSelectedModel';
import * as setSelectedModel from './sagas/setSelectedModel';

export default connect([send, copilotAnswer, reloadAnswer, saveAnswerToNote, clear, trigger, loadAvailableModels, loadSelectedModel, setSelectedModel]);
