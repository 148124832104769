import { type ScaledSize, Dimensions, Platform } from 'react-native';
import { useCallback, useEffect, useMemo, useState } from 'react';

const getInitialWidth = () => {
  if (Platform.OS === 'web') {
    return window.innerWidth;
  }
  return Dimensions.get('window').width;
};

const getBreakpoint = (width: number): 499 | 767 | 1023 | 1279 => {
  if (width <= 499) {
    return 499;
  }
  if (width <= 767) {
    return 767;
  }
  if (width <= 1023) {
    return 1023;
  }
  return 1279;
};

const useScreenSize = () => {
  const [windowWidth, setWindowWidth] = useState<499 | 767 | 1023 | 1279>(getBreakpoint(getInitialWidth()));

  const resize = useCallback((width: number) => {
    setWindowWidth(getBreakpoint(width));
  }, []);

  const handleWindowChange = useCallback(() => {
    resize(window.innerWidth);
  }, []);

  const handleDimensionChange = useCallback(({ window }: { window: ScaledSize }) => {
    resize(window.width);
  }, []);

  useEffect(() => {
    if (Platform.OS === 'web') {
      window.addEventListener('resize', handleWindowChange);
      handleWindowChange();
      return () => {
        window.removeEventListener('resize', handleWindowChange);
      };
    }
    const handler = Dimensions.addEventListener('change', handleDimensionChange);
    return () => {
      handler.remove();
    };
  }, []);

  return useMemo(
    () => ({
      is499: windowWidth <= 499,
      is767: windowWidth <= 767,
      is1023: windowWidth <= 1023,
      is1279: windowWidth <= 1279,
    }),
    [windowWidth],
  );
};

export default useScreenSize;
