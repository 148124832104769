import { useCallback, useMemo, type MouseEvent } from 'react';
import * as Linking from 'expo-linking';
import { track } from '@amplitude/analytics-browser';

import { useResourceInfo, useSharing } from 'hooks';

import type { ResourceType } from 'app/entities';
import { useDispatcher } from 'store/utils/redux/hooks';

import { controller as modal } from 'components/Modal2';
import Alert from 'components/Alert';
import { store } from 'store';

import { guard, copyToClipboard } from 'utils';

/**
 * @todo перенести в /widgets/Card/model
 * @param data
 */
export default (data: ResourceType | null) => {
  const dispatcher = useDispatcher();

  const handleAddRagMaterialLink = useCallback(
    (event?: any) => {
      event?.stopPropagation?.();
      if (!guard.isRagMaterial(data)) {
        return;
      }
      dispatcher.plus.parseText({ value: data?.originalUrl, privacy: false });
    },
    [data],
  );

  const resourceInfo = useResourceInfo(data);
  const sharing = useSharing(data);

  const handleQueue = useCallback(
    (event?: { stopPropagation?: () => void }) => {
      event?.stopPropagation?.();
      if (!resourceInfo) {
        return;
      }
      dispatcher.interaction.toggleBookmark(resourceInfo.legacy.type, resourceInfo.id);
    },
    [resourceInfo],
  );

  const handleToNewCollection = useCallback(() => {
    if (!resourceInfo || resourceInfo.type !== 'material') {
      return;
    }
    dispatcher.modal.open('AddorEditCollection', {
      create: {
        withItems: [
          {
            resourceType: 'material',
            resourceId: resourceInfo.id,
          },
        ],
      },
    });
  }, [resourceInfo]);

  const handleToExistsCollection = useCallback(
    (collectionId: number) => {
      if (!collectionId || !resourceInfo || resourceInfo.type !== 'material') {
        return;
      }
      const state = store.getState();
      const material = state.content.data[resourceInfo.id];
      if (!material) {
        return;
      }

      dispatcher.playlist.addMaterialItem({
        collectionId,
        data: material,
        itemType: 'material',
        options: { append: true },
      });
    },
    [resourceInfo],
  );

  const handleEdit = useCallback(
    (event?: { stopPropagation: () => void }) => {
      event?.stopPropagation?.();
      if (!resourceInfo) {
        return;
      }
      if (resourceInfo.type === 'material') {
        dispatcher.modal.open('EditMaterial', { id: resourceInfo.id });
      }
      if (resourceInfo.type === 'collection') {
        dispatcher.modal.open('AddorEditCollection', {
          edit: { id: resourceInfo?.id },
        });
      }
    },
    [resourceInfo],
  );

  const handleToCollection = useCallback(
    (event?: MouseEvent<HTMLButtonElement>) => {
      event?.stopPropagation?.();
      if (!resourceInfo) {
        return;
      }
      const resourceType = resourceInfo?.type;
      dispatcher.modal.open('AddToCollection', {
        resourceId: resourceInfo.id,
        resourceType,
      });
    },
    [handleToNewCollection, handleToExistsCollection, resourceInfo],
  );

  const handleShare = useCallback(() => {
    sharing.share();
  }, [sharing]);

  const handleLink = useCallback(
    (event?: { stopPropagation: () => void }) => {
      event?.stopPropagation?.();
      if (guard.isMaterial(data) && data.originalUrl) {
        copyToClipboard(data.originalUrl);
      } else {
        const internalUrl = !!data && 'internalUrl' in data ? data.internalUrl : null;
        if (!internalUrl) {
          return;
        }
        const link = Linking.createURL(internalUrl);
        copyToClipboard(link);
      }
      Alert.success('Link was copied');
    },
    [data],
  );

  const handlePlaySummary = useCallback(() => {
    modal.popup.promo.open();
    track('PROMO:Card/ContextAction(Play audio summary)');
  }, []);
  const handleTLDR = useCallback(() => {
    modal.popup.promo.open();
    track('PROMO:Card/ContextAction(TLDR with GPT4)');
  }, []);
  const handleShowRelated = useCallback(() => {
    modal.popup.promo.open();
    track('PROMO:Card/ContextAction(Show related)');
  }, []);

  const handleRemove = useCallback(
    (event?: { stopPropagation: () => void }) => {
      event?.stopPropagation?.();
      if (!resourceInfo || !resourceInfo.id) {
        return;
      }
      dispatcher.library.remove({
        resourceType: resourceInfo.type,
        resourceId: resourceInfo.id,
      });
    },
    [resourceInfo],
  );

  return useMemo(
    () => ({
      handleQueue,
      handleToCollection,
      handleShare,
      handleLink,
      handlePlaySummary,
      handleTLDR,
      handleShowRelated,
      handleRemove,
      handleEdit,
      handleAddRagMaterialLink,
    }),
    [
      handleQueue,
      handleToCollection,
      handleShare,
      handleLink,
      handlePlaySummary,
      handleTLDR,
      handleShowRelated,
      handleRemove,
      handleEdit,
      handleAddRagMaterialLink,
    ],
  );
};
