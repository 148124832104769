import { memo, useCallback } from 'react';
import { Box, type BoxProps, Button } from '@mui/joy';

import { useDispatcher } from 'store/utils/redux/hooks';

import Icon from 'ui/Icon';

const BackButton = (props: BoxProps) => {
  const { ...rest } = props;
  const dispatcher = useDispatcher();

  const handleBack = useCallback(() => {
    dispatcher.onboarding.prevStep();
  }, []);

  return (
    <Box {...rest}>
      <Button
        fullWidth
        size="sm"
        variant="plain"
        color="neutral"
        onClick={handleBack}
        startDecorator={<Icon name="arrow-left" color="inherit" size="1x" />}
        sx={{ fontWeight: '400' }}
      >
        Back
      </Button>
    </Box>
  );
};

export default memo(BackButton);
