import { useEffect } from 'react';

import { guard } from 'utils';
import { useDispatcher, useSelector } from 'store/utils/redux/hooks';

import type { CardType, LegacyCardType } from '../types';

const useLoadData = (id?: number | string, type?: CardType | LegacyCardType) => {
  const dispatcher = useDispatcher();

  const isNeedLoad = useSelector((state) => {
    if (!id || !type || typeof id === 'string') {
      return false;
    }
    if (guard.resourceKeyIsMaterial(type) && !state.content.data[id] && !state.content.list.meta.loadingMap[id]) {
      return true;
    }
    if (guard.resourceKeyIsCollection(type) && !state.playlist.data[id] && !state.playlist.meta.loadingMap[id]) {
      return true;
    }
    if (guard.resourceKeyIsDocument(type) && !state.note.data[id] && !state.note.meta.loadingMap[id]) {
      return true;
    }
    return false;
  });

  useEffect(() => {
    if (typeof id === 'string') {
      return;
    }
    if (id && isNeedLoad && 'Material'.toLowerCase() === type?.toLowerCase()) {
      dispatcher.content.loadById(id);
    }
    if (id && isNeedLoad && 'Collection'.toLowerCase() === type?.toLowerCase()) {
      dispatcher.playlist.loadById({ id });
    }
    if (id && isNeedLoad && 'Document'.toLowerCase() === type?.toLowerCase()) {
      dispatcher.note.loadById({ id });
    }
  }, [id, isNeedLoad, type]);
};

export default useLoadData;
