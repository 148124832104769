import { type DefaultRootState } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';

const selector = createSelector(
  (state: DefaultRootState) => state.library.filter.enabledMap,
  (state: DefaultRootState) => state.library.filter.data,
  (enabledMap) => {
    return Object.keys(enabledMap);
  },
);

export default (state: DefaultRootState) => selector(state);
