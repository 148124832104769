const capitalize = (text: string) => {
  if (!text) {
    return text;
  }
  if (text.length === 1) {
    return text.toUpperCase();
  }
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
};

export default capitalize;
