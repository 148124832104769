import { takeEvery, put, cancel, type SagaReturnType } from 'redux-saga/effects';
import type { SuccessResult } from 'app/entities';

import { saveLastRoute } from 'utils';
import { navigate } from 'navigation/methods';
import * as api from 'services/api';

import Alert from 'components/Alert';

import { call, select } from 'store/utils/saga/effects';

import * as actions from '../actions';
import * as selectors from '../selectors';

export const config = {
  action: [actions.subscribe.type, actions.unsubscribe.type],
  method: takeEvery,
};

export function* func(action: SagaReturnType<typeof actions.subscribe | typeof actions.unsubscribe>) {
  const { type, payload } = action;
  const { login } = payload;

  const hasSession = yield* call(() => api.credentials.hasSession());
  if (!hasSession) {
    yield* call(() => saveLastRoute());
    yield* call(() => navigate('Auth/Start'));
    if (type === actions.subscribe.type) {
      yield put(actions.subscribeDone(login));
    }
    if (type === actions.unsubscribe.type) {
      yield put(actions.unsubscribeDone(login));
    }
    yield cancel();
    return;
  }

  const userProfile = yield* select(selectors.getByLogin(login));
  if (!userProfile?.id) {
    yield cancel();
    return;
  }

  let response: SuccessResult | null = null;
  if (type === actions.subscribe.type) {
    response = yield* call(() => api.resource.user.relation.subscribe(userProfile.id));
  }
  if (type === actions.unsubscribe.type) {
    response = yield* call(() => api.resource.user.relation.unsubscribe(userProfile.id));
  }

  if (!response || response.error || !response.data) {
    Alert.error(response?.error?.message || 'Server error #26');
    if (type === actions.subscribe.type) {
      yield put(actions.subscribeDone(login));
    }
    if (type === actions.unsubscribe.type) {
      yield put(actions.unsubscribeDone(login));
    }
    yield cancel();
    return;
  }

  if (type === actions.subscribe.type) {
    yield put(actions.subscribeSuccess(login));
    yield put(actions.subscribeDone(login));
  }
  if (type === actions.unsubscribe.type) {
    yield put(actions.unsubscribeSuccess(login));
    yield put(actions.unsubscribeDone(login));
  }
}
