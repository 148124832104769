import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { type StyleProp, type ViewStyle, Platform } from 'react-native';

import { guard, styleSheetToCss } from 'utils';
import { useThemeColor } from 'hooks';

import { createUseStyles } from 'react-jss';
import type { PanelProps } from 'components/DropdownPanel/types';
import { Box, useTheme } from '@mui/joy';

const defaultProps = {
  anchor: 'left',
  transparent: false,
};

const Panel = (props: PanelProps & typeof defaultProps) => {
  const { isOpen, anchor, children, onOutsidePress, transparent, style } = props;

  const containerRef = useRef<HTMLDivElement | any>();

  const transition = 200;

  const [isMount, setMount] = useState<boolean>(false);
  const [isShow, setShow] = useState<boolean>(false);

  const classes = useStyle({
    isMount,
    isShow,
    transition,
    anchor,
    style,
  });

  const handleOutsidePress = useCallback(
    (event: { stopPropagation?: any; target?: any }) => {
      const { target } = event;
      if (!target) {
        return;
      }
      const isOutside = !target.closest('[data-type="context-menu"]');
      if (isOutside) {
        onOutsidePress?.();
      }
    },
    [onOutsidePress],
  );

  useEffect(() => {
    if (isOpen && Platform.OS !== 'web') {
      setMount(true);
      setShow(true);
    }
    if (!isOpen && Platform.OS !== 'web') {
      setMount(false);
      setShow(false);
    }
    if (isOpen && Platform.OS === 'web') {
      setMount(true);
      setTimeout(() => {
        setShow(true);
      }, 10);
    }
    if (!isOpen && Platform.OS === 'web') {
      setShow(false);
      setTimeout(() => {
        setMount(false);
      }, transition);
    }
  }, [isOpen]);

  useEffect(() => {
    const { current: container } = containerRef;
    if (!guard.isHTMLDivElement(container)) {
      return undefined;
    }
    if (isMount) {
      container.setAttribute('data-type', 'context-menu');
      document.body.addEventListener('click', handleOutsidePress, true);
    }
    return () => {
      if (isMount) {
        document.body.removeEventListener('click', handleOutsidePress, true);
      }
    };
  }, [isMount]);

  if (!isMount) {
    return null;
  }

  return (
    <Box
      ref={containerRef}
      className={classes.panel}
      sx={{
        backgroundColor: transparent ? 'none' : (theme) => theme.palette.background.level1,
        boxShadow: transparent ? 'transparent' : (theme) => theme.shadow.md,
      }}
    >
      {children}
    </Box>
  );
};

Panel.defaultProps = defaultProps;

const useStyle = createUseStyles<
  'panel',
  {
    isShow?: boolean;
    isMount?: boolean;
    transition?: number;
    anchor?: PanelProps['anchor'];
    style?: StyleProp<ViewStyle>;
  }
>({
  panel: (props) => ({
    position: 'absolute',
    top: 0,
    right: props.anchor === 'right' ? 0 : undefined,
    left: props.anchor === 'left' ? 0 : undefined,
    zIndex: 100,
    opacity: props.isShow ? 1 : 0,
    transition: `opacity ${props.transition}ms ease`,
    ...styleSheetToCss(props.style),
  }),
});

export default memo(Panel);
