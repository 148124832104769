import extensionByContentType from './extensionByContentType';

type FileToBase64Result = {
  base64: string;
  extension: string;
  contentType: string;
};

const fileToBase64 = async (file: File): Promise<FileToBase64Result> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (typeof reader.result === 'string') {
        const [, contentType, base64] = reader.result.match(/data:(.*?);base64,(.*)/i) || [];
        const extension = extensionByContentType(contentType, 'txt');
        resolve({
          base64,
          extension,
          contentType,
        });
        return;
      }
      reject(new Error('Error input data'));
    };
  });

export default fileToBase64;
