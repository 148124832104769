import { createUseStyles } from 'react-jss';

const LexicalTheme = () => {
  useStyles();
  return null;
};

const useStyles = createUseStyles({
  '@global': {
    '.EditorTheme__ltr': {
      textAlign: 'left',
    },
    '.EditorTheme__rtl': {
      textAlign: 'right',
    },
    '.EditorTheme__paragraph': {
      '&': {
        margin: '1em 0',
        position: 'relative',
      },
      '.EditorTheme__ContentEditable > &:first-child': {
        marginTop: 0,
      },
      '.EditorTheme__ContentEditable > &:last-child': {
        marginBottom: 0,
      },
    },
    '.EditorTheme__h2': {
      '&': {
        margin: '1em 0',
        textTransform: 'uppercase',
      },
      '.EditorTheme__ContentEditable > &:first-child': {
        marginTop: 0,
      },
      '.EditorTheme__ContentEditable > &:last-child': {
        marginBottom: 0,
      },
    },
    '.EditorTheme__h3': {
      '&': {
        margin: '1em 0',
        textTransform: 'uppercase',
      },
      '.EditorTheme__ContentEditable > &:first-child': {
        marginTop: 0,
      },
      '.EditorTheme__ContentEditable > &:last-child': {
        marginBottom: 0,
      },
    },
    '.EditorTheme__h4': {
      '&': {
        margin: '1em 0',
        textTransform: 'uppercase',
      },
      '.EditorTheme__ContentEditable > &:first-child': {
        marginTop: 0,
      },
      '.EditorTheme__ContentEditable > &:last-child': {
        marginBottom: 0,
      },
    },
    '.EditorTheme__indent': {
      '--lexical-indent-base-value': '40px',
    },
    '.EditorTheme__textBold': {
      fontWeight: 'bold',
    },
    '.EditorTheme__textItalic': {
      fontStyle: 'italic',
    },
    '.EditorTheme__textUnderline': {
      textDecoration: 'underline',
    },
    '.EditorTheme__textStrikethrough': {
      textDecoration: 'line-through',
    },
    '.EditorTheme__textUnderlineStrikethrough': {
      textDecoration: 'underline line-through',
    },
    '.EditorTheme__textCode': {
      padding: '1px 0.25rem',
      fontFamily: 'Menlo, Consolas, Monaco, monospace',
      fontSize: '94%',
    },
    '.EditorTheme__link': {
      color: 'rgb(33, 111, 219)',
      textDecoration: 'none',
    },
    '.EditorTheme__link:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    '.EditorTheme__ol1': {
      padding: 0,
      margin: '0 0 0 16px',
      listStylePosition: 'outside',
    },
    '.EditorTheme__ol2': {
      padding: 0,
      margin: '0 0 0 16px',
      listStyleType: 'upper-alpha',
      listStylePosition: 'outside',
    },
    '.EditorTheme__ol3': {
      padding: 0,
      margin: '0 0 0 16px',
      listStyleType: 'lower-alpha',
      listStylePosition: 'outside',
    },
    '.EditorTheme__ol4': {
      padding: 0,
      margin: '0 0 0 16px',
      listStyleType: 'upper-roman',
      listStylePosition: 'outside',
    },
    '.EditorTheme__ol5': {
      padding: 0,
      margin: '0 0 0 16px',
      listStyleType: 'lower-roman',
      listStylePosition: 'outside',
    },
    '.EditorTheme__ul': {
      padding: 0,
      margin: '0 0 0 16px',
      listStylePosition: 'outside',
    },
    '.EditorTheme__listItem': {
      margin: '0.5em 0',
    },
  },
});

export default LexicalTheme;
