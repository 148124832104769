import { takeLatest, put, cancel } from 'redux-saga/effects';

import network from 'lib/network';
import type { MenuType } from 'app/entities';

import { call, select } from 'store/utils/saga/effects';
import * as playlistStore from 'store/nodes/playlist/slice';
import * as myContentStore from 'store/nodes/myContent';
import * as userActions from 'store/nodes/user/actions';
import * as userSelectors from 'store/nodes/user/selectors';

import * as actions from '../actions';

export const config = {
  action: [
    actions.load.type,
    playlistStore.actions.updateInBackgroundDone.type,
    playlistStore.actions.removeDone.type,
    myContentStore.actions.addPlaylistDone.type,
    userActions.subscribeDone.type,
    userActions.unsubscribeDone.type,
  ],
  method: takeLatest,
};

export function* func() {
  const userProfile = yield* select(userSelectors.getMy);
  if (!userProfile) {
    yield put(actions.loadDone());
    yield cancel();
    return;
  }

  const { data, errors } = yield* call(() => network.request<MenuType>('/stack-1/user/sidebar').get());
  if (errors || !data) {
    yield put(actions.loadDone());
    yield cancel();
    return;
  }

  if (Array.isArray(data.subscriptions) && data.subscriptions.length > 0) {
    const users = yield* select((state) =>
      data.subscriptions.map((item) => ({
        ...state.user.data[item.login],
        ...item,
      })),
    );

    yield put(userActions.setProfile({ data: users }));
  }

  yield put(
    actions.setData({
      follow: data.subscriptions.map((item) => item.id),
      newsTopics: data.newsTopics,
    }),
  );
  yield put(actions.loadDone());
  yield cancel();
}
