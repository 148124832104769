import { type ReactElement, useMemo } from 'react';
import { type StyleProp, StyleSheet, type ViewStyle } from 'react-native';

import { View } from 'components/Themed';

import { unit } from 'utils';
import { useField } from '../../utils/context';

import Picker from './elements/Picker';
import type { PickerProps } from './elements/types';

const DateInput = (props: PickerProps): ReactElement => {
  const { name, label, labelFont, value, error, errorFont, InputProps, width, onChange, onFocus, onBlur, ...viewProps } = props;

  const field = useField(name);

  const onChangeFinal = onChange || field?.onValueChange;
  const valueFinal = String(value || field?.value || '');

  const containerStyles = useMemo(() => {
    const result: StyleProp<ViewStyle> = {
      ...StyleSheet.flatten(styles.container),
      marginTop: field ? unit(16) : undefined,
    };
    return result;
  }, [field]);

  return (
    <View style={containerStyles}>
      <Picker label={label} labelFont={labelFont} InputProps={InputProps} error={error} errorFont={errorFont} onChange={onChangeFinal} value={valueFinal} />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {},
});

export default DateInput;
