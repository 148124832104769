import { createAction } from '@reduxjs/toolkit';
import type { ResourceType } from 'app/entities';
import camelcaseKeys from 'camelcase-keys';

export const askSearchResult = createAction(
  'socket/ask_search_result',
  (payload: {
    data: ResourceType[];
    metadata: {
      replace: boolean;
      isMaterialsExists: boolean;
      metadataHighlights: {
        highlights: string[];
      }[];
    };
  }) => ({
    payload: camelcaseKeys<typeof payload>(payload, { deep: true }),
  }),
);

export const askSearchClear = createAction('socket/ask_search_clear', (payload: { message: string }) => ({
  payload: camelcaseKeys<typeof payload>(payload, { deep: true }),
}));
