import { useEffect, type ForwardedRef, useState, useRef, useMemo, useCallback } from 'react';

import { dispatcher } from 'store/utils/redux/hooks';
import { isForwardedController } from 'components/Modal2/helpers';

import stack from '../Stack';
import { type Controller } from '../../types';

const useMethods = (
  forwardedRef: ForwardedRef<Controller>,
  onShow?: (context: any) => void,
  onBeforeShow?: (context: any) => void,
  onHide?: () => void,
  onBeforeHide?: () => void,
  onContext?: (data: any) => void,
) => {
  const [isVisible, setVisible] = useState(false);

  const contextRef = useRef<any>({});
  const callbacksRef = useRef<any>({});

  useEffect(() => {
    if (forwardedRef && 'current' in forwardedRef) {
      // @ts-ignore
      forwardedRef.current = {
        open: (newContext, callbacks?: { onOpen?: () => void; onClose?: () => void }) => {
          contextRef.current = newContext || {};
          callbacksRef.current = callbacks;
          onContext?.(newContext || {});
          setTimeout(() => {
            onBeforeShow?.(newContext || {});
            setVisible(true);
          }, 10);
        },
        close: () => {
          onBeforeHide?.();
          setVisible(false);
        },
        getContext: () => contextRef.current || {},
      };
    }
  }, [contextRef, forwardedRef, onBeforeHide, onBeforeShow, onContext, setVisible]);

  const handleShow = useCallback(() => {
    if (isForwardedController(forwardedRef) && forwardedRef.current.name) {
      dispatcher.modal.open(forwardedRef.current.name as any);
    }
    stack.add();
    onShow?.(contextRef?.current || {});
  }, [onShow]);

  const handleHide = useCallback(() => {
    if (isForwardedController(forwardedRef) && forwardedRef.current.name) {
      dispatcher.modal.close(forwardedRef.current.name as any);
    }
    onContext?.(undefined);
    stack.remove();
    onHide?.();
    callbacksRef.current?.onClose?.();
  }, [onContext, onHide]);

  const handleCloseQuery = useCallback(() => {
    setVisible(false);
  }, []);

  return useMemo(
    () => ({
      isVisible,
      setVisible,
      contextRef,
      handleShow,
      handleHide,
      handleCloseQuery,
    }),
    [handleCloseQuery, handleHide, handleShow, isVisible],
  );
};

export default useMethods;
