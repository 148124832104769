import { memo } from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography } from '@mui/joy';

import { useRoute } from 'navigation/hooks';

import * as noteStore from 'store/nodes/note';

import Icon from 'ui/Icon';

const IndexingStatus = () => {
  const route = useRoute<'Note'>();
  const id = route.params?.id === 'create' ? 'create' : Number(route.params?.id);
  const { isIndexing } = useSelector(noteStore.selectors.indexState(id));

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      gap={0.5}
      sx={{
        pointerEvents: 'none',
        opacity: isIndexing ? 0.75 : 0,
        transition: 'opacity 200ms ease',
      }}
    >
      <Box>
        <Icon name="arrows-rotate" animate="spin" size="sm" fw />
      </Box>
      <Typography fontSize={12} mb={0.125} color="neutral">
        processing
      </Typography>
    </Box>
  );
};

export default memo(IndexingStatus);
