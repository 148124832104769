import { type DefaultRootState } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';

const selector = createSelector(
  (state: DefaultRootState) => state.library.filter.meta.isLoading,
  (state: DefaultRootState) => state.library.filter.meta.isLoaded,
  (isLoading, isLoaded) => ({
    isLoading,
    isLoaded,
  }),
);

export default (state: DefaultRootState) => selector(state);
