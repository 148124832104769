/**
 * Copies the given text to the clipboard using the Clipboard API if available,
 * with a fallback method for older browsers.
 * @param text - The text to copy to the clipboard.
 */
const copyToClipboard = (text: string): void => {
  if (navigator.clipboard && navigator.clipboard.writeText) {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        console.log('Text successfully copied to clipboard');
      })
      .catch((err) => {
        console.error('Could not copy text: ', err);
        fallbackCopyTextToClipboard(text);
      });
  } else {
    fallbackCopyTextToClipboard(text);
  }
};

/**
 * Fallback method to copy text to the clipboard for older browsers.
 * @param text - The text to copy to the clipboard.
 */
const fallbackCopyTextToClipboard = (text: string): void => {
  const textarea = document.createElement('textarea');
  textarea.value = text;

  textarea.style.position = 'fixed';
  textarea.style.top = '0';
  textarea.style.left = '0';
  textarea.style.width = '2em';
  textarea.style.height = '2em';
  textarea.style.padding = '0';
  textarea.style.border = 'none';
  textarea.style.outline = 'none';
  textarea.style.boxShadow = 'none';
  textarea.style.background = 'transparent';

  document.body.appendChild(textarea);
  textarea.focus();
  textarea.select();

  try {
    const successful = document.execCommand('copy');
    if (successful) {
      console.log('Text successfully copied to clipboard');
    } else {
      console.error('Failed to copy text');
    }
  } catch (err) {
    console.error('Error copying text: ', err);
  }

  document.body.removeChild(textarea);
};

export default copyToClipboard;
