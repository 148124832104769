import type { PromoDataType } from 'widgets/Subscription/ui/PromoInput';
import useSubscriptionConfig from 'widgets/Subscription/model/useSubscriptionConfig';

/**
 * Represents the cost details when there is no promotional discount.
 */
interface RegularCost {
  type: 'regular';
  value: number;
}

/**
 * Represents the cost details when a promotional discount is applied.
 */
interface SpecialCost {
  type: 'special';
  value: number;
  promo: number;
}

/**
 * Represents the cost details for a subscription plan.
 */
type CostItem = RegularCost | SpecialCost;

/**
 * Computes the costs of subscription plans with or without a promotional discount.
 *
 * @param {PromoDataType | null} [promo] - Optional promotional data containing discount percentage.
 * @returns {Record<string, CostItem>} A map of plan IDs to their cost details.
 */
const useCostsWithPromo = (promo?: PromoDataType | null): Record<string, CostItem> => {
  const subscriptionConfig = useSubscriptionConfig();
  const map: Record<string, CostItem> = {};

  subscriptionConfig.plans.forEach((item) => {
    const value = item.cost.value || 0;
    const sale = Math.round(value * ((promo?.percentOff || 0) / 100));

    if (!promo) {
      map[item.identity.planId] = {
        type: 'regular',
        value,
      };
    } else {
      map[item.identity.planId] = {
        type: 'special',
        value,
        promo: value - sale,
      };
    }
  });

  return map;
};

export default useCostsWithPromo;
