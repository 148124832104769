import { useEffect, useState, useRef, useCallback } from 'react';
import { type DefaultRootState, useSelector } from 'react-redux';

import * as subscriptionStoreSelectors from 'widgets/Subscription/store/selectors';

/**
 * Хук для отслеживания состояния загрузки с задержкой после завершения операций.
 *
 * @param {'signUp' | 'signIn'} authType - Тип авторизации ('signUp' или 'signIn') для доступа к соответствующему состоянию загрузки.
 * @returns {boolean} - Флаг состояния загрузки.
 */
const useIsLoading = (authType: 'signUp' | 'signIn'): boolean => {
  const isAppsumoProcessing: boolean = useSelector(subscriptionStoreSelectors.isAppsumoProcessing);

  const selectIsAuthLoading = useCallback((state: DefaultRootState) => state.auth[authType]?.isLoading ?? false, [authType]);
  const isAuthLoading: boolean = useSelector(selectIsAuthLoading);

  const [isLoading, setLoading] = useState<boolean>(false);
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    if (isAppsumoProcessing || isAuthLoading) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
      setLoading(true);
    } else if (!timeoutRef.current) {
      timeoutRef.current = setTimeout(() => {
        setLoading(false);
        timeoutRef.current = null;
      }, 3000);
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
    };
  }, [isAppsumoProcessing, isAuthLoading]);

  return isLoading;
};

export default useIsLoading;
