import { takeEvery, put, type SagaReturnType } from 'redux-saga/effects';

import * as interactionStore from 'store/nodes/interaction';

import * as actions from '../actions';

export const config = {
  action: actions.hide.type,
  method: takeEvery,
};

export function* func(action: SagaReturnType<typeof actions.hide>) {
  const { id } = action.payload;
  yield put(interactionStore.actions.toggleDislike('content', id));
}
