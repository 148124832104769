import { v4 as uuidV4 } from 'uuid';

const getAppId = () => {
  if (typeof localStorage === 'undefined') {
    return null;
  }

  let appId = localStorage.getItem('appId');
  if (appId) {
    return appId;
  }

  appId = `app-id.${uuidV4()}`;
  localStorage.setItem('appId', appId);

  return appId;
};

export default getAppId;
