import { type SagaReturnType, takeEvery, cancel, put } from 'redux-saga/effects';

import { call } from 'store/utils/saga/effects';
import { type CollectionPermissionType } from 'app/entities';

import Alert from 'components/Alert';
import network from 'lib/network';

import dialog from 'components/Dialog';
import { guard } from 'utils';
import { actions } from '../slice';

export const config = {
  action: actions.deletePermission.type,
  method: takeEvery,
};

const confirm = (): Promise<boolean> =>
  new Promise((resolve) => {
    dialog.show(
      {
        title: 'Confirmation',
        text: 'Are you sure want to delete\nthis user permission?',
      },
      [
        {
          label: 'Cancel',
          variant: 'outlined',
          lightColor: '#929292',
          darkColor: '#77767E',
          handle: (dialogId: string) => {
            dialog.hide(dialogId);
            resolve(false);
          },
        },
        {
          label: 'Yes',
          variant: 'contained',
          lightColor: '#db3327',
          darkColor: '#db3327',
          textLightColor: '#ffffff',
          textDarkColor: '#ffffff',
          handle: (dialogId: string) => {
            dialog.hide(dialogId);
            resolve(true);
          },
        },
      ],
    );
  });

export function* func(action: SagaReturnType<typeof actions.deletePermission>) {
  const { userId, collectionId, permissionId } = action.payload;

  let isConfirmed = true;

  isConfirmed = yield* call(() => confirm());

  if (!userId || !collectionId || !isConfirmed) {
    yield put(actions.deletePermissionDone({ userId, permissionId, collectionId }));
    yield cancel();
    return;
  }

  const { data, errors } = yield* call(() =>
    network.request<CollectionPermissionType[]>(`/stack-1/share/user/playlists/${collectionId}/deletePermission`).body({ user_id: userId }).delete(),
  );

  if (errors || !data) {
    Alert.error('Error delete permission');
    yield put(actions.deletePermissionDone({ userId, permissionId, collectionId }));
    yield cancel();
    return;
  }
  if (data && guard.isCollectionPermissionType(data)) {
    yield put(
      actions.setPermissions({
        collectionId,
        permissions: data,
      }),
    );
  }
  yield put(actions.deletePermissionDone({ userId, permissionId, collectionId }));
}
