import feed from './feed';
import profile from './profile';
import playlist from './playlist/route';
import content from './content/route';
import community from './community/route';
import search from './search/route';
import library from '../library';
import relation from '../relation';

export default {
  feed,
  profile,
  playlist,
  content,
  community,
  library,
  search,
  relation,
};
