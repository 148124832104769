import IconSVG from './IconSVG';
import type { PlatformOSType } from '../types';

class PlatformGroup {
  private readonly ios: IconSVG;

  private readonly android: IconSVG;

  private readonly web: IconSVG;

  constructor(ios: IconSVG, android: IconSVG, web: IconSVG) {
    this.ios = ios;
    this.android = android;
    this.web = web;
  }

  getByPlatform = (platformName: PlatformOSType): IconSVG => this[platformName];
}

export default PlatformGroup;
