import { createAction } from '@reduxjs/toolkit';

import type { MaterialType, PaginationType } from 'app/entities';

export const clear = createAction('CONTENT/CLEAR');

export const removeFromSequence = createAction('CONTENT/REMOVE_FROM_SEQUENCE', (type: 'content' | 'playlist', id: number) => ({
  payload: {
    type,
    id,
  },
}));

export const loadNextPage = createAction('CONTENT/LOAD_PAGE', (reload = false) => ({
  payload: {
    reload,
  },
}));

export const loadNextPageDone = createAction('CONTENT/LOAD_PAGE_DONE', (reload = false) => ({
  payload: {
    reload,
  },
}));

export const addPage = createAction('CONTENT/SET_PAGE', (items: MaterialType[], paginationInfo: PaginationType, reload = false) => ({
  payload: {
    items,
    paginationInfo,
  },
  meta: {
    reload,
  },
}));

export const loadRelated = createAction('CONTENT/LOAD_RELATED', (contentId: number) => ({
  payload: {
    contentId,
  },
}));

export const loadRelatedDone = createAction('CONTENT/LOAD_RELATED_DONE', (contentId: number, relatedIds: number[]) => ({
  payload: {
    contentId,
    relatedIds,
  },
}));

export const loadById = createAction('CONTENT/LOAD_BY_ID', (id: number | number[]) => ({
  payload: {
    id,
  },
}));

export const loadByIdDone = createAction('CONTENT/LOAD_BY_ID_DONE', (id: number | number[]) => ({
  payload: {
    id,
  },
}));

export const setItem = createAction('CONTENT/SET_ITEM', (data: MaterialType | MaterialType[] | null) => ({
  payload: {
    data,
  },
}));

export const loadFilters = createAction('CONTENT/LOAD_FILTERS', () => ({
  payload: {},
}));

export const loadFiltersDone = createAction('CONTENT/LOAD_FILTERS_DONE', (isError = false) => ({
  payload: {
    isError,
  },
}));

export const setFilters = createAction('CONTENT/SET_FILTERS', ({ type }: { type: { options: string[] } }) => ({
  payload: {
    type: {
      options: [...(type?.options || [])],
    },
  },
}));

export const setFilterType = createAction('CONTENT/SET_FILTER_TYPE', (value: string, triggerReload = true) => ({
  payload: {
    value,
    triggerReload,
  },
}));

export const hide = createAction('CONTENT/HIDE_CONTENT', (id: number) => ({
  payload: {
    id,
  },
}));

export const loadWatched = createAction('CONTENT/LOAD_WATCHED');

export const loadWatchedDone = createAction('CONTENT/LOAD_WATCHED_DONE');

export const setWatched = createAction('CONTENT/SET_WATCHED', (data: MaterialType) => ({
  payload: {
    data,
  },
}));

export const closeWatched = createAction('CONTENT/CLOSE_WATCHED');

export const remove = createAction('CONTENT/REMOVE', (id: number | number[]) => ({
  payload: {
    id,
  },
}));

export const removeDone = createAction('CONTENT/REMOVE_DONE', (id: number | number[]) => ({
  payload: {
    id,
  },
}));

export const logOut = createAction('CONTENT/LOGOUT');

export const highlightDelete = createAction('CONTENT/HIGHLIGHT_DELETE', (id: number, resourceId: number) => ({
  payload: {
    id,
    resourceId,
  },
}));

export const highlightDeleteDone = createAction('CONTENT/HIGHLIGHT_DELETE_DONE', (id: number, resourceId: number) => ({
  payload: {
    id,
    resourceId,
  },
}));

export const noteUpdate = createAction('CONTENT/NOTE_UPDATE', (resourceId: number, text: string, id?: number) => ({
  payload: {
    id,
    resourceId,
    text,
  },
}));

export const noteUpdateDone = createAction('CONTENT/NOTE_UPDATE_DONE', (resourceId: number, id?: number) => ({
  payload: {
    id,
    resourceId,
  },
}));

export const noteDelete = createAction('CONTENT/NOTE_DELETE', (resourceId: number, id: number) => ({
  payload: {
    id,
    resourceId,
  },
}));

export const materialIndexed = createAction('CONTENT/MATERIAL_INDEXED', (resourceId: number) => ({
  payload: {
    resourceId,
  },
}));

export const noteDeleteDone = createAction('CONTENT/NOTE_DELETE_DONE', (resourceId: number, id: number) => ({
  payload: {
    id,
    resourceId,
  },
}));
