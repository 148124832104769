import { memo, useCallback } from 'react';
import { StyleSheet } from 'react-native';

import Button from 'components/Button';
import { Text, View } from 'components/Themed';

import { navigate } from 'navigation/methods';
import { unit } from 'utils';

export type SummaryPreparedProps = {
  resourceId?: number;
  type?: string;
  title?: string | null;
  summary?: string | null;
};

const SummaryPrepared = (props: SummaryPreparedProps) => {
  const { summary, title, resourceId, type } = props;

  if (!summary) {
    return null;
  }

  const handleNavigateToMaterial = useCallback(() => {
    if (!resourceId || !type) {
      return;
    }
    navigate('Content', { resourceId, resourceType: type });
  }, [resourceId, type]);

  if (!resourceId) {
    return null;
  }

  return (
    <View style={styles.SummaryPrepared}>
      <Text darkColor="#B8B6BF" lightColor="#797979" size={15}>
        Summary prepared
      </Text>
      <Text weight="semi" size={16}>
        {title}
      </Text>
      <Text numberOfLines={6} size={15}>
        {summary}
      </Text>
      <Button style={{ alignSelf: 'center' }} variant="outlined" type="button" onPress={handleNavigateToMaterial}>
        <Text size={13}>Open material</Text>
      </Button>
    </View>
  );
};

const styles = StyleSheet.create({
  SummaryPrepared: {
    display: 'flex',
    flexDirection: 'column',
    margin: unit(8),
    gap: unit(16),
  },
});

export default memo(SummaryPrepared);
