import { createSelector } from '@reduxjs/toolkit';
import { type DefaultRootState } from 'react-redux';

const selector = createSelector(
  (state: DefaultRootState) => state.news.filter,
  (state: DefaultRootState) => state.news.list.meta,
  (filter, meta) => ({
    isConsistent: true,
    firstPage: {
      isLoading: meta.isFirstPageLoading,
      isLoaded: meta.isFirstPageLoaded,
    },
    nextPage: {
      isLoading: meta.isNextPageLoading,
      isLoaded: meta.isNextPageLoaded,
    },
  }),
);

export default (state: DefaultRootState) => selector(state);
