import { memo, type FC, type PropsWithChildren } from 'react';
import { Button, type ButtonProps } from '@mui/joy';

const UpgradeButton: FC<PropsWithChildren<Omit<ButtonProps, 'variant'>>> = (props) => {
  const { children, disabled, loading, sx, ...rest } = props;

  return (
    <Button
      disabled={disabled}
      loading={loading}
      variant={disabled ? 'outlined' : 'solid'}
      color="primary"
      sx={{
        '--variant-solidDisabledBg': loading ? 'var(--joy-palette-primary-solidBg)' : 'transparent',
        '--variant-outlinedDisabledBorder': 'var(--joy-palette-text-tertiary)',
        '--variant-softBg': 'var(--joy-palette-neutral-solidColor)',
        '--border-color': 'var(--joy-palette-primary-solidBg)',
        '&:hover': {
          '--border-color': 'var(--joy-palette-primary-solidHoverBg)',
        },
        '&:active': {
          '--border-color': 'var(--joy-palette-primary-solidActiveBg)',
        },
        fontSize: 20,
        fontWeight: '300',
        border: () => (disabled ? undefined : '1px solid var(--border-color)'),
        borderRadius: (theme) => theme.radius.lg,
        width: '100%',
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default memo(UpgradeButton);
