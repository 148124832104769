import { useMemo, useState } from 'react';
import { stripTags } from 'utils';

const useEditorState = (initialValue: string | null = null) => {
  const [value, setValue] = useState<string | null>(initialValue);

  return useMemo(() => {
    const text = stripTags(value);
    return {
      onChange: setValue,
      html: value || '',
      text: text || '',
      hasContent: !!text,
    };
  }, [value]);
};

export default useEditorState;
