import { type ReactElement, memo } from 'react';

import Icon, { type IconNames } from 'components/LegacyIcon';

const defaultProps = {
  size: 28,
};

type MenuIconProps = {
  name: IconNames;
  isSelected?: boolean;
  size?: number;
} & typeof defaultProps;

const MenuIcon = (props: MenuIconProps): ReactElement => {
  const { name, size, isSelected } = props;

  return <Icon name={name} darkColor={isSelected ? '#ffffff' : '#ffffff'} lightColor={isSelected ? '#000000' : '#000000'} size={size} />;
};

MenuIcon.defaultProps = defaultProps;

export default memo(MenuIcon);
