import { EventEmitter as OriginEventEmitter } from 'events';

import type { EventEmitterBase, EmitterSubscription } from './types';

class EventEmitter implements EventEmitterBase {
  private emitter = new OriginEventEmitter();

  public addListener = (type: string, listener: (data: any) => void, context?: any): EmitterSubscription => {
    this.emitter.on(type, listener);
    return {
      remove: () => {
        this.emitter.off(type, listener);
      },
    };
  };

  public emit = (type: string, context: Record<string, any>): void => {
    this.emitter.emit(type, context);
    window.dispatchEvent(new CustomEvent('storage', { detail: { type, context } }));
  };
}

export default EventEmitter;
