export default (containerWidth: number, columnsScheme?: Record<number, number> | string): number => {
  let result = 1;

  let scheme: Record<number, number> | null;

  if (!columnsScheme) {
    return result;
  }

  if (typeof columnsScheme === 'string') {
    try {
      scheme = {};
      columnsScheme.split(/[:;]/).forEach((width, i, array) => {
        if (i % 2 !== 0) {
          return;
        }
        if (scheme) {
          scheme[Number(width)] = Number(array[i + 1]);
        }
      });
    } catch (e) {
      scheme = null;
    }
  } else {
    scheme = columnsScheme;
  }

  if (!scheme) {
    return result;
  }

  Object.entries(scheme).forEach(([width, numColumns]) => {
    if (containerWidth >= Number(width)) {
      result = numColumns;
    }
  });

  return result;
};
