import { createAction } from '@reduxjs/toolkit';

import type { MaterialType, MaterialsType, NoteType } from 'app/entities';
import type { PropsType, PlusStore } from './types';

export const close = createAction('plus/close');

export const open = createAction('plus/open', (props?: PropsType) => ({
  payload: {
    props,
  },
}));

export const setProps = createAction('plus/setProps', (props?: PropsType) => ({
  payload: {
    props,
  },
}));

export const setUploadingInfo = createAction('plus/setUploadPercent', (data: { count?: number; percent?: number }) => ({
  payload: {
    data,
  },
}));

export const parseText = createAction('plus/parseText', (data: { value: string; privacy: boolean }) => ({
  payload: {
    data,
  },
}));

export const parseFiles = createAction('plus/parseFile', (data: { files: File[]; privacy: boolean }) => ({
  payload: {
    data,
  },
}));

export const setMaterialResult = createAction('plus/setMaterialResult', (material: MaterialType) => ({
  payload: {
    type: 'material' as const,
    data: {
      ...material,
      summary: null,
    } as MaterialType,
  },
}));

export const setMaterialsResult = createAction('plus/setMaterialsResult', (data: MaterialsType) => ({
  payload: {
    type: 'materials' as const,
    data,
  },
}));

export const setNoteResult = createAction('plus/setNoteResult', (data: NoteType) => ({
  payload: {
    type: 'note' as const,
    data,
  },
}));

export const setErrorResult = createAction('plus/setErrorResult', (errorMessage: string) => ({
  payload: {
    type: 'error' as const,
    data: errorMessage,
  },
}));

export const parseDone = createAction('plus/parseDone');

export const setSummary = createAction('plus/setSummary', (value: string) => ({
  payload: {
    value,
  },
}));

export const addMaterialsToCollection = createAction('plus/addMaterialsToCollection', (collectionId: number, uploadSessionId) => ({
  payload: {
    collectionId,
    uploadSessionId,
  },
}));

export const addMaterialsToCollectionDone = createAction('plus/addMaterialsToCollectionDone');

export const openYoutubeActivation = createAction(
  'plus/openYoutubeActivation',
  (payload: { deviceCode: string; interval: number; userCode: string; verificationUrl: string }) => ({
    payload,
  }),
);

export const setYoutubeProcessingState = createAction(
  'plus/setYoutubeProcessingState',
  (payload: { value: PlusStore['youtubeProcessingState'] }) => ({
    payload,
  }),
);
