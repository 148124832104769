import React, { memo } from 'react';
import { Box, Typography, useTheme } from '@mui/joy';
import Icon from 'ui/Icon';

export type PlanFeaturesProps = {
  titles: string[];
  hasTitles: boolean;
  values: (string | number | boolean)[];
};

const PlanFeatures = (props: PlanFeaturesProps) => {
  const { titles, hasTitles, values } = props;
  const theme = useTheme();
  return (
    <Box display="flex" flexDirection="column" gap={1.5}>
      {titles.map((title, key) => (
        <Box display="flex" flexDirection="row" alignItems="center" gap={1.5} justifyContent="space-between" pr="10%">
          <Typography>{hasTitles ? title : ' '}</Typography>
          {typeof values?.[key] === 'string' && (
            <Typography minWidth={25} textAlign="center">
              {values?.[key] || ' '}
            </Typography>
          )}
          {typeof values?.[key] === 'number' && (
            <Typography minWidth={25} textAlign="center">
              {values?.[key] || ' '}
            </Typography>
          )}
          {typeof values?.[key] === 'boolean' && values?.[key] === true && <Icon size="lg" name="check" fw weight="regular" color={theme.palette.primary.solidBg} />}
          {typeof values?.[key] === 'boolean' && values?.[key] === false && <Icon size="lg" name="xmark" fw weight="light" color={theme.palette.text.tertiary} />}
        </Box>
      ))}
    </Box>
  );
};

export default memo(PlanFeatures);
