import { useSelector } from 'react-redux';
import * as searchStore from 'store/nodes/search';
import { useCallback, useMemo } from 'react';
import { useDispatcher } from 'store/utils/redux/hooks';

const useSearchMethod = () => {
  const method = useSelector(searchStore.selectors.method);
  const dispatcher = useDispatcher();

  const state = useMemo(() => {
    const [, scope, type] = method.match(/(Global|Library|Web)(.*)/i) || [];
    return {
      scope: scope as 'Global' | 'Library' | 'Web',
      type: (type || null) as 'FullText' | 'Title' | null,
    } as const;
  }, [method]);

  const setScope = useCallback(
    (scope: 'Global' | 'Library' | 'Web') => {
      if (scope === 'Web') {
        dispatcher.search.setMethod({ value: 'Web' });
      }
      if (scope === 'Library') {
        dispatcher.search.setMethod({
          value: `Library${state.type || 'FullText'}`,
        });
      }
      if (scope === 'Global') {
        dispatcher.search.setMethod({
          value: `Global${state.type || 'FullText'}`,
        });
      }
    },
    [state],
  );

  const setType = useCallback(
    (value: 'FullText' | 'Title') => {
      if (state.scope === 'Web') {
        return;
      }
      dispatcher.search.setMethod({ value: `${state.scope}${value}` });
    },
    [state.scope],
  );

  const changeScope = useCallback(
    (value: 'Global' | 'Library') => {
      if (!value) {
        return;
      }
      dispatcher.search.setMethod({ value: `${value}${state.type}` });
    },
    [state.type],
  );

  return useMemo(
    () => ({
      ...state,
      setScope,
      setType,
      changeScope,
    }),
    [state, setScope, setType, changeScope],
  );
};

export default useSearchMethod;
