import { type ReactElement, type PropsWithChildren } from 'react';
import { Platform, type ViewStyle } from 'react-native';

import { View } from 'components/Themed';

type PlatformViewProps = {
  webClassName: string;
  nativeStyle: ViewStyle;
};

const PlatformView = (props: PropsWithChildren<PlatformViewProps>): ReactElement => {
  const { children, webClassName, nativeStyle } = props;

  if (Platform.OS === 'web') {
    return <div className={webClassName}>{children}</div>;
  }
  return <View style={nativeStyle}>{children}</View>;
};

export default PlatformView;
