import { put, type SagaReturnType, takeLatest } from 'redux-saga/effects';

import * as navigationStore from 'store/nodes/navigation';

import { actions } from '../slice';

export const config = {
  action: [actions.nextStep.type, actions.prevStep.type],
  method: takeLatest,
};

export function* func(action: SagaReturnType<typeof actions.nextStep | typeof actions.prevStep>) {
  yield put(navigationStore.actions.route());
}
