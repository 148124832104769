import * as SplashScreen from 'expo-splash-screen';
import * as React from 'react';

const useCachedResources = (): boolean => {
  const [isLoadingComplete, setLoadingComplete] = React.useState<boolean>(false);

  // Load any resources or data that we need prior to rendering the app
  React.useEffect(() => {
    async function loadResourcesAndDataAsync() {
      try {
        SplashScreen.preventAutoHideAsync().catch((error) => {
          console.log('SplashScreen.preventAutoHideAsync(): ', error.message);
        });
      } catch (e) {
        // We might want to provide this error information to an error reporting service
        console.warn(e);
      } finally {
        setLoadingComplete(true);
        SplashScreen.hideAsync().catch((error) => {
          console.log('SplashScreen.hideAsync(): ', error.message);
        });
      }
    }

    loadResourcesAndDataAsync().catch((error) => {
      console.log('SplashScreen.hideAsync(): ', error.message);
    });
  }, []);

  return isLoadingComplete;
};

export default useCachedResources;
