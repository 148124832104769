import { type FC, memo, useMemo } from 'react';

import { isEqual } from 'lodash';

import type { Resource } from './store/types';

import { ConfigProvider, type ConfigType } from './model/config';

import DefaultLayout from './layouts/DefaultLayout';

import type { ResourceType } from './types';

export { default as CopilotUtilityBar } from './ui/UtilityBar';
export { default as CopilotShowToggle } from './ui/ShowToggle';
export { default as CopilotResetButton } from './ui/ResetButton';
export { default as CopilotScopeSwitcher } from './ui/ScopeSwitcher';
export { default as CopilotModelSelect } from './ui/ModelSelect';

type BaseProps = {
  windowScroll?: boolean;
  disabled?: boolean;
  slotsProps?: ConfigType;
};

type MaterialProps = {
  variant: 'material';
  resourceId: number;
} & BaseProps;

type CollectionProps = {
  variant: 'collection';
  resourceId: number;
} & BaseProps;

type NoteProps = {
  variant: 'note';
  resourceId: number;
} & BaseProps;

type LibraryProps = {
  variant: 'library';
} & BaseProps;

type WebProps = {
  variant: 'web';
} & BaseProps;

type GlobalFulltextProps = {
  variant: 'global-fulltext';
} & BaseProps;

type LibraryFulltextProps = {
  variant: 'library-fulltext';
} & BaseProps;

type CopilotProps = MaterialProps | CollectionProps | LibraryProps | NoteProps | WebProps | GlobalFulltextProps | LibraryFulltextProps;

const Copilot: FC<CopilotProps> = (props) => {
  const { variant, windowScroll, disabled, slotsProps } = props;

  const resourceId = useMemo(() => {
    if (variant === 'material' || variant === 'collection' || variant === 'note') {
      return props.resourceId;
    }
    return 0;
  }, [variant, props]);

  const includeItemsForResource = useMemo(() => {
    const map: Record<ResourceType, Resource[]> = {
      material: [],
      collection: [],
      note: [],
      library: [],
      web: [
        { type: 'global-fulltext', id: null },
        { type: 'library-fulltext', id: null },
      ],
      'global-fulltext': [
        { type: 'web', id: null },
        { type: 'library-fulltext', id: null },
      ],
      'library-fulltext': [
        { type: 'web', id: null },
        { type: 'global-fulltext', id: null },
      ],
    } as const;
    return map[variant];
  }, [variant]);

  return (
    <ConfigProvider
      value={{
        answer: {
          actionsBar: !slotsProps?.answer?.actionsBar ? ['Reload', 'ToClipboard', 'ToNote'] : slotsProps?.answer?.actionsBar,
        },
      }}
    >
      <DefaultLayout resourceType={variant} resourceId={resourceId} includeItemsForResource={includeItemsForResource} windowScroll={windowScroll} disabled={disabled} />
    </ConfigProvider>
  );
};

export default memo(Copilot, (prevProps, nextProps) => isEqual(prevProps, nextProps));
