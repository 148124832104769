import { type ColorSchemeName, useColorScheme as _useColorScheme } from 'react-native';

import { useStorageValue } from 'lib/Storage';

// The useColorScheme value is always either light or dark, but the built-in
// type suggests that it can be null. This will not happen in practice, so this
// makes it a bit easier to work with.
export default function useColorScheme(): NonNullable<ColorSchemeName> {
  let value = useStorageValue('theme.type', 'system') as NonNullable<ColorSchemeName | 'system'>;
  const system = _useColorScheme() as NonNullable<ColorSchemeName>;
  if (value === 'system' || !value) {
    value = system;
  }
  return value;
}
