import React, { memo, useEffect, useState, type CSSProperties, type PropsWithChildren } from 'react';

import type { ItemProps } from 'react-virtuoso';

type CustomStyle = {
  '--child-height'?: string;
} & CSSProperties;

const HeightPreservingItem = (itemProps: PropsWithChildren<ItemProps<any>>) => {
  const { children, 'data-known-size': dataKnownSize } = itemProps;
  const [size, setSize] = useState(0);
  const knownSize = dataKnownSize;

  const style: CustomStyle = {
    '--child-height': `${size}px`,
  };

  useEffect(() => {
    setSize((prevSize) => {
      return knownSize === 0 ? prevSize : knownSize;
    });
  }, [knownSize]);

  return (
    <div {...itemProps} className="height-preserving-container" style={style}>
      {children}
    </div>
  );
};

export default memo(HeightPreservingItem);
