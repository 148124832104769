import { type CSSProperties, type ReactElement, type PropsWithChildren, memo, useMemo, useEffect, useRef } from 'react';
import { Platform, StyleSheet } from 'react-native';

import View from '../View';

import { type BlurViewProps } from './types';

const BlurView = (props: PropsWithChildren<BlurViewProps>): ReactElement => {
  const { className, children, factor, style, ...otherProps } = props;

  const viewRef = useRef<HTMLDivElement | any>(null);

  const styleFinal = useMemo(() => {
    const result = {
      ...StyleSheet.flatten(styles.BlurView),
      ...StyleSheet.flatten(style),
    };

    if (Platform.OS === 'web' && factor) {
      (result as CSSProperties).backdropFilter = `blur(${factor / 10}rem)`;
      (result as CSSProperties).WebkitBackdropFilter = `blur(${factor / 10}rem)`;
    }

    return result;
  }, [style, factor]);

  useEffect(() => {
    const { current: div } = viewRef;
    if (!div || !className) {
      return undefined;
    }
    const classes = className.split(' ');
    div.classList.add(...classes);
    return () => {
      div.classList.remove(...classes);
    };
  }, [className]);

  return (
    <View ref={viewRef} style={styleFinal} {...otherProps}>
      {children}
    </View>
  );
};

const styles = StyleSheet.create({
  BlurView: {
    display: 'flex',
    flexDirection: 'column',
  },
});

export default memo(BlurView);
