import { createUseStyles } from 'react-jss';
import { unit } from 'utils';

const BottomSheetStyleLight = () => {
  useStyles();
  return null;
};

const useStyles = createUseStyles({
  '@global': {
    ':root': {
      '--rsbs-backdrop-bg': 'rgba(0, 0, 0, 0.7)',
      '--rsbs-bg': '#232326',
      '--rsbs-handle-bg': 'hsla(0, 0%, 0%, 0.14)',
      '--rsbs-max-w': 'auto',
      '--rsbs-ml': 'env(safe-area-inset-left)',
      '--rsbs-mr': 'env(safe-area-inset-right)',
      '--rsbs-overlay-rounded': '30px',
    },
    '[data-rsbs-overlay]': {
      boxShadow: `0 0 ${unit(4)} #0000000d, 0 0 ${unit(48)} #00000099`,
      borderTopLeftRadius: unit(30),
      borderTopRightRadius: unit(30),
    },
  },
});

export default BottomSheetStyleLight;
