import { type SagaReturnType, takeLatest } from 'redux-saga/effects';
import { call } from 'store/utils/saga/effects';

import { actions } from '../slice';

export const config = {
  action: actions.signUp.type,
  method: takeLatest,
};

export function* func(action: SagaReturnType<typeof actions.signUp>) {
  yield call(() => console.log('sign_up', action));
}
