import request from 'services/api/request';
import type { HighlightType, ResourceVariant, ResultLegacy, PaginationResult, CursorType } from 'app/entities';

export default async (resource: ResourceVariant, resourceIds: number | number[], pageSize = 100): Promise<ResultLegacy<Record<number, PaginationResult<HighlightType>['data']>>> => {
  if (!resourceIds || (Array.isArray(resourceIds) && resourceIds.length === 0)) {
    return {
      data: {},
      error: null,
    };
  }
  const idsFinal = Array.isArray(resourceIds) ? resourceIds : [resourceIds];

  const results = await Promise.all(
    idsFinal.map((resourceId) =>
      request.get<CursorType<HighlightType>>(
        `/stack-1/${resource}/${resourceId}/highlights`,
        { query: { pageSize } },
      ),
    ),
  );

  const data: Record<number, PaginationResult<HighlightType>['data']> = {};
  let error: Error | null = null;
  results.forEach((result, key) => {
    if (error) {
      return;
    }
    if (result.error) {
      error = (Array.isArray(result.error) ? result.error?.[0] : result.error) || null;
    }
    data[idsFinal[key]] = {
      items: result.data?.items || [],
      paginationInfo: {
        nextCursor: result.data?.nextUrl || null,
        hasNext: !!result.data?.nextUrl,
      },
    };
  });
  if (error) {
    return {
      data: {},
      error,
    };
  }

  return {
    data,
    error,
  };
};
