import { type SagaReturnType, takeEvery, put, cancel } from 'redux-saga/effects';

import { gtm } from 'utils';

import * as messageStore from 'store/nodes/message';
import { call } from 'store/utils/saga/effects';

export const config = {
  action: messageStore.socketActions.messageInfo.type,
  method: takeEvery,
};

export function* func(action: SagaReturnType<typeof messageStore.socketActions.messageInfo>) {
  const { id, context } = action.payload;
  const { type: eventType } = context;

  if (eventType !== 'onboarding_done') {
    yield cancel();
    return;
  }
  yield put(messageStore.actions.prevent({ id }));

  yield call(() =>
    gtm.dataLayer.push({
      event: 'onboarding',
    }),
  );
}
