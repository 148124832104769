import { createReducer } from '@reduxjs/toolkit';

import type { ValueStore } from './types';
import * as actions from './actions';

const initialState: ValueStore = {
  data: {},
};

export default createReducer(initialState, (builder) =>
  builder.addCase(actions.set, (state, action) => {
    const { key, value } = action.payload;
    state.data[key] = value;
  }),
);
