import { memo, useState, forwardRef, type ForwardedRef, useEffect } from 'react';
import PrismJS from 'prismjs';
import { useColorScheme } from 'hooks';

import PrismLightTheme from './themes/PrismLightTheme';
import PrismDarkTheme from './themes/PrismDarkTheme';

const Prism = (props: unknown, forwardedRef: ForwardedRef<{ highlightAll: typeof PrismJS.highlightAll }>) => {
  const scheme = useColorScheme();

  useEffect(() => {
    if (forwardedRef && 'current' in forwardedRef) {
      forwardedRef.current = {
        highlightAll: PrismJS.highlightAll,
      };
    }
  }, [forwardedRef]);

  return (
    <>
      {scheme === 'light' && <PrismLightTheme />}
      {scheme === 'dark' && <PrismDarkTheme />}
    </>
  );
};

export default memo(forwardRef(Prism));
