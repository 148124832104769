import { type SagaReturnType, takeEvery, put } from 'redux-saga/effects';

import * as socketStore from 'store/nodes/socket';
import * as contentStore from 'store/nodes/content';

export const config = {
  action: socketStore.actions.materialIndexed.type,
  method: takeEvery,
};

export function* func(action: SagaReturnType<typeof socketStore.actions.materialIndexed>) {
  const { payload } = action;
  const { material_id: materialId } = payload;
  yield put(contentStore.actions.materialIndexed(materialId));
}
