import { type FC, type MouseEventHandler, memo, useCallback, useState, useMemo } from 'react';
import { Box, type BoxProps, Card, Stack, Chip, Typography, useColorScheme } from '@mui/joy';
import { isEqual } from 'lodash';

import Icon from 'ui/Icon';

type RemarkType = {
  type: 'Summary' | 'Highlight';
  text?: string | null;
};

export type RemarksProps = {
  items?: RemarkType[];
} & BoxProps;

const Remarks: FC<RemarksProps> = (props) => {
  const { items, sx, ...rest } = props;
  const [expanded, setExpanded] = useState<RemarkType['type'] | null>(null);

  const { colorScheme } = useColorScheme();

  const handleExpandClick: MouseEventHandler<HTMLButtonElement> = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    const button = event.target as HTMLButtonElement;
    const type = (button.getAttribute('itemid') as RemarkType['type']) || undefined;
    if (!type) {
      return;
    }
    setExpanded((prevState) => {
      if (prevState === type) {
        return null;
      }
      return type;
    });
  }, []);

  const text = useMemo(() => {
    if (!expanded) {
      return null;
    }
    const data = items?.find((item) => item.type === expanded && !!item.text);
    return data?.text ?? null;
  }, [items, expanded]);

  if (!Array.isArray(items) || items.length === 0) {
    return null;
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={1}
      sx={{
        '@container (width < 400px )': {
          gap: 1.5,
        },
        '--variant-softBg': `var(${colorScheme === 'light' ? '--joy-palette-neutral-200' : '--joy-palette-neutral-700'})`,
        '--variant-softHoverBg': `var(${colorScheme === 'light' ? '--joy-palette-neutral-300' : '--joy-palette-neutral-600'})`,
        '--variant-softActiveBg': `var(${colorScheme === 'light' ? '--joy-palette-neutral-400' : '--joy-palette-neutral-500'})`,
        '--variant-solidBg': `var(${colorScheme === 'light' ? '--joy-palette-highlight-100' : '--joy-palette-highlight-700'})`,
        '--variant-solidHoverBg': `var(${colorScheme === 'light' ? '--joy-palette-highlight-200' : '--joy-palette-highlight-600'})`,
        '--variant-solidActiveBg': `var(${colorScheme === 'light' ? '--joy-palette-highlight-300' : '--joy-palette-highlight-500'})`,
        ...sx,
      }}
      {...rest}
    >
      <Stack direction="row" spacing={1}>
        {items
          .filter((item) => Boolean(item.text?.trim()))
          .map((item) => (
            <Chip
              key={item.type}
              startDecorator={<Icon name="lightbulb-on" fw weight="light" color="inherit" />}
              variant={item.type === 'Summary' ? 'soft' : 'solid'}
              slotProps={{
                action: {
                  itemID: item.type,
                },
              }}
              onClick={handleExpandClick}
              sx={{
                fontSize: (theme) => theme.typography['body-xs'],
              }}
            >
              {item.type}
            </Chip>
          ))}
      </Stack>
      {!!text && (
        <Card
          variant="soft"
          color="neutral"
          size="sm"
          sx={{
            '--Card-radius': (theme) => theme.radius.md,
            backgroundColor: expanded === 'Summary' ? 'var(--variant-softBg)' : 'var(--variant-solidBg)',
            // backgroundColor: 'var(--nestedBg)',
            transition: 'background-color 200ms ease',
          }}
        >
          <Typography
            level="body-xs"
            fontWeight="normal"
            sx={{
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 8,
              overflow: 'hidden',
            }}
          >
            {text}
          </Typography>
        </Card>
      )}
    </Box>
  );
};

export default memo(Remarks, (prevProps, nextProps) => isEqual(prevProps, nextProps));
