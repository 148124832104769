import { createAction } from '@reduxjs/toolkit';
import { md5 } from 'js-md5';

import type { FilterItemType, PaginationType, ResourceType } from 'app/entities';
import type { ScreenParams } from 'navigation/types';

export const clear = createAction('LIBRARY/CLEAR');

export const loadFilters = createAction('LIBRARY/LOAD_FILTERS', () => ({
  payload: {},
}));

export const loadFiltersDone = createAction('LIBRARY/LOAD_FILTERS_DONE', (isError = false) => ({
  payload: {
    isError,
  },
}));

export const setFilters = createAction('LIBRARY/SET_FILTERS', (items: FilterItemType[]) => ({
  payload: {
    items,
  },
}));

export const enableTempTagFilter = createAction('LIBRARY/ADD_TEMP_TAG', (tag: string) => ({
  payload: {
    item: {
      id: md5(tag),
      type: 'TAG',
      label: tag,
      query: { tags: [tag] },
      isTemp: true,
    } as FilterItemType,
  },
}));

export const enableFilter = createAction('LIBRARY/ENABLE_FILTER', (id: string | string[]) => ({
  payload: {
    id,
  },
}));

export const disableFilter = createAction('LIBRARY/DISABLE_FILTER', (id: string | string[]) => ({
  payload: {
    id,
  },
}));

export const toggleFilter = createAction('LIBRARY/TOGGLE_FILTER', (id: string | string[]) => ({
  payload: {
    id,
  },
}));

export const loadList = createAction('LIBRARY/LOAD_LIST', () => ({
  payload: {
    reload: true,
  },
}));

export const loadNextPage = createAction('LIBRARY/LOAD_PAGE', () => ({
  payload: {
    type: undefined as string | undefined,
    reload: false,
  },
}));

export const loadNextPageDone = createAction('LIBRARY/LOAD_PAGE_DONE', (reload = false) => ({
  payload: {
    reload,
  },
}));

export const loadMeta = createAction('LIBRARY/LOAD_META');

export const loadMetaDone = createAction('LIBRARY/LOAD_META_DONE', (meta: { hasAnyItems: boolean }) => ({
  payload: {
    meta,
  },
}));

export const addPage = createAction('LIBRARY/SET_PAGE', (items: ResourceType[], paginationInfo: PaginationType, reload = false) => ({
  payload: {
    items,
    paginationInfo,
  },
  meta: {
    reload,
  },
}));

export const remove = createAction(
  'library/remove',
  (payload: {
    resourceType: 'material' | 'collection' | 'document' | 'note';
    resourceId: number;
    onSuccessNavigate?: {
      name: keyof ScreenParams;
      params?: Record<string, any>;
    };
  }) => ({
    payload,
  }),
);

export const prependToSequence = createAction(
  'LIBRARY/PREPEND_TO_SEQUENCE',
  (resourceType: 'material' | 'collection' | 'document' | 'note', resourceId: number) => ({
    payload: {
      resourceType,
      resourceId,
    },
  }),
);

export const removeFromSequence = createAction(
  'LIBRARY/REMOVE_FROM_SEQUENCE',
  (resourceType: 'material' | 'collection' | 'document' | 'note', resourceId: number) => ({
    payload: {
      resourceType,
      resourceId,
    },
  }),
);

export const removeDone = createAction('LIBRARY/REMOVE_DONE');

export const logOut = createAction('LIBRARY/LOGOUT');
