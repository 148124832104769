import { useMemo } from 'react';
import { Platform, type TextStyle } from 'react-native';
import { type FontProps, type FontSizeVariant, type FontWeightVariant } from 'font';

import { unit } from 'utils';

const isWeb = Platform.OS === 'web';

export const fontSettingBySize = (size?: FontSizeVariant) => {
  const map = {
    11: {
      fontSize: unit(11),
      lineHeight: isWeb ? `${13 / 11}` : unit(13),
      letterSpacing: isWeb ? `${0.07 / 11}em` : unit(0.07),
    },
    12: {
      fontSize: unit(12),
      lineHeight: isWeb ? `${16 / 12}` : unit(16),
      letterSpacing: isWeb ? `${0 / 12}em` : unit(0),
    },
    13: {
      fontSize: unit(13),
      lineHeight: isWeb ? `${18 / 13}` : unit(18),
      letterSpacing: isWeb ? `${-0.08 / 13}em` : unit(-0.08),
    },
    14: {
      fontSize: unit(14),
      lineHeight: isWeb ? `${19 / 14}` : unit(19),
      letterSpacing: isWeb ? `${-0.24 / 14}em` : unit(-0.24),
    },
    15: {
      fontSize: unit(15),
      lineHeight: isWeb ? `${20 / 15}` : unit(20),
      letterSpacing: isWeb ? `${-0.24 / 15}em` : unit(-0.24),
    },
    16: {
      fontSize: unit(16),
      lineHeight: isWeb ? `${21 / 16}` : unit(21),
      letterSpacing: isWeb ? `${-0.32 / 16}em` : unit(-0.32),
    },
    17: {
      fontSize: unit(17),
      lineHeight: isWeb ? `${22 / 17}` : unit(22),
      letterSpacing: isWeb ? `${-0.41 / 17}em` : unit(-0.41),
    },
    20: {
      fontSize: unit(20),
      lineHeight: isWeb ? `${25 / 20}` : unit(25),
      letterSpacing: isWeb ? `${0.38 / 20}em` : unit(0.38),
    },
    22: {
      fontSize: unit(22),
      lineHeight: isWeb ? `${28 / 22}` : unit(28),
      letterSpacing: isWeb ? `${0.35 / 22}em` : unit(0.35),
    },
    28: {
      fontSize: unit(28),
      lineHeight: isWeb ? `${34 / 28}` : unit(34),
      letterSpacing: isWeb ? `${0.36 / 28}em` : unit(0.36),
    },
    34: {
      fontSize: unit(34),
      lineHeight: isWeb ? `${41 / 34}` : unit(41),
      letterSpacing: isWeb ? `${0.37 / 34}em` : unit(0.37),
    },
    54: {
      fontSize: unit(54),
      lineHeight: isWeb ? `${52 / 54}` : unit(52),
      letterSpacing: isWeb ? `${0 / 54}em` : unit(0),
    },
    57: {
      fontSize: unit(57),
      lineHeight: isWeb ? `${64 / 57}` : unit(64),
      letterSpacing: isWeb ? `${0 / 57}em` : unit(-0.25),
    },
  };
  return map[size || 16] || map[16];
};

export const weightToNumber = (weight?: FontWeightVariant) =>
  (
    ({
      regular: '400',
      medium: '500',
      semi: '600',
      bold: '700',
    }) as const
  )[weight || 'regular'];

const useFontProps = <T = TextStyle>(props: Partial<FontProps>): T => {
  const { font, size, weight, align, opacity } = props;

  return useMemo(() => {
    const settings = fontSettingBySize(size);
    const result: TextStyle = {
      fontSize: settings.fontSize,
      lineHeight: settings.lineHeight as unknown as number,
      letterSpacing: settings.letterSpacing as unknown as number,
      fontWeight: weightToNumber(weight),
      fontFamily:
        // eslint-disable-next-line max-len
        '-apple-system, "Roboto", "Segoe UI", "Helvetica Neue", sans-serif',
    };
    if (font === 'system-serif') {
      result.fontFamily = 'sans-serif';
    }
    if (font === 'system-mono') {
      result.fontFamily = "'Ubuntu Mono', ui-monospace, SFMono-Regular, ui-monospace, Monaco, 'Andale Mono', monospace";
    }
    if (align) {
      result.textAlign = align;
    }
    if (opacity) {
      result.opacity = opacity;
    }
    return result as T;
  }, [font, align, opacity, size, weight]);
};

export default useFontProps;
