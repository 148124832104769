import type { ResultLegacy, TopicType } from 'app/entities';
import request from 'services/api/request';

export default async (
  query?: string,
  pageSize = 50,
  pageNum = 0,
): Promise<ResultLegacy<Omit<TopicType, 'directionId' | 'rank'>[]>> => {
  const { data, error } = await request.get<
    Omit<TopicType, 'directionId' | 'rank'>[]
  >('/stack-1/skills/directions', {
    query: {
      q: query,
      pageSize,
      pageNum,
    },
  });
  return {
    data,
    error,
  };
};
