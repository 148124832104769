import { type ReactElement, useEffect, useRef } from 'react';
import { DotLottieReact } from '@lottiefiles/dotlottie-react';

import { unit } from 'utils';

import source from '../../assets/lottie/63274-loading-animation_iki.json';

const defaultProps = {
  size: 100,
  loop: true,
};

type SpinnerDottedProps = {
  size?: number;
  loop?: boolean;
} & typeof defaultProps;

const SpinnerDotted = (props: SpinnerDottedProps): ReactElement => {
  const { size, loop } = props;

  const lottie = useRef<any>(null);

  useEffect(() => {
    if (lottie.current && 'play' in lottie.current) {
      lottie.current.play?.();
    }
  }, [lottie]);

  return (
    <DotLottieReact
      style={{
        width: unit(size),
      }}
      data={source}
      loop={loop}
      autoplay
    />
  );
};

SpinnerDotted.defaultProps = defaultProps;

export default SpinnerDotted;
