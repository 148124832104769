import { memo, useCallback } from 'react';
import { type StyleProp, type ViewStyle, StyleSheet, TouchableOpacity } from 'react-native';
import { useSelector } from 'react-redux';

import { useThemeColor, useHasSession, useCollectionMethods } from 'hooks';
import { unit } from 'utils';

import { useDispatcher } from 'store/utils/redux/hooks';
import * as playlistStore from 'store/nodes/playlist';

import { controller as modal } from 'components/Modal2';
import { Text, View } from 'components/Themed';
import Icon from 'components/LegacyIcon';

const defaultProps = {};

type ActionsProps = {
  style?: StyleProp<ViewStyle>;
  id: number;
} & typeof defaultProps;

const Actions = (props: ActionsProps) => {
  const { style, id: collectionId } = props;

  const dispatcher = useDispatcher();
  const hasSession = useHasSession();
  const borderColor = useThemeColor({ light: '#000000', dark: '#ffffff' });
  const { handleAddNote } = useCollectionMethods(collectionId);

  const data = useSelector(playlistStore.selectors.dataById(collectionId));

  const handleInQueue = useCallback(() => {
    if (!collectionId) {
      return;
    }
    dispatcher.interaction.toggleBookmark('playlist', collectionId);
  }, [collectionId, hasSession]);

  const handleComplete = useCallback(() => {
    if (!collectionId) {
      return;
    }
    modal.popup.review.open({ resource: 'playlist', resourceId: collectionId });
  }, [collectionId]);

  const handleSmartList = useCallback(() => {
    dispatcher.playlist.toggleSmartList({ id: collectionId });
  }, [collectionId]);

  const handleAddMaterial = useCallback(() => {
    dispatcher.modal.open('PlusBottomSheet', {
      toCollection: { id: collectionId },
    });
  }, []);

  if (!collectionId) {
    return <View />;
  }

  return (
    <View style={[styles.Actions, style]}>
      <View style={styles.buttons}>
        {!data?.isMy && (
          <>
            <TouchableOpacity style={[styles.action, { borderColor }]} onPress={handleInQueue} activeOpacity={0.8}>
              <Icon name={!data?.isBookmarked ? 'BookmarkOutline28' : 'BookmarkFill28'} size={28} lightColor="#000000" darkColor="#ffffff" />
              <Text size={12} lightColor="#000000" darkColor="#ffffff">
                {!data?.isBookmarked ? 'In queue' : 'Queue'}
              </Text>
            </TouchableOpacity>
            <TouchableOpacity style={[styles.action, { borderColor }]} onPress={!data?.isCompleted ? handleComplete : undefined} activeOpacity={!data?.isCompleted ? 0.8 : 1}>
              <Icon name={!data?.isCompleted ? 'DoneFill28' : 'DoneAllFill28'} size={28} lightColor="#000000" darkColor="#ffffff" />
              <Text size={12} lightColor="#000000" darkColor="#ffffff">
                {!data?.isCompleted ? 'Complete' : 'Completed'}
              </Text>
            </TouchableOpacity>
          </>
        )}
        {data?.isMy && (
          <>
            <TouchableOpacity style={[styles.action, { borderColor }]} onPress={handleAddMaterial} activeOpacity={0.8}>
              <Icon name="AddOutline28" size={28} lightColor="#000000" darkColor="#ffffff" />
              <Text size={12} lightColor="#000000" darkColor="#ffffff">
                Add material
              </Text>
            </TouchableOpacity>
            <TouchableOpacity style={[styles.action, { borderColor }]} onPress={handleAddNote} activeOpacity={0.8}>
              <Icon name="ChatOutline28" size={28} lightColor="#000000" darkColor="#ffffff" />
              <Text size={12} lightColor="#000000" darkColor="#ffffff">
                Add note
              </Text>
            </TouchableOpacity>
          </>
        )}
        <TouchableOpacity style={[styles.action, { borderColor }]} onPress={handleSmartList} activeOpacity={0.5}>
          {data?.isSmartListEnabled && <Icon name="MarkerByIKI" size={24} lightColor="#000000" darkColor="#ffffff" style={{ margin: unit(2) }} />}
          {!data?.isSmartListEnabled && <Icon name="MarkerByIKIGray" size={24} lightColor="#000000" darkColor="#ffffff" style={{ margin: unit(2) }} />}
          <Text size={12} lightColor="#000000" darkColor="#ffffff">
            Enhance list
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

Actions.defaultProps = defaultProps;

const styles = StyleSheet.create({
  Actions: {
    flexDirection: 'row',
    overflow: 'hidden',
  },
  buttons: {
    flexDirection: 'row',
    marginHorizontal: unit(-5),
    flex: 1,
  },
  action: {
    borderWidth: 1,
    borderRadius: unit(10),
    paddingHorizontal: unit(8),
    paddingVertical: unit(8),
    alignItems: 'center',
    flex: 1,
    marginHorizontal: unit(5),
  },
  smartButton: {
    position: 'relative',
    width: unit(50),
    height: unit(30),
  },
  smartLineIcon: {
    position: 'absolute',
    left: 0,
    top: unit(-10),
    width: unit(50),
    height: unit(50),
    justifyContent: 'center',
  },
});

export default memo(Actions);
