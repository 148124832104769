import { md5 } from 'js-md5';

import { request, type ResultLegacy } from 'services/api';

import type { FilterItemType } from 'app/entities';

export default async (): Promise<ResultLegacy<FilterItemType[]>> => {
  const { data, error } = await request.get<Omit<FilterItemType, 'id'>[]>(
    '/stack-1/user/library/filters',
  );
  if (error || !data) {
    return {
      data: null,
      error,
    };
  }

  return {
    data: data.map((item) => ({
      id: md5(item.label),
      ...item,
    })),
    error,
  };
};
