import { type DefaultRootState } from 'react-redux';
import { createCachedSelector } from 're-reselect';

import { type RemarkType } from 'app/entities';

const remarkSelector = createCachedSelector(
  (state: DefaultRootState, remarkId: number | null | undefined) => (!remarkId ? null : state.content.remarks[remarkId]),
  (remark): RemarkType | null => {
    if (!remark) {
      return null;
    }
    return remark;
  },
)((_state_, remarkId?: number | null) => remarkId);

export default (remarkId?: number | null) =>
  (state: DefaultRootState): RemarkType | null => {
    return remarkSelector(state, remarkId);
  };
