import { cancel, put, type SagaReturnType, takeLatest } from 'redux-saga/effects';

import { call, select } from 'store/utils/saga/effects';

import { getRoute, navigate } from 'navigation/methods';

import * as selectors from 'widgets/Onboarding/store/selectors';
import { type ScreenParams } from 'navigation/types';
import { actions } from '../slice';

export const config = {
  action: actions.route.type,
  method: takeLatest,
};

type Step = 'SetUpProfile' | 'TellUsAboutYou' | 'InviteTeam' | 'TryFree';

export function* func(action: SagaReturnType<typeof actions.route>) {
  const { exitScreen } = action.payload;
  const onBoardingState = yield* select(selectors.onboardingState);
  const route = yield* call(() => getRoute());

  const { profile, about, invite, trial } = onBoardingState;

  if (profile && about && invite && trial) {
    yield put(actions.routeDone({ navigated: false }));
    if (
      route.isScreen('Auth/Onboarding/Profile') ||
      route.isScreen('Auth/Onboarding/AboutYou') ||
      route.isScreen('Auth/Onboarding/InviteTeam') ||
      route.isScreen('Auth/Onboarding/TryFree')
    ) {
      yield* call(() => navigate(exitScreen));
    }
    yield cancel();
    return;
  }

  const stepToScreen: Record<Step, keyof ScreenParams> = {
    SetUpProfile: 'Auth/Onboarding/Profile',
    TellUsAboutYou: 'Auth/Onboarding/AboutYou',
    InviteTeam: 'Auth/Onboarding/InviteTeam',
    TryFree: 'Auth/Onboarding/TryFree',
  };

  let step: Step | null = null;

  if (!profile) {
    step = 'SetUpProfile';
  } else if (!about) {
    step = 'TellUsAboutYou';
  } else if (!invite) {
    step = 'InviteTeam';
  } else if (!trial) {
    step = 'TryFree';
  }

  if (!step) {
    yield put(actions.routeDone({ navigated: false }));
    yield cancel();
    return;
  }

  const screen = stepToScreen[step];
  if (!route.isScreen(screen)) {
    yield* call(() => navigate(screen));
    yield put(actions.routeDone({ navigated: true }));
  }
}
