import { type KeyboardEvent } from 'react';

const isLineBreakCombination = (event: KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>): boolean => {
  const isMacOS = typeof navigator !== 'undefined' && /Macintosh|Mac OS X/i.test(navigator.userAgent);

  if ((event.key || event.code) !== 'Enter') {
    return false;
  }

  if (isMacOS) {
    return event.metaKey && !event.ctrlKey && !event.shiftKey && !event.altKey;
  }

  return (event.ctrlKey || event.shiftKey) && !event.metaKey && !event.altKey;
};

export default isLineBreakCombination;
