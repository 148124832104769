import { useState, useMemo, useCallback, type SetStateAction } from 'react';
import useSubscriptionConfig from 'widgets/Subscription/model/useSubscriptionConfig';

function usePlanState() {
  const subscriptionConfig = useSubscriptionConfig();
  const [value, setValue] = useState(subscriptionConfig.trial.default);

  const handleChange = useCallback((event: { target: { value: SetStateAction<string> } }) => {
    setValue(event.target.value);
  }, []);

  return useMemo(
    () => ({
      value,
      handleChange,
    }),
    [value, handleChange],
  );
}

export default usePlanState;
