import { type CSSProperties } from 'react';

/**
 * Function to flatten style prop which can be an array or object.
 * @param {CSSProperties | CSSProperties[] | undefined} style - The style prop.
 * @returns {CSSProperties} Flattened style object.
 */
export function flattenStyle(style: CSSProperties | CSSProperties[] | undefined): CSSProperties {
  if (Array.isArray(style)) {
    return Object.assign({}, ...style);
  }
  return style || {};
}
