import { createReducer } from '@reduxjs/toolkit';

import { type SmartNoteStore } from './types';

import * as actions from './actions';

const initialState: SmartNoteStore = {
  data: {},
};

export default createReducer(initialState, (builder) =>
  builder
    .addCase(actions.setData, (state, action) => {
      const { data } = action.payload;
      if (Array.isArray(data)) {
        data.forEach((value) => {
          state.data[value.id] = value;
        });
      }
      if (typeof data === 'object') {
        Object.entries(data).forEach(([key, value]) => {
          state.data[Number(key)] = value;
        });
      }
    })
    .addCase(actions.createDone, (state, action) => {
      const { success, data } = action.payload;
      if (!success || !data) {
        return;
      }
      state.data[data.id] = data;
    })
    .addCase(actions.update, (state, action) => {
      const { data } = action.payload;
      const items = !Array.isArray(data) ? [data] : data;
      items.forEach((item) => {
        if (!state.data[item.id] || state.data[item.id].text === item.text) {
          return;
        }
        state.data[item.id].text = item.text;
      });
    })
    .addCase(actions.remove, (state, action) => {
      const ids = !Array.isArray(action.payload.id) ? [action.payload.id] : action.payload.id;
      ids.forEach((id) => {
        if (!state.data[id]) {
          return;
        }
        delete state.data[id];
      });
    }),
);
