import subscriptions from './resolvers/subscriptions';
import subscribers from './resolvers/subscribers';
import subscribe from './resolvers/subscribe';
import unsubscribe from './resolvers/unsubscribe';

export default {
  subscriptions,
  subscribers,
  subscribe,
  unsubscribe,
};
