import { type DefaultRootState } from 'react-redux';
import { createCachedSelector } from 're-reselect';

import type { MaterialMetadataItemType } from 'app/entities';

const selectorMaterialsMetadataByIdSelector = createCachedSelector(
  (state: DefaultRootState, resourceId: number) => state.playlist.data[resourceId],
  (collection) => {
    if (!Array.isArray(collection.materialsMetadata) || collection.materialsMetadata.length === 0) {
      return [] as MaterialMetadataItemType[];
    }
    return [
      ...collection.materialsMetadata.filter((item: { type: string; isSmartListRecommendation: boolean }) => {
        if (item.type === 'material' && collection.isSmartListEnabled === false && item.isSmartListRecommendation === true) {
          return false;
        }
        return true;
      }),
    ];
  },
)((_state_, resourceId) => resourceId);

export default (resourceId: number | null | undefined) => (state: DefaultRootState) => {
  if (!resourceId || !state.playlist.data[resourceId]) {
    return null;
  }
  return selectorMaterialsMetadataByIdSelector(state, resourceId);
};
