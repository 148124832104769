import { useEffect, useMemo, useState } from 'react';

import { useSelector } from 'store/utils/redux/hooks';
import * as selectors from '../store/selectors';

import usePeriodOptions from './usePeriodOptions';
import type { PeriodType } from '../types';

const usePeriodState = () => {
  const currentPlan = useSelector(selectors.currentPlan);
  const [value, setValue] = useState<PeriodType>('monthly');
  const options = usePeriodOptions() as { value: PeriodType; label: string }[];

  useEffect(() => {
    if (currentPlan.period) {
      setValue(currentPlan.period);
    }
  }, [currentPlan.period]);

  return useMemo(
    () => ({
      value,
      change: setValue,
      options,
    }),
    [value, options],
  );
};

export default usePeriodState;
