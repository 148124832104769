import { createAction } from '@reduxjs/toolkit';

import type { Body, ButtonAlias } from './types';

export const ready = createAction('APP/READY');

export const prepared = createAction('APP/PREPARED');

export const hideSplashScreen = createAction('app/hideSplashScreen');

export const dialogShow = createAction('APP/DIALOG_SHOW', (id: string, body: Body, buttons: ButtonAlias[]) => ({
  payload: {
    id,
    body,
    buttons,
  },
}));

export const dialogHide = createAction('APP/DIALOG_HIDE', (id: string) => ({
  payload: {
    id,
  },
}));

export const dialogRemove = createAction('APP/DIALOG_REMOVE', (id: string) => ({
  payload: {
    id,
  },
}));

export const initProtection = createAction('app/initProtection');

export const initProtectionDone = createAction('app/initProtectionDone');
