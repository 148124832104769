import { type ReactNode, memo, useCallback } from 'react';
import { Box, type BoxProps } from '@mui/joy';
import type { DefaultRootState } from 'react-redux';
import { isEqual } from 'lodash';

import { type AuthorType, type UserType } from 'app/entities';

import { useNavigate } from 'navigation/hooks';

import { useSelector } from 'store/utils/redux/hooks';
import * as contentStore from 'store/nodes/content';
import * as collectionStore from 'store/nodes/playlist';
import * as noteStore from 'store/nodes/note';
import * as searchStore from 'store/nodes/search';

import { guard } from 'utils';

import useLoadData from './model/useLoadData';
import usePosterSources from './model/usePosterSources';

import RagMaterial from './layout/RagMaterial';
import Wireframe from './layout/Wireframe';
import Collection from './layout/Collection';
import Material from './layout/Material';
import Note from './layout/Note';

import Footer from './ui/Footer';
import Body from './ui/Body';

import type { CardType, LegacyCardType } from './types';

type CardProps = {
  id: number;
  type: CardType | LegacyCardType;
  hasDescription?: boolean;
  descriptionLineLimit?: number;
  hasTags?: boolean;
  tagLimit?: number;
  footerStart?: ReactNode;
  hasSummary?: boolean;
  hasHighlight?: boolean;
  hasQueue?: boolean;
  hasRemove?: boolean;
  hasToCollection?: boolean;
  hasLink?: boolean;
  hasEdit?: boolean;
  hasAuthor?: boolean;
  hasIndexed?: boolean;
  AuthorBlockComponent?: ReactNode;
} & Omit<BoxProps, 'id'>;

const Card = (props: CardProps) => {
  const {
    id,
    type,
    hasDescription,
    descriptionLineLimit,
    hasTags,
    tagLimit,
    footerStart,
    hasSummary,
    hasHighlight,
    hasQueue,
    hasRemove,
    hasToCollection,
    hasLink,
    hasEdit,
    hasAuthor,
    hasIndexed,
    AuthorBlockComponent,
    sx,
    ...rest
  } = props;

  useLoadData(id, type);
  const navigate = useNavigate();

  const data = useSelector((state: DefaultRootState) => {
    if (guard.resourceKeyIsMaterial(type)) {
      return contentStore.selectors.dataById(id)(state);
    }
    if (guard.resourceKeyIsCollection(type)) {
      return collectionStore.selectors.dataById(id)(state);
    }
    if (guard.resourceKeyIsNote(type)) {
      return noteStore.selectors.dataById(id)(state);
    }
    if (guard.resourceKeyIsDocument(type)) {
      return noteStore.selectors.dataById(id)(state);
    }
    if (guard.resourceKeyIsRagMaterial(type)) {
      return searchStore.selectors.dataById(id)(state);
    }
    return null;
  });

  const posterSources = usePosterSources(data);

  const handleAuthorPress = useCallback((value: UserType | AuthorType | null) => {
    if (guard.isUser(value)) {
      navigate('Profile', {
        login: value.login,
      });
      return;
    }
    if (guard.isAuthor(value) && value.owner) {
      navigate('Profile', {
        login: value.owner.login,
      });
      return;
    }
    if (guard.isAuthor(value) && !value.owner) {
      navigate('Author', {
        id: value.id,
      });
    }
  }, []);

  const handleBodyPress = useCallback(() => {
    if (!data) {
      return;
    }
    if (guard.isMaterial(data)) {
      const resourceId = id;
      const resourceType = data?.type?.toLowerCase?.();
      if (resourceType && /^https?:\/\//i.test(data?.originalUrl || '')) {
        navigate('Content', { resourceId, resourceType });
      }
    }
    if (guard.isCollection(data)) {
      navigate('Playlist', { resourceId: id });
    }
    if (guard.isNote(data)) {
      navigate('Note', { id });
    }
    if (guard.isDocument(data)) {
      navigate('Note', { id });
    }
    if (guard.isRagMaterial(data)) {
      const link = document.createElement('a');
      link.setAttribute('href', data?.originalUrl);
      link.setAttribute('target', '_blank');
      link.click();
    }
  }, [data, id]);

  if (!data) {
    return (
      <Box sx={sx} {...rest}>
        <Wireframe />
      </Box>
    );
  }

  return (
    <Body
      handleBodyPress={handleBodyPress}
      sx={{
        ...sx,
      }}
      {...rest}
    >
      {guard.isCollection(data) && (
        <>
          <Collection
            data={data}
            posterSources={posterSources}
            handleAuthorPress={handleAuthorPress}
            hasDescription={hasDescription}
            hasAuthor={hasAuthor}
            hasSummary={hasSummary}
            descriptionLineLimit={descriptionLineLimit}
          />
          <Footer
            id={id}
            type={type}
            data={data}
            hasTags={hasTags}
            hasQueue={hasQueue}
            hasEdit={false}
            hasLink={hasLink}
            hasRemove={hasRemove}
            hasToCollection={false}
            tagLimit={tagLimit}
            startElements={footerStart}
          />
        </>
      )}
      {guard.isMaterial(data) && (
        <>
          <Material
            data={data}
            posterSources={posterSources}
            handleAuthorPress={handleAuthorPress}
            hasDescription={hasDescription}
            hasAuthor={hasAuthor}
            hasSummary={hasSummary}
            hasHighlight={hasHighlight}
            AuthorBlockComponent={AuthorBlockComponent}
            descriptionLineLimit={descriptionLineLimit}
          />
          <Footer
            id={id}
            type={type}
            data={data}
            hasTags={hasTags}
            hasQueue={hasQueue}
            hasEdit={false}
            hasLink={hasLink}
            hasRemove={hasRemove}
            hasToCollection={hasToCollection}
            tagLimit={tagLimit}
            startElements={footerStart}
          />
        </>
      )}
      {guard.isRagMaterial(data) && (
        <>
          <RagMaterial data={data} hasDescription={hasDescription} descriptionLineLimit={descriptionLineLimit} />
          <Footer
            id={id}
            type={type}
            hasTags={false}
            hasQueue={false}
            hasEdit={false}
            hasLink={hasLink}
            hasRemove={false}
            hasToCollection={false}
            tagLimit={tagLimit}
            startElements={footerStart}
          />
        </>
      )}
      {guard.isNote(data) && !!id && (
        <>
          <Note id={id} hasLink={hasLink} tagLimit={tagLimit} handleAuthorPress={handleAuthorPress} hasAuthor={hasAuthor} />
          <Footer
            id={id}
            type="Note"
            hasTags={false}
            hasQueue={false}
            hasEdit={false}
            hasLink={hasLink}
            hasRemove={hasRemove && data?.isMy}
            hasToCollection={hasToCollection}
            tagLimit={tagLimit}
            startElements={footerStart}
          />
        </>
      )}
      {guard.isDocument(data) && !!id && (
        <>
          <Note id={id} hasLink={hasLink} tagLimit={tagLimit} handleAuthorPress={handleAuthorPress} hasAuthor={hasAuthor} />
          <Footer
            id={id}
            type="Note"
            hasTags={false}
            hasQueue={false}
            hasEdit={false}
            hasLink={hasLink}
            hasRemove={hasRemove && data?.isMy}
            hasToCollection={hasToCollection}
            tagLimit={tagLimit}
            startElements={footerStart}
          />
        </>
      )}
    </Body>
  );
};

export default memo(Card, (prevProps, nextProps) => isEqual(prevProps, nextProps));
