import { createAction } from '@reduxjs/toolkit';

type Item<ID = number> = { type: 'material' | 'note' | 'unknown'; id: number };

export const open = createAction('SCREEN_COLLECTION/OPEN', (id: number) => ({
  payload: {
    id,
  },
}));

export const close = createAction('SCREEN_COLLECTION/CLOSE');

export const setHead = createAction('SCREEN_COLLECTION/SET_HEAD', (value) => ({
  payload: {
    value,
  },
}));

export const loadNextMaterials = createAction('SCREEN_COLLECTION/NEXT_MATERIALS', (reset = false) => ({
  payload: {
    reset,
  },
}));

export const addPage = createAction('SCREEN_COLLECTION/ADD_PAGE', (items: Item[]) => ({
  payload: {
    items,
  },
}));

export const setPage = createAction('SCREEN_COLLECTION/SET_PAGE', (items: Item[]) => ({
  payload: {
    items,
  },
}));

export const loadNextMaterialsDone = createAction('SCREEN_COLLECTION/NEXT_MATERIALS_DONE', (hasNext = false) => ({
  payload: {
    hasNext,
  },
}));

export const sortItems = createAction('SCREEN_COLLECTION/SORT_ITEMS', (oldIndex: number, newIndex: number) => ({
  payload: {
    oldIndex,
    newIndex,
  },
}));

export const newNote = createAction('SCREEN_COLLECTION/NEW_NOTE', (options?: { after: Item<number | string> }) => ({
  payload: {
    options,
  },
}));

export const cancelNewNote = createAction('SCREEN_COLLECTION/CANCEL_NEW_NOTE', (tempId: string) => ({
  payload: {
    tempId,
  },
}));

export const commitNewNote = createAction('SCREEN_COLLECTION/COMMIT_NEW_NOTE', (tempId: string, text: string) => ({
  payload: {
    tempId,
    text,
  },
}));

export const saveNewNoteDone = createAction(
  'SCREEN_COLLECTION/SAVE_NEW_NOTE_DONE',
  (
    success: boolean,
    data?: {
      tempId: string;
      realId: number;
    },
  ) => ({
    payload: {
      success,
      data,
    },
  }),
);

export const removeFromSequence = createAction('SCREEN_COLLECTION/REMOVE_FROM_SEQUENCE', (type: 'material' | 'note' | 'new-note' | 'unknown', id: number | string) => ({
  payload: {
    type,
    id,
  },
}));

export const setHasNext = createAction('SCREEN_COLLECTION/SET_HAS_NEXT', (value: boolean) => ({
  payload: {
    value,
  },
}));

export const itemAdded = createAction('SCREEN_COLLECTION/ITEM_ADDED', (id: number, position: number) => ({
  payload: {
    id,
    position,
  },
}));

export const itemsRemoved = createAction('SCREEN_COLLECTION/ITEMS_REMOVED', (items: Item[]) => ({
  payload: {
    items,
  },
}));

export const updateSequence = createAction('SCREEN_COLLECTION/UPDATE_SEQUENCE', (items: Item[]) => ({
  payload: {
    items,
  },
}));
