import list from './list';
import create from './create';
import update from './update';
import remove from './remove';
import addContent from './addContent';
import removeContent from './removeContent';

export default {
  list,
  create,
  update,
  remove,
  addContent,
  removeContent,
};
