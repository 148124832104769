import { memo } from 'react';
import { Box, Button, FormControl, FormLabel, Input, Typography } from '@mui/joy';

import { PhotoInput } from 'components/Form';

import DualPanels from 'widgets/DualPanels';

import Icon from 'ui/Icon';

import Header from 'widgets/Onboarding/ui/Header';
import useSetUpState from 'widgets/Onboarding/model/useSetUpState';

const backgroundProps = {
  imageBackground: require('widgets/Onboarding/assets/profile.jpg'),
};

const SetUpProfile = () => {
  const { onChangeLogin, onChangePhoto, onChangeName, onSubmit, onKeyDown, name, login, photo, validation, isLoading } = useSetUpState();

  return (
    <DualPanels
      leftHeaderPanel={<Header />}
      leftTitlePanel={
        <Box display="flex" flexDirection="column" alignItems="center" mb={2} gap={1.5}>
          <Typography fontSize={52} fontWeight={500}>
            Set up profile
          </Typography>
          <Typography fontSize={16} fontWeight={400}>
            You can change this later
          </Typography>
        </Box>
      }
      leftPanel={
        <Box display="flex" flexDirection="column" minWidth={320}>
          <Box display="flex" flexDirection="column" gap={2}>
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" gap={1} mb={1}>
              <PhotoInput value={photo} onChange={onChangePhoto} />
              <Typography fontSize={16} fontWeight={400}>
                Profile photo
              </Typography>
            </Box>
            <FormControl size="md" inputMode="email">
              <FormLabel sx={{ color: (theme) => theme.palette.neutral.plainColor }}>Name *</FormLabel>
              <Input
                placeholder="Enter your name"
                variant="soft"
                required
                disabled={isLoading}
                value={name || ''}
                name="name"
                onChange={onChangeName}
                size="lg"
                sx={{
                  '--_Input-focusedHighlight': (theme) => theme.palette.neutral.softColor,
                }}
              />
            </FormControl>
            <FormControl size="md" inputMode="email">
              <FormLabel sx={{ color: (theme) => theme.palette.neutral.plainColor }}>Username *</FormLabel>
              <Input
                placeholder="Enter your username"
                variant="soft"
                disabled={isLoading}
                required
                value={login || ''}
                name="name"
                onChange={onChangeLogin}
                onKeyDown={onKeyDown}
                size="lg"
                sx={{
                  '--_Input-focusedHighlight': (theme) => theme.palette.neutral.softColor,
                }}
              />
            </FormControl>
            <Button
              fullWidth
              loading={isLoading}
              disabled={!validation?.isValid}
              onClick={onSubmit}
              size="lg"
              variant="soft"
              color="neutral"
              sx={{
                mt: 2,
              }}
              endDecorator={<Icon name="arrow-right" color="inherit" />}
            >
              Next
            </Button>
          </Box>
        </Box>
      }
      rightPanelBackGround={backgroundProps}
    />
  );
};

export default memo(SetUpProfile);
