import {
  type SagaReturnType,
  takeLatest,
  put,
  cancel,
} from 'redux-saga/effects';

import { call } from 'store/utils/saga/effects';

import network from 'lib/network';
import * as userStore from 'store/nodes/user';
import Alert from 'components/Alert';
import { actions } from '../slice';

export const config = {
  action: actions.tellUsAbout.type,
  method: takeLatest,
};

export function* func(action: SagaReturnType<typeof actions.tellUsAbout>) {
  const { profession, howUse } = action.payload;

  const { errors, data } = yield* call(() =>
    network
      .request<{
        resumed: boolean;
      }>('/stack-2/onboarding/about', {
        baseUrl: process.env.PROTECTED_API_URL || '/api',
      })
      .body({
        profession,
        howUse,
      })
      .post(),
  );

  if (errors) {
    Alert.error(errors[0]?.message);
    yield put(actions.tellUsAboutDone());
    yield cancel();
    return;
  }

  yield put(userStore.actions.loadProfile('my'));
  yield put(actions.tellUsAboutDone());
  yield put(actions.nextStep());
  yield cancel();
}
