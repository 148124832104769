import { memo } from 'react';
import { useListLayout } from 'hooks';

import { Box, type BoxProps, IconButton, Tooltip } from '@mui/joy';
import Icon from 'ui/Icon';

type ListLayoutSwitcherProps = {
  stateKey: string;
} & BoxProps;

const ListLayoutSwitcher = (props: ListLayoutSwitcherProps) => {
  const { stateKey, ...rest } = props;

  const { listLayout, listToGrid, listToRow } = useListLayout(stateKey);

  return (
    <Box {...rest}>
      {listLayout.isGrid && (
        <Tooltip disableInteractive arrow variant="outlined" size="sm" title="Row layout view">
          <IconButton variant="soft" size="sm" onClick={listToRow}>
            <Icon name="list" size="lg" fw weight="light" color="primary" />
          </IconButton>
        </Tooltip>
      )}
      {listLayout.isRow && (
        <Tooltip disableInteractive arrow variant="outlined" size="sm" title="Grid layout view">
          <IconButton variant="soft" size="sm" onClick={listToGrid}>
            <Icon name="grid" size="lg" fw weight="light" color="primary" />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
};

export default memo(ListLayoutSwitcher);
