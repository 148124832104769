import { takeEvery, put, cancel, delay, type SagaReturnType } from 'redux-saga/effects';

import type { AuthorType } from 'app/entities';
import * as api from 'services/api';

import { call } from 'store/utils/saga/effects';
import * as contentStore from 'store/nodes/content';

import Alert from 'components/Alert';

import * as actions from '../actions';

export const config = {
  action: [actions.loadProfile.type],
  method: takeEvery,
};

export function* func(action: SagaReturnType<typeof actions.loadProfile>) {
  const { payload } = action;
  const { id } = payload;

  const [profile, contents] = yield* call(() =>
    Promise.all([api.resource.author.profile(id), api.resource.author.content.list(id, { pageSize: 50 })]),
  );

  if (profile.error || !profile.data || contents.error || !contents.data) {
    Alert.error(profile.error?.message || 'Server error #25');
    yield put(actions.loadProfileDone(id));
    yield cancel();
    return;
  }
  const result: AuthorType = {
    ...profile.data,
    contentIds: contents.data?.items?.map((item) => item.id) || [],
  };

  yield put(actions.setProfile(result));
  yield put(contentStore.actions.setItem(contents.data?.items || []));
  yield delay(10);
  yield put(actions.loadProfileDone(id));
}
