import type { DefaultRootState } from 'react-redux';

import subscriptionAppSumoConfig from 'data/subscriptionAppSumoConfig.json';
import subscriptionStripeConfig from 'data/subscriptionStripeConfig.json';

const subscriptionConfig = (state: DefaultRootState) => {
  if (!state.user.my?.login) {
    return subscriptionStripeConfig;
  }
  if (state.user.data[state.user.my?.login]?.isAppsumo) {
    return subscriptionAppSumoConfig;
  }
  return subscriptionStripeConfig;
};

export default subscriptionConfig;
