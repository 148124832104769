import { memo } from 'react';
import Picture from 'components/Picture';

type ImageViewerProps = {
  src?: string;
};

const ImageViewer = (props: ImageViewerProps) => {
  const { src } = props;

  if (!src) {
    return null;
  }
  return <Picture source={src} />;
};

export default memo(ImageViewer);
