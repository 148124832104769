import { createReducer } from '@reduxjs/toolkit';
import md5 from 'blueimp-md5';

import { capitalize, prepare } from 'utils';

import type { ContentStore } from './types';
import * as actions from './actions';

const initialState: ContentStore = {
  data: {},
  remarks: {},
  list: {
    sequence: [],
    map: {},
    filters: {
      type: {
        options: [],
        value: null,
      },
    },
    paginationInfo: {
      hasNext: false,
      nextCursor: null,
    },
    meta: {
      resultHash: md5(JSON.stringify({ type: 'all' })),
      loadingMap: [],
      isFiltersLoading: false,
      isFiltersLoaded: false,
      isFirstPageLoading: false,
      isFirstPageLoaded: false,
      isNextPageLoading: false,
      isNextPageLoaded: false,
    },
  },
  watched: {
    id: null,
    isShow: false,
  },
};

export default createReducer(initialState, (builder) =>
  builder
    .addCase(actions.loadFilters, (state) => {
      state.list.meta.isFiltersLoading = true;
    })
    .addCase(actions.loadFiltersDone, (state, action) => {
      state.list.meta.isFiltersLoading = false;
      state.list.meta.isFiltersLoaded = true;
    })
    .addCase(actions.setFilters, (state, action) => {
      state.list.filters.type.options = [...action.payload.type.options];
    })
    .addCase(actions.setFilterType, (state, action) => {
      state.list.filters.type.value = action.payload.value;
    })

    .addCase(actions.removeFromSequence, (state, action) => {
      if (!action.payload.id) {
        return;
      }
      const foundIndex = state.list.map[action.payload.id];
      if (typeof foundIndex === 'undefined') {
        return;
      }
      state.list.sequence.splice(foundIndex, 1);
      state.list.map = {};
      state.list.sequence.forEach((item, index) => {
        state.list.map[item.id] = index;
      });
    })
    .addCase(actions.loadNextPage, (state, action) => {
      if (action?.payload?.reload) {
        state.list.meta.isFirstPageLoading = true;
      } else {
        state.list.meta.isNextPageLoading = true;
      }
    })
    .addCase(actions.loadNextPageDone, (state, action) => {
      const { reload } = action.payload;
      state.list.meta.isFirstPageLoading = false;
      state.list.meta.isNextPageLoading = false;
      if (reload) {
        state.list.meta.isFirstPageLoaded = true;
      } else {
        state.list.meta.isNextPageLoaded = true;
      }
      state.list.meta.resultHash = md5(JSON.stringify({ type: state.list.filters.type.value }));
    })
    .addCase(actions.addPage, (state, action) => {
      if (!action.payload.items) {
        return;
      }
      if (action.meta.reload) {
        state.list.sequence = [];
        state.list.map = {};
      }
      action.payload.items.forEach((item) => {
        if (!state.data[item.id]) {
          state.data[item.id] = {
            ...item,
            internalUrl: `/content/${(item.type || 'article').toLowerCase()}/${item.id}`,
            type: capitalize(item.type || 'article'),
            source: prepare.materialSource(item),
          };
        }
        if (item.id in state.list.map) {
          return;
        }
        state.list.sequence.push({
          id: item.id,
        });
        state.list.map[item.id] = state.list.sequence.length - 1;
      });
      state.list.paginationInfo.nextCursor = action.payload.paginationInfo.nextCursor;
      state.list.paginationInfo.hasNext = action.payload.paginationInfo.hasNext;
    })

    .addCase(actions.loadById, (state, action) => {
      const { payload } = action;
      const idFinal = Array.isArray(payload.id) ? payload.id : [payload.id];
      if (idFinal.length === 0) {
        return;
      }
      idFinal.forEach((id) => {
        if (!state.list.meta.loadingMap.includes(id)) {
          state.list.meta.loadingMap.push(id);
        }
      });
    })
    .addCase(actions.loadRelated, (state, action) => {})
    .addCase(actions.loadRelatedDone, (state, action) => {
      const { contentId, relatedIds } = action.payload;
      const origin = state.data[contentId];
      state.data[contentId] = {
        ...origin,
        relatedIds,
      };
    })
    .addCase(actions.loadByIdDone, (state, action) => {
      const { payload } = action;
      const idFinal = Array.isArray(payload.id) ? payload.id : [payload.id];
      if (idFinal.length === 0) {
        return;
      }
      idFinal.forEach((id) => {
        const index = state.list.meta.loadingMap.indexOf(id);
        if (index > -1) {
          state.list.meta.loadingMap.splice(index, 1);
        }
      });
    })
    .addCase(actions.setItem, (state, action) => {
      const { data } = action.payload;
      if (!data) {
        return;
      }
      const items = !Array.isArray(data) ? [data] : data;
      items.forEach((item) => {
        const origin = state?.data?.[item.id];
        state.data[item.id] = {
          ...origin,
          ...item,
          internalUrl: `/content/${(item.type || 'article').toLowerCase()}/${item.id}`,
          type: capitalize(item.type || origin?.type || 'article'),
          source: prepare.materialSource(item),
        };
        item.remarks?.forEach((remark) => {
          state.remarks[remark.id] = remark;
        });
      });
    })
    .addCase(actions.hide, (state, action) => {
      const { id } = action.payload;
      if (!state.list.map[id]) {
        return;
      }
      state.list.sequence.splice(state.list.map[id], 1);
      state.list.map = {};
      state.list.sequence.forEach((item, pos) => {
        state.list.map[item.id] = pos;
      });
    })

    .addCase(actions.setWatched, (state, action) => {
      const { data } = action.payload;
      if (!data) {
        return;
      }
      if (state.watched.id !== data.id) {
        state.watched.id = data.id;
        state.watched.isShow = true;
      }
      if (!state.data[data.id]) {
        state.data[data.id] = {
          ...data,
          internalUrl: `/content/${(data.type || 'article').toLowerCase()}/${data.id}`,
          type: capitalize(data.type || 'article'),
          source: prepare.materialSource(data),
        };
      }
    })
    .addCase(actions.materialIndexed, (state, action) => {
      const { resourceId } = action.payload;
      if (!resourceId) {
        return;
      }
      state.data[resourceId].indexed = true;
    })
    .addCase(actions.closeWatched, (state) => {
      state.watched.isShow = false;
    })
    .addCase(actions.logOut, (state) => {
      const cloned = JSON.parse(JSON.stringify(initialState));
      state.data = cloned.data;
      state.list = cloned.list;
      state.watched = cloned.watched;
    }),
);
