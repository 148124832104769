import { memo } from 'react';
import { Box } from '@mui/joy';
import BackButton from 'widgets/Onboarding/ui/BackButton';
import IndicatorTopStepper from 'widgets/Onboarding/ui/IndicatorTopStepper';
import { useRoute } from 'navigation/hooks';

const Header = () => {
  const route = useRoute();
  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {!route.isScreen('Auth/Onboarding/Profile') && <BackButton ml={3} mt={1} />}
      <IndicatorTopStepper
        sx={{
          position: 'absolute',
          left: '50%',
          top: 20,
          transform: 'translateX(-50%)',
        }}
      />
    </Box>
  );
};

export default memo(Header);
