/**
 * @deprecated
 */
export default (type: 'material' | 'collection' | 'note' | 'document'): 'content' | 'playlist' | 'note' =>
  ({
    material: 'content',
    collection: 'playlist',
    note: 'note',
    document: 'note',
  })[type] as 'content' | 'playlist' | 'note';
