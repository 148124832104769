import { useCallback, useEffect, useMemo, useState } from 'react';
import { type NativeSyntheticEvent, type TextInputFocusEventData, type ViewStyle, type StyleProp, type TextStyle, StyleSheet } from 'react-native';
import DropDownPicker, { type ThemeNameType } from 'react-native-dropdown-picker';

import { useColorScheme, useThemeColor, useFontProps } from 'hooks';

import { unit } from 'utils';
import { Text, View } from 'components/Themed';
import { useField } from 'components/Form';

type Item = {
  label: string;
  value: string;
};

const defaultProps = {
  readonly: false as boolean,
  disabled: false as boolean,
  border: 'half' as 'none' | 'half' | 'full',
};

type DropdownInputProps = {
  style?: StyleProp<ViewStyle>;
  pickerStyle?: StyleProp<ViewStyle>;
  labelStyle?: StyleProp<TextStyle>;
  items?: (Item | string)[];
  value?: string | null;
  label?: string;
  name?: string;
  error?: string;
  readonly?: boolean;
  disabled?: boolean;
  border?: 'none' | 'half' | 'full';
  onChange?: (value: any) => void;
  onBlur?: (event: NativeSyntheticEvent<TextInputFocusEventData>) => void;
  onFocus?: (event: NativeSyntheticEvent<TextInputFocusEventData>) => void;
} & typeof defaultProps;

export const DropdownInput = (props: DropdownInputProps) => {
  const { style, pickerStyle, labelStyle, items, value, label, name, error, readonly, disabled, border, onChange, onBlur, onFocus } = props;

  const isBlocked = disabled || readonly;
  const theme = useColorScheme();
  const textColor = useThemeColor({
    light: isBlocked ? '#00000077' : '#000000ff',
    dark: isBlocked ? '#ffffff55' : '#ffffffff',
  });
  const itemTextColor = useThemeColor({
    light: '#000000ff',
    dark: '#ffffffff',
  });
  const itemSelectedTextColor = useThemeColor({
    light: '#000000ff',
    dark: '#ffffffff',
  });
  const backgroundColor = useThemeColor({
    light: disabled ? '#F9F9F999' : '#F9F9F9',
    dark: disabled ? '#23232699' : '#232326',
  });
  const dropDownBackgroundColor = useThemeColor({
    light: '#d2d2d2',
    dark: '#232326',
  });

  const [open, setOpen] = useState(false);

  const field = useField(name);

  const onChangeFinal = onChange || field?.onValueChange;
  const onBlurFinal = onBlur || field?.onBlur;
  const onFocusFinal = onFocus;
  const valueFinal = String(value || field?.value || '');
  const errorFinal = error || field?.error;

  const [dropDownValue, setDropDownValue] = useState<any>(valueFinal);

  const themeTransform: Record<'light' | 'dark', ThemeNameType> = {
    light: 'LIGHT',
    dark: 'DARK',
  };
  const themeFinal = themeTransform[theme];

  const handleChange = useCallback(
    (newValue: any) => {
      if (newValue !== valueFinal) {
        onChangeFinal?.(newValue);
      }
    },
    [onChangeFinal, valueFinal],
  );

  useEffect(() => {
    if (disabled) {
      setOpen(false);
    }
  }, [disabled]);

  useEffect(() => {
    setDropDownValue(valueFinal);
  }, [valueFinal]);

  const borderRadius = useMemo(() => {
    const radiusMap = {
      none: 0,
      half: 10,
      full: 44 / 2,
    };
    return radiusMap[border];
  }, [border]);

  const labelStyles = useFontProps({
    size: 17,
    weight: 'regular',
    align: 'left',
  });

  const styleFinal = useMemo(
    () => ({
      ...StyleSheet.flatten(style),
      zIndex: open ? 1000 : undefined,
      opacity: disabled ? 0.75 : 1,
    }),
    [style, open, disabled],
  );

  const containerStyleFinal = useMemo(
    (): StyleProp<ViewStyle> => ({
      ...StyleSheet.flatten(styles.container),
      backgroundColor,
      borderRadius,
      ...StyleSheet.flatten(pickerStyle),
    }),
    [disabled, pickerStyle, backgroundColor, borderRadius],
  );

  const labelStyleFinal = useMemo(() => {
    const result: StyleProp<TextStyle> = {
      ...StyleSheet.flatten(styles.selectedLabel),
      ...labelStyles,
      color: textColor,
      ...StyleSheet.flatten(labelStyle),
    };
    return result;
  }, [disabled, labelStyles, backgroundColor, borderRadius]);

  const itemsFinal = useMemo(() => {
    if (!items || items.length === 0) {
      return [];
    }
    return items.map((item) => {
      if (typeof item === 'string') {
        return {
          label: item,
          value: item.toLowerCase(),
        };
      }
      return item;
    });
  }, [items]);

  return (
    <View style={styleFinal} pointerEvents={disabled ? 'none' : 'auto'}>
      {Boolean(label) && (
        <Text size={15} weight="regular" lightColor="#797979" darkColor="#B8B6BF" style={styles.label}>
          {label}
        </Text>
      )}
      <DropDownPicker
        open={open}
        theme={themeFinal}
        value={dropDownValue}
        items={itemsFinal}
        setOpen={setOpen}
        setValue={setDropDownValue}
        multiple={false}
        onChangeValue={handleChange}
        disabled={disabled}
        style={styles.input}
        containerStyle={containerStyleFinal}
        labelStyle={labelStyleFinal}
        dropDownContainerStyle={[
          styles.dropDownContainer,
          {
            backgroundColor: dropDownBackgroundColor,
          },
        ]}
        listItemContainerStyle={styles.itemContainer}
        listItemLabelStyle={[
          styles.itemText,
          {
            color: itemTextColor,
          },
        ]}
        selectedItemContainerStyle={[styles.itemSelectedContainer, {}]}
        selectedItemLabelStyle={[
          styles.itemSelectedText,
          {
            color: itemSelectedTextColor,
          },
        ]}
      />
    </View>
  );
};

DropdownInput.defaultProps = defaultProps;

const styles = StyleSheet.create({
  label: {
    marginBottom: unit(6),
    marginLeft: unit(6),
  },
  container: {
    height: unit(44),
    width: '100%',
  },
  input: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: unit(16),
    flex: 1,
  },
  selectedLabel: {},
  itemContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: unit(44),
    paddingHorizontal: unit(16),
  },
  itemText: {},
  itemSelectedContainer: {},
  itemSelectedText: {},
  dropDownContainer: {
    marginVertical: unit(8),
    borderRadius: unit(8),
    overflow: 'hidden',
    borderWidth: 0,
  },
});

export default DropdownInput;
