import { memo, useCallback, useEffect, useId, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { DateTime } from 'luxon';

import { useRoute } from 'navigation/hooks';
import { useListLayout } from 'hooks';
import { unit } from 'utils';

import { useDispatcher } from 'store/utils/redux/hooks';
import * as libraryStore from 'store/nodes/library';

import { View } from 'components/Themed';
import AdaptivePage from 'components/AdaptivePage';
import List from 'components/List';

import * as userStore from 'store/nodes/user';

import Card from 'widgets/Card';

import EmptyMessage from '../../ui/StateMessage';
import CardPlus from './ui/CardPlus';
import TopBarDesktop from './ui/TopBarDesktop';
import TopBarMobile from './ui/TopBarMobile';

const useScreenKey = () => {
  const route = useRoute();
  return useMemo(() => JSON.stringify([route.name, route.params]), [route.name, route.params]);
};

const Library = () => {
  const dispatcher = useDispatcher();
  const { listLayout } = useListLayout('library.list.mode');

  const screenKey = useScreenKey();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'instant' as unknown as 'smooth' | 'auto',
    });
  }, [screenKey]);

  const sequence = useSelector(libraryStore.selectors.sequence);
  const paginationInfo = useSelector(libraryStore.selectors.paginationInfo);
  const isPageLoading = useSelector(libraryStore.selectors.isPageLoading);
  const listMeta = useSelector(libraryStore.selectors.listMeta);

  const userProfile = useSelector(userStore.selectors.getMy);

  const handleNextPage = useCallback(() => {
    if (!listMeta.isConsistent) {
      return;
    }
    dispatcher.library.loadNextPage();
  }, [listMeta.isConsistent]);

  const isTwoWeekAgo = useMemo(
    () => DateTime.now().diff(DateTime.fromISO(userProfile?.createdAt as string), ['weeks']).weeks > 2,
    [userProfile?.createdAt],
  );

  const getKey = useCallback((item: (typeof sequence)[0]) => {
    if (!item || typeof item !== 'object') {
      return 'unknown';
    }
    return `${item.type}-${item.id}`;
  }, []);

  const preparedSequence = useMemo((): typeof sequence => {
    if (isTwoWeekAgo) {
      return sequence;
    }
    return [
      {
        type: 'material',
        id: -1,
      },
      ...sequence,
    ];
  }, [sequence, isTwoWeekAgo]);

  const renderItem = useCallback(
    (args: { data: (typeof sequence)[0]; index: number }) => {
      if (!args.data || typeof args.data !== 'object' || args.data.type === 'unknown') {
        return null;
      }
      if (args.data.type === 'material' && args.data.id === -1) {
        return <CardPlus />;
      }
      return (
        <Card
          id={args.data.id}
          type={args.data.type}
          hasDescription={listLayout.isRow}
          hasSummary
          hasHighlight
          hasTags
          hasRemove
          hasQueue={false}
          hasLink
          hasEdit
          hasAuthor
          hasToCollection
          hasIndexed
        />
      );
    },
    [listLayout.isRow, isTwoWeekAgo],
  );

  return (
    <AdaptivePage desktopHeaderLeftPanel="search" bodyMaxWidth={1280} paddingHorizontalScheme={{ 320: 16 }}>
      <TopBarDesktop />
      <TopBarMobile />
      <EmptyMessage />
      <List
        items={preparedSequence}
        getKey={getKey}
        renderItem={renderItem}
        columnsScheme={listLayout.isRow ? '320:1' : '320:1;528:2;672:3;960:4'}
        loading={isPageLoading}
        hasNextPage={(isPageLoading || sequence.length > 0) && paginationInfo.hasNext}
        onEndReached={handleNextPage}
      />
      <View style={{ height: unit(108) }} />
    </AdaptivePage>
  );
};

export default memo(Library);
