import { type ReactElement, useCallback, useState } from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import moment from 'moment-timezone';

import { useThemeColor } from 'hooks';

import { unit } from 'utils';
import { Text, View } from 'components/Themed';
import Icon from 'components/LegacyIcon';

type JobProps = {
  id: number;
  company: string;
  position: string;
  location?: string | null;
  startDate: string;
  endDate?: string | null;
  description?: string | null;
  editable?: boolean;
  onEdit?: (id: number) => void;
};

const Job = (props: JobProps): ReactElement => {
  const { id, company, location = '', startDate, endDate, position, description = '', editable, onEdit } = props;

  const [isExpand, setExpand] = useState(false);
  const backgroundColor = useThemeColor({ dark: '#232323', light: '#efefef' });

  const handleToggleExpand = useCallback(() => {
    setExpand(!isExpand);
  }, [isExpand]);

  const handleEdit = useCallback(() => {
    onEdit?.(id);
  }, [id, onEdit]);

  const startDateFinal = moment(startDate).format('MMM YYYY');
  const endDateFinal = endDate ? moment(endDate).format('MMM YYYY') : 'Now';

  return (
    <View style={[styles.Job, { backgroundColor }]}>
      <View style={styles.header}>
        <View style={styles.info}>
          <Text size={22} weight="medium" lightColor="#515151" darkColor="#E6E4EA">
            {company}
          </Text>
          {!!location && (
            <Text size={11} weight="medium" lightColor="#888888" darkColor="#9A99A2" style={styles.locationText}>
              {location}
            </Text>
          )}
        </View>
        {editable && (
          <TouchableOpacity style={styles.updateBtn} onPress={handleEdit} activeOpacity={0.8}>
            <Icon name="Dots" size={24} />
          </TouchableOpacity>
        )}
      </View>
      <View style={styles.action}>
        <Text size={20} weight="medium">
          {position}
        </Text>
        <View style={styles.flex1} />
        <TouchableOpacity style={styles.moreBtn} onPress={handleToggleExpand} activeOpacity={0.8}>
          {!isExpand && <Icon name="ChevronDown" size={16} lightColor="#888888" darkColor="#9A99A2" />}
          {isExpand && <Icon name="ChevronUp" size={16} lightColor="#888888" darkColor="#9A99A2" />}
        </TouchableOpacity>
      </View>
      <View style={[styles.description, isExpand ? styles.isExpand : undefined]}>
        <Text size={15} weight="medium" style={styles.marginTop24}>
          Start / end of work
        </Text>
        <Text size={13} lightColor="#797979" darkColor="#B8B6BF" style={styles.marginTop8}>{`${startDateFinal} - ${endDateFinal}`}</Text>
        {!!description && (
          <>
            <Text size={15} weight="medium" style={styles.marginTop24}>
              Job description
            </Text>
            <Text size={13} lightColor="#797979" darkColor="#B8B6BF" style={styles.marginTop8}>
              {description}
            </Text>
          </>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  Job: {
    borderRadius: unit(15),
    paddingHorizontal: unit(20),
    paddingVertical: unit(16),
    marginTop: unit(16),
  },
  flex1: {
    flex: 1,
  },
  action: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    marginTop: unit(24),
  },
  moreBtn: {
    marginLeft: unit(6),
    width: unit(34),
    height: unit(34),
    borderWidth: 1,
    borderColor: '#9a99a2',
    borderRadius: unit(17),
    justifyContent: 'center',
    alignItems: 'center',
  },
  description: {
    display: 'none',
  },
  isExpand: {
    display: 'flex',
    marginTop: unit(16),
    borderTopWidth: 1,
    borderTopColor: '#4E4E53',
    borderTopRadius: unit(17),
  },
  header: {
    flexDirection: 'row',
  },
  info: {
    flex: 1,
  },
  updateBtn: {
    height: unit(32),
    width: unit(32),
    marginRight: unit(-8),
    marginTop: unit(-8),
    justifyContent: 'center',
    alignItems: 'center',
  },
  locationText: {
    marginTop: unit(6),
  },
  marginTop24: {
    marginTop: unit(24),
  },
  marginTop8: {
    marginTop: unit(8),
  },
});

export default Job;
