import { type DefaultRootState } from 'react-redux';
import { createCachedSelector } from 're-reselect';

import { guard } from 'utils';

const selectorUser = createCachedSelector(
  (state: DefaultRootState, login: string) => state.user.data[login],
  (user) => {
    if (!user) {
      return null;
    }
    return user;
  },
)((_state_, login) => login);

const selectorCollections = createCachedSelector(
  (state: DefaultRootState) => state.playlist.data,
  (_: DefaultRootState, collectionIds: number[]) => collectionIds,
  (collections, collectionIds) => {
    return collectionIds.map((id) => collections[id]).filter((item) => !!item);
  },
)((_state_, collectionIds) => collectionIds.join(','));

export default (login: 'my') => (state: DefaultRootState) => {
  let targetLogin = login;
  if (targetLogin === 'my' && state.user.my) {
    targetLogin = state.user.my.login;
  }
  const user = selectorUser(state, targetLogin);
  if (!guard.isUser(user)) {
    return [];
  }
  return selectorCollections(state, user.playlistIds);
};
