import { type FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Box, Button, DialogActions, Sheet, Typography } from '@mui/joy';

import Icon from 'ui/Icon';

import { useDispatcher } from 'store/utils/redux/hooks';
import StripeForm from 'widgets/StripeForm';
import usePaymentState from 'widgets/StripeForm/model/usePaymentState';

import * as selectors from '../store/selectors';

type SubscriptionContentProps = {
  planId: string;
  promoCode?: string | null;
};

const SubscriptionContent: FC<SubscriptionContentProps> = (props) => {
  const { planId, promoCode } = props;
  const dispatcher = useDispatcher();

  const isProcessing = useSelector(selectors.isProcessing);

  const { addressComplete, cardComplete, onCreatePaymentMethod, loadingStripe, retrievedPayment } = usePaymentState({
    planId,
    promoCode,
    onSubmitPayment: dispatcher.subscription.subscribeStripeElements,
  });

  const cardIcon = useMemo(() => {
    if (!retrievedPayment) {
      return null;
    }
    if (retrievedPayment?.cardType === 'visa') {
      return <Icon family="brands" name="cc-visa" size="2x" color="icon" fontSize="1.5rem" />;
    }
    if (retrievedPayment?.cardType === 'mastercard') {
      return <Icon family="brands" name="c-mastercard" size="2x" color="icon" fontSize="1.5rem" />;
    }
    return <Icon weight="solid" name="credit-card-front" size="2x" color="icon" fontSize="1.5rem" />;
  }, [retrievedPayment]);

  return (
    <>
      {retrievedPayment && (
        <Sheet
          variant="outlined"
          sx={{
            px: 1,
            pt: 0.75,
            pb: 0.75,
            borderRadius: (theme) => theme.radius.md,
          }}
        >
          <Box display="flex" gap={2} alignItems="center">
            {cardIcon}
            <Typography>
              **** **** **** {retrievedPayment.last4} {retrievedPayment.expMonth} / {retrievedPayment.expYear}
            </Typography>
          </Box>
        </Sheet>
      )}
      <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="baseline" mb={2}>
        <Typography>Payment information</Typography>
        <Typography fontSize={12}>Powered by Stripe</Typography>
      </Box>
      {!retrievedPayment && <StripeForm isLoading={isProcessing} />}
      <DialogActions sx={{ pt: 0 }}>
        <Box display="flex" justifyContent="center" flex={1} gap={1}>
          <Button
            disabled={!(addressComplete && cardComplete) || loadingStripe}
            loading={isProcessing}
            color="primary"
            onClick={onCreatePaymentMethod}
          >
            Payment
          </Button>
        </Box>
      </DialogActions>
    </>
  );
};

export default SubscriptionContent;
