import { put, cancel, delay, takeEvery, type SagaReturnType } from 'redux-saga/effects';

import * as api from 'services/api';

import Alert from 'components/Alert';

import { call } from 'store/utils/saga/effects';
import * as userStore from 'store/nodes/user';

import * as actions from '../actions';

export const config = {
  action: actions.loadById.type,
  method: takeEvery,
};

const debounceTime = 150;
const loadBuffer: number[] = [];
let lastCallId = 0;

export function* func(action: SagaReturnType<typeof actions.loadById>) {
  const callId = new Date().getMilliseconds();
  lastCallId = callId;

  const { id } = action.payload;

  loadBuffer.push(...(!Array.isArray(id) ? [id] : id));

  yield delay(debounceTime);
  if (callId !== lastCallId) {
    yield cancel();
    return;
  }

  const finalLoadIds = [...new Set(loadBuffer.splice(0))];

  const { data, ...response } = yield* call(() => api.resource.content.items(finalLoadIds));
  const error = Array.isArray(response.error) ? response.error?.[0] || null : response.error;

  if (error || !data) {
    Alert.error(error?.message || 'Server error 12');
    yield put(actions.loadByIdDone(finalLoadIds));
    yield cancel();
    return;
  }

  yield put(actions.setItem(data));
  yield put(actions.loadByIdDone(finalLoadIds));

  const userLogins: string[] = [];
  data.forEach((item) => {
    if (!Array.isArray(item.remarks) || item.remarks.length === 0) {
      return;
    }
    userLogins.push(...item.remarks.map((remark) => remark.user.login));
  });

  yield put(
    userStore.actions.loadUser({
      login: [...new Set(userLogins)],
      mode: 'basic',
    }),
  );
}
