import { memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { StyleSheet } from 'react-native';

import { useRoute } from 'navigation/hooks';
import { useDispatcher } from 'store/utils/redux/hooks';
import * as activityStore from 'store/nodes/activity';
import * as userStore from 'store/nodes/user';

import { useListData, useListLayout, useResponsive } from 'hooks';
import { unit } from 'utils';

import { Text, View } from 'components/Themed';
import List from 'components/List';

import TopBarDesktop from './elements/TopBarDesktop';
import TopBarMobile from './elements/TopBarMobile';
import Item from './ui/Item';

const PeersFeed = () => {
  const route = useRoute<'CommunityPeers'>();
  const dispatcher = useDispatcher();
  const responsive = useResponsive();

  const { listLayout } = useListLayout('peers.list.mode');

  const user = useSelector(userStore.selectors.getByLogin(route.params?.user));
  const sequence = useSelector(activityStore.selectors.sequence);
  const isEmpty = useSelector(activityStore.selectors.isEmpty);
  const paginationInfo = useSelector(activityStore.selectors.paginationInfo);
  const isPageLoading = useSelector(activityStore.selectors.isPageLoading);
  const listMeta = useSelector(activityStore.selectors.listMeta);

  const handleNextPage = useCallback(() => {
    dispatcher.activity.loadNextPage();
  }, []);

  const getKey = useCallback((data: (typeof sequence)[0]) => {
    if (!data || typeof data !== 'object') {
      return 'unknown';
    }
    return `${data.resourceType}-${data.resourceId}-${data.userId}-${data.eventType}-${data.eventTime}-${data.comment?.text}`;
  }, []);

  const renderItem = useCallback(
    ({ data }: any) => (
      <Item
        key={data.id || data.resourceId}
        resourceId={data.id || data.resourceId}
        resourceType={data.resourceType}
        eventType={data.eventType}
        eventTime={data.eventTime}
        userId={data.userId}
        comment={data.comment?.text}
      />
    ),
    [responsive.is.tabletL, listLayout.isRow],
  );

  return (
    <>
      <TopBarDesktop />
      <TopBarMobile />
      {!isEmpty && (
        <>
          <List
            items={sequence}
            getKey={getKey}
            renderItem={renderItem}
            columnsScheme={listLayout.isRow ? '320:1' : '320:1;560:2;960:3'}
            loading={isPageLoading}
            hasNextPage={(isPageLoading || sequence.length > 0) && paginationInfo.hasNext}
            onEndReached={handleNextPage}
            columnGutter={24}
          />
          <View style={{ height: unit(108) }} />
        </>
      )}
      {isEmpty && !user && (
        <View style={styles.subscribe}>
          <Text size={17} lightColor="#000000" darkColor="#ffffff">
            Subscribe to your peers
            <br />
            to check what they read
          </Text>
        </View>
      )}
      {isEmpty && !!user && (
        <View style={styles.subscribe}>
          <Text size={17} lightColor="#000000" darkColor="#ffffff">
            {`${user.name} hasn't been active`}
          </Text>
        </View>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  listComponent: {
    marginHorizontal: unit(-10),
  },
  listContentContainer: {
    alignSelf: 'stretch',
  },
  subscribe: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
});

export default memo(PeersFeed);
