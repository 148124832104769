import { type DefaultRootState } from 'react-redux';
import { createCachedSelector } from 're-reselect';

import { type MessageId } from '../slice';

const selector = createCachedSelector(
  (state: DefaultRootState, messageId: string) => state.message.preventIds.includes(messageId),
  (isExists) => {
    return isExists;
  },
)((_state_, id) => id);
export default (messageId: MessageId) => (state: DefaultRootState) => {
  return selector(state, messageId);
};
