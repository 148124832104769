import { memo, useCallback } from 'react';
import { type EditorState } from 'lexical';
import { $generateHtmlFromNodes } from '@lexical/html';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';

type HtmlChangePluginProps = {
  onChange?: (html: string) => void;
};

const HtmlChangePlugin = (props: HtmlChangePluginProps) => {
  const { onChange } = props;
  const [editor] = useLexicalComposerContext();

  const handleChange = useCallback(
    (editorState: EditorState) => {
      editorState.read(() => {
        onChange?.($generateHtmlFromNodes(editor));
      });
    },
    [onChange],
  );

  return <OnChangePlugin onChange={handleChange} />;
};

export default memo(HtmlChangePlugin);
