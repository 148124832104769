import { memo, useCallback } from 'react';
import moment from 'moment-timezone';
import pluralize from 'pluralize';

import { Avatar, Box, Typography, type BoxProps } from '@mui/joy';
import Chip from '@mui/joy/Chip';

import { navigate } from 'navigation/methods';
import type { UserType } from 'app/entities';

import { controller as modal } from 'components/Modal2';
import { useSelector } from 'react-redux';
import * as playlistStore from 'store/nodes/playlist';

import Icon from 'ui/Icon';
import { useDispatcher } from 'store/utils/redux/hooks';
import * as userStore from 'store/nodes/user';

const prepareTime = (value: string) => {
  if (value.toLowerCase() === 'a few seconds ago') {
    return 'just now';
  }
  return value;
};

type InfoProps = {
  resourceId?: number;
} & BoxProps;

const Info = (props: InfoProps) => {
  const { resourceId, ...rest } = props;
  const dispatcher = useDispatcher();

  const data = useSelector(playlistStore.selectors.dataById(resourceId));
  const materialsMetadata = useSelector(playlistStore.selectors.materialsMetadataById(resourceId));
  const user = useSelector(userStore.selectors.getById(data?.user?.id)) as UserType | null;

  const handleAuthorPress = useCallback(() => {
    if (!user) {
      return;
    }
    navigate('Profile', {
      login: user?.login,
    });
  }, [user]);

  const handleComments = useCallback(
    (event: { preventDefault: () => void; stopPropagation: () => void }) => {
      event.preventDefault();
      event.stopPropagation();
      if (!data?.id) {
        return;
      }
      modal.popup.comments.open({
        resource: 'playlist',
        resourceId: data.id,
      });
    },
    [data?.id],
  );

  const handleSubscribe = useCallback(() => {
    if (!user?.login) {
      return;
    }
    dispatcher.user.subscribe(user?.login);
  }, [user?.login]);

  const handleUnsubscribe = useCallback(() => {
    if (!user?.login) {
      return;
    }
    dispatcher.user.unsubscribe(user?.login);
  }, [user?.login]);

  return (
    <Box display="flex" flexDirection="row" alignItems="center" gap={2.5} {...rest}>
      <Avatar onClick={handleAuthorPress} size="lg" src={user?.photo?.url} sx={{ cursor: 'pointer' }} />
      <Box display="flex" flexDirection="column">
        <Box display="flex" flexDirection="row" gap={1}>
          <Typography fontSize={13}>{`${user?.name || ''} ${user?.surname || ''}`}</Typography>
          {!data?.isMy && (
            <Chip
              onClick={!user?.isSubscribed ? handleSubscribe : handleUnsubscribe}
              size="sm"
              variant="outlined"
              color="neutral"
              sx={{
                '--Chip-radius': '6px',
                fontWeight: 400,
                fontSize: 11,
                mb: '-1px',
                borderColor: (theme) => theme.palette.background.level3,
              }}
            >
              {!user?.isSubscribed ? 'Subscribe' : 'Unsubscribe'}
            </Chip>
          )}
        </Box>
        <Box display="flex" flexDirection="row" gap={0.5}>
          <Typography fontSize={13}>{prepareTime(moment(data?.createdAt).fromNow())}</Typography>
          <Typography fontSize={12} mx={0.25}>
            •
          </Typography>
          <Typography fontSize={13}>{`${materialsMetadata?.length || 0} ${pluralize('item', materialsMetadata?.length || 0)}`}</Typography>
          <Typography fontSize={12} mx={0.25}>
            •
          </Typography>
          <Box onClick={handleComments} display="flex" flexDirection="row" gap={0.25} alignItems="center">
            <Icon size="xs" name="message-middle" fw weight="regular" color="icon" sx={{ position: 'relative', bottom: '-0.16666em' }} />
            <Typography fontSize={13}>{data?.commentMeta?.commentsCount}</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default memo(Info);
