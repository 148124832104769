import { takeLatest, put, cancel, all, fork, take, delay } from 'redux-saga/effects';

import { call } from 'store/utils/saga/effects';

import type { MaterialType, NoteType } from 'app/entities';

import network from 'lib/network';
import { guard } from 'utils';

import * as appStore from 'store/nodes/app';
import * as smartNoteStore from 'store/nodes/smartNote';
import * as contentStore from 'store/nodes/content';

import { actions } from '../slice';

export const config = {
  action: appStore.actions.prepared.type,
  method: takeLatest,
};

const map = {
  note: 'nt',
  material: 'mt',
} as const;

function makeId(payload: { type: 'material' | 'note'; id: number }) {
  const { type, id } = payload;
  return `${map[type]}-${id}`;
}

function* flushBatch(collectionId: number, ids: string[]) {
  const response = yield* call(() =>
    network
      .request<Record<string, MaterialType | NoteType>>(`/stack-1/share/playlists/${collectionId}/materials`)
      .query({ items: ids.join(',') })
      .get(),
  );
  const { data } = response;
  if (!data) {
    yield cancel();
    return;
  }

  const materials: MaterialType[] = [];
  const notes: NoteType[] = [];
  Object.values(data).forEach((item) => {
    if (guard.isMaterial(item)) {
      materials.push(item);
    }
    if (guard.isNote(item)) {
      notes.push(item);
    }
  });

  if (materials.length) {
    yield put(contentStore.actions.setItem(materials));
  }
  if (notes.length) {
    yield put(smartNoteStore.actions.setData(notes));
  }
  yield delay(10);
  yield put(actions.loadItemFlushDone({ collectionId, keyIds: ids }));
}

function* batchLoadSaga() {
  let collectionId: number | null = null;
  let task: any = null;
  const batch: string[] = [];

  function* makeFlush() {
    task = null;
    const ids = [...batch];
    const resourceId = collectionId || 0;
    batch.splice(0);
    collectionId = null;
    yield flushBatch(resourceId, ids);
  }

  function* debounceWorker() {
    yield delay(100);
    yield makeFlush();
  }

  while (true) {
    const {
      payload,
    }: {
      payload: { collectionId: number; type: 'material' | 'note'; id: number };
    } = yield take(actions.loadItem.type);
    if (collectionId !== null && collectionId !== payload.collectionId && batch.length > 0) {
      yield makeFlush();
    }

    if (collectionId === null) {
      collectionId = payload.collectionId;
    }

    yield put(actions.loadItemPush({ collectionId, keyId: makeId(payload) }));
    batch.push(makeId(payload));

    if (task) {
      yield cancel(task);
    }

    // @ts-ignore
    task = yield fork(debounceWorker);
  }
}

export function* func() {
  yield all([fork(batchLoadSaga)]);
}
