import { memo, useCallback, useMemo } from 'react';
import { Box, Typography } from '@mui/joy';
import { DateTime } from 'luxon';

import { useDispatcher, useSelector } from 'store/utils/redux/hooks';

import StripeElementsWrapper from 'components/StripeElementsWrapper';

import DualPanels from 'widgets/DualPanels';
import SubscriptionTryFree from 'widgets/Subscription/TryFree';
import usePlan from 'widgets/Subscription/model/usePlan';

import * as onboardingSelectors from '../store/selectors';
import usePlanState from '../model/usePlanState';

import StripeContainer, { type StripeContainerProps } from '../components/StripeContainer';
import Header from '../ui/Header';
import SkipButton from '../ui/SkipButton';

import BcgIcon from '../assets/bcg.svg';
import McIcon from '../assets/mc.svg';
import MiroIcon from '../assets/miro.svg';
import PwcIcon from '../assets/pwc.svg';

const TryFree = () => {
  const dispatcher = useDispatcher();

  const isSkipping = useSelector(onboardingSelectors.isSkipLoading);
  const isLoading = useSelector(onboardingSelectors.isLoading);

  const { value: planId, handleChange: handlePlanChange } = usePlanState();

  const { plan } = usePlan(planId);

  const trialEndDate = useMemo(() => {
    if (!plan) {
      return 0;
    }
    return DateTime.now().plus(plan?.trial).toFormat('MMM dd, yyyy');
  }, [plan?.trial]);

  const handleSkip = useCallback(() => {
    dispatcher.onboarding.skip({ target: 'trial' });
  }, []);

  const handleTrialStart: StripeContainerProps['onTrialStart'] = useCallback((params) => {
    dispatcher.onboarding.startTrial(params);
  }, []);

  return (
    <DualPanels
      sx={{
        '--left-max-width': '40rem',
        '--panel-max-width': '300px',
      }}
      leftHeaderPanel={<Header />}
      leftPanel={
        <>
          <Box display="flex" flexDirection="column" alignItems="center" gap={1}>
            <Typography mx={2} fontSize={52} fontWeight={600} textAlign="center">
              Start your free trial
            </Typography>
            <Typography fontSize={16} fontWeight={400} maxWidth={400} textAlign="center">
              You won&apos;t be charged anything until {trialEndDate}. Cancel anytime.
            </Typography>
            <StripeElementsWrapper>
              <StripeContainer isSkipLoading={isSkipping} planId={planId} onPlanChange={handlePlanChange} onTrialStart={handleTrialStart} />
            </StripeElementsWrapper>
            <Box alignSelf="center" display="flex" flexDirection="row" alignItems="baseline" mt={1} pl={3} gap={0.5}>
              <Typography fontSize={14}>Not ready yet?</Typography>
              <SkipButton onClick={handleSkip} loading={isSkipping} disabled={isLoading} />
            </Box>
          </Box>
        </>
      }
      rightPanel={
        <Box display="flex" alignItems="center" justifyContent="center" flex={1} sx={{ opacity: 0.9 }}>
          <Box display="flex" flexDirection="column" alignItems="center" width="100%">
            <SubscriptionTryFree planId={planId} />
            <Typography fontSize={20} mt={8}>
              Trusted by people and teams from
            </Typography>
            <Box display="flex" flexDirection="row" width="100%" maxWidth={500} justifyContent="space-between">
              <BcgIcon />
              <McIcon />
              <MiroIcon />
              <PwcIcon />
            </Box>
          </Box>
        </Box>
      }
    />
  );
};

export default memo(TryFree);
