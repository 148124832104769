import { useEffect, useState } from 'react';

import * as api from 'services/api';

const useHasSession = () => {
  const [hasSession, setHasSession] = useState(api.credentials.hasSession());

  useEffect(() => {
    setHasSession(api.credentials.hasSession());
  }, [api.credentials.hasSession()]);

  return hasSession;
};

export default useHasSession;
