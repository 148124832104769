import { type DefaultRootState } from 'react-redux';
import createCachedSelector from 're-reselect';

import type { Resource } from '../types';

const cacheKeyCreator = (state: DefaultRootState, resources: Resource | Resource[] | '*') => `typing-${JSON.stringify(resources)}`;

const isAnswerTypingSelector = createCachedSelector(
  (state: DefaultRootState) => state.copilot.typingMap,
  (_: DefaultRootState, resources: Resource | Resource[] | '*') => JSON.stringify(resources),
  (typingMap, resourcesJSON) => {
    const parsed = JSON.parse(resourcesJSON) as Resource | Resource[] | '*';
    if (parsed === '*') {
      return Object.values(typingMap).length > 0;
    }
    const resources = !Array.isArray(parsed) ? [parsed] : parsed;
    let result = false;
    Object.values(typingMap).forEach((itemAttribute) => {
      if (result) {
        return;
      }
      resources.forEach((resource) => {
        if (result) {
          return;
        }
        result = resource.type === itemAttribute.resourceType && (resource.id || null) === itemAttribute.resourceId;
      });
    });
    return result;
  },
)(cacheKeyCreator);

export default (resources: Resource | Resource[] | '*') => (state: DefaultRootState) => {
  return isAnswerTypingSelector(state, resources);
};
