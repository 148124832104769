import connect from '../../../store/utils/saga/connect';
import * as inviteTeam from './sagas/inviteTeam';
import * as loadState from './sagas/loadState';
import * as route from './sagas/route';
import * as startTrial from './sagas/startTrial';
import * as setupProfile from './sagas/setupProfile';
import * as tellUsAboutYou from './sagas/tellUsAboutYou';
import * as skip from './sagas/skip';
import * as handleStateUpdate from './sagas/handleStateUpdate';
import * as handleOnboardingDone from './sagas/handleOnboardingDone';

export default connect([inviteTeam, startTrial, setupProfile, tellUsAboutYou, skip, handleStateUpdate, loadState, route, handleOnboardingDone]);
