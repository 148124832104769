import { takeLatest, put, cancel, type SagaReturnType } from 'redux-saga/effects';

import type { CollectionType, CommunityItemVariant } from 'app/entities';

import { guard } from 'utils';

import * as api from 'services/api';

import { call } from 'store/utils/saga/effects';

import * as playlistStore from 'store/nodes/playlist';

import Alert from 'components/Alert';

import * as actions from '../actions';

export const config = {
  action: actions.loadCollections.type,
  method: takeLatest,
};

export function* func(action: SagaReturnType<typeof actions.loadCollections>) {
  const response = yield* call(() => api.resource.user.community.feed());

  if (response.error || !response.data) {
    Alert.error(response?.error?.message || 'Server error #19');
    yield put(actions.loadCollectionDone());
    yield cancel();
    return;
  }

  const playlists: CollectionType[] = [];
  const result = response.data.map((collection) => ({
    title: collection.title,
    payload: collection.payload
      .map((data) => {
        if (guard.isCollection(data)) {
          playlists.push(data);
          return data;
        }
        if (guard.isUser(data)) {
          return {
            ...data,
            name: data?.name || '',
            surname: data?.surname || '',
          };
        }
        return null;
      })
      .filter((item) => !!item),
  })) as CommunityItemVariant[];

  if (playlists.length > 0) {
    yield put(playlistStore.actions.setItem({ data: playlists }));
  }

  yield put(actions.setCollection(result));
  yield put(actions.loadCollectionDone());
}
