import connect from '../../utils/saga/connect';
import * as handleLocationChange from './sagas/handleLocationChange';
import * as handleScreenFeedFocus from './sagas/handleScreenFeedFocus';
import * as handleScreenAskFocus from './sagas/handleScreenAskFocus';
import * as handleScreenCommunityCollectionFocus from './sagas/handleScreenCommunityCollectionFocus';
import * as handleScreenCommunityPeersFocus from './sagas/handleScreenCommunityPeersFocus';
import * as handleScreenLibraryFocus from './sagas/handleScreenLibraryFocus';
import * as handleScreenNewsFocus from './sagas/handleScreenNewsFocus';
import * as handleScreenProfileFocus from './sagas/handleScreenProfileFocus';
import * as handleScreenAuthorFocus from './sagas/handleScreenAuthorFocus';
import * as handleScreenContentFocus from './sagas/handleScreenContentFocus';
import * as handleScreenCollectionFocus from './sagas/handleScreenCollectionFocus';

export default connect([
  handleLocationChange,
  handleScreenFeedFocus,
  handleScreenAskFocus,
  handleScreenCommunityCollectionFocus,
  handleScreenCommunityPeersFocus,
  handleScreenLibraryFocus,
  handleScreenNewsFocus,
  handleScreenProfileFocus,
  handleScreenAuthorFocus,
  handleScreenContentFocus,
  handleScreenCollectionFocus,
]);
