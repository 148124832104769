import { takeLatest, put, cancel, type SagaReturnType } from 'redux-saga/effects';

import { select } from 'store/utils/saga/effects';
import * as contentStore from 'store/nodes/content';

import * as actions from '../actions';

export const config = {
  action: actions.createPlaylistAddSelectedId.type,
  method: takeLatest,
};

export function* func(action: SagaReturnType<typeof actions.createPlaylistAddSelectedId>) {
  const { payload } = action;
  if (!Array.isArray(payload.ids) || payload.ids.length === 0) {
    yield cancel();
    return;
  }

  const isLoadedMap = yield* select(contentStore.selectors.isLoadedById(payload.ids));
  const needToLoadIds: number[] = isLoadedMap.filter((item) => !item.isLoaded).map((item) => item.id);
  yield put(contentStore.actions.loadById(needToLoadIds));
}
