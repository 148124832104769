import { takeLatest, put, cancel } from 'redux-saga/effects';
import type { SagaReturnType } from 'redux-saga/effects';

import { call, select } from 'store/utils/saga/effects';

import { getRoute, navigate } from 'navigation/methods';
import * as userStore from 'store/nodes/user';

import * as actions from '../actions';

export const config = {
  action: actions.processConfirmEmail.type,
  method: takeLatest,
};

export function* func(action: SagaReturnType<typeof actions.processConfirmEmail>) {
  const route = yield* call(() => getRoute());
  const user = yield* select(userStore.selectors.getByLogin('my'));

  if (user && !user.isAppsumo && !user.isConfirmedEmail) {
    if (!route.isScreen('Auth/EmailConfirm')) {
      yield put(actions.processConfirmEmailDone({ break: true }));
      yield* call(() => navigate('Auth/EmailConfirm', { replace: true }));
    }
    yield put(actions.processConfirmEmailDone({ break: true }));
    yield cancel();
    return;
  }

  yield put(actions.processConfirmEmailDone({ break: false }));
}
