import { type SagaReturnType, put, takeLatest, delay, take } from 'redux-saga/effects';

import { select } from 'store/utils/saga/effects';
import * as userStore from 'store/nodes/user';
import * as authStore from 'store/nodes/auth';
import * as appStore from 'store/nodes/app';
import { actions } from '../slice';
import * as selectors from '../selectors';

export const config = {
  action: [appStore.actions.prepared.type, authStore.actions.logOutDone.type, authStore.actions.doSignInDone.type],
  method: takeLatest,
};

function* startPromo() {
  let currentPlan = yield* select(selectors.currentPlan);
  if (!currentPlan.isLoaded) {
    yield take(actions.loadStateDone);
  }
  yield delay(500);
  currentPlan = yield* select(selectors.currentPlan);
  const userProfile = yield* select(userStore.selectors.getByLogin('my'));
  if (!['-1', '0'].includes(currentPlan?.id || '-1') || !userProfile || typeof window === 'undefined' || !window.localStorage) {
    return;
  }
  const startPromoShownAt = window.localStorage.getItem('startPromoShownAt');
  const oneWeekInMilliseconds = 7 * 24 * 60 * 60 * 1000;

  if (!startPromoShownAt || Date.now() - new Date(startPromoShownAt).getTime() > oneWeekInMilliseconds) {
    yield put(actions.showPricing());
    window.localStorage.setItem('startPromoShownAt', new Date().toISOString());
  }
}

export function* func(action: SagaReturnType<typeof appStore.actions.prepared | typeof authStore.actions.doSignInDone | typeof authStore.actions.logOutDone>) {
  if (action.type === appStore.actions.prepared.type) {
    yield put(actions.loadState());
    yield put(actions.loadPlans());
  }
  if (action.type === authStore.actions.doSignInDone.type) {
    yield put(actions.loadState());
  }
  if (action.type === authStore.actions.logOutDone.type) {
    yield put(actions.reset());
  }
  yield startPromo();
}
