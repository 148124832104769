import { useEffect, useCallback, useState } from 'react';

import Storage from '../classes/Storage';

const useStorageValue = (key: string, defaultValue?: string): string | null => {
  const [value, setValue] = useState<string | null>(Storage.get(key) || defaultValue || null);

  const handleSync = useCallback((context) => {
    const { keys } = context;
    if (!Array.isArray(keys) || keys.length === 0 || !keys.includes(key)) {
      return;
    }
    setValue(Storage.get(key));
  }, []);

  const handleSet = useCallback((context) => {
    const { keys, values } = context;
    if (!Array.isArray(keys) || keys.length === 0 || !keys.includes(key)) {
      return;
    }
    setValue(values[key]);
  }, []);

  useEffect(() => {
    const syncListener = Storage.addListener('DATA_SYNC', handleSync);
    const setListener = Storage.addListener('DATA_SET', handleSet);
    return () => {
      syncListener.remove();
      setListener.remove();
    };
  }, []);

  return value;
};

export default useStorageValue;
