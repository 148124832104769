import { type DefaultRootState } from 'react-redux';
import createCachedSelector from 're-reselect';

const cacheKeyCreator = (state: DefaultRootState, id: number | 'create' | null) => id;

const selectNoteData = (state: DefaultRootState, id: number | 'create' | null) => {
  if (!id || id === 'create' || !state.note.data[id]) {
    return null;
  }
  const { indexed, indexing } = state.note.data[id];
  return JSON.stringify({ indexed, indexing });
};

const indexStateSelector = createCachedSelector(selectNoteData, (noteDataJson) => {
  if (!noteDataJson) {
    return {
      isIndexed: null,
      isIndexing: null,
    };
  }

  const { indexed, indexing } = JSON.parse(noteDataJson);
  return {
    isIndexed: indexed as boolean,
    isIndexing: indexing as boolean,
  };
})(cacheKeyCreator);

export default (id?: number | 'create' | null) => (state: DefaultRootState) => indexStateSelector(state, id ?? 0);
