import { type SagaReturnType, takeLatest, put, cancel, take } from 'redux-saga/effects';

import { call, select } from 'store/utils/saga/effects';
import * as userStore from 'store/nodes/user';
import * as subscriptionStore from 'widgets/Subscription/store';

import network from 'lib/network';
import Alert from 'components/Alert';

import { actions } from '../slice';

export const config = {
  action: actions.loadState.type,
  method: takeLatest,
};

function* getCurrentSubscriptionPlanId() {
  const isLoading = yield* select(subscriptionStore.selectors.isLoading);
  if (isLoading) {
    yield take(subscriptionStore.actions.loadStateDone.type);
  }

  let currentPlan = yield* select(subscriptionStore.selectors.currentPlan);
  if (currentPlan.id === '-1') {
    yield put(subscriptionStore.actions.loadState());
    yield take(subscriptionStore.actions.loadStateDone.type);
    currentPlan = yield* select(subscriptionStore.selectors.currentPlan);
  }

  return currentPlan.id;
}

export function* func(action: SagaReturnType<typeof actions.loadState>) {
  const user = yield* select(userStore.selectors.getByLogin('my'));

  const { errors, data } = yield* call(() =>
    network
      .request<{
        profile: boolean;
        about: boolean;
        invite: boolean;
        trial: boolean;
      }>('/stack-2/onboarding', {
        baseUrl: process.env.PROTECTED_API_URL || '/api',
      })
      .get(),
  );

  if (errors) {
    Alert.error(errors[0]?.message);
    yield cancel();
    return;
  }

  const currentPlanId = yield* getCurrentSubscriptionPlanId();

  if (data) {
    yield put(
      actions.loadStateDone({
        state: data,
        ignore: {
          ...(user?.isAppsumo ? { trial: true } : undefined),
          ...(['-1', '0'].includes(currentPlanId || '-1') ? undefined : { trial: true }),
        },
      }),
    );
    yield cancel();
  }
}
