class Gtm {
  private pushDataLayer(payload: { event: string; [key: string]: any }) {
    if (typeof window !== 'undefined' && Array.isArray(window.dataLayer)) {
      window.dataLayer.push(payload);
    } else {
      console.warn('GTM is not available');
    }
  }

  get dataLayer() {
    return {
      push: (payload: { event: string; [key: string]: any }) => this.pushDataLayer(payload),
    };
  }
}

export default new Gtm();
