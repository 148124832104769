import type { ResultLegacy, UserType } from 'app/entities';
import request from 'services/api/request';

export default async (login?: string): Promise<ResultLegacy<UserType>> => {
  const { data, error } = await request.get<UserType>(
    !login || login === 'my'
      ? '/stack-1/user/profile'
      : `/stack-1/user/profile/${login}`,
  );
  if (error || !data) {
    return { data, error };
  }
  if (!Array.isArray(data.playlistIds)) {
    data.playlistIds = [];
  }
  return { data, error };
};
