import { type ReactNode, memo, useCallback, useMemo } from 'react';
import { Box, CardContent, Tooltip, Typography } from '@mui/joy';
import { isEqual } from 'lodash';

import { type AuthorType, type UserType } from 'app/entities';

import Icon from 'ui/Icon';

import { useSelector } from 'store/utils/redux/hooks';
import * as noteStore from 'store/nodes/note';

import AuthorBlock from '../ui/AuthorBlock';

import useDocumentDescription from '../model/useDocumentDescription';
import useAuthor from '../model/useAuthor';

type NoteProps = {
  id: number;
  hasLink?: boolean;
  tagLimit?: number;
  hasAuthor?: boolean;
  footerStartElements?: ReactNode;
  handleAuthorPress?: (value: UserType | AuthorType) => void;
};

const Note = (props: NoteProps) => {
  const { id, hasLink, tagLimit = 10, handleAuthorPress, footerStartElements, hasAuthor } = props;

  const data = useSelector(noteStore.selectors.dataById(id));

  const author = useAuthor(data?.user as UserType);
  const { title, paragraph, hasContent } = useDocumentDescription(data?.text);

  const handlePress = useCallback(() => {
    if (!author) {
      return;
    }
    handleAuthorPress?.(author);
  }, [author]);

  const privateIconRender = useMemo(() => {
    if (!data?.private) {
      return null;
    }
    return (
      <Tooltip placement="top" arrow disableInteractive variant="outlined" size="sm" title="This document is visible only to you.">
        <Typography component="span" fontSize={16}>
          <Icon size="sm" marginRight={1} weight="solid" color="var(--joy-palette-primary-solidDisabledColor)" name="eye-slash" />
        </Typography>
      </Tooltip>
    );
  }, [data?.private]);

  return (
    <CardContent>
      <Box display="flex" flexDirection="column" gap={1}>
        {hasContent && title && (
          <Typography fontSize={17} fontWeight={600}>
            {privateIconRender}
            {title}
          </Typography>
        )}
        {hasContent && paragraph && (
          <Typography
            sx={{
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 8,
              overflow: 'hidden',
              opacity: 0.9,
            }}
            fontSize={14}
          >
            {!title && privateIconRender}
            {paragraph}
          </Typography>
        )}
        {!hasContent && (
          <Typography fontSize={17}>
            {privateIconRender}
            <Typography component="span" fontWeight={400} color="neutral" sx={{ color: 'var(--joy-palette-warning-solidDisabledColor)' }}>
              Empty document
            </Typography>
          </Typography>
        )}
        {hasAuthor && !!author && <AuthorBlock onPress={handlePress} data={author} mt={1} />}
      </Box>
    </CardContent>
  );
};

export default memo(Note, (prevProps, nextProps) => isEqual(prevProps, nextProps));
