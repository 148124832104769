import { memo } from 'react';
import { StyleSheet } from 'react-native';

import { useResponsive } from 'hooks';
import { unit } from 'utils';

import { View } from 'components/Themed';

const TopBarMobile = () => {
  const responsive = useResponsive();

  if (!responsive.is.tabletL) {
    return null;
  }

  return <View style={styles.wrapper} />;
};

const styles = StyleSheet.create({
  wrapper: {
    height: unit(48),
  },
});

export default memo(TopBarMobile);
