import { createSelector } from '@reduxjs/toolkit';
import { type DefaultRootState } from 'react-redux';
import md5 from 'blueimp-md5';

const selector = createSelector(
  (state: DefaultRootState) => state.search.filter,
  (state: DefaultRootState) => state.search.list.meta,
  (filter, meta) => ({
    isConsistent: meta.resultWithFilterHash === md5(JSON.stringify(filter)),
    firstPage: {
      isLoading: meta.isFirstPageLoading,
      isLoaded: meta.isFirstPageLoaded,
    },
    nextPage: {
      isLoading: meta.isNextPageLoading,
      isLoaded: meta.isNextPageLoaded,
    },
  }),
);

export default (state: DefaultRootState) => selector(state);
