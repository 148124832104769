import { type ChangeEvent, useCallback, useMemo, useState } from 'react';
import { useOnInputEnter } from 'hooks';
import { useDispatcher, useSelector } from 'store/utils/redux/hooks';
import * as onboardingStore from 'widgets/Onboarding/store';

const isValidEmail = (email: string) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

const useInviteTeamState = () => {
  const dispatcher = useDispatcher();
  const isLoading = useSelector(onboardingStore.selectors.isLoading);
  const isSkipping = useSelector(onboardingStore.selectors.isSkipLoading);
  const [emails, setEmails] = useState<string[]>(['']);

  const handleChangeEmails = useCallback(
    (index: number) => (event: ChangeEvent<HTMLInputElement>) => {
      const newEmails = [...emails];
      newEmails[index] = event.target.value;
      setEmails(newEmails);
    },
    [emails],
  );

  const handleAddEmails = useCallback(() => {
    const lastEmail = emails[emails.length - 1];
    if (lastEmail && isValidEmail(lastEmail)) {
      setEmails([...emails, '']);
    }
  }, [emails]);

  const handleSubmit = useCallback(() => {
    dispatcher.onboarding.inviteTeam({
      emails,
    });
  }, [dispatcher.onboarding, emails]);

  const areAllEmailsValid = useMemo(() => emails.every((email) => email && isValidEmail(email)), [emails]);

  const { onKeyDown } = useOnInputEnter(
    useCallback(() => {
      handleSubmit();
    }, [handleSubmit]),
  );

  const handleSkip = useCallback(() => {
    dispatcher.onboarding.skip({ target: 'invite' });
  }, [dispatcher.onboarding]);

  return useMemo(
    () => ({
      emails,
      onChange: handleChangeEmails,
      onAddEmails: handleAddEmails,
      onSkip: handleSkip,
      onSubmit: handleSubmit,
      areAllEmailsValid,
      onKeyDown,
      isValidEmail,
      isLoading,
      isSkipping,
    }),
    [emails, handleChangeEmails, handleAddEmails, handleSkip, handleSubmit, areAllEmailsValid, onKeyDown, isValidEmail, isLoading, isSkipping],
  );
};

export default useInviteTeamState;
