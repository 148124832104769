import { memo, forwardRef, useCallback } from 'react';

import { useDispatcher } from 'store/utils/redux/hooks';

import Icon from 'components/LegacyIcon';

import { type Controller } from '../types';
import Menu from '../elements/Menu';

interface PlusOldProps {}

const PlusOld = forwardRef<Controller, PlusOldProps>((props, forwardedRef) => {
  const dispatcher = useDispatcher();

  const handleOpenCreatePlaylist = useCallback(
    (context: any) => {
      if (forwardedRef && 'current' in forwardedRef) {
        forwardedRef?.current?.close();
      }
      setTimeout(() => {
        dispatcher.modal.open('AddorEditCollection', {
          create: {
            withItems: [],
          },
        });
      }, 100);
    },
    [forwardedRef],
  );

  const handlePlusOpen = useCallback((context: any) => {
    if (forwardedRef && 'current' in forwardedRef) {
      forwardedRef?.current?.close();
    }
    setTimeout(() => {
      dispatcher.modal.open('PlusBottomSheet');
    }, 100);
  }, []);

  return (
    <>
      <Menu.Panel ref={forwardedRef}>
        <Menu.Item panelRef={forwardedRef} icon={<Icon name="Playlist28" size={28} lightColor="#696969" darkColor="#D1CFD7" />} label="Create collection" onPress={handleOpenCreatePlaylist} />
        <Menu.Item panelRef={forwardedRef} icon={<Icon name="School28" size={28} lightColor="#696969" darkColor="#D1CFD7" />} label="Add material" onPress={handlePlusOpen} />
      </Menu.Panel>
    </>
  );
});

export default memo(PlusOld);
