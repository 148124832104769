import type { ResultLegacy, MaterialType, SourceMaterialType } from 'app/entities';
import request from 'services/api/request';
import { prepare } from 'utils';
import interaction from 'services/api/interaction/route';

export default async (content: MaterialType | SourceMaterialType): Promise<ResultLegacy<MaterialType>> => {
  const body: Record<string, any> = {
    description: content?.description,
    duration: content?.duration,
    image: content?.image,
    authorUrls: ('authorUrls' in content && content?.authorUrls) || [],
    originalUrl: content?.originalUrl,
    sectionId: content?.sectionId,
    sourceId: content?.sourceId,
    private: content?.private,
    tags: content?.tags,
    title: content?.title,
    type: content?.type ? (content?.type || '').toLocaleUpperCase() : undefined,
    level: content?.level ? (content?.level || '').toLocaleUpperCase() : undefined,
    publishedAt: content?.publishedAt,
  };

  const { data, error } = await request.post<any>('/stack-1/content', { body });
  if (error || !data) {
    return {
      data: null,
      error,
    };
  }

  const [interactions] = await Promise.all([interaction.items('content', data.id)]);

  if (interactions.error || !interactions.data) {
    return {
      data: null,
      error: interactions.error,
    };
  }

  const result = {
    ...data,
    ...interactions.data[data?.id],
  };

  return {
    data: prepare.material(result),
    error,
  };
};
