import { memo } from 'react';
import { type UserType } from 'app/entities';
import { useSelector } from 'react-redux';

import * as menuStore from 'store/nodes/menu';

import HorizontalSwiper from 'widgets/HorizontalSwiper';
import Person from 'components/Person';

type UsersSwiperProps = {
  users: UserType[];
};

const UsersSwiper = (props: UsersSwiperProps) => {
  const { users } = props;

  const leftBarWidth = useSelector(menuStore.selectors.leftBarWidth);
  const isOpen = leftBarWidth > 64;

  return (
    <HorizontalSwiper
      className="swiperUser"
      autoHeight={false}
      breakpoints={{
        320: {
          slidesPerView: 3.25,
          spaceBetween: 16,
        },
        560: {
          slidesPerView: 4.75,
          spaceBetween: 16,
        },
        768: {
          slidesPerView: 4.75,
          spaceBetween: 16,
        },
        1024: {
          slidesPerView: !isOpen ? 8.25 : 6.25,
          spaceBetween: 16,
        },
        1280: {
          slidesPerView: !isOpen ? 10.25 : 8.25,
          spaceBetween: 16,
        },
        1440: {
          slidesPerView: 10.5,
          spaceBetween: 16,
        },
      }}
    >
      {users.map((item) => (
        <Person key={`user-${item.id}`} type="user" login={item.login} name={item.name} surname={item.surname} photo={item.photo} profession={item.profession} style={{ marginBottom: 16 }} />
      ))}
    </HorizontalSwiper>
  );
};

export default memo(UsersSwiper);
