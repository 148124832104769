import { type MouseEvent, memo, useCallback, useMemo, useRef, useEffect } from 'react';
import { Box, IconButton, Tooltip, Typography } from '@mui/joy';

import { useDispatcher, useSelector } from 'store/utils/redux/hooks';
import * as askStore from 'store/nodes/ask';

import { useScreenSize } from 'hooks';

import AdaptivePage from 'components/AdaptivePage';
import Icon from 'ui/Icon';
import { CopilotScopeSwitcher } from 'widgets/Copilot';
import CopilotInput, { type CopilotInputMethods } from 'widgets/Copilot/ui/CopilotInput';

import SearchPresets from './ui/SearchPresets';

const Start = () => {
  const pagePaddingHorizontalScheme = useMemo(
    () => ({
      320: 16,
    }),
    [],
  );

  const screenSize = useScreenSize();
  const copilotInputRef = useRef<CopilotInputMethods>(null);
  const dispatcher = useDispatcher();
  const scope = useSelector(askStore.selectors.scope);
  const query = useSelector(askStore.selectors.query);

  const handlePresetPress = useCallback(
    (event: MouseEvent<HTMLButtonElement>, context: { preset: string }) => {
      dispatcher.copilot.sendQuestion({
        text: context.preset,
        resourceType: scope,
        resourceId: 0,
      });
      dispatcher.ask.setQuery({ query: '' });
    },
    [scope],
  );

  const handleTextChange = useCallback((text: string) => {
    dispatcher.ask.setQuery({ query: text });
  }, []);

  const handleSubmit = useCallback(() => {
    dispatcher.copilot.sendQuestion({
      text: query,
      resourceType: scope,
      resourceId: 0,
    });
    dispatcher.ask.setQuery({ query: '' });
  }, [query, scope]);

  useEffect(() => {
    copilotInputRef.current?.cursor?.toEnd();
  }, []);

  return (
    <AdaptivePage bodyMaxWidth={768} paddingHorizontalScheme={pagePaddingHorizontalScheme}>
      <Box display="flex" flex={1} flexDirection="column" justifyContent="flex-start">
        {!screenSize.is767 && (
          <Box>
            <Typography textColor="text.primary" fontSize={48} fontWeight="bolder" lineHeight={1.25} my={3} textAlign="center">
              Intelligent Knowledge Interface
            </Typography>
            <Box display="flex" flexDirection="column" alignItems="stretch">
              <CopilotInput
                ref={copilotInputRef}
                autoFocus
                size="large"
                focusBorderSize="0.125em"
                onSubmit={handleSubmit}
                onChange={handleTextChange}
                value={query}
                endAdornment={
                  <Box display="flex" mr={1} alignSelf="center">
                    <Tooltip arrow variant="outlined" size="sm" title="Send">
                      <IconButton color="neutral" size="lg" onClick={handleSubmit} disabled={!query}>
                        <Icon name="circle-arrow-up" fw weight="solid" color="inherit" size="lg" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                }
              />
            </Box>
            <CopilotScopeSwitcher mx={2} mt={3} />
            <SearchPresets onPresetPress={handlePresetPress} />
          </Box>
        )}
        {screenSize.is767 && (
          <Box position="absolute" display="flex" flexDirection="column" left={0} right={0} bottom="6rem" alignItems="stretch" px={2}>
            <Box
              mx={3}
              mb={10}
              sx={{
                '@media (max-height: 900px)': {
                  mb: 7,
                },
                '@media (max-height: 800px)': {
                  mb: 4,
                },
                '@media (max-height: 700px)': {
                  mb: 3,
                  '--Button-minHeight': '1rem',
                  '--Button-paddingBlock': '0.375rem',
                },
                '@media (max-width: 380px)': {
                  mx: 0,
                },
              }}
            >
              <SearchPresets onPresetPress={handlePresetPress} />
            </Box>
            <CopilotInput
              ref={copilotInputRef}
              autoFocus
              onSubmit={handleSubmit}
              onChange={handleTextChange}
              value={query}
              endAdornment={
                <Box display="flex" mr={1} alignSelf="flex-end" mb={0.75}>
                  <Tooltip arrow variant="outlined" size="sm" title="Send">
                    <IconButton color="neutral" size="md" onClick={handleSubmit} disabled={!query}>
                      <Icon name="circle-arrow-up" fw weight="solid" color="inherit" />
                    </IconButton>
                  </Tooltip>
                </Box>
              }
            />
          </Box>
        )}
      </Box>
    </AdaptivePage>
  );
};

export default memo(Start);
