import { type SagaReturnType, takeEvery, cancel, put } from 'redux-saga/effects';

import { call } from 'store/utils/saga/effects';
import { type CollectionPermissionType } from 'app/entities';

import Alert from 'components/Alert';
import network from 'lib/network';

import { guard } from 'utils';
import { actions } from '../slice';

export const config = {
  action: actions.updatePermission.type,
  method: takeEvery,
};

export function* func(action: SagaReturnType<typeof actions.updatePermission>) {
  const { userId, permissionId, collectionId } = action.payload;

  if (!userId || !permissionId || !collectionId) {
    yield put(actions.updatePermissionDone({ userId, permissionId, collectionId }));
    yield cancel();
    return;
  }

  const { data, errors } = yield* call(() =>
    network.request<CollectionPermissionType[]>(`/stack-1/share/user/playlists/${collectionId}/updatePermission`).body({ user_id: userId, permission_id: permissionId }).post(),
  );

  if (!data || errors) {
    Alert.error('Error update permission');
    yield put(actions.updatePermissionDone({ userId, permissionId, collectionId }));
    yield cancel();
    return;
  }

  if (guard.isCollectionPermissionType(data)) {
    yield put(
      actions.setPermissions({
        collectionId,
        permissions: data,
      }),
    );
  }

  yield put(actions.updatePermissionDone({ userId, permissionId, collectionId }));
}
