import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useDispatcher } from 'store/utils/redux/hooks';
import * as libraryStore from 'store/nodes/library';

export default () => {
  const dispatcher = useDispatcher();

  const items = useSelector(libraryStore.selectors.panelTypes);
  const selectedValue = useSelector(libraryStore.selectors.enabledFilterIds);

  const handleToggle = useCallback((newValue?: string) => {
    if (!newValue) {
      return;
    }
    dispatcher.library.toggleFilter(newValue);
  }, []);

  return useMemo(
    () => ({
      items,
      selectedValue,
      handleToggle,
    }),
    [items, selectedValue],
  );
};
