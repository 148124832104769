import { memo, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useRoute } from 'navigation/hooks';

import { unit, guard } from 'utils';

import Icon from 'components/LegacyIcon';
import Button from 'components/Button';
import { controller as modal } from 'components/Modal2';
import * as userStore from 'store/nodes/user';
import * as authorStore from 'store/nodes/author';

const ProfileActions = () => {
  const route = useRoute<'Content'>();

  const { user, author } = useMemo(() => {
    if (route.isScreen('Profile')) {
      const { login, entity } = route.params as Record<string, string>;
      return {
        user: {
          login: login || ('my' as string),
          entity: entity as string,
        },
        author: null,
      };
    }
    if (route.isScreen('Author')) {
      const { id } = route.params as Record<string, number>;
      return {
        user: null,
        author: {
          id: id as number,
        },
      };
    }
    return {
      user: null,
      author: null,
    };
  }, [route]);

  const profile = useSelector((state) => {
    if (user) {
      return userStore.selectors.getByLogin(user.login)(state);
    }
    if (author) {
      return authorStore.selectors.getById(author.id)(state);
    }
    return null;
  });

  const handleSetting = useCallback(() => {
    modal.popup.profileSettings.open();
  }, []);

  return (
    <>
      {guard.isUser(profile) && profile?.isMy && (
        <Button type="button-icon" variant="text" onPress={handleSetting} width={32} height={32} style={{ marginRight: unit(-2) }}>
          <Icon name="SettingsOutline" size={28} />
        </Button>
      )}
    </>
  );
};

export default memo(ProfileActions);
