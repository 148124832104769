import { type SagaReturnType, takeLatest, put, cancel, delay } from 'redux-saga/effects';

import type { MaterialType, CollectionType } from 'app/entities';

import Alert from 'components/Alert';

import { getRoute } from 'navigation/methods';

import * as api from 'services/api';

import { guard } from 'utils';

import { call, select } from 'store/utils/saga/effects';
import { checkUnauthorized } from 'store/utils/credentials';
import * as materialStore from 'store/nodes/content';
import * as collectionStore from 'store/nodes/playlist';

import * as actions from '../actions';
import * as selectors from '../selectors';

export const config = {
  action: [actions.loadNextPage.type],
  method: takeLatest,
};

export function* func(action: SagaReturnType<typeof actions.loadNextPage>) {
  const route = yield* call(() => getRoute<'News'>());
  const { reload } = action.payload;
  const { topic } = route.params;

  const nextCursor = yield* select(selectors.selectLoadNextCursor(reload));

  if (!topic) {
    yield put(actions.loadNextPageDone(reload));
    yield cancel();
    return;
  }

  const response = yield* call(() =>
    api.resource.user.library.news(topic, {
      cursor: nextCursor,
      pageSize: 24,
    }),
  );
  yield checkUnauthorized(response);

  if (response.error || !response.data) {
    Alert.error(response?.error?.message || 'Server error #1');
    yield put(actions.loadNextPageDone(reload));
    yield cancel();
    return;
  }

  const materials: MaterialType[] = [];
  const collections: CollectionType[] = [];
  response.data.items.forEach((item) => {
    if (guard.isMaterial(item)) {
      materials.push(item);
    }
    if (guard.isCollection(item)) {
      collections.push(item);
    }
  });

  if (materials.length > 0) {
    yield put(materialStore.actions.setItem(materials));
  }
  if (collections.length > 0) {
    yield put(collectionStore.actions.setItem({ data: collections }));
  }

  yield delay(10);
  yield put(actions.addPage(response.data.items, response.data.paginationInfo, reload));
  yield put(actions.loadNextPageDone(reload));
}
