import { useEffect, useState } from 'react';

import Storage from '../classes/Storage';

const useStorageSync = (): boolean => {
  const [isSynchronized, setSynchronized] = useState(false);

  useEffect(() => {
    Storage.sync();
    setSynchronized(true);
  }, []);

  return isSynchronized;
};

export default useStorageSync;
