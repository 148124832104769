import { type MutableRefObject, useLayoutEffect, useMemo, useState } from 'react';

const useLayoutWidth = (elementRef: MutableRefObject<HTMLElement | null>) => {
  const [width, setWidth] = useState<number | null>(null);

  useLayoutEffect(() => {
    const updateLayoutWidth = () => {
      if (elementRef.current) {
        const { width: containerWidth } = elementRef.current.getBoundingClientRect();
        setWidth((prevWidth) => (prevWidth !== containerWidth ? containerWidth : prevWidth));
      }
    };
    updateLayoutWidth();
    window.addEventListener('resize', updateLayoutWidth);
    return () => {
      window.removeEventListener('resize', updateLayoutWidth);
    };
  }, []);

  return useMemo(
    () => ({
      width,
    }),
    [width],
  );
};

export default useLayoutWidth;
