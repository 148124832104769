import qs from 'query-string';

import getAppId from 'utils/getAppId';
import getSessionId from 'utils/getSessionId';

import RequestContext from './RequestContext';

const responseCodes: Record<number, string> = {
  100: 'Continue',
  101: 'Switching Protocol',
  102: 'Processing',
  103: 'Early Hints',
  200: 'OK',
  201: 'Created',
  202: 'Accepted',
  203: 'Non-Authoritative Information',
  204: 'No Content',
  205: 'Reset Content',
  206: 'Partial Content',
  300: 'Multiple Choice',
  301: 'Moved Permanently',
  302: 'Found',
  303: 'See Other',
  304: 'Not Modified',
  305: 'Use Proxy',
  306: 'Switch Proxy',
  307: 'Temporary Redirect',
  308: 'Permanent Redirect',
  400: 'Bad Request',
  401: 'Unauthorized',
  402: 'Payment Required',
  403: 'Forbidden',
  404: 'Not Found',
  405: 'Method Not Allowed',
  406: 'Not Acceptable',
  407: 'Proxy Authentication Required',
  408: 'Request Timeout',
  409: 'Conflict',
  410: 'Gone',
  411: 'Length Required',
  412: 'Precondition Failed',
  413: 'Request Entity Too Large',
  414: 'Request-URI Too Long',
  415: 'Unsupported Media Type',
  416: 'Requested Range Not Satisfiable',
  417: 'Expectation Failed',
  500: 'Internal Server Error',
  501: 'Not Implemented',
  502: 'Bad Gateway',
  503: 'Service Unavailable',
  504: 'Gateway Timeout',
  505: 'HTTP Version Not Supported',
};

interface ResponseType<T> {
  data: T | null;
  hasError: boolean;
  errors: Error[] | null;
}

class Request<T = Record<string, any> | string | number> {
  private readonly baseUrl;

  private readonly endpointUrl;

  private readonly optionsData;

  private readonly context: RequestContext;

  private method: 'get' | 'post' | 'put' | 'delete' = 'get';

  private headersData: Record<string, any> | null = null;

  private queryData: Record<string, any> | null = null;

  private bodyData: Record<string, any> | null = null;

  constructor(baseUrl: string, endpoint: string, options: Record<string, any> = {}, context: RequestContext = new RequestContext()) {
    this.baseUrl = baseUrl;
    this.endpointUrl = endpoint;
    this.optionsData = options;
    this.context = context;
  }

  private prepareURL() {
    let url = `${this.baseUrl.replace(/\/$/gi, '')}/${this.endpointUrl.replace(/^\//gi, '')}`;
    if (this.queryData && Object.keys(this.queryData).length > 0) {
      url = `${url}?${qs.stringify(this.queryData)}`;
    }
    return url;
  }

  private prepareOptions() {
    const options: Record<string, any> = {
      ...(this.optionsData || {}),
      method: this.method,
      headers: {
        ...(this.context.headers || {}),
        ...(this.headersData || {}),
        'x-app-id': getAppId(),
        'x-session-id': getSessionId(),
      },
    };
    if (['post', 'delete'].includes(options?.method)) {
      options.headers['Content-Type'] = 'application/json';
    }
    if (this.bodyData && Object.keys(this.bodyData).length > 0) {
      options.body = JSON.stringify(this.bodyData);
    }
    return options;
  }

  private async send<M = T>(modifier?: (response: T) => M): Promise<ResponseType<M>> {
    try {
      const result = await fetch(this.prepareURL(), this.prepareOptions());
      const buffer: string = await result.text();
      if (buffer.length > 0) {
        const parsed = JSON.parse(buffer);
        if (parsed?.errors && Array.isArray(parsed.errors) && parsed.errors.length > 0) {
          return {
            data: null,
            hasError: true,
            errors: parsed.errors.map((error: any) => new Error(error?.message || error || 'Unknown error')),
          };
        }
        if (parsed?.errors && !Array.isArray(parsed.errors)) {
          return {
            data: null,
            hasError: true,
            errors: [new Error(parsed.errors?.message || parsed.errors || 'Unknown error')],
          };
        }
        if (parsed?.data?.errors && Array.isArray(parsed.data.errors) && parsed.data.errors.length > 0) {
          return {
            data: null,
            hasError: true,
            errors: parsed.data.errors.map((error: any) => new Error(error?.message || error || 'Unknown error')),
          };
        }
        if (parsed?.error) {
          return {
            data: null,
            hasError: true,
            errors: [new Error(parsed?.error?.message || parsed?.error || 'Unknown error')],
          };
        }
        const modifiedData = modifier ? modifier(parsed) : parsed;
        return {
          data: 'data' in modifiedData ? modifiedData.data : modifiedData,
          hasError: false,
          errors: null,
        };
      }
      if (buffer.length === 0 && result.status < 300) {
        return {
          data: null,
          hasError: false,
          errors: null,
        };
      }
      if (buffer.length === 0 && result.status >= 300) {
        return {
          data: null,
          hasError: true,
          errors: [new Error(responseCodes[result.status])],
        };
      }
    } catch (error) {
      if ((error as Error).name === 'SyntaxError') {
        return {
          data: null,
          hasError: true,
          errors: [new Error('SyntaxError: Response is not JSON')],
        };
      }
      return {
        data: null,
        hasError: true,
        errors: [error as Error],
      };
    }

    return {
      data: null,
      hasError: true,
      errors: [new Error('Unknown error')],
    };
  }

  public headers(value: Record<string, any>): Request<T> {
    this.headersData = value;
    return this;
  }

  public query(value: Record<string, any>): Request<T> {
    this.queryData = value;
    return this;
  }

  public body(value: Record<string, any>): Omit<Request<T>, 'get'> {
    this.bodyData = value;
    return this;
  }

  public async get<M = T>(modifier?: (response: T) => M): Promise<ResponseType<M>> {
    this.method = 'get';
    return this.send(modifier);
  }

  public async post<M = T>(modifier?: (response: T) => M): Promise<ResponseType<M>> {
    this.method = 'post';
    return this.send(modifier);
  }

  public async put<M = T>(modifier?: (response: T) => M): Promise<ResponseType<M>> {
    this.method = 'put';
    return this.send(modifier);
  }

  public async delete<M = T>(modifier?: (response: T) => M): Promise<ResponseType<M>> {
    this.method = 'delete';
    return this.send(modifier);
  }
}

export default Request;
