import { useMemo } from 'react';
import { prepareHtml } from 'utils';

/**
 * Хук для обработки текста, который может быть строкой, null или undefined. Возвращает сокращенную версию текста, полный HTML,
 * а также информационные флаги о наличии текста и необходимости разворота.
 *
 * @param {string | null | undefined} content - Исходный текст для обработки.
 * @param {number} [maxLength=500] - Максимальная длина сокращенного текста.
 * @returns {object} Результирующий объект, содержащий:
 *   @property {string} shortText - Сокращенный текст до заданной длины, с многоточием, если был обрезан.
 *   @property {string} html - Полный текст, преобразованный в HTML-формат.
 *   @property {boolean} hasText - Флаг, указывающий, содержит ли исходный текст какие-либо символы.
 *   @property {boolean} hasExpand - Флаг, указывающий, нужно ли развернуть текст (т.е. укорочен ли он).
 */
function useContent(content: string | null | undefined, maxLength: number = 500) {
  const hasText = useMemo(() => {
    return Boolean(content && content.trim().length > 0);
  }, [content]);

  const shortText = useMemo(() => {
    if (!content || content.trim().length === 0) return '';
    if (content.length <= maxLength) {
      return content;
    }
    let truncationIndex = content.lastIndexOf(' ', maxLength);
    if (truncationIndex === -1) {
      truncationIndex = maxLength;
    }
    const truncated = content.substring(0, truncationIndex);
    return `${truncated}...`;
  }, [content, maxLength]);

  const html = useMemo(() => {
    return prepareHtml({ text: content || '' });
  }, [content]);

  const hasExpand = useMemo(() => {
    if (!content) return false;
    return shortText !== content;
  }, [shortText, content]);

  return { shortText, html, hasText, hasExpand };
}

export default useContent;
