import { type SagaReturnType, cancel, takeEvery, put, delay } from 'redux-saga/effects';

import { select } from 'store/utils/saga/effects';
import * as socketStore from 'store/nodes/socket';
import * as actions from 'store/nodes/plus/actions';
import * as selectors from 'store/nodes/plus/selectors';
import * as materialStore from 'store/nodes/content';
import Alert from 'components/Alert';

export const config = {
  action: socketStore.actions.summaryPrepared.type,
  method: takeEvery,
};

export function* func(action: SagaReturnType<typeof socketStore.actions.summaryPrepared>) {
  const { payload } = action;

  const { request_id: requestId, material_id: materialId, summary } = payload;
  if (!summary) {
    yield cancel();
    return;
  }

  let requestInfo = yield* select(selectors.requestInfo);
  if (requestInfo.id === requestId) {
    while (true) {
      requestInfo = yield* select(selectors.requestInfo);
      if (requestInfo.status === null) {
        break;
      }
      if (requestInfo.status === 'done') {
        yield delay(20);
        break;
      }
      yield delay(100);
    }
  }

  const isOpen = yield* select(selectors.isOpen);
  const resultType = yield* select(selectors.resultType);
  const result = yield* select(selectors.materialResult);

  if (isOpen && resultType === 'material' && result?.id === materialId) {
    yield put(actions.setSummary(summary));
    yield cancel();
    return;
  }

  const material = yield* select(materialStore.selectors.dataById(result?.id));
  if (material) {
    Alert.info('SummaryPrepared', {
      closeOnClick: true,
      autoClose: 10000,
      data: {
        resourceId: material.id,
        type: material.type,
        title: material.title,
        summary,
      },
    });
  }
}
