import { type DefaultRootState } from 'react-redux';
import type { RelationVariants } from 'app/entities';

export const nextParams = (listType: RelationVariants) => (state: DefaultRootState) => ({
  login: state.relation.user?.login,
  cursor: state.relation?.[listType]?.list?.paginationInfo?.nextCursor || undefined,
});

export const listMeta = (listType: RelationVariants) => (state: DefaultRootState) => ({
  firstPage: {
    isLoading: state.relation[listType].meta.isFirstPageLoading,
    isLoaded: state.relation[listType].meta.isFirstPageLoaded,
  },
  nextPage: {
    isLoading: state.relation[listType].meta.isNextPageLoading,
    isLoaded: state.relation[listType].meta.isNextPageLoaded,
  },
});

export const list = (listType: RelationVariants) => (state: DefaultRootState) => ({
  sequence: state.relation[listType].list.sequence,
  paginationInfo: state.relation[listType].list.paginationInfo,
});
