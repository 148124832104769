import type { DefaultRootState } from 'react-redux';
import type { AuthorType } from 'app/entities';

export const getById =
  (id?: number) =>
  (state: DefaultRootState): AuthorType | null => {
    if (!id) {
      return null;
    }
    return state.author.data[id] || null;
  };

export const isLoading = (id?: number) => (state: DefaultRootState) => {
  if (!id) {
    return false;
  }
  return state.author.meta.loadingMap[id] || false;
};
