/**
 * Opens a new window with the specified URL and title.
 * If the screen width is less than or equal to 599 pixels, it opens the URL in a new tab.
 * Otherwise, it opens a centered popup window with specified dimensions.
 *
 * @param {string} url - The URL to open.
 * @param {string} title - The title of the window.
 * @returns {Window | null} - The window object of the opened window or null if the window couldn't be opened.
 */
const openWindow = (url: string, title: string) => {
  if (window.innerWidth <= 599) {
    return window.open(url, '_blank');
  }

  const width = 500;
  const height = 650;
  const left = (window.innerWidth - width) / 2;
  const top = (window.innerHeight - height) / 2;

  return window.open(url, title, `width=${width},height=${height},left=${left},top=${top}`);
};

export default openWindow;
