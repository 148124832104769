import { memo, useCallback, useEffect } from 'react';
import { Box, IconButton } from '@mui/joy';

import { useSelector } from 'react-redux';

import type { CollectionType } from 'app/entities';

import * as collectionStore from 'store/nodes/playlist';

import Icon from 'ui/Icon';
import { useDispatcher } from 'store/utils/redux/hooks';

type EditProps = {
  id: number;
  onPress: (context: CollectionType) => void;
};

const Edit = (props: EditProps) => {
  const { id, onPress } = props;

  const dispatcher = useDispatcher();
  const data = useSelector(collectionStore.selectors.dataById(id));
  const userPermission = useSelector(collectionStore.selectors.userPermission(id, 'my'));

  useEffect(() => {
    dispatcher.playlist.loadById({ id });
  }, [id]);

  const handlePress = useCallback(
    (event: { stopPropagation: () => void }) => {
      event.stopPropagation();
      if (!data) {
        return;
      }
      onPress?.(data);
    },
    [onPress, data],
  );

  if (!data || !userPermission.hasWrite) {
    return null;
  }

  return (
    <Box
      sx={{
        position: 'relative',
      }}
    >
      <IconButton
        onClick={handlePress}
        variant="soft"
        color="neutral"
        sx={{
          opacity: '0.7',
          borderRadius: (theme) => theme.radius.md,
        }}
      >
        <Icon name="edit" />
      </IconButton>
    </Box>
  );
};

export default memo(Edit);
