import { memo, useCallback } from 'react';
import { Box, Typography } from '@mui/joy';

import { useResponsive } from 'hooks';

import Icon from 'components/LegacyIcon';

import { useDispatcher } from 'store/utils/redux/hooks';

const TopBarDesktop = () => {
  const responsive = useResponsive();
  const dispatcher = useDispatcher();

  const handleSubscribe = useCallback(() => {
    dispatcher.modal.open('SearchUser');
  }, []);

  if (responsive.is.tabletL) {
    return null;
  }

  return (
    <Box my={2}>
      <Box display="flex" flexDirection="row" alignItems="center" ml={-0.2}>
        <Icon size={28} name="MenuCommunityOutline" />
        <Typography fontSize={22} fontWeight={500} ml={0.25}>
          Community
          <Typography fontSize={22} fontWeight={500} color="neutral">
            {' '}
            explore latest collections
          </Typography>
        </Typography>
      </Box>
      <Box mt={0.5} height={30}>
        <Typography fontSize={17}>
          <Typography color="primary" fontWeight={500} onClick={handleSubscribe} sx={{ cursor: 'pointer' }}>
            subscribe
          </Typography>
          {' to the most popular authors and active peers.'}
        </Typography>
      </Box>
    </Box>
  );
};

export default memo(TopBarDesktop);
