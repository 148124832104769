import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import * as libraryStore from 'store/nodes/library';

const useLibraryState = () => {
  const list = useSelector(libraryStore.selectors.list);
  const listMeta = useSelector(libraryStore.selectors.listMeta);

  return useMemo(() => {
    if (!list.sequence.length && listMeta.firstPage.isLoaded) {
      return 'nothing-to-show';
    }
    if (listMeta.firstPage.isLoading) {
      return 'loading';
    }
    return 'list';
  }, [list.sequence.length, listMeta.firstPage.isLoading]);
};

export default useLibraryState;
