import { type DefaultRootState } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';

const selector = createSelector(
  (state: DefaultRootState) => state.content.list.meta.isFiltersLoading,
  (state: DefaultRootState) => state.content.list.meta.isFiltersLoaded,
  (isFiltersLoading, isFiltersLoaded) => ({
    isLoading: isFiltersLoading,
    isLoaded: isFiltersLoaded,
  }),
);

export const filtersMeta = (state: DefaultRootState) => selector(state);
