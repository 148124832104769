import { createReducer } from '@reduxjs/toolkit';

import type { CommunityStore } from './types';
import * as actions from './actions';

const initialState: CommunityStore = {
  collections: {
    pool: [],
    meta: {
      isLoading: false,
      isLoaded: false,
      loadingMap: {},
    },
  },
  controls: {
    panels: {
      options: ['Collections', 'Peers feed'],
      value: 'Collections',
    },
  },
};

export default createReducer(initialState, (builder) =>
  builder
    .addCase(actions.loadCollections, (state, action) => {
      state.collections.meta.isLoading = true;
    })
    .addCase(actions.loadCollectionDone, (state, action) => {
      state.collections.meta.isLoading = false;
      state.collections.meta.isLoaded = true;
    })
    .addCase(actions.setCollection, (state, action) => {
      const data = Array.isArray(action.payload.data) ? action.payload.data : [action.payload.data];
      state.collections.pool = [...data];
    })
    .addCase(actions.setPanelName, (state, action) => {
      state.controls.panels.value = action.payload.name;
    })
    .addCase(actions.logOut, (state, action) => {
      const cloned: CommunityStore = JSON.parse(JSON.stringify(initialState));
      state.collections = cloned.collections;
      state.controls = cloned.controls;
    }),
);
