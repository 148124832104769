import { type ReactElement, memo, useCallback } from 'react';
import { Box, IconButton } from '@mui/joy';

import Icon from 'ui/Icon';

type DeleteProps = {
  id: number;
  onPress: (id: number) => void;
};

const Delete = (props: DeleteProps): ReactElement => {
  const { id, onPress } = props;

  const handlePress = useCallback(
    (event: { stopPropagation: () => void }) => {
      event.stopPropagation();
      onPress?.(id);
    },
    [id],
  );

  return (
    <Box
      sx={{
        position: 'relative',
      }}
    >
      <IconButton
        onClick={handlePress}
        variant="soft"
        sx={{
          opacity: '0.7',
          borderRadius: (theme) => theme.radius.md,
        }}
      >
        <Icon name="trash" color="red" />
      </IconButton>
    </Box>
  );
};

export default memo(Delete);
