import { type SagaReturnType, takeEvery, cancel, put, take } from 'redux-saga/effects';

import { select } from 'store/utils/saga/effects';

import { actions } from '../slice';

export const config = {
  action: actions.commitNewNote.type,
  method: takeEvery,
};

export function* func(action: SagaReturnType<typeof actions.commitNewNote>) {
  const { tempId, text, collectionId } = action.payload;

  if (!collectionId || !tempId || !text) {
    yield cancel();
    return;
  }

  const options = yield* select((state) => {
    let result: {
      after: { type: 'note' | 'material'; id: number | string };
    } | null = null;
    const index = state.playlist.data[collectionId].materialsMetadata.findIndex((item) => item.id === tempId);
    if (index > 0) {
      const item = state.playlist.data[collectionId].materialsMetadata[index - 1];
      result = {
        after: {
          type: item.type as 'note' | 'material',
          id: item.id,
        },
      };
    }
    return result || undefined;
  });

  yield put(
    actions.addNoteItem({
      collectionId,
      itemType: 'note',
      text,
      options,
    }),
  );

  const { payload: addItemResult }: SagaReturnType<typeof actions.addItemDone> = yield take(actions.addItemDone.type);
  if (!addItemResult.success || !addItemResult.data) {
    yield put(actions.saveNewNoteDone({ success: false }));
    yield cancel();
    return;
  }

  yield put(
    actions.saveNewNoteDone({
      success: true,
      data: { realId: addItemResult.data.item.id, tempId },
      collectionId,
    }),
  );
}
