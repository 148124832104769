import { createAction } from '@reduxjs/toolkit';

import type { PaginationType, RagMaterialType, SearchItemType } from 'app/entities';

export const triggerSearch = createAction('SEARCH/TRIGGER', () => ({
  payload: {
    triggerReload: true,
  },
}));

export const clear = createAction('SEARCH/CLEAR');

export const setFilterTypeValue = createAction('SEARCH/SET_FILTER_TYPE_VALUE', (id: string | string[], triggerReload = true) => ({
  payload: {
    id,
    triggerReload,
  },
}));

export const toggleFilterTypeValue = createAction('SEARCH/TOGGLE_FILTER_TYPE_VALUE', (id: string | string[], triggerReload = true) => ({
  payload: {
    id,
    triggerReload,
  },
}));

export const setFilterText = createAction('SEARCH/SET_FILTER_TEXT', (value: string, triggerReload = true, immediateUpdate = false) => ({
  payload: {
    value,
    triggerReload,
    immediateUpdate,
  },
}));

export const setFilterTags = createAction('SEARCH/SET_FILTER_TAGS', (tags?: string | string[] | null, triggerReload = true) => {
  let value: string[] = [];
  if (Array.isArray(tags) && tags.length > 0) {
    value = [...new Set(tags)];
  }
  if (typeof tags === 'string' && tags.length > 0) {
    value = [tags];
  }
  return {
    payload: {
      value,
      triggerReload,
    },
  };
});

export const setMethod = createAction('SEARCH/SET_METHOD', (payload: { value: 'GlobalFullText' | 'GlobalTitle' | 'LibraryFullText' | 'LibraryTitle' | 'Web' | string; triggerReload?: boolean }) => ({
  payload: {
    value: payload.value,
    triggerReload: payload.triggerReload || true,
  },
}));

export const loadNextPage = createAction(
  'SEARCH/LOAD_NEXT_PAGE',
  (
    payload: {
      reload?: boolean;
      setMethod?: 'GlobalFullText' | 'GlobalTitle' | 'LibraryFullText' | 'LibraryTitle' | 'Web' | string;
    } = {},
  ) => ({
    payload,
  }),
);

export const loadNextPageDone = createAction('SEARCH/LOAD_NEXT_PAGE_DONE', (reload = false, status: 'has-results' | 'no-results' | 'nothing-to-search' | 'error' | null = null) => ({
  payload: {
    reload,
    status,
  },
}));

export const addPage = createAction('SEARCH/SET_PAGE', (items: SearchItemType[], paginationInfo: PaginationType, reload = false) => ({
  payload: {
    items,
    paginationInfo,
  },
  meta: {
    reload,
  },
}));

export const setRagMaterial = createAction('SEARCH/SET_RAG_MATERIAL', (items: RagMaterialType[] | null) => ({
  payload: {
    items,
  },
}));

export const logOut = createAction('SEARCH/LOGOUT');
