import { takeLatest, put, cancel, type SagaReturnType, delay } from 'redux-saga/effects';

import { call } from 'store/utils/saga/effects';

import Alert from 'components/Alert';
import network from 'lib/network';

import type { MaterialType } from 'app/entities';
import * as materialStore from 'store/nodes/content';
import * as actions from '../actions';

export const config = {
  action: actions.editMaterial.type,
  method: takeLatest,
};

export function* func(action: SagaReturnType<typeof actions.editMaterial>) {
  const { material, options } = action.payload;

  if (!material?.type) {
    Alert.error('"Content type" is required');
    yield put(actions.editMaterialDone());
    yield cancel();
    return;
  }
  if (!material?.title) {
    Alert.error('"Title" is required');
    yield put(actions.editMaterialDone());
    yield cancel();
    return;
  }

  const { data, errors } = yield* call(() =>
    network
      .request<MaterialType>(`/stack-1/content/${material.id}`)
      .body({
        ...material,
        type: material.type?.toUpperCase(),
      })
      .put(),
  );
  if (errors || !data) {
    Alert.error(errors?.[0]?.message || 'Unknown error #20');
    yield put(actions.editMaterialDone());
    yield cancel();
  }

  yield put(materialStore.actions.setItem(data));
  yield delay(10);
  if (!!options && options.showSuccessToast) {
    Alert.success('Material updated');
  }
  yield put(actions.editMaterialDone());
}
