import { type DefaultRootState } from 'react-redux';
import { createCachedSelector } from 're-reselect';

import type { MaterialType } from 'app/entities';

const selectorDataById = createCachedSelector(
  (state: DefaultRootState, resourceId: number) => state.content.data[resourceId],
  (state: DefaultRootState, resourceId: number) => state.interaction.content?.[resourceId],
  (content, interaction) => {
    if (!content) {
      return null;
    }
    return {
      ...content,
      ...interaction,
    };
  },
)((_state_, resourceId) => resourceId);

const selectorDataByIdWithUpdateMeta = createCachedSelector(
  (state: DefaultRootState, resourceId: number) => state.content.data[resourceId],
  (state: DefaultRootState, resourceId: number) => state.interaction.content?.[resourceId],
  (content, interaction) => {
    const result = {
      ...content,
      ...interaction,
    };
    const userPhotos: string[] = [];
    return {
      ...result,
    };
  },
)((_state_, resourceId, updateMeta) => `${resourceId}-${updateMeta.mode}`);

export const dataById =
  (resourceId: number | null | undefined, updateMeta?: { mode: 'add' | 'sub' }) =>
  (state: DefaultRootState): MaterialType | null => {
    if (!resourceId) {
      return null;
    }
    if (typeof updateMeta !== 'undefined') {
      return selectorDataByIdWithUpdateMeta(state, resourceId, updateMeta);
    }
    return selectorDataById(state, resourceId);
  };
