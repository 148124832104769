// import { DateTime } from 'luxon';

const log = (text: string) => {
  // console.log(
  //   'WS',
  //   DateTime.now().toLocaleString(DateTime.TIME_24_WITH_SECONDS),
  //   text,
  // );
};

export default log;
