import { Platform } from 'react-native';
import * as uuid from 'uuid';

class Stack {
  uuid = '';

  data: string[] = [];

  constructor() {
    this.uuid = uuid.v4();

    const style = document.createElement('style');
    style.innerHTML = `.no-scroll-${this.uuid} { overflow: hidden; }`;
    document.head.appendChild(style);
  }

  add = () => {
    if (Platform.OS === 'web' && this.data.length === 0) {
      document.body.classList.add(`no-scroll-${this.uuid}`);
    }
    this.data.push('');
  };

  remove = () => {
    if (Platform.OS === 'web' && this.data.length === 1) {
      document.body.classList.remove(`no-scroll-${this.uuid}`);
    }
    this.data.pop();
  };

  count = () => this.data.length;
}

export default new Stack();
