import { useParams } from 'navigation/hooks';
import { useSelector } from 'store/utils/redux/hooks';
import * as libraryStore from 'store/nodes/library';
import * as userStore from 'store/nodes/user';

const useLayoutName = () => {
  const params = useParams<'Library'>();
  const user = useSelector(userStore.selectors.getMy);

  if (user?.isLibraryEmpty) {
    return 'empty';
  }

  if (params.interaction === 'search') {
    return 'search';
  }

  return 'regular';
};

export default useLayoutName;
