import { memo } from 'react';

import useLayout from './model/useLayout';

import StartLayout from './layouts/Start';
import RegularLayout from './layouts/Regular';

const Ask = () => {
  const layout = useLayout();

  if (layout === 'Start') {
    return <StartLayout />;
  }

  if (layout === 'Regular') {
    return <RegularLayout />;
  }

  return null;
};

export default memo(Ask);
