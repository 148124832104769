import createCachedSelector from 're-reselect';
import { type DefaultRootState } from 'react-redux';

import type { ItemAttribute, ItemId } from '../types';

// Селекторы для получения payload и attribute
const getPayload = (state: DefaultRootState, itemId: string) => state.copilot.payload[itemId];
const getAttributes = (state: DefaultRootState, itemId: string) => state.copilot.attribute[itemId];

// Кэшированный селектор для объединения payload и attribute
const getCombinedItem = createCachedSelector(
  getPayload,
  getAttributes,
  (_: any, itemId) => itemId,
  (itemPayload, itemAttributes, itemId) => {
    return {
      id: itemId,
      payload: itemPayload,
      attributes: itemAttributes,
    };
  },
)({
  keySelector: (_, itemId) => itemId,
});

export default <P = undefined>(itemId: string) =>
  (state: DefaultRootState) =>
    getCombinedItem(state, itemId) as {
      id: ItemId;
      payload: P;
      attributes: ItemAttribute;
    };
