import { createAction } from '@reduxjs/toolkit';

import type { ModalType, ModalParams } from './types';

export const open = createAction(
  'modals/open',
  <T extends ModalType>(
    name: T,
    // @ts-ignore @todo fix it
    params?: ModalParams[T],
  ) => ({
    payload: {
      name,
      params: params || null,
    },
  }),
);

export const close = createAction('modals/close', (name: ModalType, result?: any) => ({
  payload: {
    name,
    result,
  },
}));
