import { takeEvery, put, cancel, type SagaReturnType } from 'redux-saga/effects';

import * as smartNoteStore from 'store/nodes/smartNote';

import { select } from 'store/utils/saga/effects';
import * as collectionStore from 'store/nodes/playlist';

import { actions } from '../slice';

export const config = {
  action: actions.removeItem.type,
  method: takeEvery,
};

export function* func(action: SagaReturnType<typeof actions.removeItem>) {
  const { collectionId, itemType, itemId } = action.payload;
  if (!itemType || !itemId) {
    yield cancel();
    return;
  }

  const collection = yield* select(collectionStore.selectors.dataById(collectionId));
  if (!collection) {
    yield cancel();
    return;
  }

  if (itemType === 'note' || itemType === 'document') {
    yield put(smartNoteStore.actions.remove(itemId));
    yield put(
      actions.removeItemDone({
        collectionId,
        success: true,
        removedMaterials: [{ type: 'note', id: itemId }],
      }),
    );
  }
  if (itemType === 'material') {
    const relatedItems = collection.materialsMetadata.filter((item) => item.type === 'material' && item.relatedToContentId === itemId) || [];
    yield put(
      actions.removeItemDone({
        collectionId,
        success: true,
        removedMaterials: [
          { type: 'material', id: itemId },
          ...relatedItems.map((item) => ({
            type: 'material' as const,
            id: item.id as number,
          })),
        ],
      }),
    );
  }

  yield put(actions.updateInBackground({ id: collectionId }));
}
