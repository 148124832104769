import type { DefaultRootState } from 'react-redux';

const isLoadingPermission = (userId: number | string, permissionId: number | string | null, collectionId: number) => (state: DefaultRootState) => {
  if (!userId && !permissionId && !collectionId) {
    return false;
  }
  const key = `${userId}-${permissionId}-${collectionId}`;

  return state.playlist.meta.loadingPermissionMap[key];
};

export default isLoadingPermission;
