import { useContext, createContext } from 'react';

import { type PlusProps } from '../types';

type ContextType = Pick<PlusProps, 'variant' | 'hasSummary' | 'hasNewCollectionButton'>;

const context = createContext<ContextType>({});

export const usePlusContext = () => useContext<ContextType>(context);

export const PlusContextProvider = context.Provider;
