import createCachedSelector from 're-reselect';
import { type DefaultRootState } from 'react-redux';

import { createIndexByResource } from '../utils';
import type { Resource } from '../types';

const getSequence = (state: DefaultRootState) => state.copilot.sequence;
const getAttributes = (state: DefaultRootState) => state.copilot.attribute;

const checkResourceInSequence = createCachedSelector(
  getSequence,
  getAttributes,
  (_: any, resources: Resource | Resource[]) => createIndexByResource(resources),
  (sequence, attributes, resourceIndex) => {
    let result = false;
    for (let i = 0; i < sequence.length; i++) {
      if (result) {
        break;
      }
      const itemId = sequence[i];
      const itemAttribute = attributes[itemId];
      const itemIndex = `${itemAttribute.resourceType}::${itemAttribute.resourceId || 'null'}`;
      if (resourceIndex.includes(itemIndex)) {
        result = true;
      }
    }
    return result;
  },
)({
  keySelector: (_, resources) => createIndexByResource(resources),
});

export default (resources: Resource | Resource[]) => (state: DefaultRootState) => checkResourceInSequence(state, resources);
