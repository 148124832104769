import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useDispatcher } from 'store/utils/redux/hooks';
import * as searchStore from 'store/nodes/search';

const getIconName = (label: string) =>
  ({
    all: 'GridView' as const,
    collection: 'Playlist28' as const,
    course: 'School28' as const,
    article: 'ArticleOutline28' as const,
    video: 'YoutubeOutline28' as const,
    source: 'SourceOutline28' as const,
    link: 'Source28' as const,
  })[label.toLowerCase()] || 'Source28';

export default () => {
  const dispatcher = useDispatcher();

  const method = useSelector(searchStore.selectors.method);
  const items = useSelector(searchStore.selectors.filterResourceTypeItems);
  const value = useSelector(searchStore.selectors.filterResourceTypeValue);

  const change = useCallback((newValue?: string) => {
    if (!newValue) {
      return;
    }
    dispatcher.search.setFilterTypeValue(newValue);
  }, []);

  const toggle = useCallback((newValue?: string) => {
    if (!newValue) {
      return;
    }
    dispatcher.search.toggleFilterTypeValue(newValue);
  }, []);

  return useMemo(
    () => ({
      method,
      items: items.map((item) => ({
        ...item,
        icon: getIconName(item.label.toLowerCase()),
      })),
      value,
      change,
      toggle,
    }),
    [items, value, change, toggle],
  );
};
