import { memo } from 'react';
import { Box } from '@mui/joy';
import { isEqual } from 'lodash';

import ScrollBox from 'ui/ScrollBox';

import Material from './components/Material';

type NotesProps = {
  resourceIds: number[];
};

const RelatedMaterials = (props: NotesProps) => {
  const { resourceIds } = props;

  if (!resourceIds) {
    return null;
  }

  return (
    <ScrollBox>
      <Box position="sticky" top={0} left={2} right={0} height={4} ml={1} zIndex={2} bgcolor="var(--joy-palette-background-body)" />
      <Box
        display="flex"
        flexDirection="column"
        gap={2}
        pt={2}
        ml={3}
        mr={2}
        pb={4}
        sx={{
          '@media (max-width: 767px)': {
            pl: 0.5,
            pr: 0.5,
          },
        }}
      >
        {resourceIds.map((id) => (
          <Material key={id} materialId={id} />
        ))}
      </Box>
    </ScrollBox>
  );
};

export default memo(RelatedMaterials, (prevProps, nextProps) => isEqual(prevProps, nextProps));
