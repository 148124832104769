import { memo, useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, DialogTitle, Divider, ListItem, Modal, ModalClose, ModalDialog, Radio, Typography } from '@mui/joy';

import { useDispatcher } from 'store/utils/redux/hooks';
import { formatCurrency } from 'utils';

import * as modalStore from 'widgets/modals/store';
import useCostsWithPromo from 'widgets/Onboarding/model/useCostsWithPromo';

import StripeElementsWrapper from 'components/StripeElementsWrapper';
import Alert from 'components/Alert';

import SubscriptionContent from '../modals/SubscriptionContent';
import usePlan from '../model/usePlan';

import PromoInput, { type OnPromoInputEventHandler, type PromoDataType } from '../ui/PromoInput';

export type SubscriptionParams = {
  planId: string;
};

const Subscription = () => {
  const dispatcher = useDispatcher();
  const { isOpen, params } = useSelector(modalStore.selectors.state('Subscription'));
  const [promo, setPromo] = useState<(PromoDataType & { code: string }) | null>(null);
  const { plan } = usePlan(params?.planId);
  const costMap = useCostsWithPromo(promo);
  const currentCost = costMap[params?.planId];

  const handleModalClose = useCallback(async () => {
    dispatcher.modal.close('Subscription');
    setPromo(null);
  }, []);

  const handlePromoEvent: OnPromoInputEventHandler = useCallback((event) => {
    if (event.type === 'applied') {
      setPromo({ ...event.data, code: event.code });
    }
    if (event.type === 'error') {
      Alert.error(event.error);
    }
  }, []);

  return (
    <Modal open={isOpen} onClose={handleModalClose}>
      <StripeElementsWrapper>
        <ModalDialog
          variant="soft"
          size="lg"
          sx={{
            maxWidth: 600,
            minWidth: 420,
            '--ModalClose-radius': '1.125em',
          }}
        >
          <ModalClose onClick={handleModalClose} />
          <DialogTitle sx={{ mt: -1 }}>Upgrading to {plan?.name}</DialogTitle>
          {!!plan && (
            <Box mb={2}>
              <Box display="flex">
                <Typography>IKI {plan.identity.label}</Typography>
                <Box flex={1} />
                {currentCost.type === 'regular' && <Typography component="span">{`${formatCurrency(currentCost.value)} / mo`}</Typography>}
                {currentCost.type === 'special' && (
                  <Box textAlign="right">
                    <Typography>{`${formatCurrency(currentCost.promo)} / mo for first ${promo?.durationInMonths} months`}</Typography>
                    <Typography color="neutral" level="body-xs">{`${formatCurrency(currentCost.value)} / mo thereafter`}</Typography>
                  </Box>
                )}
              </Box>
              <Box display="flex" mt={1}>
                <Typography fontWeight={500}>Total</Typography>
                <Box flex={1} />
                {currentCost?.type === 'regular' && !promo && (
                  <Typography fontWeight={500}>{formatCurrency(currentCost.value * 12)} billed yearly</Typography>
                )}
                {currentCost?.type === 'special' && promo && (
                  <Box textAlign="right">
                    <Typography fontWeight={500}>
                      <Typography color="primary">
                        {formatCurrency(currentCost.promo * promo.durationInMonths + currentCost.value * (12 - promo.durationInMonths))}
                      </Typography>{' '}
                      billed for the first year
                    </Typography>
                    <Typography color="neutral" level="body-xs">
                      {formatCurrency(currentCost.value * 12)} annually thereafter
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          )}
          <PromoInput isApplied={!!promo} onEvent={handlePromoEvent} />
          <Divider />
          <SubscriptionContent planId={params?.planId} promoCode={promo?.code} />
        </ModalDialog>
      </StripeElementsWrapper>
    </Modal>
  );
};

export default memo(Subscription);
