import { type DocumentType } from 'app/entities';
import { select } from 'store/utils/saga/effects';
import * as userStore from 'store/nodes/user';

function* prepareDocument<T = DocumentType | DocumentType[]>(note: T) {
  const user = yield* select(userStore.selectors.getMy);
  const notes = Array.isArray(note) ? note : [note];
  const result: DocumentType[] = notes.map((data) => ({
    ...data,
    isMy: user?.id === data?.user?.id,
  }));
  if (!Array.isArray(note)) {
    return result[0] as T;
  }
  return result as T;
}

export default prepareDocument;
